import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col ,Select, DatePicker, Input, Upload  } from "antd";
import { Tabs } from "antd";
import { PlusOutlined } from '@ant-design/icons';
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { training_api } from "../../library/training_api";
import { training_review_api } from "../../library/training_review_api";
import { region_api } from "../../library/region_api";
import { certificatebody_api } from "../../library/certificatebody_api";
import { country_api } from "../../library/country_api";
import { states_api } from "../../library/states_api";
import { payment_api} from '../../library/payment_api';
import { location_api } from "../../library/location_api";
import { venue_api } from "../../library/venue_api";
import { permission_api } from "../../library/permission_api";
import { dashboard_api } from "../../library/dashboard_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import { find } from 'lodash'
// added
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import FormDateGroup from "../part/Form/FormDateGroup";
import FormTimeGroup from "../part/Form/FormTimeGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import { country_array } from "../local_data/variousCountryListFormats";
import { timezone_array } from "../local_data/timezones";
import { currency_array } from "../local_data/currency";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { get_data } from "../server_data/get_data";
import MyLoading from "../part/MyLoading";
import FormRadioGroup from "../part/Form/FormRadioGroup";
import AddNew from "../part/AddNew";
import AddNewImport from "../part/AddNewImport";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import { back_api } from "../../library/back_api";
import Reviews from "./Reviews";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faTrash, faEnvelope, faUserPlus, faPlusCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
const { TabPane } = Tabs;

// ===============================================================================
class Evaluation_result extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      trainingreviewimport: "",
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        course_name: { valMet: vj_val.checkLength, valLen: 1 },
        languages: { valMet: vj_val.checkLength, valLen: 1 },
        duration: { valMet: vj_val.checkNumber, valLen: 1 },
        region: { valMet: vj_val.checkLength, valLen: 1 },
        start_date: { valMet: vj_val.checkLength, valLen: 1 },
        start_time: { valMet: vj_val.checkLength, valLen: 1 },
        end_time: { valMet: vj_val.checkLength, valLen: 1 },
        // result_publish_start: { valMet: vj_val.checkLength, valLen: 1 },
        // result_publish_end: { valMet: vj_val.checkLength, valLen: 1 },
        // certificate_publish_start: { valMet: vj_val.checkLength, valLen: 1 },
        // certificate_publish_end: { valMet: vj_val.checkLength, valLen: 1 },
        delivery_method: { valMet: vj_val.checkLength, valLen: 1 },
        seats: { valMet: vj_val.checkNumber, valLen: 1 },
        timezone: { valMet: vj_val.checkLength, valLen: 1 },
        currency: { valMet: vj_val.checkLength, valLen: 1 },
        course_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        certificate_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        // instructor_1 : { valMet: vj_val.checkLength, valLen: 1 },
        // instructor_2 : { valMet: vj_val.checkLength, valLen: 1 },
        // partner : { valMet: vj_val.checkLength, valLen: 1 },
        // exam: { valMet: vj_val.checkLength, valLen: 1 },
        // repeat_exam: { valMet: vj_val.checkLength, valLen: 1 },
        // evaluation_template: { valMet: vj_val.checkLength, valLen: 1 },
        lca_template: { valMet: vj_val.checkLength, valLen: 1 },
        // pre_course_template: { valMet: vj_val.checkLength, valLen: 1 },
        
        // ============================================================
        // ============================================================
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
      uploadFileList: [],
      country:[],
      certificate_allow:"No",
      evaluation_allow:"No",
      exam_allow:"No",
      lca_allow:"No",
      serchTxt: "",
      startDt:"",
      endDt:"",
	  countryList:[],
      stateList:[],
      states:[],
      delivery_method : [],
      region:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }

  
  displayFields = {
    sno: {
      title: "SN",
      width: 60,
      defaultSortOrder: "descend",
    
      order: 0,
    },
    course_name: {
      title: "Course Name",
    width:400, 
      className: "course_name",
  
      order: 1,
      custom: (text, record) => this.state.course2[text],
    },
    regionName: {
      title: "Region",
      // width: 300,
      className: "region",
      order: 2,
     
      ellipsis: true,
    },
    start_date: {
      title: "Start Date",
      // width: 300,
      className: "start_date",
      order: 3,
      ellipsis: true,
    },
    languages: {
        title: "Language",
        // width: 300,
        className: "language",
        order: 6,
        ellipsis: true,
        custom: (text, record) => {
          return text.split(",").map((t) => this.state.language2[t]);
        },
      },
    seats: {
      title: "Booked Seats",
      // width: 300,
      className: "seats",
      order: 7,
      ellipsis: true,
      custom: (text, record) => {
        return <>
        <p>{record.totalOrder}</p>
        </>
    }
    },
    ended_on: {
      title: "Status",
      // width: 300,
      className: "Status",
      order: 8,
      ellipsis: true,
      custom: (text, record) => {
        let currentDt = new Date()
        if(currentDt > new Date(text)){
           return <span className="text-success">Completed</span>
        }else{
          return <span className="text-primary">Not yet completed</span>
        }
        
      }
    },
    totalEvalau: {
        title: "Evalaution ratio",
        // width: 300,
        className: "Status",
        order: 9,
        ellipsis: true,
        custom: (text, record) => {
            return `${record.totalEvalau}/${record.totalOrder}`
        }
      },
 
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi, validation_info } = this.state;
    let queryParms = new URLSearchParams(window.location.search);
    let type = queryParms.get("pageFor")
        //============= define permission =============
        let { loggedUser } = this.props
        if(loggedUser.roleId){
         const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"training";
         let modulePermission = await myAxios({
           method: "get",
           url,
           header: { Chabi },
         });
        if(modulePermission.status == 200){
             if(modulePermission.data.records.length >= 1){
               console.log("ROLE APPLIED")
               let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
               this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
             }else{
               console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
             }
        }else{
         console.log("PERMISSION API CRASHED")
        }
        }
   
       //=============================================
    
    const url = `${training_api}/training_list/dasboardEvalaution/getAllTraningPostInEvalautionDashboard/post?data_for=${loggedUser.role}&id=${loggedUser.email}`;
    const trainingResponse = await myAxios({
      method: "post",
      url,
      header: { Chabi },
    });

    const url1 = `${dashboard_api}/evalautionResult`;
    const evalautionResult = await myAxios({
      method: "post",
      url: url1,
      header: { Chabi },
      request: {type :  type == undefined || type == 2 ? 1 : 2}

    });
	
	// const certificateBodyUrl = certificatebody_api + "dropdown/get";
  //   const certificateBodyResponse = await myAxios({
  //     method: "get",
  //     url:certificateBodyUrl,
  //     header: { Chabi },
  //   });
	
    const urlGet = country_api+"dropdown/country/traning";
    const countryList = await myAxios({
      method: "get",
      url: urlGet,
      header: { Chabi },
    });



    const response = await get_data({
      others: [
        "delivery_method",
        "learning_scheme",
        "course",
        "language",
        "location",
        "region",
        // "venue",
        // "organization",
        // "consultant",
        // "Partner",
        // "Exam_template",
        // "evaluation_template",
        // "lca_template",
        // "pre_course_template",
        // "certificate_template",
        // "delivery_method_notonline",
      ],
      primary: "",
      extra: { type: "normal" },
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          delivery_method,
          learning_scheme,
          course,
          language,
          location,
          region,
          // venue,
          // organization,
          // consultant,
          // Partner,
          // Exam_template,
          // evaluation_template,
          // lca_template,
          // pre_course_template,
          // certificate_template,
        } = data;

        let mainRecords = []
       let trainingResponsefinal = trainingResponse.data.records.map(_ => {
        let records = evalautionResult.data.records.filter((r) => r.training_id == _._id);
        if(records.length > 0){
          mainRecords.push({ ..._, totalEvalau: records.length})
        }
       
        return { ..._, totalEvalau: records.length}
      })

    //   let records = add_sno(trainingResponse.data.records);
      let records = add_sno(mainRecords);
        // let location2 = create_object({
        //   arr: location,
        //   title: "location_name",
        // });
        // let venue2 = create_object({
        //   arr: venue,
        //   title: "venue_name",
        // });
        // let organization2 = create_object({
        //   arr: organization,
        //   title: "organization_name",
        // });
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
      //    organization2,
        //  organization,
          learning_scheme,
          learning_scheme2,
          course,
          course2,
          language,
          language2,
          delivery_method,
          delivery_method2,
          region,
          region2,
          records,
         // venue2,
         // venue,
          // location,
          // location2,
          // consultant,
          // Partner,
          // Exam_template,
          // evaluation_template,
          // lca_template,
          // pre_course_template,
          // certificate_template,
		  // certificatebody:certificateBodyResponse.data.records,
		  countryList: countryList.data.records
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  // on file upload
  fileUpload = async (fl) => {
    const { request, uploadFileList, Chabi } = this.state;
    let { course_name, regionId} = request;
    const { file } = fl;
    const url = payment_api + "image_upload";
    const formData = new FormData();
    formData.append("type", "pdf"); //notice here
    formData.append("itemName", `${course_name}_${regionId}`); //notice here
    formData.append("folder", `${course_name}/${regionId}/propsalPdf`); //notice here
    formData.append("file", file.originFileObj, file.originFileObj.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 200){
        const { data: locationData } = data;
        this.setRequest({ name: "proposalImage", value: locationData.location });
        this.setState({ uploadFileList: locationData.location });
      }else{
        return message.error("image not uploaded")
      }
      
    }
  };

  addTrainingReview = async () => {
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = training_review_api;
    //const url = training_review_api + "/training_review";

    console.log("training_id11", this.state);
    console.log("training_id1", this.state.trainingreviewimport);
    console.log("training_id", this.state.selectedRecord._id);
    let form_data = new FormData();
    //form_data.append('trainingreviewimport', this.state.trainingreviewimport);
    form_data.append("training_id", this.state.selectedRecord._id);
    form_data.append(
      "trainingreviewimport",
      this.state.trainingreviewimport.originFileObj,
      this.state.trainingreviewimport.name
    );
    console.log("form_data", form_data);
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: form_data,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
		this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          //cmodal: false,
		  cmodali: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");

    /*try {
            var {data} = await Axios.get(Constant.apiBasePath + 'exportChapterData');
            if(data.status === Constant.statusSuccess) {
                var csvString = data.data;
                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
                a.setAttribute('download', 'chapters.csv');
                window.document.body.appendChild(a);
                a.click();
                window.location.reload();
            }
            else {
                alert(data.message);
                return false;
            }
        }
        catch(error) {
            console.log(error);
        }*/
  };
 
  nextManage = ({ activeKey }) => {
  
    if (activeKey == "2") {
      const fields = [
        "course_name",
        "region",
        "languages",
        "duration",
        "start_date",
        "start_time",
        "end_time",
        // "result_publish_start",
        // "result_publish_end",
        // "certificate_publish_start",
        // "certificate_publish_end",
      ];
      const { val_result, reason } = this.checkNextValidation({
        fields,
      });
      // this.checkRegionValidation();
      if (val_result) this.setState({ activeKey: "2" });
      else this.setState({ validation: reason });
    } else if(activeKey == "1"){
      this.setState({ activeKey: "1" });
    }else if(activeKey == "3"){
      // const fields = [
      //   "course_name",
      //   "region",
      //   "languages",
      //   "duration",
      //   "start_date",
      //   "start_time",
      //   "end_time",
      //   "result_publish_start",
      //   "result_publish_end",
      //   "certificate_publish_start",
      //   "certificate_publish_end",
      //   "delivery_method",
      //   "seats",
      //   "timezone",
      //   "currency",
      //   "course_fees",
      //   "certificate_fees",
      // ];
      // const { val_result, reason } = this.checkNextValidation({
      //   fields,
      // });
      
      // if (val_result) this.setState({ activeKey: "3" });
      // else this.setState({ validation: reason });
      this.setState({ activeKey: "3" });
    }else{
      // const fields = [
      //   "course_name",
      //   "region",
      //   "languages",
      //   "duration",
      //   "start_date",
      //   "start_time",
      //   "end_time",
      //   "result_publish_start",
      //   "result_publish_end",
      //   "certificate_publish_start",
      //   "certificate_publish_end",
      //   "delivery_method",
      //   "seats",
      //   "timezone",
      //   "currency",
      //   "course_fees",
      //   "certificate_fees",
      //   "instructor_1",
      //   "instructor_2",
      //   "partner",
      // ];
      // const { val_result, reason } = this.checkNextValidation({
      //   fields,
      // });
    
      // if (val_result) this.setState({ activeKey: "4" });
      // else this.setState({ validation: reason });
      const { request, certificatebody } = this.state;
      if(request.certificatebody != undefined){
        const certificateRecord = certificatebody.filter((r) => r._id == request.certificatebody)[0];
        let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
        request["certificatebody"] = request.certificatebody;
        this.setState({ request , certificate_allow, evaluation_allow,exam_allow, lca_allow});
      }
      this.setState({ activeKey: "4" });
    }
  };

  renderEdit = async (id) => {
    let {Chabi, records} = this.state

    const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
   
    const record = records.filter((r) => r._id == id)[0];
    const regionUrl = region_api + "/region/country/"+record.region;
    const certificateRecord = certificateBodyResponse.data.records.filter((r) => r._id == record.certificatebody)[0];
    const countryResponse = await myAxios({ method: "get", url:regionUrl, header: { Chabi }});
    this.setState({ country: countryResponse.data.records})
    if(certificateRecord){
      let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
      this.setState({ certificatebody: certificateBodyResponse.data.records, certificatebody:certificateBodyResponse.data.records, certificate_allow, evaluation_allow,exam_allow, lca_allow})
    }else{
      this.setState({ certificatebody: certificateBodyResponse.data.records, certificatebody:certificateBodyResponse.data.records, certificate_allow:'No', evaluation_allow:'No',exam_allow:'No', lca_allow:'No'})
    }
  }

  renderAssementExamination = async (obj) => {
    const { name, value } = obj;
    const { Chabi, certificatebody } = this.state;
    const certificateRecord = certificatebody.filter((r) => r._id == value)[0];
    let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
   
    const { request } = this.state;
    request[name] = value;
    this.setState({ request , certificate_allow, evaluation_allow,exam_allow, lca_allow});
  }
  
  fiterResult = async () => {
    this.setState({ CDM: false });
    let { loggedUser } = this.props
    const {startDt, endDt, country, serchTxt,delivery_method_value, region_value } = this.state
    const { doUserLogout, Chabi } = this.state;
   // const url = `${training_api}/training_list/post`;
    const url = `${training_api}/training_list/dasboard/post?data_for=${loggedUser.role}&id=${loggedUser.email}`;

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {startDt, endDt, country, serchTxt, delivery_method_value, region: region_value}
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(records);
        this.setState({ records })
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }

  countryHandler = async (value) => {
    const { request } = this.state;   
      let stateList = []
      let { Chabi , records} = this.state;
      const urlGet = states_api+"dropDown/get?countryCode="+value;
        const countryList = await myAxios({
          method: "get",
          url: urlGet,
          header: { Chabi },
        });
        stateList = countryList.data.records;
        let data = request;
        delete data['states']
        this.setState({ country : value, stateList });
  }

  setRequest = (obj) => {
    const { name, value } = obj;
    const { filtered_records, request, validation_info } = this.state;
    if(name == "delivery_method"){
      if(value == "623afef06fbc25650029f46e" || value == "623afee36fbc25650029f46c"){
             this.setState({
               validation_info : { 
                 ...validation_info, 
                 country: { valMet: vj_val.checkLength, valLen: 1 },
                 states: { valMet: vj_val.checkLength, valLen: 1 },
                 location: { valMet: vj_val.checkLength, valLen: 1 },
                 venue: { valMet: vj_val.checkLength, valLen: 1 },
           }
         })
      }else{
        this.setState({ validation_info });
      }

    }
    request[name] = value;
    this.setState({ request });
  };

  renderState = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;

     let stateUrl = states_api + "/dropdown/gets/"+value;
     const stateResponse = await myAxios({
      method: "get",
      url: stateUrl,
      header: { Chabi },
    });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , states: stateResponse.data.records});
  }

  renderLocation = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;
    let url = location_api + "/countryBy?stateId="+value;
     const locationResponse = await myAxios({
       method: "post",
       url,
       header: { Chabi },
     });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , location: locationResponse.data.records});
  }

// on edit render venue
  rendervenue = async (obj) => {
    const { name, value } = obj;
    const { Chabi, record } = this.state;
    let venueUrl = venue_api + "/countryBy?location="+value;
     const venueResponse = await myAxios({
       method: "post",
       url: venueUrl,
       header: { Chabi },
     });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request, venue: venueResponse.data.records})
  }

  renderCertificateBody = async (obj) => {
    const { Chabi, records, validation_info } = this.state;
    const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
    let recordsDt = records.filter((r) => r._id == obj)[0];
    let coutry = recordsDt.country;
    let location = recordsDt.location;
    if(recordsDt.delivery_method == "623afef06fbc25650029f46e" || recordsDt.delivery_method == "623afee36fbc25650029f46c"){
      this.setState({
        validation_info : { 
          ...validation_info, 
          country: { valMet: vj_val.checkLength, valLen: 1 },
          states: { valMet: vj_val.checkLength, valLen: 1 },
          location: { valMet: vj_val.checkLength, valLen: 1 },
          venue: { valMet: vj_val.checkLength, valLen: 1 },
    }
  })
}else{
 this.setState({ validation_info });
}

    this.renderState({name:"country", value:coutry})
    this.rendervenue({name: "location", value:location})
    const certificateRecord = certificateBodyResponse.data.records.filter((r) => r._id == obj)[0];
    if(certificateRecord){
      let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
      this.setState({ certificatebody:certificateBodyResponse.data.records, certificate_allow, evaluation_allow,exam_allow, lca_allow})
    }else{
      this.setState({ certificatebody:certificateBodyResponse.data.records, certificate_allow:'No', evaluation_allow:'No',exam_allow:'No', lca_allow:'No'})
    }
  }


  render() {
	  const { RangePicker } = DatePicker;
    console.log("training State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      cmodali,
      request,
      filtered_records,
      learning_scheme,
      language,
      region,
      activeKey,
      seomodal,
      course,
      venue,
      location,
      delivery_method,
      nextTabMsg,
      organization,
      CDM,
      CDMI,
      coffcanvas,
      selectedRecord,
      organization2,
      region2,
      course2,
      delivery_method2,
      language2,
      consultant,
      Partner,
      Exam_template,
      evaluation_template,
      lca_template,
      pre_course_template,
      certificate_template,
	    country,
      examination_fee,
	    certificatebody,
      certificate_allow, 
      evaluation_allow,
      exam_allow, 
      lca_allow,
	  countryList,
      stateList,
      states,
      permission
    } = this.state;

    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    // columns.push({
    //   //width: 100,
    //   title: "Tranining Id",
    //   dataIndex: "_id",
    // });

    if(permission.childModule && permission.childModule?.seo){
    columns.push({
      //width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
  }

  let { loggedUser } = this.props


  if(permission.childModule){
    columns.push({
      title: "Details",
      className: "details",
       width: 100,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <>
          {permission.childModule?.details ? 
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              const url = back_api + "/training_details";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord._id },
                header: { Chabi },
              });
              let learner = {};
              let bulk_order = {};
              if (response.status == 200) {
                const { data } = response;
                learner = data.learner;
                bulk_order = data.bulk_order;
              }
              this.setState({
                coffcanvas: true,
                selectedRecord: {
                  ...selectedRecord,
                  extra: { bulk_order, learner },
                },
              });
            }}
            className="btn btn-outline-primary btn-sm"
          >
            Details
          </span> : null}
          {permission.childModule?.learner ?  <Link to={`/tranning_learner?training=${btoa(btoa(selectedRecord._id))}&orderType=${btoa(btoa("1"))}`}><button className="btn btn-sm btn-primary mt-1">Learners</button></Link> : null }
          {permission.childModule?.organization ? <Link to={`/tranning_learner?training=${btoa(btoa(selectedRecord._id))}&orderType=${btoa(btoa("2"))}`}><button className="btn btn-sm btn-primary mt-1">Organization</button></Link> : null }
          <Link to={`/evalaution_result_update?searchTxt=${selectedRecord._id}`}><button className="btn btn-sm btn-primary mt-1">Evalaution List</button></Link>
          </>
        );
      },
    });
  }
   
    const beforeUpload = (file) => {
      console.log("sdds",file)
      const isJpgOrPng = file.type === 'application/pdf';
      if (!isJpgOrPng) {
        message.error('You can only upload PDF file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );

    return (
      <>
        <PageHeading2 active="Evalaution Result" page={["Evalaution Result"]}>
          {/* <AddNew
            disabled={!CDM}
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          /> */}
        </PageHeading2>
        <MainArea>
          
		 
<Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{marginLeft: 10}}>
              <label>Training Window</label><br />
              <RangePicker onChange={(e) => this.dateChanger(e)}/>
            </Col>
            <Col span={8} style={{marginLeft: 10}}>
            <label>Search by Course Name.</label>
            <Input placeholder="Search by Course Name" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Delivery Method</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Delivery Method"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ delivery_method_value : e})}
               options={delivery_method.map( rec => {return { label:rec.title , value:rec._id }})}
            />
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Country</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Country"
              //  defaultValue={[""]}
               onChange={(e) => this.countryHandler(e)}
               options={countryList.map( rec => {return { label:rec.name , value:rec.isoCode }})}
            />
            </Col>


            <Col span={6} style={{marginLeft: 10}}>
              <label>Region</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Region"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ region_value : e})}
               options={region.map( rec => {return { label:rec.region_name , value:rec._id }})}
            />
            </Col>

          {/* end of filter sections */}
          </Row>
          {/* end of filter sections */}
          <Row>
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()}>
                Submit
              </button>
            </Col>
          </Row>


          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}

              // loadData={permission.update ? (id) => {
              //   this.renderCertificateBody(id)
              //   this.renderEdit(id); 
              //   this.loadRecord(id)
              // }  : null}
              // removeData={permission.itemRemoved ? this.removeRecord : null}
              // activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodali}
          title="Import Training Review"
          close={this.close_modal_import}
          className="modal-form-course sws_training_pdf"
        >
          <FormContainer>
            <Row>
              <Col span={8}>
                {/* <FormInputGroup
                      type="file"
                      id="trainingreviewimport"
                      name="trainingreviewimport"
                      label="Upload Training Review Excel"
                      placeholder="Upload Training Review Excel"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    /> */}
                <FormFileUploadGroup
                  type="file"
                  id="trainingreviewimport"
                  name="trainingreviewimport"
                  label="Upload Training Review Excel"
                  placeholder="Upload Training Review Excel"
                  data={this.state}
                  required
                  fileUpload={this.fileUpload}
                />
              </Col>
            </Row>
            <FormButton
              submit={() => {
                //check validation status here
                this.addTrainingReview();
              }}
              submit_text="Submit"
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal_import}
            />
          </FormContainer>
        </CustomModal>
       
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      course_name,
                      certificate_fees,
                      course_fees,
                      currency,
                      delivery_method,
                      duration,
                      languages,
                      region,
                      material_fees,
                      seats,
                      start_date,
                      start_time,
                      timezone,
                    } = selectedRecord;
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {course2[course_name]}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">Seats</span>
                            <span className="d-block info-value">{seats}</span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Start Date
                            </span>
                            <span className="d-block info-value">
                              {start_date}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Start_time
                            </span>
                            <span className="d-block info-value">
                              {start_time}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Timezone
                            </span>
                            <span className="d-block info-value">
                              {timezone}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">Region</span>
                            <span className="d-block info-value">
                              {region2[region]}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Course_fees
                            </span>
                            <span className="d-block info-value">
                              {course_fees}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Certificate_fees
                            </span>
                            <span className="d-block info-value">
                              {certificate_fees}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">
                              Material_fees
                            </span>
                            <span className="d-block info-value">
                              {material_fees}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Delivery_method
                            </span>
                            <span className="d-block info-value">
                              {delivery_method}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Languages
                            </span>
                            <span className="d-block info-value">
                              {languages}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">
                             Duration
                            </span>
                            <span className="d-block info-value">
                              {duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
            
             
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
       
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Evaluation_result);
