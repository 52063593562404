import React from "react";
import { Row, Col } from "antd";
function FormContainer(props) {
  return (
    <Row>
      <Col span={24}>{props.children}</Col>
    </Row>
  );
}
export default FormContainer;
