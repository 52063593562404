// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_col__jn07b {
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
}
.styles_b__joBf- ol {
  padding-left: 20px;
  background-color: unset;
  border-radius: 0;
}
.styles_b-item__Sdiz3 {
  font-size: 14px;
  font-weight: 500;
}
.styles_active__Fz4xD a{
  color: rgb(34, 32, 32) !important;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/PageHeading2/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,iCAAiC;AACnC","sourcesContent":[".col {\n  height: 40px;\n  line-height: 40px;\n  background-color: #ffffff;\n}\n.b ol {\n  padding-left: 20px;\n  background-color: unset;\n  border-radius: 0;\n}\n.b-item {\n  font-size: 14px;\n  font-weight: 500;\n}\n.active a{\n  color: rgb(34, 32, 32) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col": `styles_col__jn07b`,
	"b": `styles_b__joBf-`,
	"b-item": `styles_b-item__Sdiz3`,
	"active": `styles_active__Fz4xD`
};
export default ___CSS_LOADER_EXPORT___;
