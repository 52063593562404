import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col ,Select, DatePicker, Input, Upload  } from "antd";
import { Tabs } from "antd";

import { training_api } from "../../library/training_api";
import { permission_api } from "../../library/permission_api";
import { dashboard_api } from "../../library/dashboard_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { add_sno, create_object } from "../../library/object_functions";
import { get_data } from "../server_data/get_data";
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import MyLoading from "../part/MyLoading";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";


const { TabPane } = Tabs;

// ===============================================================================
class Exam_result_listing extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      trainingreviewimport: "",
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
      uploadFileList: [],
      country:[],
      serchTxt: "",
      startDt:"",
      endDt:"",
	    countryList:[],
      stateList:[],
      states:[],
      delivery_method : [],
      region:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      },
      rating: "",
      learning_scheme: [],
      filter_scheme: "",
      filter_region: ""
    };
  }

  displayFields = {
    sno: {
      title: "SN",
      width: 30,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    trainingName: {
      title: "Course Name",
       width:100, 
      className: "course_name",
      sorter: true,
      order: 1,
    },
    templateName: {
        title: "Exam Name",
        width: 100,
        className: "templateName",
        order: 2,
        sorter: true,
    },
    trainingDate: {
      title: "Start Date",
      width: 50,
      className: "start_date",
      order: 3,
      ellipsis: true,
    },
    learnerName: {
      title: "Learner",
      width: 80,
      className: "start_date",
      order: 4,
      ellipsis: true,
      custom: (text, record) => {
        return <span>{record.learnerName} {record.learnerLastName}</span>
      }
    },
    learnerEmail: {
      title: "Learner Email",
      width: 100,
      className: "learnerEmail",
      order: 5,
      ellipsis: true,
    },
    total_exam_marks: {
        title: "Marks obtained",
        width: 50,
        className: "rating",
        order: 6,
        ellipsis: true,
        custom: (text, record) => {
            return <span>{record.total_exam_marks}/{record.total_min_grade}</span>
          }
      },
      percent: {
        title: "Marks obtained(%)",
        width: 55,
        className: "rating",
        order: 7,
        ellipsis: true,
        custom: (text, record) => {
            return parseInt(text)
          }
      },
      
      
    }
   
  componentDidMount = async () => {
    const { doUserLogout, Chabi, validation_info } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        // if(loggedUser.roleId){
        //  const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"training";
        //  let modulePermission = await myAxios({
        //    method: "get",
        //    url,
        //    header: { Chabi },
        //  });
        // if(modulePermission.status == 200){
        //      if(modulePermission.data.records.length >= 1){
        //        console.log("ROLE APPLIED")
        //        let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
        //        this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
        //      }else{
        //        console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
        //      }
        // }else{
        //  console.log("PERMISSION API CRASHED")
        // }
        // }
   
       //=============================================
    
       const response = await get_data({
         others: [
           "learning_scheme",
           "delivery_method",
           "region",
         ],
         primary: "",
         Chabi,
       });
       if (response.status == 200) {
         const { data } = response;
         if (data.status == 420) this.setLogout();
         else if (data.status == 200) {
           let {
             learning_scheme,
             delivery_method,
             region,
           } = data;
           let learning_scheme2 = create_object({
             arr: learning_scheme,
             title: "main_head",
           });
           let delivery_method2 = create_object({
             arr: delivery_method,
             title: "title",
           });
           let region2 = create_object({ arr: region, title: "region_name" });
           this.setState({
             learning_scheme,
             learning_scheme2,
             delivery_method,
             delivery_method2,
             region,
             region2,
           });
         }
       } else console.log("Network Error");
    
    const url = `${dashboard_api}/examResult`;
    const trainingResponse = await myAxios({
      method: "post",
      url,
      header: { Chabi },
    });
    if(trainingResponse.data.status == 200){
        let { records } = trainingResponse.data
        records = add_sno(records);
	    this.setState({ records })
    }
    
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  
  fiterResult = async () => {
    this.setState({ CDM: false });
    let { loggedUser } = this.props
    const {startDt, endDt, rating, serchTxt, filter_scheme, filter_region } = this.state
    const { doUserLogout, Chabi } = this.state;
   // const url = `${training_api}/training_list/post`;
    // const url = `${training_api}/training_list/post?data_for=${loggedUser.role}&id=${loggedUser.email}`;
    const url = `${dashboard_api}/examResult`;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {startDt, endDt, rating, serchTxt, filter_scheme, filter_region}
    });
    if (response.status == 420) this.setLogout();
    if (response.status == 200) {
        let { records } = response.data
        records = add_sno(records);
	    this.setState({ records })
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }


  setRequest = (obj) => {
    const { name, value } = obj;
    const {  request } = this.state;
    request[name] = value;
    this.setState({ request });
  };





  render() {
	  const { RangePicker } = DatePicker;
    console.log("training State ", this.state);
    let {
      records,
      loginStatus,
      learning_scheme,
      region,
      filtered_records,
      delivery_method,
      CDM,
    } = this.state;

    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    return (
      <>
        <PageHeading2 active="Exam Result List" page={["Exam Result List"]}>
         
        </PageHeading2>
        <MainArea>
          
         <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{marginLeft: 10}}>
              <label>Training Start Date</label>
              <RangePicker onChange={(e) => this.dateChanger(e)} style={{width: 381}}/>
            </Col>
            <Col span={8} style={{marginLeft: 10}}>
            <label>Search by Course & learner Name</label>
            <Input placeholder="Search by Course & learner Name" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>
          {/* end of filter sections */}

            <Col span={6} style={{marginLeft: 10}}>
              <label>Scheme</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose Scheme"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_scheme : e})}
               options={learning_scheme.map(_ => { return { value : _._id, label: _.main_head}})}
            />
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Region</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Region"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_region : e})}
               options={region.map( rec => {return { label:rec.region_name , value:rec._id }})}
            />
            </Col>

          </Row>
          {/* end of filter sections */}
          <Row>
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()}>
                Submit
              </button>
            </Col>
          </Row>


          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}

              // loadData={ this.loadRecord}
            //   removeData={permission.itemRemoved ? this.removeRecord : null}
            //   activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Exam_result_listing);
