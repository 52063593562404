import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col ,Select, DatePicker, Input, Upload  } from "antd";
import { Tabs } from "antd";
import { PlusOutlined } from '@ant-design/icons';
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { training_api } from "../../library/training_api";
import { training_review_api } from "../../library/training_review_api";
import { region_api } from "../../library/region_api";
import { certificatebody_api } from "../../library/certificatebody_api";
import { country_api } from "../../library/country_api";
import { states_api } from "../../library/states_api";
import { payment_api} from '../../library/payment_api';
import { location_api } from "../../library/location_api";
import { venue_api } from "../../library/venue_api";
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import { find } from 'lodash'
// added
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import FormDateGroup from "../part/Form/FormDateGroup";
import FormTimeGroup from "../part/Form/FormTimeGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import { country_array } from "../local_data/variousCountryListFormats";
import { timezone_array } from "../local_data/timezones";
import { currency_array } from "../local_data/currency";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { get_data } from "../server_data/get_data";
import MyLoading from "../part/MyLoading";
import FormRadioGroup from "../part/Form/FormRadioGroup";
import AddNew from "../part/AddNew";
import AddNewImport from "../part/AddNewImport";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import { back_api } from "../../library/back_api";
import Reviews from "./Reviews";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faTrash, faEnvelope, faUserPlus, faPlusCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
const { TabPane } = Tabs;

// ===============================================================================
class Training extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      trainingreviewimport: "",
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        course_name: { valMet: vj_val.checkLength, valLen: 1 },
        languages: { valMet: vj_val.checkLength, valLen: 1 },
        duration: { valMet: vj_val.checkNumber, valLen: 1 },
        region: { valMet: vj_val.checkLength, valLen: 1 },
        start_date: { valMet: vj_val.checkLength, valLen: 1 },
        start_time: { valMet: vj_val.checkLength, valLen: 1 },
        end_time: { valMet: vj_val.checkLength, valLen: 1 },
        // result_publish_start: { valMet: vj_val.checkLength, valLen: 1 },
        // result_publish_end: { valMet: vj_val.checkLength, valLen: 1 },
        // certificate_publish_start: { valMet: vj_val.checkLength, valLen: 1 },
        // certificate_publish_end: { valMet: vj_val.checkLength, valLen: 1 },
        delivery_method: { valMet: vj_val.checkLength, valLen: 1 },
        seats: { valMet: vj_val.checkNumber, valLen: 1 },
        timezone: { valMet: vj_val.checkLength, valLen: 1 },
        currency: { valMet: vj_val.checkLength, valLen: 1 },
        course_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        certificate_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        // instructor_1 : { valMet: vj_val.checkLength, valLen: 1 },
        // instructor_2 : { valMet: vj_val.checkLength, valLen: 1 },
        // partner : { valMet: vj_val.checkLength, valLen: 1 },
        // exam: { valMet: vj_val.checkLength, valLen: 1 },
        // repeat_exam: { valMet: vj_val.checkLength, valLen: 1 },
        // evaluation_template: { valMet: vj_val.checkLength, valLen: 1 },
        lca_template: { valMet: vj_val.checkLength, valLen: 1 },
        // pre_course_template: { valMet: vj_val.checkLength, valLen: 1 },
        
        // ============================================================
        // ============================================================
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
      uploadFileList: [],
      country:[],
      certificate_allow:"No",
      evaluation_allow:"No",
      exam_allow:"No",
      lca_allow:"No",
      serchTxt: "",
      startDt:"",
      endDt:"",
	  countryList:[],
      stateList:[],
      states:[],
      delivery_method : [],
      region:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }

  
  displayFields = {
    sno: {
      title: "SN",
      width: 60,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    course_name: {
      title: "Course Name",
    width:250, 
      className: "course_name",
      sorter: true,
      order: 1,
      custom: (text, record) => this.state.course2[text],
    },
    regionName: {
      title: "Region",
      // width: 300,
      className: "region",
      order: 2,
      sorter: true,
      ellipsis: true,
    },
    start_date: {
      title: "Start Date",
      // width: 300,
      className: "start_date",
      order: 3,
      sorter: true,
      ellipsis: true,
    },
    
    course_fees: {
      title: "Course Fees",
      // width: 300,
      className: "course_fees",
      order: 4,
      sorter: true,
      ellipsis: true,
    },
    // certificate_fees: {
    //   title: "Certificate Fees",
    //   // width: 300,
    //   className: "certificate_fees",
    //   order: 5,
    //   sorter: true,
    //   ellipsis: true,
    // },
    seats: {
      title: "Seats",
      // width: 300,
      className: "seats",
      order: 6,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        return <>
        <p><b>Total: </b>{record.maxseats}</p>
        <p><b>Available: </b>{record.maxseats - record.totalOrder}</p>
        <p><b>Booked: </b>{record.totalOrder}</p>
        </>
    }
    },
    languages: {
      title: "Language",
      // width: 300,
      className: "language",
      order: 7,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        return text.split(",").map((t) => this.state.language2[t]);
      },
    },
   
    ended_on: {
      title: "Status",
      // width: 300,
      className: "Status",
      order: 8,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        let currentDt = new Date()
        if(currentDt > new Date(text)){
           return <span className="text-success">Completed</span>
        }else{
          return <span className="text-primary">Not yet completed</span>
        }
        
      }
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi, validation_info } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        if(loggedUser.roleId){
         const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"training";
         let modulePermission = await myAxios({
           method: "get",
           url,
           header: { Chabi },
         });
        if(modulePermission.status == 200){
             if(modulePermission.data.records.length >= 1){
               console.log("ROLE APPLIED")
               let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
               this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
             }else{
               console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
             }
        }else{
         console.log("PERMISSION API CRASHED")
        }
        }
   
       //=============================================
    
    const url = `${training_api}/training_list/post?data_for=${loggedUser.role}&id=${loggedUser.email}`;
    const trainingResponse = await myAxios({
      method: "post",
      url,
      header: { Chabi },
    });
	
	const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
	
    const urlGet = country_api+"dropdown/country/traning";
    const countryList = await myAxios({
      method: "get",
      url: urlGet,
      header: { Chabi },
    });



    const response = await get_data({
      others: [
        "delivery_method",
        "learning_scheme",
        "course",
        "language",
        "location",
        "region",
        "venue",
        "organization",
        "consultant",
        "Partner",
        "Exam_template",
        "evaluation_template",
        "lca_template",
        "pre_course_template",
        "certificate_template",
        "delivery_method_notonline",
      ],
      primary: "",
      extra: { type: "normal" },
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          delivery_method,
          learning_scheme,
          course,
          language,
          location,
          region,
          venue,
          organization,
          consultant,
          Partner,
          Exam_template,
          evaluation_template,
          lca_template,
          pre_course_template,
          certificate_template,
        } = data;

       let training_info = trainingResponse.data.records.filter((traing) => loggedUser.regionArr.includes(traing.region))
       let records = add_sno(training_info);
        let location2 = create_object({
          arr: location,
          title: "location_name",
        });
        let venue2 = create_object({
          arr: venue,
          title: "venue_name",
        });
        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
          organization2,
          organization,
          learning_scheme,
          learning_scheme2,
          course,
          course2,
          language,
          language2,
          delivery_method,
          delivery_method2,
          region,
          region2,
          records,
          venue2,
          venue,
          location,
          location2,
          consultant,
          Partner,
          Exam_template,
          evaluation_template,
          lca_template,
          pre_course_template,
          certificate_template,
		  certificatebody:certificateBodyResponse.data.records,
		  countryList: countryList.data.records
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  // on file upload
  fileUpload = async (fl) => {
    const { request, uploadFileList, Chabi } = this.state;
    let { course_name, regionId} = request;
    const { file } = fl;
    const url = payment_api + "image_upload";
    const formData = new FormData();
    formData.append("type", "pdf"); //notice here
    formData.append("itemName", `${course_name}_${regionId}`); //notice here
    formData.append("folder", `${course_name}/${regionId}/propsalPdf`); //notice here
    formData.append("file", file.originFileObj, file.originFileObj.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 200){
        const { data: locationData } = data;
        this.setRequest({ name: "proposalImage", value: locationData.location });
        this.setState({ uploadFileList: locationData.location });
      }else{
        return message.error("image not uploaded")
      }
      
    }
  };

  addTrainingReview = async () => {
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = training_review_api;
    //const url = training_review_api + "/training_review";

    console.log("training_id11", this.state);
    console.log("training_id1", this.state.trainingreviewimport);
    console.log("training_id", this.state.selectedRecord._id);
    let form_data = new FormData();
    //form_data.append('trainingreviewimport', this.state.trainingreviewimport);
    form_data.append("training_id", this.state.selectedRecord._id);
    form_data.append(
      "trainingreviewimport",
      this.state.trainingreviewimport.originFileObj,
      this.state.trainingreviewimport.name
    );
    console.log("form_data", form_data);
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: form_data,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
		this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          //cmodal: false,
		  cmodali: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");

    /*try {
            var {data} = await Axios.get(Constant.apiBasePath + 'exportChapterData');
            if(data.status === Constant.statusSuccess) {
                var csvString = data.data;
                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
                a.setAttribute('download', 'chapters.csv');
                window.document.body.appendChild(a);
                a.click();
                window.location.reload();
            }
            else {
                alert(data.message);
                return false;
            }
        }
        catch(error) {
            console.log(error);
        }*/
  };
  addTraining = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = training_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
          cmodali: false,
          activeKey: "1",
          nextTabMsg: "",
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editTraining = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      course_name,
      languages,
      region,
      delivery_method,
      country,
      location,
      venue,
      currency,
      seats,
      timezone,
      duration,
      course_fees,
      material_fees,
      print_material_fees,
      print_material_fees_30days,
      certificate_fees,
      start_time,
      end_time,
      start_date,
      result_publish_start,
      result_publish_end,
      certificate_publish_start,
      certificate_publish_end,
      releated_training,
      book_dis_fees_90,
      book_dis_fees_60,
      book_dis_fees_30,
      book_dis_fees_1,
      book_dis_public,
      book_dis_organization,
      instructor_1,
      instructor_2,
      instructor_3,
      partner,
      exam,
      repeat_exam,
      exam_2,
      repeat_exam_2,
      evaluation_template,
      lca_template,
      lca_template_2,
      examination_fee,
      pre_course_template,
      certificate_completion_template,
      certificate_attendance_template,
      certificatebody,
      class_date,
      proposalImage,
      minseats,
      maxseats
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = training_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        course_name,
        languages,
        region,
        delivery_method,
        country,
        location,
        venue,
        currency,
        seats,
        timezone,
        duration,
        course_fees,
        material_fees,
        print_material_fees,
        print_material_fees_30days,
        certificate_fees,
        start_time,
        end_time,
        start_date,
        // result_publish_start,
        // result_publish_end,
        // certificate_publish_start,
        // certificate_publish_end,
        releated_training,
        book_dis_fees_90,
        book_dis_fees_60,
        book_dis_fees_30,
        book_dis_fees_1,
        book_dis_public,
        book_dis_organization,
        instructor_1,
        instructor_2,
        instructor_3,
        partner,
        exam,
        repeat_exam,
        exam_2,
        repeat_exam_2,
        evaluation_template,
        lca_template,
        lca_template_2,
        // pre_course_template,
        certificate_completion_template,
        certificate_attendance_template,
        examination_fee,
        certificatebody,
        class_date,
        proposalImage,
        minseats,
        maxseats
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
          cmodali: false,
          activeKey: "1",
          nextTabMsg: "",
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["course_name"] = course_name;
            rec["languages"] = languages;
            rec["region"] = region;
            rec["delivery_method"] = delivery_method;
            rec["country"] = country;
            rec["location"] = location;
            rec["venue"] = venue;
            rec["currency"] = currency;
            rec["seats"] = seats;
            rec["maxseats"] = maxseats;
            rec["timezone"] = timezone;
            rec["duration"] = duration;
            rec["course_fees"] = course_fees;
            rec["material_fees"] = material_fees;
            rec["print_material_fees"] = print_material_fees;
            rec["print_material_fees_30days"] = print_material_fees_30days;
            rec["certificate_fees"] = certificate_fees;
            rec["start_time"] = start_time;
            rec["end_time"] = end_time;
            rec["start_date"] = start_date;
            rec["result_publish_start"] = result_publish_start;
            rec["result_publish_end"] = result_publish_end;
            rec["certificate_publish_start"] = certificate_publish_start;
            rec["certificate_publish_end"] = certificate_publish_end;
            rec["releated_training"] = releated_training;
            rec["book_dis_fees_90"] = book_dis_fees_90;
            rec["book_dis_fees_60"] = book_dis_fees_60;
            rec["book_dis_fees_30"] = book_dis_fees_30;
            rec["book_dis_fees_1"] = book_dis_fees_1;
            rec["book_dis_public"] = book_dis_public;
            rec["book_dis_organization"] = book_dis_organization;
            rec["proposalImage"] = proposalImage
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
          cmodali: false,
          activeKey: "1",
          nextTabMsg: "",
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${training_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  nextManage = ({ activeKey }) => {
  
    if (activeKey == "2") {
      const fields = [
        "course_name",
        "region",
        "languages",
        "duration",
        "start_date",
        "start_time",
        "end_time",
        // "result_publish_start",
        // "result_publish_end",
        // "certificate_publish_start",
        // "certificate_publish_end",
      ];
      const { val_result, reason } = this.checkNextValidation({
        fields,
      });
      // this.checkRegionValidation();
      if (val_result) this.setState({ activeKey: "2" });
      else this.setState({ validation: reason });
    } else if(activeKey == "1"){
      this.setState({ activeKey: "1" });
    }else if(activeKey == "3"){
      // const fields = [
      //   "course_name",
      //   "region",
      //   "languages",
      //   "duration",
      //   "start_date",
      //   "start_time",
      //   "end_time",
      //   "result_publish_start",
      //   "result_publish_end",
      //   "certificate_publish_start",
      //   "certificate_publish_end",
      //   "delivery_method",
      //   "seats",
      //   "timezone",
      //   "currency",
      //   "course_fees",
      //   "certificate_fees",
      // ];
      // const { val_result, reason } = this.checkNextValidation({
      //   fields,
      // });
      
      // if (val_result) this.setState({ activeKey: "3" });
      // else this.setState({ validation: reason });
      this.setState({ activeKey: "3" });
    }else{
      // const fields = [
      //   "course_name",
      //   "region",
      //   "languages",
      //   "duration",
      //   "start_date",
      //   "start_time",
      //   "end_time",
      //   "result_publish_start",
      //   "result_publish_end",
      //   "certificate_publish_start",
      //   "certificate_publish_end",
      //   "delivery_method",
      //   "seats",
      //   "timezone",
      //   "currency",
      //   "course_fees",
      //   "certificate_fees",
      //   "instructor_1",
      //   "instructor_2",
      //   "partner",
      // ];
      // const { val_result, reason } = this.checkNextValidation({
      //   fields,
      // });
    
      // if (val_result) this.setState({ activeKey: "4" });
      // else this.setState({ validation: reason });
      const { request, certificatebody } = this.state;
      if(request.certificatebody != undefined){
        const certificateRecord = certificatebody.filter((r) => r._id == request.certificatebody)[0];
        let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
        request["certificatebody"] = request.certificatebody;
        this.setState({ request , certificate_allow, evaluation_allow,exam_allow, lca_allow});
      }
      this.setState({ activeKey: "4" });
    }
  };

  renderEdit = async (id) => {
    let {Chabi, records} = this.state

    const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
   
    const record = records.filter((r) => r._id == id)[0];
    const regionUrl = region_api + "/region/country/"+record.region;
    const certificateRecord = certificateBodyResponse.data.records.filter((r) => r._id == record.certificatebody)[0];
    const countryResponse = await myAxios({ method: "get", url:regionUrl, header: { Chabi }});
    this.setState({ country: countryResponse.data.records})
    if(certificateRecord){
      let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
      this.setState({ certificatebody: certificateBodyResponse.data.records, certificatebody:certificateBodyResponse.data.records, certificate_allow, evaluation_allow,exam_allow, lca_allow})
    }else{
      this.setState({ certificatebody: certificateBodyResponse.data.records, certificatebody:certificateBodyResponse.data.records, certificate_allow:'No', evaluation_allow:'No',exam_allow:'No', lca_allow:'No'})
    }
  }

  renderAssementExamination = async (obj) => {
    const { name, value } = obj;
    const { Chabi, certificatebody } = this.state;
    const certificateRecord = certificatebody.filter((r) => r._id == value)[0];
    let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
   
    const { request } = this.state;
    request[name] = value;
    this.setState({ request , certificate_allow, evaluation_allow,exam_allow, lca_allow});
  }
  
  fiterResult = async () => {
    this.setState({ CDM: false });
    let { loggedUser } = this.props
    const {startDt, endDt, country, serchTxt,delivery_method_value, region_value } = this.state
    const { doUserLogout, Chabi } = this.state;
   // const url = `${training_api}/training_list/post`;
    const url = `${training_api}/training_list/post?data_for=${loggedUser.role}&id=${loggedUser.email}`;

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {startDt, endDt, country, serchTxt, delivery_method_value, region: region_value}
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(records);
        this.setState({ records })
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }

  countryHandler = async (value) => {
    const { request } = this.state;   
      let stateList = []
      let { Chabi , records} = this.state;
      const urlGet = states_api+"dropDown/get?countryCode="+value;
        const countryList = await myAxios({
          method: "get",
          url: urlGet,
          header: { Chabi },
        });
        stateList = countryList.data.records;
        let data = request;
        delete data['states']
        this.setState({ country : value, stateList });
  }

  setRequest = (obj) => {
    const { name, value } = obj;
    const { filtered_records, request, validation_info } = this.state;
    if(name == "delivery_method"){
      if(value == "623afef06fbc25650029f46e" || value == "623afee36fbc25650029f46c"){
             this.setState({
               validation_info : { 
                 ...validation_info, 
                 country: { valMet: vj_val.checkLength, valLen: 1 },
                 states: { valMet: vj_val.checkLength, valLen: 1 },
                 location: { valMet: vj_val.checkLength, valLen: 1 },
                 venue: { valMet: vj_val.checkLength, valLen: 1 },
           }
         })
      }else{
        this.setState({ validation_info });
      }

    }
    request[name] = value;
    this.setState({ request });
  };

  renderState = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;

     let stateUrl = states_api + "/dropdown/gets/"+value;
     const stateResponse = await myAxios({
      method: "get",
      url: stateUrl,
      header: { Chabi },
    });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , states: stateResponse.data.records});
  }

  renderLocation = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;
    let url = location_api + "/countryBy?stateId="+value;
     const locationResponse = await myAxios({
       method: "post",
       url,
       header: { Chabi },
     });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , location: locationResponse.data.records});
  }

// on edit render venue
  rendervenue = async (obj) => {
    const { name, value } = obj;
    const { Chabi, record } = this.state;
    let venueUrl = venue_api + "/countryBy?location="+value;
     const venueResponse = await myAxios({
       method: "post",
       url: venueUrl,
       header: { Chabi },
     });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request, venue: venueResponse.data.records})
  }

  renderCertificateBody = async (obj) => {
    const { Chabi, records, validation_info } = this.state;
    const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
    let recordsDt = records.filter((r) => r._id == obj)[0];
    let coutry = recordsDt.country;
    let location = recordsDt.location;
    if(recordsDt.delivery_method == "623afef06fbc25650029f46e" || recordsDt.delivery_method == "623afee36fbc25650029f46c"){
      this.setState({
        validation_info : { 
          ...validation_info, 
          country: { valMet: vj_val.checkLength, valLen: 1 },
          states: { valMet: vj_val.checkLength, valLen: 1 },
          location: { valMet: vj_val.checkLength, valLen: 1 },
          venue: { valMet: vj_val.checkLength, valLen: 1 },
    }
  })
}else{
 this.setState({ validation_info });
}

    this.renderState({name:"country", value:coutry})
    this.rendervenue({name: "location", value:location})
    const certificateRecord = certificateBodyResponse.data.records.filter((r) => r._id == obj)[0];
    if(certificateRecord){
      let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
      this.setState({ certificatebody:certificateBodyResponse.data.records, certificate_allow, evaluation_allow,exam_allow, lca_allow})
    }else{
      this.setState({ certificatebody:certificateBodyResponse.data.records, certificate_allow:'No', evaluation_allow:'No',exam_allow:'No', lca_allow:'No'})
    }
  }


  render() {
	  const { RangePicker } = DatePicker;
    console.log("training State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      cmodali,
      request,
      filtered_records,
      learning_scheme,
      language,
      region,
      activeKey,
      seomodal,
      course,
      venue,
      location,
      delivery_method,
      nextTabMsg,
      organization,
      CDM,
      CDMI,
      coffcanvas,
      selectedRecord,
      organization2,
      region2,
      course2,
      delivery_method2,
      language2,
      consultant,
      Partner,
      Exam_template,
      evaluation_template,
      lca_template,
      pre_course_template,
      certificate_template,
	    country,
      examination_fee,
	    certificatebody,
      certificate_allow, 
      evaluation_allow,
      exam_allow, 
      lca_allow,
	  countryList,
      stateList,
      states,
      permission
    } = this.state;

    let { loggedUser } = this.props

    if(loggedUser.role == "consultants"){
      let displayFields = this.displayFields
      delete displayFields['course_fees']
      this.displayFields = displayFields
    }

    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

   
    // columns.push({
    //   //width: 100,
    //   title: "Tranining Id",
    //   dataIndex: "_id",
    // });

    if(permission.childModule && permission.childModule?.seo){
    columns.push({
      //width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
  }

  if(permission.childModule){
    columns.push({
      title: "Details",
      className: "details",
       width: 100,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <>
          {permission.childModule?.details ? 
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              const url = back_api + "/training_details";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord._id },
                header: { Chabi },
              });
              let learner = {};
              let bulk_order = {};
              if (response.status == 200) {
                const { data } = response;
                learner = data.learner;
                bulk_order = data.bulk_order;
              }
              this.setState({
                coffcanvas: true,
                selectedRecord: {
                  ...selectedRecord,
                  extra: { bulk_order, learner },
                },
              });
            }}
            className="btn btn-outline-primary btn-sm"
          >
            Details
          </span> : null}
          {permission.childModule?.learner ?  <Link to={`/tranning_learner?training=${btoa(btoa(selectedRecord._id))}&orderType=${btoa(btoa("1"))}`}><button className="btn btn-sm btn-primary mt-1">Learners</button></Link> : null }
          {permission.childModule?.organization ? <Link to={`/tranning_learner?training=${btoa(btoa(selectedRecord._id))}&orderType=${btoa(btoa("2"))}`}><button className="btn btn-sm btn-primary mt-1">Organization</button></Link> : null }
          </>
        );
      },
    });
  }
   
    const beforeUpload = (file) => {
      console.log("sdds",file)
      const isJpgOrPng = file.type === 'application/pdf';
      if (!isJpgOrPng) {
        message.error('You can only upload PDF file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );

    return (
      <>
        <PageHeading2 active="Training" page={["Training"]}>
          {/* <AddNew
            disabled={!CDM}
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          /> */}
        </PageHeading2>
        <MainArea>
          
		  {/* <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-12 col-sm-3 col-md-4 col-lg-4">
              <div className="form-group">
              <label>Training Date</label>
              <RangePicker onChange={(e) => this.dateChanger(e)}/>
              </div>
            </div>
            <div className="col-6 col-sm-3 col-md-3 col-lg-3">
            <div className="form-group">
              <label>Country</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Country"
              //  defaultValue={[""]}
               onChange={(e) => this.countryHandler(e)}
               options={countryList.map( rec => {return { label:rec.country_name , value:rec.isoCode }})}
            /></div>
            </div>
            <Col span={4} style={{marginLeft: 10}}>
            <label>Search by payment ref and order number</label>
            <Input placeholder="Search by payment ref and order number" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>

            <div className="col-12 col-sm-3 col-md-2 col-lg-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()} style={{ marginTop: "28px" }}>
                Submit
              </button>
          </div>
          </div> */}

<Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{marginLeft: 10}}>
              <label>Training Window</label>
              <RangePicker onChange={(e) => this.dateChanger(e)}/>
            </Col>
            <Col span={8} style={{marginLeft: 10}}>
            <label>Search by Course Name.</label>
            <Input placeholder="Search by Course Name" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Delivery Method</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Delivery Method"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ delivery_method_value : e})}
               options={delivery_method.map( rec => {return { label:rec.title , value:rec._id }})}
            />
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Country</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Country"
              //  defaultValue={[""]}
               onChange={(e) => this.countryHandler(e)}
               options={countryList.map( rec => {return { label:rec.name , value:rec.isoCode }})}
            />
            </Col>


            <Col span={6} style={{marginLeft: 10}}>
              <label>Region</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Region"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ region_value : e})}
               options={region.map( rec => {return { label:rec.region_name , value:rec._id }})}
            />
            </Col>

          {/* end of filter sections */}
          </Row>
          {/* end of filter sections */}
          <Row>
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()}>
                Submit
              </button>
            </Col>
          </Row>


          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}

              loadData={permission.update ? (id) => {
                this.renderCertificateBody(id)
                this.renderEdit(id); 
                this.loadRecord(id)
              }  : null}
              removeData={permission.itemRemoved ? this.removeRecord : null}
              activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodali}
          title="Import Training Review"
          close={this.close_modal_import}
          className="modal-form-course sws_training_pdf"
        >
          <FormContainer>
            <Row>
              <Col span={8}>
                {/* <FormInputGroup
                      type="file"
                      id="trainingreviewimport"
                      name="trainingreviewimport"
                      label="Upload Training Review Excel"
                      placeholder="Upload Training Review Excel"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    /> */}
                <FormFileUploadGroup
                  type="file"
                  id="trainingreviewimport"
                  name="trainingreviewimport"
                  label="Upload Training Review Excel"
                  placeholder="Upload Training Review Excel"
                  data={this.state}
                  required
                  fileUpload={this.fileUpload}
                />
              </Col>
            </Row>
            <FormButton
              submit={() => {
                //check validation status here
                this.addTrainingReview();
              }}
              submit_text="Submit"
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal_import}
            />
          </FormContainer>
        </CustomModal>
        <CustomModal
          key={`manageTranning_${request._id}`}
          status={cmodal}
          title="Manage Training"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Tabs
              defaultActiveKey={"1"}
              activeKey={activeKey}
              onChange={(activeKey) => {
                this.nextManage({ activeKey });
              }}
            >
              <TabPane tab="Info" key="1">
                <Row>
                  <Col span={24}>
                    <FormSelectGroup
                      allowClear
                      id="course_name"
                      name="course_name"
                      label="Course Name"
                      placeholder="Course Name"
                      data={this.state}
                      disabled
                      options={course}
                      option_value="_id"
                      option_text="main_head"
                      setValidation={this.setValidation}
                      sendValue={(obj) => {
                        const { name, value } = obj;
                        const selected_course = course.filter(
                          (c) => c._id == value
                        )[0];
                        const { languages, category, course_type, scheme } = selected_course;
                        let Exam_template_Match = Exam_template.filter(
                          (c) => c.exam_category.toString() == category && c.exam_course_type.toString() == course_type && c.exam_scheme.toString() == scheme
                        )
                        let lca_template_Match = lca_template.filter(
                          (c) => c.category.toString() == category && c.course_type.toString() == course_type && c.scheme.toString() == scheme
                        )
                        this.setState({ lca_template: lca_template_Match, Exam_template: Exam_template_Match});
                        request["languages"] = languages;
                        request[name] = value;
                        this.setState({ request });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    {/* <FormSelectGroup
                      allowClear
                      id="region"
                      name="region"
                      label="Region"
                      placeholder="Region"
                      data={this.state}
                      required
                      options={filter_region}
                      option_value="_id"
                      option_text="region_name"
                      setValidation={this.setValidation}
                      sendValue={(obj) => {
                        const { name, value } = obj;
                        // const course_id = request["course_name"];
                        const selected_region = filter_region.filter(
                          (c) => c._id == value
                        )[0];
                        const { currency, timezone } = selected_region;
                        request["currency"] = currency;
                        request["timezone"] = timezone;
                        const { fees, duration, delivery_method } =
                          selected_region_info[value];
                        request["course_fees"] = fees;
                        request["duration"] = duration;
                        request["delivery_method"] = delivery_method;
                        request[name] = value;
                        this.setState({ request });
                      }}
                    /> */}

                    <FormInputGroup
                      type="text"
                      id="regionName"
                      name="regionName"
                      label="Region"
                      placeholder="Region"
                      data={this.state}
                      disabled
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />

                  </Col>
                  <Col span={8}>
                    <FormMultiSelectGroup
                      allowClear
                      id="languages"
                      name="languages"
                      label="Languages"
                      placeholder="Languages"
                      data={this.state}
                      required
                      options={language}
                      option_value="_id"
                      option_text="language"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormInputGroup
                      type="number"
                      id="duration"
                      name="duration"
                      label="Duration (In Days)"
                      placeholder="Duration"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <FormDateGroup
                      id="start_date"
                      name="start_date"
                      label="Start Date"
                      placeholder="Start Date"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormTimeGroup
                      id="start_time"
                      name="start_time"
                      label="Start Time"
                      placeholder="Start Time"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormTimeGroup
                      id="end_time"
                      name="end_time"
                      label="End time"
                      placeholder="End time"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={12}>
                    <FormDateGroup
                      key={request["start_date"]}
                      id="result_publish_start"
                      name="result_publish_start"
                      label="Result Publish Start Date"
                      placeholder="Result Publish Start Date"
                      data={this.state}
                      required
                      disabled={
                        !(request["start_date"] && request["start_date"].length)
                      }
                      disableDateBefore={request["start_date"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={12}>
                    <FormDateGroup
                      id="result_publish_end"
                      name="result_publish_end"
                      label="Result Publish End Date"
                      placeholder="Result Publish End Date"
                      data={this.state}
                      required
                      disabled={
                        !(
                          request["result_publish_start"] &&
                          request["result_publish_start"].length
                        )
                      }
                      disableDateBefore={request["result_publish_start"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
                {/* <Row className="mb-4">
                  <Col span={12}>
                    <FormDateGroup
                      id="certificate_publish_start"
                      name="certificate_publish_start"
                      label="Certificate Publish Start Date"
                      placeholder="Certificate Publish Start Date"
                      data={this.state}
                      required
                      disabled={
                        !(
                          request["result_publish_end"] &&
                          request["result_publish_end"].length
                        )
                      }
                      disableDateBefore={request["result_publish_end"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={12}>
                    <FormDateGroup
                      id="certificate_publish_end"
                      name="certificate_publish_end"
                      label="Certificate Publish End Date"
                      placeholder="Certificate Publish End Date"
                      data={this.state}
                      required
                      disabled={
                        !(
                          request["certificate_publish_start"] &&
                          request["certificate_publish_start"].length
                        )
                      }
                      disableDateBefore={request["certificate_publish_start"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "2" });
                  }}
                >
                  Next
                </button>
                <span className="d-inline-block ml-2 text-danger">
                  {nextTabMsg}
                </span>
                </Col>
                </Row>
              </TabPane>
              <TabPane tab="Details" key="2">
                <Row>
                  <Col span={12}>
                  <FormSelectGroup
                      id="delivery_method"
                      name="delivery_method"
                      label="Delivery Method"
                      placeholder="Delivery Method"
                      data={this.state}
                      options={delivery_method}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                      required
                    />   
                  </Col>
                  {this.state.request.delivery_method == "623afef06fbc25650029f46e" ? 
                  <Col span={6}>
                  <FormDateGroup
                    id="class_date"
                    name="class_date"
                    label="Classroom Date"
                    placeholder="Classroom Date"
                    data={this.state}
                    required
                    setValidation={this.setValidation}
                    sendValue={this.setRequest}
                  />
                </Col>
                : null}
                  <Col span={this.state.request.delivery_method == "623afef06fbc25650029f46e" ? 6 : 12}>
                    <FormInputGroup
                      type="number"
                      id="seats"
                      name="seats"
                      label="Maximum Seats"
                      placeholder="Maximum Seats"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                    <FormInputGroup
                      type="number"
                      id="minseats"
                      name="minseats"
                      label="Minimum Seats"
                      placeholder="Minimum Seats"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormSelectGroup
                      id="country"
                      name="country"
                      label="Country"
                      sub_label=""
                      placeholder="Country"
                      data={this.state}
                      options={country}
                      option_value="isoCode"
                      option_text="name"
                      setValidation={this.setValidation}
                      sendValue={this.renderState}
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="states"
                      name="states"
                      label="States"
                      sub_label=""
                      placeholder="States"
                      data={this.state}
                      options={states}
                      option_value="_id"
                      option_text="state_name"
                      setValidation={this.setValidation}
                      sendValue={this.renderLocation}
                    />
                  </Col>

                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="location"
                      name="location"
                      label="Location"
                      sub_label=""
                      placeholder="Location"
                      data={this.state}
                      options={location}
                      option_value="_id"
                      option_text="location_name"
                      setValidation={this.setValidation}
                      sendValue={this.rendervenue}
                    />
                  </Col>
                  {this.state.request.delivery_method != "623afedf6fbc25650029f46b" ?
                  <Col span={6}>
                    <FormSelectGroup
                      id="venue"
                      name="venue"
                      label="Venue"
                      sub_label=""
                      placeholder="Venue"
                      data={this.state}
                      options={venue}
                      option_value="_id"
                      option_text="venue_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  :  null}
                  <Col span={6}>
                    <FormSelectGroup
                      id="timezone"
                      name="timezone"
                      label="Timezone"
                      placeholder="Timezone"
                      data={this.state}
                      required
                      options={timezone_array}
                      option_value="name"
                      option_text="full_name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormSelectGroup
                      id="currency"
                      name="currency"
                      label="Currency"
                      placeholder="Currency"
                      data={this.state}
                      required
                      options={currency_array}
                      option_value="code"
                      option_text="full_name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="course_fees"
                      name="course_fees"
                      label="Course Fees"
                      placeholder="Course Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="material_fees"
                      name="material_fees"
                      label="Material Fees"
                      sub_label="(Optional)"
                      placeholder="Material Fees"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="certificate_fees"
                      name="certificate_fees"
                      label="Certificate Fees"
                      placeholder="Certificate Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="examination_fee"
                      name="examination_fee"
                      label="Examination Fees"
                      placeholder="Examination Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>

                </Row>
                <Row>
                  <Col span={6}>
                    <FormRadioGroup
                      label="Offer Type"
                      name="book_dis_public"
                      data={this.state}
                      options={[
                        { value: 1, text: "Is Public" },
                        { value: 0, text: "Is Private" },
                      ]}
                      defaultValue={{ value: 1, text: "Is Public" }}
                      option_value="value"
                      option_text="text"
                      sendValue={this.setRequest}
                    />
                  </Col>
                  {request.book_dis_public == 0 ? (
                    <>
                    <Col span={18}>
                      <FormMultiSelectGroup
                        allowClear
                        id="book_dis_organization"
                        name="book_dis_organization"
                        label="Organization Name"
                        placeholder="Organization Name"
                        data={this.state}
                        required
                        options={organization}
                        option_value="_id"
                        option_text="organization_name"
                        sendValue={this.setRequest}
                      />
                    </Col>
                    <Col span={24}>
                    <label>Upload</label>
                    <Upload
                        maxCount={1}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={this.fileUpload}
                        accept="application/pdf"
                      >
                        {this.state.request.proposalImage ? (
                          <img
                            src={this.state.request.proposalImage}
                            alt="Pdf uploaded"
                            style={{
                              width: '100%',
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                  </Col>
                  </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_90"
                      label="90 Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_60"
                      label="60 Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_30"
                      label="30 Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_1"
                      label="Below Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                {/* <Row  className="mb-4">
                  <Col span={24}>
                    <FormMultiSelectGroup
                      allowClear
                      id="releated_training"
                      name="releated_training"
                      label="Releated Training"
                      placeholder="Releated Training"
                      data={this.state}required
                      options={records}
                      option_value="_id"
                      option_text="course_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
               <Row>
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "2" });
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-sm btn-outline-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "3" });
                  }}
                >
                  Next
                </button>
                <span className="d-inline-block ml-2 text-danger">
                  {nextTabMsg}
                </span>
                </Col></Row>
              </TabPane>
              {/* Instructors and Partners */}
              <TabPane tab="Consultants and Partners" key="3">

                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="instructor_1"
                      name="instructor_1"
                      label="Consultant 1"
                      sub_label=""
                      placeholder="Choose Consultant 1"
                      data={this.state}
                      options={consultant}
                      option_value="_id"
                      option_text="name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                 
                </Row>
                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="instructor_2"
                      name="instructor_2"
                      label="Consultant 2"
                      placeholder="Choose Consultant 2"
                      data={this.state}
                      required
                      options={consultant}
                      option_value="_id"
                      option_text="name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="instructor_3"
                      name="instructor_3"
                      label="Consultant 3"
                      placeholder="Choose Consultant 3"
                      data={this.state}
                      required
                      options={consultant}
                      option_value="_id"
                      option_text="name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="partner"
                      name="partner"
                      label="Partner"
                      placeholder="choose partner"
                      data={this.state}
                      required
                      options={Partner}
                      option_value="_id"
                      option_text="company_name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "2" });
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-sm btn-outline-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "4" });
                  }}
                >
                  Next
                </button>
                <span className="d-inline-block ml-2 text-danger">
                  {nextTabMsg}
                </span>
                </Col></Row>
              </TabPane>
              {/* Assessment and Examination */}
              <TabPane tab="Assessment and Examination" key="4">
              <Row>
                  <Col span={24}>
                    <FormSelectGroup
                      id="certificatebody"
                      name="certificatebody"
                      label="Certificate Body"
                      placeholder="Certificate Body"
                      data={this.state}
                      options={certificatebody}
                      option_value="_id"
                      option_text="certificate_body_name"
                      setValidation={this.setValidation}
                      sendValue={this.renderAssementExamination}
                    />
                  </Col>
                  </Row>

                  {(exam_allow === "Yes") ?  
                   <Row>
				            <Col span={6}>
                      <FormSelectGroup
                        id="exam"
                        name="exam"
                        label="Exam"
                        placeholder="Exam"
                        data={this.state}
                        options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                        option_value="_id"
                        option_text="exam_name"
                        setValidation={this.setValidation}
                        sendValue={this.setRequest}
                      />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="repeat_exam"
                      name="repeat_exam"
                      label="Repeat Exam"
                      placeholder="Location"
                      data={this.state}
                      options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                      option_value="_id"
                      option_text="exam_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      id="exam_2"
                      name="exam_2"
                      label="Exam 2"
                      placeholder="Exam"
                      sub_label="(Optional)"
                      data={this.state}
                      options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                      option_value="_id"
                      option_text="exam_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="repeat_exam_2"
                      name="repeat_exam_2"
                      label="Repeat Exam 2"
                      placeholder="Repeat Exam 2"
                      sub_label="(Optional)"
                      data={this.state}
                      options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                      option_value="_id"
                      option_text="exam_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  </Row> : null}

                  <Row>
   
                  {(evaluation_allow === "Yes") ?
                  <Col span={12}>
                    <FormSelectGroup
                      id="evaluation_template"
                      name="evaluation_template"
                      label="Evaluation Template"
                      placeholder="Evaluation Template"
                      data={this.state}
                      options={(evaluation_template != undefined) ? [...evaluation_template, { _id: "null", template_name: "No templates assigned"}]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> : null }

                  {(lca_allow === "Yes") ? 
                  <><Col span={6}>
                    <FormSelectGroup
                      id="lca_template"
                      name="lca_template"
                      label="LCA Template"
                      placeholder="LCA Template"
                      data={this.state}
                      options={(lca_template != undefined) ? [...lca_template]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  {/* <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="lca_template_2"
                      name="lca_template_2"
                      label="LCA Template 2"
                      placeholder="LCA Template 2"
                      sub_label="(Optional)"
                      data={this.state}
                      options={(lca_template != undefined) ? [...lca_template]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> */}
                  </>
                   : null }

                  </Row>

                  <Row>
                  {/* <Col span={12}>
                    <FormSelectGroup
                      id="pre_course_template"
                      name="pre_course_template"
                      label="Pre Course Template"
                      placeholder="Pre Course Template"
                      data={this.state}
                      options={(pre_course_template != undefined) ? [...pre_course_template, { _id: "null", template_name: "Does not apply"}]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> */}
                  {(certificate_allow === "Yes") ? <>
                  <Col span={12}>
                    <FormSelectGroup
                      id="certificate_completion_template"
                      name="certificate_completion_template"
                      label="Certificate Template"
                      sub_label="(Successful Completion)" 
                      placeholder="Certificate Template"
                      data={this.state}
                      options={certificate_template}
                      option_value="_id"
                      option_text="certificate_template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                 
                  <Col span={12}>
                    <FormSelectGroup
                      id="certificate_attendance_template"
                      name="certificate_attendance_template"
                      label="Certificate Template"
                      sub_label="(Attendance Only)" 
                      placeholder="Certificate Template"
                      data={this.state}
                      options={certificate_template}
                      option_value="_id"
                      option_text="certificate_template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> </>: null}
                  </Row>

                  <Row>
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "3" });
                  }}
                >
                  Back
                </button>
                </Col></Row>
              </TabPane>
            </Tabs>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editTraining();
                else this.addTraining();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      course_name,
                      certificate_fees,
                      course_fees,
                      currency,
                      delivery_method,
                      duration,
                      languages,
                      region,
                      material_fees,
                      seats,
                      start_date,
                      start_time,
                      timezone,
                    } = selectedRecord;
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {course2[course_name]}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">Seats</span>
                            <span className="d-block info-value">{seats}</span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Start Date
                            </span>
                            <span className="d-block info-value">
                              {start_date}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Start_time
                            </span>
                            <span className="d-block info-value">
                              {start_time}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Timezone
                            </span>
                            <span className="d-block info-value">
                              {timezone}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">Region</span>
                            <span className="d-block info-value">
                              {region2[region]}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Course_fees
                            </span>
                            <span className="d-block info-value">
                              {course_fees}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Certificate_fees
                            </span>
                            <span className="d-block info-value">
                              {certificate_fees}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">
                              Material_fees
                            </span>
                            <span className="d-block info-value">
                              {material_fees}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Delivery_method
                            </span>
                            <span className="d-block info-value">
                              {delivery_method}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Languages
                            </span>
                            <span className="d-block info-value">
                              {languages}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">
                             Duration
                            </span>
                            <span className="d-block info-value">
                              {duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
            
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records });
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Training);
