import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { insight_api, update_country_api } from "../../library/insight_api";
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CustomSwitch from "../part/Display/CustomSwitch";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import MyLoading from "../part/MyLoading";
import FormTextEditorGroup from "../part/Form/FormTextEditorGroup";
import MyToolTip from "../part/MyToolTip/MyToolTip";
import SeoBox from "./SeoBox";
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
// ===============================================================================
class Insight extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: insight_api,
      filter: {},
      records: [],
	  team: [],
      request: {},
      validation: {},
      validation_info: {
		  featured: { valMet: vj_val.checkLength, valLen: 1 },
		  teamId: { valMet: vj_val.checkLength, valLen: 1 },
		main_head: { valMet: vj_val.checkLength, valLen: 1 },
        image: { valMet: vj_val.checkImage },
        tag: { valMet: vj_val.checkLength, valLen: 1 },
        category: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      seomodal: false,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      cmodal_country: false,
      cmodal_country_content:{},
      pdfmodal: false,
      uploadImageList: [],
      uploadFileList: [],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    main_head: {
      title: "Main Head",
      // width: 300,
      className: "main_head",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    tag: {
      title: "Tag",
      // width: 300,
      className: "tag",
      order: 2,
    },
    category: {
      title: "Category",
      // width: 300,
      className: "category",
      order: 3,
      custom: (text, record) => this.state.insight_category2[text],
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 4,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 3,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${insight_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = insight_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    //============= define permission =============
    let { loggedUser } = this.props
    if(loggedUser.roleId){
     let modulePermission = await myAxios({
       method: "get",
       url: permission_api+'/role/permission/'+loggedUser.roleId+"/"+"insight",
       header: { Chabi },
     });
    if(modulePermission.status == 200){
         if(modulePermission.data.records.length >= 1){
           console.log("ROLE APPLIED")
           let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
           this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
         }else{
           console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
         }
    }else{
     console.log("PERMISSION API CRASHED")
    }
    }
   //=============================================

    const response = await get_data({
      others: ["region", "insight_category", "insight_tag","team"],
      primary: "insight",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region, records, insight_category, insight_tag,team } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        let insight_category2 = create_object({
          arr: insight_category,
          title: "title",
        });
        this.setState({
          insight_category,
          insight_category2,
          region,
          region2,
          records,
		  team,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
  
  addNewspdf = async (insight_id) => {
    console.log("pdf submitbtn clik");
      
    const { request, Chabi } = this.state;
      let { records } = this.state;
      const url = insight_api + "/pdf_upload";
        
    let form_data = new FormData();
      form_data.append("_id", insight_id);
      form_data.append(
        "insightuploadpdf",
        this.state.insightuploadpdf.originFileObj,
        this.state.insightuploadpdf.name
      );
    
      const response = await myAxios({
        method: "post",
        url,
        header: { Chabi },
        request: form_data,
      });
      if (response.status == 200) {
        const { data } = response;
        if (data.status == 420) this.setLogout();
        else if (data.status == 200) {
          message.success("Record Saved");
      
      this.setState({
            records,
            request: {},
            validation: {},
            uploadImageList: [],
            cmodal: false,
        cmodali: false,
          });
      
        } else {
          const { error, message } = data;
          if (message) return console.log(message);
        }
      } else alert("Network Error !");
    }
    fileUpload = (e) => {
      
      this.setState({
        insightuploadpdf: e,
      });
    };
  addInsight = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = insight_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editInsight = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      tag,
      _id,
      category,
      image,
	  featured,
	  teamId,
      main_head,
      region_info,
      title,
      description,
      short_description,
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = insight_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        tag,
        _id,
        category,
        image,
		featured,
		teamId,
        main_head,
        region_info,
        title,
        description,
        short_description,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["image"] = image;
			rec["teamId"] = teamId;
			rec["featured"] = featured;
            rec["main_head"] = main_head;
            rec["region_info"] = region_info;
            rec["tag"] = tag;
            rec["category"] = category;
            rec["title"] = title;
            rec["description"] = description;
            rec["short_description"] = short_description;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  editInsightCountry = async (obj) => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
   
    const {
      _id,
      region_info
    } = request;
    console.log("recored",_id,obj)
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
     const url = update_country_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        region_info: obj,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal_country: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
           rec["region_info"] = region_info; 
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal_country: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };


  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${insight_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    let {
      records,
	  team,
      edit,
      loginStatus,
      cmodali,
      cmodal,
      cmodal_country,
      cmodal_country_content,
      request,
      filtered_records,
      insight_category,
      region,
      selectedTab,
      seomodal,
      pdfmodal,
      CDM,
      permission
    } = this.state;
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      width: 100,
      title: "Insight Id",
      dataIndex: "_id",
    });

    if(permission.childModule && permission.childModule?.pdf && permission.upload){
    columns.push({
      width: 100,
      title: "Add Pdf",
      render: (text, record) => {
        return (
          <>
            <a
              onClick={() => {
                this.setState({
                  cmodali: !cmodali,
                  pdfmodal: record,
                  request: {},
                });
              }}
            >
              Upload Pdf 
            </a>
            <br></br>
            {record.insightuploadpdf != "" &&
            record.insightuploadpdf != undefined ? (
              <a
                target="_blank"
				href={`http://apiv2.kelmacgroup.com:3003/uploaded_images/${record.insightuploadpdf}`}
                //href={`http://54.94.88.96:7656/uploaded_images/${record.insightuploadpdf}`}
                // href={`http://localhost:7656/uploaded_images/${record.insightuploadpdf}`}
              >
                Download Pdf
              </a>
            ) : null}
          </>
        );
      },
    });
  }

  if(permission.childModule && permission.childModule?.seo){
    columns.push({
      width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
  }

  if(permission.childModule && permission.childModule?.countryInsight){
    columns.push({
      width: 100,
      title: "country insight",
      render: (text, record) => {
        
        return (
          <a
            onClick={() => {
              // render data
              request = { ...request, ...record };
              this.setState({ cmodal_country: true, request, edit: true });
            }}
          >
            country insight
          </a>
        );
      },
    });
  }

    return (
      <>
        <PageHeading2
          active="Insight"
          page={["Website Administration", "Insight", "Insight"]}
        >
          {permission.create ? 
          <button
            className="add-btn btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> : null }
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
           {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={permission.update ? this.loadRecord : null}
              removeData={permission.itemRemoved ? this.removeRecord : null}
              activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Insight"
          close={this.close_modal}
          className="modal-form-insight"
        >
          <FormContainer>
            <FormImageUploadGroup
              // src={`${page_api}/image/`}
              src={`${PORTAL.api_url}uploaded_images/`}
              id="image"
              name="image"
              label="Insight Image"
              sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
              imageCount="1"
              data={this.state}
              required
              imageUpload={this.imageUpload}
              changeUploadImageList={this.changeUploadImageList}
            />
            <div className="row">
			<div className="col-12 col-sm-6 col-md-6 col-lg-4">
				<FormSelectGroup
				   allowClear
				   id="featured"
				   name="featured"
				   label="Featured"
				   placeholder="Select Featured"
				   data={this.state}
				   required
				   options={[
					  { text: "Yes", value: "Yes" },
					  { text: "No", value: "No" },
					]}
					option_value="value"
					option_text="text"
				   setValidation={this.setValidation}
				   sendValue={this.setRequest}
				   />
				</div>
				<div className="col-12 col-sm-6 col-md-6 col-lg-4">
				<FormSelectGroup
				   allowClear
				   id="teamId"
				   name="teamId"
				   label="Author"
				   placeholder="Select Author"
				   data={this.state}
				   required
				   options={team}
					option_value="_id"
					option_text="first_name"
				   setValidation={this.setValidation}
				   sendValue={this.setRequest}
				   />
				</div>
			  <div className="col-12 col-sm-6 col-md-6 col-lg-4">
            <FormInputGroup
              id="main_head"
              name="main_head"
              label="Main Head"
              placeholder="Main Head"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
            <FormInputGroup
              id="tag"
              name="tag"
              label="Tag"
              placeholder="Tag"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
            <FormSelectGroup
              id="category"
              name="category"
              label="Category"
              placeholder="Category"
              data={this.state}
              required
              options={insight_category}
              option_value="_id"
              option_text="title"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
           </div></div>
            {/* {region && region.length ? (
              <Tab.Container defaultActiveKey="default">
                <Row className="mt-4">
                  <Col span={4}>
                    <Nav variant="" className="flex-column">
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let active = 0;
                        if (single_region) active = single_region["active"];
                        return (
                          <Row
                            className={`region-nav-link ${
                              r._id == selectedTab ? "region-active" : ""
                            }`}
                          >
                            <MyToolTip
                              title={active == 1 ? "" : "Enable before select"}
                              placement="left"
                              color="#c6001d"
                            >
                              <Col span={18}>
                                <Nav.Link
                                  eventKey={r.region_name}
                                  disabled={active == 1 ? false : true}
                                  onClick={() => {
                                    this.setState({ selectedTab: r._id });
                                  }}
                                >
                                  {r.region_name}
                                </Nav.Link>
                              </Col>
                            </MyToolTip>
                            <Col span={6}>
                              <CustomSwitch
                                key={active}
                                activeData={(active) => {
                                  this.set_region_request({
                                    name: "active",
                                    value: +active,
                                    _id: r._id,
                                  });
                                }}
                                active={active}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col span={20} className="border pb-2">
                    <Tab.Content>
                      <Tab.Pane eventKey="default">
                        <MyEmpty message="Region Not Selected" />
                      </Tab.Pane>
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let description = "";
                        let title = "";
                        let short_description = "";
                        let active = false;
                        if (single_region) {
                          title = single_region["title"];
                          description = single_region["description"];
                          short_description =
                            single_region["short_description"];
                          active = single_region["active"];
                        }
                        return (
                          <Tab.Pane eventKey={r.region_name}>
                            <Row className="mt-2">
                              <Col
                                span={20}
                                className="text-center font-weight-bold"
                              >
                                Region : {r.region_name}
                              </Col>
                            </Row>
                            <FormInputGroup
                              id="title"
                              name="title"
                              label="Title"
                              placeholder="Title"
                              data={this.state}
                              required
                              value={title}
                              sendValue={(obj) => {
                                this.set_region_request({
                                  ...obj,
                                  _id: r._id,
                                });
                              }}
                              disabled={!active}
                            />
                            <FormTextareaGroup
                              id="short_description"
                              name="short_description"
                              label="Short Description"
                              placeholder="Short Description"
                              data={this.state}
                              required
                              value={short_description}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                            <FormTextEditorGroup
                              id="description"
                              name="description"
                              label="Description"
                              placeholder="Description"
                              data={this.state}
                              required
                              value={description}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              ""
            )} */}
           
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editInsight();
                else this.addInsight();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
       
        {/* model for country */}
        <CustomModal
          key={`modal-form-insight${request._id}`}
          status={cmodal_country}
          title="Manage country Insight"
          close={this.close_modal_country}
          className="modal-form-insight"
        >
          <FormContainer>
            {region && region.length ? (
              <Tab.Container defaultActiveKey="default">
                <Row className="mt-4 mb-4">
                  <Col span={5}>
                    <Nav variant="" className="flex-column">
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let active = 0;
                        if (single_region) active = single_region["active"];
                        return (
                          <Row
                            className={`region-nav-link ${
                              r._id == selectedTab ? "region-active" : ""
                            }`}
                          >
                            <MyToolTip
                              title={active == 1 ? "" : "Enable before select"}
                              placement="left"
                              color="#2F4B70"
                            >
                              <Col span={18}>
                                <Nav.Link
                                  eventKey={r.region_name}
                                  disabled={active == 1 ? false : true}
                                  onClick={() => {
                                    this.setState({ selectedTab: r._id });
                                  }}
                                >
                                  {r.region_name}
                                </Nav.Link>
                              </Col>
                            </MyToolTip>
                            <Col span={6}>
                              <CustomSwitch
                                key={active}
                                activeData={(active) => {
                                  this.set_region_request({
                                    name: "active",
                                    value: +active,
                                    _id: r._id,
                                  });
                                }}
                                active={active}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col span={19} className="border pb-2">
                    <Tab.Content>
                      <Tab.Pane eventKey="default">
                        <MyEmpty message="Region Not Selected" />
                      </Tab.Pane>
                      {region.map((r) => {
                      
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let description = "";
                        let title = "";
                        let short_description = "";
                        let active = false;
                        if (single_region) {
                         
                          title = single_region["title"];
                          description = single_region["description"];
                          short_description =  single_region["short_description"];
                          active = single_region["active"];
                        }
                        return (
                          <Tab.Pane eventKey={r.region_name}>
                            <Row className="mt-2">
                              <Col
                                span={20}
                                className="text-center font-weight-bold"
                              >
                                Region : {r.region_name}
                              </Col>
                            </Row>
                            <FormInputGroup
                              id="title"
                              name="title"
                              label="Title"
                              placeholder="Title"
                              data={this.state}
                              required
                              value={title}
                              sendValue={(obj) => {
                                this.set_region_request({
                                  ...obj,
                                  _id: r._id,
                                });
                              }}
                              disabled={!active}
                            />
                            <FormTextareaGroup
                              id="short_description"
                              name="short_description"
                              label="Short Description"
                              placeholder="Short Description"
                              data={this.state}
                              required
                              value={short_description}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                            <FormTextEditorGroup
                              id="description"
                              name="description"
                              label="Description"
                              placeholder="Description"
                              data={this.state}
                              required
                              value={description}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                            {/* form submit buttons */}
                            <FormButton
                                    submit={() => this.editInsightCountry({id : r._id, description : single_region["description"], short_description: single_region["short_description"], active: single_region["active"], title: single_region["title"]})}
                            />
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              ""
            )}
          
            
          </FormContainer>
        </CustomModal>
        {/* end of model for country */}

        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records });
            }}
          />
        ) : (
          ""
        )}
        {pdfmodal ? (
			<CustomModal
			  key={"p"+pdfmodal._id}
			  status={cmodali}
			  title="Manage PDF"
			  close={this.close_modal_import}
			  className="modal-form-news_update_p"
			>
				<FormContainer>
					<Row>
					  <Col span={8}>
						<FormFileUploadGroup
						  type="file"
						  id="insightuploadpdf"
						  name="insightuploadpdf"
						  label="Upload insight Update Pdf" 
						  placeholder="Upload insight Update Pdf"
						  data={this.state}
						  required
						  fileUpload={this.fileUpload}
						/>
					  </Col>
					</Row>
					<FormButton
					  submit={() => {
						//check validation status here
						this.addNewspdf(pdfmodal._id);
					  }}
					  submit_text="Submit"
					  reset={() => this.setState({ request: {} })}
					  cancel={this.close_modal_import}
					/>
				  </FormContainer>
		
			</CustomModal>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Insight);
