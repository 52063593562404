import React from "react";

function WhatsappLink(props) {
  const { mobile, code } = props;

  return (
    <a
      target="_blank"
      href={`https://web.whatsapp.com/send?phone=${code}${mobile}&text=Hello`}
    >
      {mobile}
    </a>
  );
}

export default WhatsappLink;
