// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record-table-container .ant-pagination {
  margin-right: 10px !important;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f5f3f3;
}
.record-table-row td {
  /* background-color: aqua !important; */
}

.ant-table-cell {
  font-size: 13px;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  color: #464e5f;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/Display/MyDataTable/mydatatable.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,6CAA6C;EAC7C,0BAA0B;EAC1B,mCAAmC;EACnC,cAAc;AAChB","sourcesContent":[".record-table-container .ant-pagination {\n  margin-right: 10px !important;\n}\n.table-row-light {\n  background-color: #ffffff;\n}\n.table-row-dark {\n  background-color: #f5f3f3;\n}\n.record-table-row td {\n  /* background-color: aqua !important; */\n}\n\n.ant-table-cell {\n  font-size: 13px;\n  font-weight: 400;\n  font-family: Poppins, Helvetica, \"sans-serif\";\n  -ms-text-size-adjust: 100%;\n  -webkit-font-smoothing: antialiased;\n  color: #464e5f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
