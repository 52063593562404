import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Select, DatePicker, Input, Row, Space, Tabs, Col, message } from 'antd';
// ===============================================================================
// ===============================================================================
import FormParent from "./FormParent";
import PageHeading2 from "../part/PageHeading2";
import MainArea from "../part/MainArea";
import { add_sno, create_object } from "../../library/object_functions";
import { get_data } from "../server_data/get_data";
import { myAxios } from "../../library/networkFunction";
import { dashboard_api } from "../../library/dashboard_api";
import { sales_target_api } from "../../library/sales_target_api";
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
import MyLoading from "../part/MyLoading";
import { currency_obj } from "../local_data/currency";
import dayjs from 'dayjs'
import axios from 'axios'

const { TabPane } = Tabs;
// ===============================================================================
class Dashboard extends FormParent {
  constructor(props) {
    super(props);
    const { match } = this.props;
    let useruid;

    if (match) {
      const { params } = match;
      useruid = params.useruid;
    }
	
	this.state = {
      useruid: useruid ? useruid : 0,
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
	  records:{},
	  CountCmodel: false,
	  revenueData : {},
	  modalTitle:"",
	  activeKey:"1",
	  filterDate: new dayjs(),
	  salesTarget:[],
	  region:[],
	  finance:null
    };
  }

  componentDidMount = async () => {
    const { doUserLogout, Chabi, validation_info } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
    
		const url1 = `${sales_target_api}?salesReport=${new Date().toISOString()}`;
            const Response1 = await myAxios({
              method: "get",
              url: url1,
              header: { Chabi },
            });

			const response = await get_data({
				others: [
				  "region",
				],
				primary: "",
				Chabi,
			  });

			  if (response.status == 200) {
				const { data } = response;
				if (data.status == 420) this.setLogout();
				else if (data.status == 200) {
				  let {
					region,
				  } = data;
				 
				  let region2 = create_object({ arr: region, title: "region_name" });
				  this.setState({
					region,
					region2,
				  });
				}
			}
		
	if(loggedUser.role != "marketing_and_sales" && loggedUser.role != "finance"){
    const url = `${dashboard_api}/getDashboard?year=${new Date().getFullYear()}`;
    const Response = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });
	  this.setState({ records : Response.data.records, salesTarget: Response1.data.records})
    }else if(loggedUser.role != "finance"){
		
		this.setState({finance : null})
	}else{
		this.setState({ salesTarget: Response1.data.records})
	}
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  close_modal = () => {
    this.setState({
		revenueData:{},
      CountCmodel: false,
    });
  };

  renderRevenue = (data) => {
		let { bulk_obj, pre_obj } = data

	    let prekeys = Object.keys(pre_obj)
	    let bulkkeys = Object.keys(bulk_obj)
		let final = [...prekeys, ...bulkkeys]
		final = [...new Set(final)];
		let finalRenvenue = final.map( _ => {
			let pre_object = pre_obj[_] == undefined ? 0 : pre_obj[_]
			let bulk_object = bulk_obj[_] == undefined ? 0 : bulk_obj[_]
			let total = pre_object + bulk_object
			return { label: _, value: total > 0 ? total.toFixed(2) : 0}
		})
		prekeys = final.map( _ => {
			let total = pre_obj[_] == undefined ? 0 : pre_obj[_]
			return { label: _, value: total > 0 ? total.toFixed(2) : 0}
		})

		bulkkeys = final.map( _ => {
			let total = bulk_obj[_] == undefined ? 0 : bulk_obj[_]
			return { label: _, value: total > 0 ? total.toFixed(2) : 0}
		})
		this.setState({revenueData : { finalRenvenue, final, prekeys, bulkkeys}, CountCmodel: true, modalTitle:"Kelmac Group Revenue"})
  }

  renderindirectRevenue = (data) => {
	let { pre_indirect_obj, bulk_indirect_obj } = data
	let prekeys = Object.keys(pre_indirect_obj)
	let bulkkeys = Object.keys(bulk_indirect_obj)
	let final = [...prekeys, ...bulkkeys]
	final = [...new Set(final)];
	let finalRenvenue = final.map( _ => {
		let pre_indirect_object = pre_indirect_obj[_] == undefined ? 0 : pre_indirect_obj[_]
			let bulk_indirect_object = bulk_indirect_obj[_] == undefined ? 0 : bulk_indirect_obj[_]
		let total = pre_indirect_object[_] + bulk_indirect_object[_]
		return { label: _, value: total > 0 ? total.toFixed(2) : 0}
	})
	prekeys = final.map( _ => {
		let total = pre_indirect_obj[_] == undefined ? 0 : pre_indirect_obj[_]
		return { label: _, value: total > 0 ? total.toFixed(2) : 0}
	})

	bulkkeys = final.map( _ => {
		let total = bulk_indirect_obj[_] == undefined ? 0 : bulk_indirect_obj[_]
		return { label: _, value: total > 0 ? total.toFixed(2) : 0}
	})
	this.setState({revenueData : { finalRenvenue, final, prekeys, bulkkeys}, CountCmodel: true, modalTitle:"Indirect sale Revenue"})
}

    renderPrivateRevenue = (data) => {
		let { bulk_private_revenue_obj } = data

		let bulkkeys = Object.keys(bulk_private_revenue_obj)
		let final = [...bulkkeys]
		final = [...new Set(final)];
		let finalRenvenue = final.map( _ => {
			let total = bulk_private_revenue_obj[_]
			return { label: _, value: total.toFixed(2)}
		})
		
		this.setState({revenueData : { finalRenvenue, final}, CountCmodel: true, modalTitle:"Private Training Classes Revenue"})
	}

	dashboardDateHandler = async (value) => {
		this.setState({ CDM: false });
        this.setState({ CDMI: false });
		const { doUserLogout, Chabi, validation_info } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
    
        const url = `${dashboard_api}/getDashboard?year=${value == "" ? new Date().getFullYear() : value}`;
        const Response = await myAxios({
          method: "get",
          url,
          header: { Chabi },
        });
	    this.setState({ records : Response.data.records, filterDate: value == "" ? new dayjs() : new dayjs(value)})
        this.setState({ CDM: true });
        this.setState({ CDMI: true });
	}

	nextManage = async ({ activeKey }) => {
		const { doUserLogout, Chabi, validation_info } = this.state;
		if(activeKey == "1"){
			this.setState({activeKey})
			const url = `${sales_target_api}/?date=${new Date().toISOString()}`;
            const Response = await myAxios({
              method: "get",
              url,
              header: { Chabi },
            });
			this.setState({ salesTarget: Response.data.records})

		}else if(activeKey == "2"){
			this.setState({ salesTarget: {}})
		}else if(activeKey == "4"){
			this.setState({ CDM: false });
            this.setState({ CDMI: false });
			this.setState({activeKey})
			const url = `${dashboard_api}/getDashboard?year=${new Date().getFullYear()}`;
			const Response = await myAxios({
			  method: "get",
			  url,
			  header: { Chabi },
			});
			  this.setState({ records : Response.data.records, CDM: true, CDMI: true })
			  
		}
		this.setState({activeKey})
	  };

	  fiterResult = async () => {
		
		let { loggedUser } = this.props
		const {yearlyYear, filter_region } = this.state

		if(yearlyYear == undefined || yearlyYear == "" || filter_region == undefined || filter_region == ""){
			alert("region & year required ...!!")
			return false;
		}
		this.setState({ CDM: false });
		const { doUserLogout, Chabi } = this.state;
		const url = `${sales_target_api}/yearlyReport`;
		const response = await myAxios({
		  method: "post",
		  url,
		  header: { Chabi },
		  request: {yearlyYear, filter_region, type: "get"}
		});
		if (response.status == 420) this.setLogout();
		if (response.status == 200) {
		 
			let { records } = response.data
			this.setState({ salesTarget: records })
	 
		} else console.log("Network Error");
		this.setState({ CDM: true });
	 
	  }

	  financeFiterResult = async () => {
		
		let { loggedUser } = this.props
		const {yearlyYear, filter_region } = this.state

		if(yearlyYear == undefined || yearlyYear == "" || filter_region == undefined || filter_region == ""){
			alert("region & year required ...!!")
			return false;
		}
		this.setState({ CDMI: false });
		const { doUserLogout, Chabi } = this.state;
		const url = `${dashboard_api}/getDashboard?year=${yearlyYear}&regionId=${filter_region}`;
		const response = await myAxios({
		  method: "get",
		  url,
		  header: { Chabi },
		  request: {yearlyYear, filter_region, type: "get"}
		});
		if (response.status == 420) this.setLogout();
		if (response.status == 200) {
		 
			let { records } = response.data
			this.setState({ finance: records })
	 
		} else console.log("Network Error");
		this.setState({ CDMI: true });
	 
	  }

	  exportToExcelYearly = async () => {
		console.log("export")
		const { Chabi, yearlyYear, filter_region } = this.state;
		this.setState({ CDM: false });
	
		const urls = `${sales_target_api}/yearlyReport`;
	
		const response = await axios({
		  url: urls,
		  method: "post", // Changed to POST
		  responseType: "blob",
		  headers: {
			token: Chabi,
			"Content-Type": "application/json", // Added content type for POST request
		  },
		  data: { yearlyYear, filter_region, type: "export" }
		});
	  
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.download = yearlyYear+"_salesReport.xlsx";
		document.body.appendChild(link);
		link.click();
		// Clean up the URL object when the download is complete
		URL.revokeObjectURL(url);
		this.setState({ CDM: true });
		message.success("report downloaded")
	  }

  render() {
	const { RangePicker } = DatePicker;
    console.log("Dashboard State ", this.state);
	const { activeKey, salesTarget, region, finance} = this.state
	if (this.props.loginStatus != true) return <Navigate to="/" />;
    let cards = ""
    if(this.state.loggedUser.role === "consultants"){
      cards = <div className="row">
      <div className="col-6 col-sm-4 col-md-3 col-lg-3">
        <div className="dashboard-item media">
          <a href="/in_complete_training">
            <div className="media-left">
              <div className="dashboard-icon">
                {" "}
              <img src="Ongoing-Courses-icon.png" />
              </div>
            </div>
            <div className="media-body">
              <h6>Learning Classes</h6>
              <h3>{this.state.records.learning_classes_booked}</h3>
            </div>
          </a>
        </div>
      </div>
    
	  <div className="col-6 col-sm-4 col-md-3 col-lg-3">
        <div className="dashboard-item media">
          <a href="/tranining_learner_list?learning=self">
            <div className="media-left">
              <div className="dashboard-icon">
                {" "}
              <img src="Completed-Courses-icon.png" />
              </div>
            </div>
            <div className="media-body">
              <h6>Incomplete Learning Classes</h6>
              <h3>{ this.state.records.total_incompleted_class}</h3>
            </div>
          </a>
        </div>
      </div>
      
      <div className="col-6 col-sm-4 col-md-3 col-lg-3">
        <div className="dashboard-item media">
          <a href="/exam_result_list">
            <div className="media-left">
              <div className="dashboard-icon">
                {" "}
              <img src="Completed-Courses-icon.png" />
              </div>
            </div>
            <div className="media-body">
              <h6>Learner Pass Rate</h6>
			  <h3>{(this.state.records.overall_learner_pass * 100).toFixed(1)}</h3>
            </div>
          </a>
        </div>
      </div>
      <div className="col-6 col-sm-4 col-md-3 col-lg-3">
        <div className="dashboard-item media">
          <a href="/evalaution_result_list">
            <div className="media-left">
              <div className="dashboard-icon">
                {" "}
              <img src="Completed-Courses-icon.png" />
              </div>
            </div>
            <div className="media-body">
              <h6>Learner Satisfaction {'>'} 4</h6>
			  <h3>{this.state.records.learner_satisfaction_rate}</h3>
            </div>
          </a>
        </div>
      </div>
      <div className="col-6 col-sm-4 col-md-3 col-lg-3">
        <div className="dashboard-item media">
          <a href="/training_ticket">
            <div className="media-left">
              <div className="dashboard-icon">
                {" "}
              <img src="Completed-Courses-icon.png" />
              </div>
            </div>
            <div className="media-body">
              <h6>Overall Complaints</h6>
			  <h3>{this.state.records.overall_complined}</h3>
            </div>
          </a>
        </div>
      </div>
    
    </div>
    }else if(this.state.loggedUser.role === "marketing_and_sales"){
      cards = <>
	  <div className="main bg-white mb-2 rounded">
	            <div className="row">
					<div className="col-md-23 col-lg-23">
					<label className="mr-2">Date filter</label>
					    <DatePicker onChange={(e,value) => this.dashboardDateHandler(value)} picker="year" defaultValue={this.state.filterDate}/>
					</div>
				</div>
				</div>
	            <div className="row">
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/training_schedule?type=3&comp=false&less=0">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Total Public Training Classes Scheduled</h6>
								  <h3>{this.state.records.total_public_training_schedule}</h3>
								</div>
							</a>
						</div>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/training_schedule?type=3&comp=true&less=0">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Total Public Training Classes Completed</h6>
								  <h3>{this.state.records.total_public_training_completed}</h3>
								</div>
							</a>
						</div>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/#">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Total Public Training Classes Rescheduled</h6>
								  <h3>{this.state.records.total_public_training_reshedule}</h3>
								</div>
							</a>
						</div>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/training_schedule?type=3&comp=false&less=1">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Public Training Classes Scheduled below 8</h6>
								  <h3>{this.state.records.total_public_training_booking_less_than_8}</h3>
								</div>
							</a>
						</div>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/organization_order">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Total Bulk Orders</h6>
								  <h3>{this.state.records.total_no_of_organization_learner}</h3>
								</div>
							</a>
						</div>
					</div>
					
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/learner_order">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Total Individual Orders</h6>
								  <h3>{this.state.records.total_no_of_learner}</h3>
								</div>
							</a>
						</div>
					</div>
					
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/exam_result_list">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Learner Pass Rate</h6>
								  <h3>{this.state.records.exam_result_avg}</h3>
								</div>
							</a>
						</div>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/evalaution_result_list?pageFor=2">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Learner Satisfaction Average Rating</h6>
								  <h3>{this.state.records.learner_satification_avg}</h3>
								</div>
							</a>
						</div>
					</div>

					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/evalaution_result_list?pageFor=1">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Rating less than 3</h6>
								  <h3>{this.state.records.less_than_4_rating}</h3>
								</div>
							</a>
						</div>
					</div>
					
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media" >
							{/* <a href="/#" onClick={() => { this.setState({ CountCmodel: true})}}> */}
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Kelmac Group Revenue</h6>
								  <button type="button" className="btn btn-link pl-0" onClick={() => this.renderRevenue(this.state.records.total_revenue)}>Click to know more</button>
								</div>
							{/* </a> */}
						</div>
					</div>
					{/* <div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							<a href="/training">
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Sales Target</h6>
								  <h3>{this.state.records.sale_target}</h3>
								</div>
							</a>
						</div>
					</div> */}
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							{/* <a href="/training"> */}
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Indirect Sales Revenue</h6>
								  <button type="button" className="btn btn-link pl-0" onClick={() => this.renderindirectRevenue(this.state.records.indirect_sale_revenue)}>Click to know more</button>

								  {/* <h3>{this.state.records.indirect_sale_revenue}</h3> */}
								</div>
							{/* </a> */}
						</div>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-3">
						<div className="dashboard-item media">
							{/* <a href="/training"> */}
								<div className="media-left">
								  <div className="dashboard-icon">
									{" "}
								  <img src="Ongoing-Courses-icon.png" />
								  </div>
								</div>
								<div className="media-body">
								  <h6>Private Training Classes Revenue</h6>
								  {/* <h3>{this.state.records.private_class_revenue}</h3> */}
								  <button type="button" className="btn btn-link pl-0" onClick={() => this.renderPrivateRevenue(this.state.records.private_class_revenue)}>Click to know more</button>

								</div>
							{/* </a> */}
						</div>
					</div>
					
				</div></>
	}else if(this.state.loggedUser.role === "research_and_development"){
		cards = <div className="row">
					  <div className="col-6 col-sm-4 col-md-3 col-lg-3">
						  <div className="dashboard-item media">
							  <a href="/analytics-course?type=1">
								  <div className="media-left">
									<div className="dashboard-icon">
									  {" "}
									<img src="Ongoing-Courses-icon.png" />
									</div>
								  </div>
								  <div className="media-body">
									<h6>Total Active Courses</h6>
									<h3>{this.state.records.active}</h3>
								  </div>
							  </a>
						  </div>
					  </div>
					  <div className="col-6 col-sm-4 col-md-3 col-lg-3">
						  <div className="dashboard-item media">
							  <a href="/analytics-course?type=2">
								  <div className="media-left">
									<div className="dashboard-icon">
									  {" "}
									<img src="Ongoing-Courses-icon.png" />
									</div>
								  </div>
								  <div className="media-body">
									<h6>Total Deactive Courses</h6>
									<h3>{this.state.records.deactive}</h3>
								  </div>
							  </a>
						  </div>
					  </div>
					  <div className="col-6 col-sm-4 col-md-3 col-lg-3">
						  <div className="dashboard-item media">
							  <a href="/analytics-course?type=3">
								  <div className="media-left">
									<div className="dashboard-icon">
									  {" "}
									<img src="Ongoing-Courses-icon.png" />
									</div>
								  </div>
								  <div className="media-body">
									<h6>Total Courses Not Reviewed {">"} 9 Months</h6>
									<h3>{this.state.records.nine_month_review}</h3>
								  </div>
							  </a>
						  </div>
					  </div>
					
					  
				  </div>
	  }else if(this.state.loggedUser.role === "finance"){

	}

    return (
      <>
        <PageHeading2 active="Region" page={["Dashboard"]}></PageHeading2>
        {this.state.loggedUser.role === "marketing_and_sales" ? (
          <div className="main">
            <Tabs
              className="nav-btns"
              defaultActiveKey={2}
              activeKey={activeKey}
              onChange={(activeKey) => {
                this.nextManage({ activeKey });
              }}
            >
              <TabPane tab="Training Revenue" key="1">
                {!this.state.CDM ? (
                  <MyLoading />
                ) : (
                  salesTarget.length >= 1 &&
                  salesTarget.map((region) => {
                    return (
                      <div className="dashboard-list">
                        <h2 className="text-uppercase">{region.regionName}</h2>
                        <div className="row text-center">
                          {region.sale.map((sale) => {
                            return (
                              <div className="col col-md-3">
                                <div className="dashbors-card card">
                                  <h6 className="text-uppercase">
                                    Monthly Target
                                  </h6>
                                  <div className="d-flex monthyear-btn w-100">
                                    <span className="btn btn-sm btn-light w-100">
                                      {sale.year}
                                    </span>
                                    <span className="btn btn-sm btn-light w-100 text-uppercase">
                                      {sale.period_value_start}
                                    </span>
                                  </div>
                                  <div className="mt-3 count-btn">
                                    <span className="btn btn-lg btn-light w-100">
                                      {sale.sale_amount}/
                                      <a
                                        href={`/sales_target_report?regionId=${region.regionId}&gte=${sale.yearMatch.$gte}&lt=${sale.yearMatch.$lt}&type=monthly&month=${sale.period_value_start}&region=${region.regionName}&year=${sale?.year}`}
                                      >
                                        <span
                                          className={
                                            sale.sale_amount > sale.total
                                              ? "text-danger"
                                              : "text-success"
                                          }
                                        >
                                          {sale.total}
                                        </span>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                )}
              </TabPane>

              <TabPane tab="Yearly  Revenue" key="2">
                <>
                  <Row style={{ marginTop: "20px", marginBottom: 20}}>
                    <Col span={3} style={{ marginLeft: 10 }}>
                      <Select
                        allowClear
                        style={{width: "100%",}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>(option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        placeholder="Choose Region"
                        onChange={(e) => this.setState({ filter_region: e })}
                        options={region.map((rec) => {
                          return { label: rec.region_name, value: rec._id };
                        })}
                      />
                    </Col>
					<Col span={4} style={{ marginLeft: 10 }}>
                      <DatePicker onChange={(e,value) => this.setState({ yearlyYear: value })} picker="year" placeholder="Choose Year" style={{ width: 200 }}/>
                    </Col>
					<Col className="m-6">
					<button className="btn btn-sm btn-primary" onClick={() => this.fiterResult()}>Submit</button>
					<button className="ml-2 btn-sm btn btn-success" onClick={() => this.exportToExcelYearly()}>Export To Excel</button>
                    <button className="ml-2 btn-sm btn btn-warning" onClick={() => console.log("required design approval")}>Generate Report</button>
                    </Col>

                    {/* end of filter sections */}
                  </Row>
                  {/* end of filter sections */}
                 
                  {!this.state.CDM ? (
                    <MyLoading />
                  ) : (<>
				  {salesTarget.yearlySalesObj != undefined &&
				      <div className="col col-md-3">
                                  <div className="dashbors-card card">
                                    <h6 className="text-uppercase">
									yearly Target
                                    </h6>
                                    <div className="d-flex monthyear-btn w-100">
                                      <span className="btn btn-sm btn-light w-100">
                                        {salesTarget.yearlySalesObj?.year}
                                      </span>
                                      
                                    </div>
                                    <div className="mt-3 count-btn">
                                      <span className="btn btn-lg btn-light w-100">
									  {salesTarget.yearlySalesObj?.sale_amount}/
                                        <a
                                          href={`/sales_target_report?regionId=${salesTarget.regionId}&gte=${salesTarget.yearlySalesObj.yearMatch.$gte}&lt=${salesTarget.yearlySalesObj.yearMatch.$lt}&type=yearly&region=${salesTarget.regionName}&year=${salesTarget.yearlySalesObj?.year}`}
                                        >
                                          <span
                                            className={
												salesTarget.yearlySalesObj?.sale_amount > salesTarget.yearlySalesObj?.total
                                                ? "text-danger"
                                                : "text-success"
                                            }
                                          >
											{salesTarget.yearlySalesObj?.total}
                                          </span>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div> }
								<br />
								<hr />
                    {salesTarget.sale != undefined &&
                    // salesTarget.map((region) => {
                    //   return (
                        <div className="dashboard-list mb-2">
                          <div className="row text-center">
                            {salesTarget.sale.map((sale) => {
                              return (
                                <div className="col col-md-3">
                                  <div className="dashbors-card card">
                                    <h6 className="text-uppercase">
                                      Monthly Target
                                    </h6>
                                    <div className="d-flex monthyear-btn w-100">
                                      <span className="btn btn-sm btn-light w-100">
                                        {sale.year}
                                      </span>
                                      <span className="btn btn-sm btn-light w-100 text-uppercase">
                                        {sale.period_value_start}
                                      </span>
                                    </div>
                                    <div className="mt-3 count-btn">
                                      <span className="btn btn-lg btn-light w-100">
                                        {sale.sale_amount}/
                                        <a
                                          href={`/sales_target_report?regionId=${salesTarget.regionId}&gte=${sale.yearMatch.$gte}&lt=${sale.yearMatch.$lt}&type=monthly&region=${salesTarget.regionName}&month=${sale.period_value_start}&year=${salesTarget.yearlySalesObj?.year}`}
                                        >
                                          <span
                                            className={
                                              sale.sale_amount > sale.total
                                                ? "text-danger"
                                                : "text-success"
                                            }
                                          >
                                            {sale.total}
                                          </span>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                    //   );
                    // })
					}
                  </>)}
                </>
              </TabPane>

              <TabPane tab="Opportunities" key="3">
                <div className="dashboard-list">
                  <h3>Coming soon</h3>
                </div>
              </TabPane>
              <TabPane tab="Training Management" key="4">
                {!this.state.CDM ? (
                  <MyLoading />
                ) : (
                  <MainArea> {cards} </MainArea>
                )}
              </TabPane>
            </Tabs>
          </div>
        ) : !this.state.CDM ? (
          <MyLoading />
        ) : this.state.loggedUser.role === "finance" ?   (<>
		<Row style={{ marginTop: "20px", marginBottom: 20}}>
		  <Col span={3} style={{ marginLeft: 10 }}>
			<Select
			  allowClear
			  style={{width: "100%",}}
			  showSearch
			  optionFilterProp="children"
			  filterOption={(input, option) =>(option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
			  placeholder="Choose Region"
			  onChange={(e) => this.setState({ filter_region: e })}
			  options={region.map((rec) => {
				return { label: rec.region_name, value: rec._id };
			  })}
			/>
		  </Col>
		  <Col span={4} style={{ marginLeft: 10 }}>
			<DatePicker onChange={(e,value) => this.setState({ yearlyYear: value })} picker="year" placeholder="Choose Year" style={{ width: 200 }}/>
		  </Col>
		  <Col className="m-6">
		  <button className="btn btn-sm btn-primary" onClick={() => this.financeFiterResult()}>Submit</button>
		  <button className="ml-2 btn-sm btn btn-success" onClick={() => this.exportToExcelYearly()}>Export To Excel</button>
		  <button className="ml-2 btn-sm btn btn-warning" onClick={() => console.log("required design approval")}>Generate Report</button>
		  </Col>
		</Row>
		{finance != null ? 
		 !this.state.CDMI ? (<MyLoading />)
			: <Row>
		           <div className="col col-md-3">
						<div className="dashbors-card card">
						  <h6 className="text-uppercase">
						  yearly Revenue
						  </h6>
						  <div className="d-flex monthyear-btn w-100">
							<span className="btn btn-sm btn-light w-100">
							  {finance.year}
							</span>
							
						  </div>
						  <div className="mt-3 count-btn">
							<span className="btn btn-lg btn-light w-100">
							  <a
								href={`/revenue-report?filterType=1&regionId=${finance.regionId}&gte=${finance.yearMatch.$gt}&lt=${finance.yearMatch.$lte}&type=yearly&region=${finance.region_name}&year=${finance.year}`}
							  > 
								 <span>
								  {currency_obj[finance.currency].symbol+" "+finance.yearlyRevenue}
								</span>
							  </a>
							</span>
						  </div>
						</div>
					  </div>
					  <div className="col col-md-3">
						<div className="dashbors-card card">
						  <h6 className="text-uppercase">
						  Private Training  Revenue 
						  </h6>
						  <div className="d-flex monthyear-btn w-100">
							<span className="btn btn-sm btn-light w-100">
							{finance.year}
							</span>
							
						  </div>
						  <div className="mt-3 count-btn">
						  <span className="btn btn-lg btn-light w-100">
							  <a
								href={`/revenue-report?filterType=2&regionId=${finance.regionId}&gte=${finance.yearMatch.$gt}&lt=${finance.yearMatch.$lte}&type=yearly&region=${finance.region_name}&year=${finance.year}`}
							  > 
								 <span>
								 {currency_obj[finance.currency].symbol+" "+finance.privateRevenue}
								</span>
							  </a>
							</span>
						  </div>
						</div>
					  </div>
					  <div className="col col-md-3">
						<div className="dashbors-card card">
						  <h6 className="text-uppercase">
						  Public Training  Revenue
						  </h6>
						  <div className="d-flex monthyear-btn w-100">
							<span className="btn btn-sm btn-light w-100">
							{finance.year}
							</span>
							
						  </div>
						  <div className="mt-3 count-btn">
						  <span className="btn btn-lg btn-light w-100">
							  <a
								href={`/revenue-report?filterType=3&regionId=${finance.regionId}&gte=${finance.yearMatch.$gt}&lt=${finance.yearMatch.$lte}&type=yearly&region=${finance.region_name}&year=${finance.year}`}
							  > 
								 <span>
								 {currency_obj[finance.currency].symbol+" "+finance.publicRevenue}
								</span>
							  </a>
							</span>
						  </div>
						</div>
					  </div>
					  <div className="col col-md-3">
						<div className="dashbors-card card">
						  <h6 className="text-uppercase">
						  Outstanding  Revenue
						  </h6>
						  <div className="d-flex monthyear-btn w-100">
							<span className="btn btn-sm btn-light w-100">
							{finance.year}
							</span>
							
						  </div>
						  <div className="mt-3 count-btn">
						  <span className="btn btn-lg btn-light w-100">
							  <a
								href={`/revenue-report?filterType=4&regionId=${finance.regionId}&gte=${finance.yearMatch.$gt}&lt=${finance.yearMatch.$lte}&type=outstanding&region=${finance.region_name}&year=${finance.year}`}
							  > 
								 <span>
								 {currency_obj[finance.currency].symbol+" "+finance.outstandingRevenue}
								</span>
							  </a>
							</span>
						  </div>
						</div>
					  </div>
					  </Row> : null}
	 </>)
		: (
			<MainArea> {cards} </MainArea>
        )}

        <CustomModal
          key={"total"}
          status={this.state.CountCmodel}
          title={this.state.modalTitle}
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            {this.state.revenueData.finalRenvenue != undefined &&
            this.state.revenueData.finalRenvenue.length > 0 &&
            this.state.revenueData.prekeys != undefined &&
            this.state.revenueData.prekeys.length > 0 &&
            this.state.revenueData.bulkkeys != undefined &&
            this.state.revenueData.bulkkeys.length > 0 ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <p>No revenue in this year</p>
              </div>
            )}
            {this.state.revenueData.finalRenvenue != undefined &&
            this.state.revenueData.finalRenvenue.length > 0 ? (
              <>
                <p>
                  <b>Total revenue in Respective Currency </b>
                </p>
                <p>
                  {this.state.revenueData.finalRenvenue != undefined &&
                    this.state.revenueData.finalRenvenue.map((_) => {
                      return (
                        <>
                          <span>
                            <b>{_.label}:</b>{" "}
                            <b>{currency_obj[_.label].symbol}</b>
                            {_.value}
                          </span>
                          {", "}
                        </>
                      );
                    })}
                </p>
              </>
            ) : null}
            {this.state.revenueData.prekeys != undefined &&
            this.state.revenueData.prekeys.length > 0 ? (
              <>
                <p>
                  <b>Revenue by Organisation Order</b>
                  <a href="/organization_order">
                    <span style={{ marginLeft: "10px", color: "blue" }}>
                      click to view
                    </span>
                  </a>
                </p>
                <p>
                  {this.state.revenueData.prekeys != undefined &&
                    this.state.revenueData.prekeys.map((_) => {
                      return (
                        <>
                          <span>
                            <b>{_.label}:</b>{" "}
                            <b>{currency_obj[_.label].symbol}</b>
                            {_.value}
                          </span>
                          {", "}
                        </>
                      );
                    })}
                </p>
              </>
            ) : null}
            {this.state.revenueData.bulkkeys != undefined &&
            this.state.revenueData.bulkkeys.length > 0 ? (
              <>
                <p>
                  <b>Revenue by Individual Order</b>
                  <a href="/learner_order">
                    <span style={{ marginLeft: "10px", color: "blue" }}>
                      click to view
                    </span>
                  </a>
                </p>
                <p>
                  {this.state.revenueData.bulkkeys != undefined &&
                    this.state.revenueData.bulkkeys.map((_) => {
                      return (
                        <>
                          <span>
                            <b>{_.label}:</b>{" "}
                            <b>{currency_obj[_.label].symbol}</b>
                            {_.value}
                          </span>
                          {", "}
                        </>
                      );
                    })}
                </p>
              </>
            ) : null}
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
