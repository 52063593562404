import React, { useState, useEffect } from "react";
import { Input, Table, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import StatusIcon from "../StatusIcon";
import DeleteIcon from "../DeleteIcon";
import EditIcon from "../EditIcon";
import WhatsappLink from "../../WhatsappLink/WhatsappLink";
import MailLink from "../../MailLink/MailLink";
import "./mydatatable.css";
import CustomSwitch from "../CustomSwitch/";
function MyDataTable(props) {
  let {
    columns, //column name list
    dataSource, // actual data "records"
    rowKey,
    loadData,
    removeData,
    activeData,
    fix_filter,
    pagination,
    coloredRow,
    action_fields,
    expandable,
    onRowClick,
  } = props;
  // ================================
  let searchInput;
  // ================================
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [tableColumn, setTableColumn] = useState(columns);
  const [sortOrder, setSortOrder] = useState({
    field: undefined,
    order: undefined,
  });
  const [searchInfo, setSearchInfo] = useState({
    searchText: "",
    searchedColumn: "",
  });
  // ================================
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchInfo({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  // ================================
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchInfo({
      ...searchInfo,
      searchText: "",
    });
  };
  // ================================
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchInfo({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select(), 100);
    },
    render: (text) => {
      //not working
      return searchInfo.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchInfo.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });
  // ================================
  //add custom key with columns
  // ========================
  useEffect(() => {
    const new_columns = columns.map((col, index) => {
      const { title, search, dataIndex } = col;
      // col["width"] = 400;
      // ==============================
      if (title == sortOrder.field) {
        col["sortOrder"] = sortOrder.order;
        // col["sortDirections"] = ["descend", "ascend"]; //set sort disretion
      } else delete col.sortOrder;
      // ==============================
      if (fix_filter && fix_filter[title]) {
        col["filters"] = fix_filter[title];
        col["onFilter"] = (value, record) => record[title].indexOf(value) === 0;
      } else if (search) col = { ...col, ...getColumnSearchProps(dataIndex) };
      // ==============================
      return col;
    });
    if (
      loadData ||
      activeData ||
      removeData ||
      (action_fields && action_fields.length)
    ) {
      new_columns.push({
        // width: 150,
        title: "Actions",
        key: "action",
        render: (text, record) => {
          return (
            <>
              {action_fields
                ? action_fields.map((af) => af.render(record))
                : ""}
              {loadData ? (
                <span>
                  <EditIcon loadData={() => loadData(record[rowKey])} />
                </span>
              ) : (
                ""
              )}
              {activeData ? (
                <span className="ml-3">
                  <CustomSwitch
                    active={record["active"]}
                    activeData={() => activeData(record)}
                  />
                  {/* <StatusIcon
                    active={record["active"]}
                    activeData={() => activeData(record)}
                  /> */}
                </span>
              ) : (
                ""
              )}
              {removeData ? (
                <span className="ml-3">
                  <DeleteIcon removeData={() => removeData(record)} />
                </span>
              ) : (
                ""
              )}
            </>
          );
        },
        // fixed: "right", //fix col in right side
      });
    }
    setTableColumn(new_columns);
  }, [1]);
  // ================================
  // console.log("tableColumn", tableColumn);
  return (
    <AntTable
      key={JSON.stringify(dataSource)}
      onRowClick={onRowClick}
      columns={tableColumn}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      pageNo={pageNo}
      setPageNo={setPageNo}
      rowKey={rowKey}
      dataSource={dataSource}
      pagination={pagination}
      coloredRow={coloredRow}
      expandable={expandable}
    />
  );
}
function AntTable(props) {
  const { Column } = Table;
  const {
    dataSource,
    rowKey,
    setSortOrder,
    selectedRowKeys,
    setSelectedRowKeys,
    pageNo,
    setPageNo,
    pagination,
    coloredRow,
    expandable,
    onRowClick,
  } = props;
  let { columns } = props;
  // =========================
  // custom serial no
  // columns = [
  //   {
  //     //note :sorting remaining
  //     // sorter: (a, b) => {
  //     //   console.log(a, b);
  //     // },
  //     title: "No",
  //     dataIndex: "no",
  //     render: (value, item, index) => {
  //       return <span>{(pageNo - 1) * 10 + index + 1}</span>;
  //     },
  //     width: 70,
  //   },
  //   ...columns,
  // ];
  //columns // columns list
  //dataSouce //data array
  //rowKey //unique key
  // const scroll = { y: 450 }; // fixed header
  // const scroll = { x: 270, y: 415 }; //fixed header and column
  const scroll = {};
  const local_props = { columns, dataSource, rowKey, scroll };
  // =========================================================
  // =========================================================
  // checkbox on row
  // local_props["rowSelection"] = {
  //   selectedRowKeys, // pre selected rows
  //   onChange: (selectedRowKeys, records) => setSelectedRowKeys(selectedRowKeys),
  // };
  // =========================================================
  // =========================================================
  if (coloredRow) {
    local_props["rowClassName"] = (record, index) =>
      index % 2 === 0 ? "table-row-light" : "table-row-dark";
  }
  // =========================================================
  // =========================================================
  if (pagination) {
    local_props["pagination"] = {
      current: pageNo,
      position: ["bottomRight"],
      hideOnSinglePage: true,
      defaultPageSize: 10,
    };
    // local_props["pagination"] = { pageSize:50,current: pageNo,    position: ["topRight", "bottomRight"], };
  } else local_props["pagination"] = false;
  if (expandable) local_props["expandable"] = expandable;
  return (
    <Table
      {...local_props}
      className="record-table-container"
      rowClassName="record-table-row"
      onChange={(pagination, filters, sorter, extra) => {
        const { field, order } = sorter;
        setSortOrder({ field, order });
        setPageNo(pagination.current);
      }}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: (event) => {
      //       if (onRowClick) onRowClick(record);
      //     },
      //   };
      // }}
      // onCell={(...obj) => {
      //   // return {
      //   //   onClick: (event) => {
      //   //     // if (onRowClick) onRowClick(record);
      //   //   },
      //   // };
      // }}
      // onHeaderRow={(columns, index) => {
      //   return {
      //     onClick: () => {}, // click header row
      //   };
      // }}
    >
      {/* <Column className="ant-table-column" /> */}
    </Table>
  );
}
export function createColumn(obj) {
  let { records, displayFields, fastEdit } = obj;
  let columns = [];
  if (!records || records.length == 0) return columns;
  const columns_keys = Object.keys(records[0]);
  const display_fields_name = Object.keys(displayFields);
  columns_keys.forEach((c) => {
    if (display_fields_name.length && display_fields_name.includes(c)) {
      const {
        title,
        width,
        defaultSortOrder,
        ellipsis,
        mail_type,
        mobile_type,
        className,
        quick_edit,
        sorter,
        order,
        custom,
      } = displayFields[c];
      const local_props = {
        title: title, //title of column
        dataIndex: c, //actual column data
      };
      // ===============================================================
      if (className) local_props["className"] = className;
      if (width) local_props["width"] = width;
      if (defaultSortOrder) local_props["defaultSortOrder"] = defaultSortOrder;
      if (ellipsis) local_props["ellipsis"] = ellipsis;
      if (sorter)
        local_props["sorter"] = (a, b) => {
          if (typeof a[c] == "string")
            return a[c].toLowerCase().localeCompare(b[c].toLowerCase());
          else if (typeof a[c] == "number") return a[c] - b[c];
        };
      // ===============================================================
      const col = {
        ...displayFields[c],
        ...local_props,
        // sortDirections: ["descend"],
        // filters:[],
        // onFilter :()=>{},
        render: quick_edit
          ? (text, record) => (
              <input
                type="text"
                readOnly={true}
                className="border-0 value-input"
                style={{
                  background: "unset",
                  width: "100%",
                }}
                onDoubleClick={(e) => {
                  e.target.readOnly = false;
                  e.target.style.background = "white";
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  fastEdit({
                    value,
                    record,
                    field: c,
                  });
                  e.target.readOnly = true;
                  e.target.style.background = "unset";
                }}
                defaultValue={text}
              />
            )
          : mobile_type
          ? (text, record) => <WhatsappLink mobile={text} code={"+91"} />
          : mail_type
          ? (text, record) => <MailLink mail={text} />
          : custom
          ? custom
          : undefined,
      };
      if (order) columns[order] = col;
      else columns.push(col);
    }
  });
  return columns;
}
export default MyDataTable;
