export const timezone_array = [
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
    full_name: "GMT-12:00 - Etc/GMT-12 ",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
    full_name: "GMT-11:00 - Etc/GMT-11 ",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
    full_name: "GMT-11:00 - Pacific/Midway ",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
    full_name: "GMT-10:00 - America/Adak ",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
    full_name: "GMT-09:00 - America/Anchorage ",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
    full_name: "GMT-09:00 - Pacific/Gambier ",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
    full_name: "GMT-08:00 - America/Dawson_Creek ",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
    full_name: "GMT-08:00 - America/Ensenada ",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
    full_name: "GMT-08:00 - America/Los_Angeles ",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
    full_name: "GMT-07:00 - America/Chihuahua ",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
    full_name: "GMT-07:00 - America/Denver ",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
    full_name: "GMT-06:00 - America/Belize ",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
    full_name: "GMT-06:00 - America/Cancun ",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
    full_name: "GMT-06:00 - America/Chicago ",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
    full_name: "GMT-06:00 - Chile/EasterIsland ",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
    full_name: "GMT-05:00 - America/Bogota ",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
    full_name: "GMT-05:00 - America/Havana ",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
    full_name: "GMT-05:00 - America/New_York ",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
    full_name: "GMT-04:30 - America/Caracas ",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
    full_name: "GMT-04:00 - America/Campo_Grande ",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
    full_name: "GMT-04:00 - America/Glace_Bay ",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
    full_name: "GMT-04:00 - America/Goose_Bay ",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
    full_name: "GMT-04:00 - America/Santiago ",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
    full_name: "GMT-04:00 - America/La_Paz ",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
    full_name: "GMT-03:00 - America/Argentina/Buenos_Aires ",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
    full_name: "GMT-03:00 - America/Montevideo ",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
    full_name: "GMT-03:00 - America/Araguaina ",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
    full_name: "GMT-03:00 - America/Godthab ",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
    full_name: "GMT-03:00 - America/Miquelon ",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
    full_name: "GMT-03:00 - America/Sao_Paulo ",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
    full_name: "GMT-03:30 - America/St_Johns ",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
    full_name: "GMT-02:00 - America/Noronha ",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
    full_name: "GMT-01:00 - Atlantic/Cape_Verde ",
  },
  {
    offset: "GMT",
    name: "Europe/Belfast",
    full_name: "GMT - Europe/Belfast ",
  },
  {
    offset: "GMT",
    name: "Africa/Abidjan",
    full_name: "GMT - Africa/Abidjan ",
  },
  {
    offset: "GMT",
    name: "Europe/Dublin",
    full_name: "GMT - Europe/Dublin ",
  },
  {
    offset: "GMT",
    name: "Europe/Lisbon",
    full_name: "GMT - Europe/Lisbon ",
  },
  {
    offset: "GMT",
    name: "Europe/London",
    full_name: "GMT - Europe/London ",
  },
  {
    offset: "UTC",
    name: "UTC",
    full_name: "UTC - UTC ",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
    full_name: "GMT+01:00 - Africa/Algiers ",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Windhoek",
    full_name: "GMT+01:00 - Africa/Windhoek ",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
    full_name: "GMT+01:00 - Atlantic/Azores ",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
    full_name: "GMT+01:00 - Atlantic/Stanley ",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
    full_name: "GMT+01:00 - Europe/Amsterdam ",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
    full_name: "GMT+01:00 - Europe/Belgrade ",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
    full_name: "GMT+01:00 - Europe/Brussels ",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
    full_name: "GMT+02:00 - Africa/Cairo ",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
    full_name: "GMT+02:00 - Africa/Blantyre ",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
    full_name: "GMT+02:00 - Asia/Beirut ",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
    full_name: "GMT+02:00 - Asia/Damascus ",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
    full_name: "GMT+02:00 - Asia/Gaza ",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
    full_name: "GMT+02:00 - Asia/Jerusalem ",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
    full_name: "GMT+03:00 - Africa/Addis_Ababa ",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
    full_name: "GMT+03:00 - Asia/Riyadh89 ",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
    full_name: "GMT+03:00 - Europe/Minsk ",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
    full_name: "GMT+03:30 - Asia/Tehran ",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
    full_name: "GMT+04:00 - Asia/Dubai ",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
    full_name: "GMT+04:00 - Asia/Yerevan ",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
    full_name: "GMT+04:00 - Europe/Moscow ",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
    full_name: "GMT+04:30 - Asia/Kabul ",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
    full_name: "GMT+05:00 - Asia/Tashkent ",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
    full_name: "GMT+05:30 - Asia/Kolkata ",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
    full_name: "GMT+05:45 - Asia/Katmandu ",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
    full_name: "GMT+06:00 - Asia/Dhaka ",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
    full_name: "GMT+06:00 - Asia/Yekaterinburg ",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
    full_name: "GMT+06:30 - Asia/Rangoon ",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
    full_name: "GMT+07:00 - Asia/Bangkok ",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
    full_name: "GMT+07:00 - Asia/Novosibirsk ",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
    full_name: "GMT+08:00 - Etc/GMT+8 ",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
    full_name: "GMT+08:00 - Asia/Hong_Kong ",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
    full_name: "GMT+08:00 - Asia/Krasnoyarsk ",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
    full_name: "GMT+08:00 - Australia/Perth ",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
    full_name: "GMT+08:45 - Australia/Eucla ",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
    full_name: "GMT+09:00 - Asia/Irkutsk ",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
    full_name: "GMT+09:00 - Asia/Seoul ",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
    full_name: "GMT+09:00 - Asia/Tokyo ",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
    full_name: "GMT+09:30 - Australia/Adelaide ",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
    full_name: "GMT+09:30 - Australia/Darwin ",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
    full_name: "GMT+09:30 - Pacific/Marquesas ",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
    full_name: "GMT+10:00 - Etc/GMT+10 ",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
    full_name: "GMT+10:00 - Australia/Brisbane ",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
    full_name: "GMT+10:00 - Australia/Hobart ",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
    full_name: "GMT+10:00 - Asia/Yakutsk ",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
    full_name: "GMT+10:30 - Australia/Lord_Howe ",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
    full_name: "GMT+11:00 - Asia/Vladivostok ",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
    full_name: "GMT+11:30 - Pacific/Norfolk ",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
    full_name: "GMT+12:00 - Etc/GMT+12 ",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
    full_name: "GMT+12:00 - Asia/Anadyr ",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
    full_name: "GMT+12:00 - Asia/Magadan ",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
    full_name: "GMT+12:00 - Pacific/Auckland ",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
    full_name: "GMT+12:45 - Pacific/Chatham ",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
    full_name: "GMT+13:00 - Pacific/Tongatapu ",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
    full_name: "GMT+14:00 - Pacific/Kiritimati ",
  },
];
export const timezone_obj = {
  "GMT-12:00": {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
    full_name: "GMT-12:00 - Etc/GMT-12 ",
  },
  "GMT-11:00": {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
    full_name: "GMT-11:00 - Pacific/Midway ",
  },
  "GMT-10:00": {
    offset: "GMT-10:00",
    name: "America/Adak",
    full_name: "GMT-10:00 - America/Adak ",
  },
  "GMT-09:00": {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
    full_name: "GMT-09:00 - Pacific/Gambier ",
  },
  "GMT-08:00": {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
    full_name: "GMT-08:00 - America/Los_Angeles ",
  },
  "GMT-07:00": {
    offset: "GMT-07:00",
    name: "America/Denver",
    full_name: "GMT-07:00 - America/Denver ",
  },
  "GMT-06:00": {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
    full_name: "GMT-06:00 - Chile/EasterIsland ",
  },
  "GMT-05:00": {
    offset: "GMT-05:00",
    name: "America/New_York",
    full_name: "GMT-05:00 - America/New_York ",
  },
  "GMT-04:30": {
    offset: "GMT-04:30",
    name: "America/Caracas",
    full_name: "GMT-04:30 - America/Caracas ",
  },
  "GMT-04:00": {
    offset: "GMT-04:00",
    name: "America/La_Paz",
    full_name: "GMT-04:00 - America/La_Paz ",
  },
  "GMT-03:00": {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
    full_name: "GMT-03:00 - America/Sao_Paulo ",
  },
  "GMT-03:30": {
    offset: "GMT-03:30",
    name: "America/St_Johns",
    full_name: "GMT-03:30 - America/St_Johns ",
  },
  "GMT-02:00": {
    offset: "GMT-02:00",
    name: "America/Noronha",
    full_name: "GMT-02:00 - America/Noronha ",
  },
  "GMT-01:00": {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
    full_name: "GMT-01:00 - Atlantic/Cape_Verde ",
  },
  GMT: {
    offset: "GMT",
    name: "Europe/London",
    full_name: "GMT - Europe/London ",
  },
  UTC: {
    offset: "UTC",
    name: "UTC",
    full_name: "UTC - UTC ",
  },
  "GMT+01:00": {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
    full_name: "GMT+01:00 - Europe/Brussels ",
  },
  "GMT+02:00": {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
    full_name: "GMT+02:00 - Asia/Jerusalem ",
  },
  "GMT+03:00": {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
    full_name: "GMT+03:00 - Europe/Minsk ",
  },
  "GMT+03:30": {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
    full_name: "GMT+03:30 - Asia/Tehran ",
  },
  "GMT+04:00": {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
    full_name: "GMT+04:00 - Europe/Moscow ",
  },
  "GMT+04:30": {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
    full_name: "GMT+04:30 - Asia/Kabul ",
  },
  "GMT+05:00": {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
    full_name: "GMT+05:00 - Asia/Tashkent ",
  },
  "GMT+05:30": {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
    full_name: "GMT+05:30 - Asia/Kolkata ",
  },
  "GMT+05:45": {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
    full_name: "GMT+05:45 - Asia/Katmandu ",
  },
  "GMT+06:00": {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
    full_name: "GMT+06:00 - Asia/Yekaterinburg ",
  },
  "GMT+06:30": {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
    full_name: "GMT+06:30 - Asia/Rangoon ",
  },
  "GMT+07:00": {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
    full_name: "GMT+07:00 - Asia/Novosibirsk ",
  },
  "GMT+08:00": {
    offset: "GMT+08:00",
    name: "Australia/Perth",
    full_name: "GMT+08:00 - Australia/Perth ",
  },
  "GMT+08:45": {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
    full_name: "GMT+08:45 - Australia/Eucla ",
  },
  "GMT+09:00": {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
    full_name: "GMT+09:00 - Asia/Tokyo ",
  },
  "GMT+09:30": {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
    full_name: "GMT+09:30 - Pacific/Marquesas ",
  },
  "GMT+10:00": {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
    full_name: "GMT+10:00 - Asia/Yakutsk ",
  },
  "GMT+10:30": {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
    full_name: "GMT+10:30 - Australia/Lord_Howe ",
  },
  "GMT+11:00": {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
    full_name: "GMT+11:00 - Asia/Vladivostok ",
  },
  "GMT+11:30": {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
    full_name: "GMT+11:30 - Pacific/Norfolk ",
  },
  "GMT+12:00": {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
    full_name: "GMT+12:00 - Pacific/Auckland ",
  },
  "GMT+12:45": {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
    full_name: "GMT+12:45 - Pacific/Chatham ",
  },
  "GMT+13:00": {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
    full_name: "GMT+13:00 - Pacific/Tongatapu ",
  },
  "GMT+14:00": {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
    full_name: "GMT+14:00 - Pacific/Kiritimati ",
  },
};
