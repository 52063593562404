import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col, Upload } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { InboxOutlined } from '@ant-design/icons';
import { Tabs } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { training_api } from "../../library/training_api";
import { certificatebody_api } from "../../library/certificatebody_api";
import { training_review_api } from "../../library/training_review_api";
import { course_api } from "../../library/course_api";
import { location_api } from "../../library/location_api";
import { venue_api } from "../../library/venue_api";
import { states_api } from '../../library/states_api';
import { region_api } from "../../library/region_api";
import { payment_api} from '../../library/payment_api';
import { bulk_upload_api } from "../../library/bulk_upload_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import { find } from 'lodash'
// added
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import FormDateGroup from "../part/Form/FormDateGroup";
import FormTimeGroup from "../part/Form/FormTimeGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import { country_array } from "../local_data/variousCountryListFormats";
import { timezone_array } from "../local_data/timezones";
import { currency_array } from "../local_data/currency";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import MyLoading from "../part/MyLoading";
import FormRadioGroup from "../part/Form/FormRadioGroup";
import AddNew from "../part/AddNew";
import AddNewImport from "../part/AddNewImport";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import { back_api } from "../../library/back_api";
import Reviews from "./Reviews";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faTrash, faEnvelope, faUserPlus, faPlusCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
const { TabPane } = Tabs;
const { Dragger } = Upload;

// ===============================================================================
class Training extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      trainingreviewimport: "",
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        // course_name: { valMet: vj_val.checkLength, valLen: 1 },
        languages: { valMet: vj_val.checkLength, valLen: 1 },
        duration: { valMet: vj_val.checkNumber, valLen: 1 },
        // region: { valMet: vj_val.checkLength, valLen: 1 },
        start_date: { valMet: vj_val.checkLength, valLen: 1 },
        start_time: { valMet: vj_val.checkLength, valLen: 1 },
        end_time: { valMet: vj_val.checkLength, valLen: 1 },
        // result_publish_start: { valMet: vj_val.checkLength, valLen: 1 },
        // result_publish_end: { valMet: vj_val.checkLength, valLen: 1 },
        // certificate_publish_start: { valMet: vj_val.checkLength, valLen: 1 },
        // certificate_publish_end: { valMet: vj_val.checkLength, valLen: 1 },
        delivery_method: { valMet: vj_val.checkLength, valLen: 1 },
        maxseats: { valMet: vj_val.checkNumber, valLen: 1 },
		minseats: { valMet: vj_val.checkNumber, valLen: 1 },
        timezone: { valMet: vj_val.checkLength, valLen: 1 },
        currency: { valMet: vj_val.checkLength, valLen: 1 },
        course_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        certificate_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        // instructor_1 : { valMet: vj_val.checkLength, valLen: 1 },
        // instructor_2 : { valMet: vj_val.checkLength, valLen: 1 },
        // partner : { valMet: vj_val.checkLength, valLen: 1 },
        // exam: { valMet: vj_val.checkLength, valLen: 1 },
        // repeat_exam: { valMet: vj_val.checkLength, valLen: 1 },
        // evaluation_template: { valMet: vj_val.checkLength, valLen: 1 },
        lca_template: { valMet: vj_val.checkLength, valLen: 1 },
        // pre_course_template: { valMet: vj_val.checkLength, valLen: 1 },
        
        // ============================================================
        // ============================================================
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
      uploadFileList: [],
      venue:[],
      courseId:"",
      regionId:"",
      states:[],
      certificate_allow:"No",
      evaluation_allow:"No",
      exam_allow:"No",
      lca_allow:"No",
      modelBulkUpload:false,
      uploadingIssues:[],
      issueRows:0,
      processRow:0,
      bulkUploadDone:false,
      uploadingLoader:false,
      currencyList:[]
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    course_title: {
      title: "Course Name",
      width: 300,
      className: "course_name",
      sorter: true,
      order: 1,
    },
    region: {
      title: "Region",
      // width: 300,
      className: "region",
      order: 2,
      sorter: true,
      ellipsis: true,
    },
    country_name: {
      title: "Country Name",
      // width: 300,
      className: "country_name",
      order: 3,
      sorter: true,
      ellipsis: true,
    },
    start_date: {
      title: "Start Date",
      // width: 300,
      className: "start_date",
      order: 4,
      sorter: true,
      ellipsis: true,
    },
    course_fees: {
      title: "Course Fees",
      // width: 300,
      className: "course_fees",
      order: 5,
      sorter: true,
      ellipsis: true,
    },
    certificate_fees: {
      title: "Certificate Fees",
      // width: 300,
      className: "certificate_fees",
      order: 6,
      sorter: true,
      ellipsis: true,
    },
    seats: {
      title: "Seats",
      // width: 300,
      className: "seats",
      order: 7,
      sorter: true,
      ellipsis: true,
    },
    languages: {
      title: "Language",
      // width: 300,
      className: "language",
      order: 8,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        return text.split(",").map((t) => this.state.language2[t]);
      },
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 9,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    let queryParms = new URLSearchParams(window.location.search);
	let _id = queryParms.get("identifier")
    let courseId = queryParms.get("cid");
    let regionId = queryParms.get("rid");

    const url = course_api + "/region/schedule?courseId="+courseId+"&regionId="+regionId;
    const trainingResponse = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });

    const regionInfoResponse = await myAxios({
      method: "post",
      url: region_api + "/regionDetails",
      header: { Chabi },
      request:{ _id : regionId}
    });
    if(regionInfoResponse.status == 200){
       let { data, status} = regionInfoResponse;
       if(status == 200){
        const currency = currency_array.filter((r) => r.code === data.records[0].currency)[0];
        this.setState({currencyList: [currency], request: { ...this.state.request, currency: currency.code}})
      
       }
    }


    const regionUrl = region_api + "/region/country/"+regionId;
    const regionResponse = await myAxios({
      method: "get",
      url:regionUrl,
      header: { Chabi },
    });
	
	const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
	

    const response = await get_data({
      others: [
        "delivery_method",
        "learning_scheme",
        "course",
        "language",
        "location",
        "region",
        "organization",
        "consultant",
        "Partner",
        "Exam_template",
        "evaluation_template",
        "lca_template",
        "pre_course_template",
        "certificate_template",
        "country",
        "delivery_method_notonline",
      ],
      primary: "",
      extra: { type: "normal" },
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          delivery_method,
          learning_scheme,
          course,
          language,
          location,
          region,
          organization,
          consultant,
          Partner,
          Exam_template,
          evaluation_template,
          lca_template,
          pre_course_template,
          certificate_template,
          country,
          delivery_method_notonline,
        } = data;

        let location2 = create_object({
          arr: location,
          title: "location_name",
        });

        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        this.setState({
          organization2,
          organization,
          learning_scheme,
          learning_scheme2,
          course,
          course2,
          language,
          language2,
          delivery_method,
          delivery_method2,
          region,
          records: add_sno(trainingResponse.data.records),
          location,
          location2,
          consultant,
          Partner,
          Exam_template,
          evaluation_template,
          lca_template,
          pre_course_template,
          certificate_template,
          country: regionResponse.data.records,
		  certificatebody:certificateBodyResponse.data.records
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  onReload = async () => {
    this.setState({ CDM: false });
    const { doUserLogout, Chabi } = this.state;
    let queryParms = new URLSearchParams(window.location.search);
	  let _id = queryParms.get("identifier")
    let courseId = queryParms.get("cid");
    let regionId = queryParms.get("rid");
    
    const url = course_api + "/region/schedule?courseId="+courseId+"&regionId="+regionId;
    const trainingResponse = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });

    const regionUrl = region_api + "/region/country/"+regionId;
    const regionResponse = await myAxios({
      method: "get",
      url:regionUrl,
      header: { Chabi },
    });
	
	const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });

    const response = await get_data({
      others: [
        "delivery_method",
        "learning_scheme",
        "course",
        "language",
        "location",
        "region",
        "organization",
        "consultant",
        "Partner",
        "Exam_template",
        "evaluation_template",
        "lca_template",
        "pre_course_template",
        "certificate_template",
        "country"
      ],
      primary: "",
      extra: { type: "normal" },
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          delivery_method,
          learning_scheme,
          course,
          language,
          location,
          region,
          organization,
          consultant,
          Partner,
          Exam_template,
          evaluation_template,
          lca_template,
          pre_course_template,
          certificate_template,
          country
        } = data;

        let location2 = create_object({
          arr: location,
          title: "location_name",
        });

        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        this.setState({
          courseId,
          regionId,
          organization2,
          organization,
          learning_scheme,
          learning_scheme2,
          course,
          course2,
          language,
          language2,
          delivery_method,
          delivery_method2,
          region,
          records: add_sno(trainingResponse.data.records),
          location,
          location2,
          consultant,
          Partner,
          Exam_template,
          evaluation_template,
          lca_template,
          pre_course_template,
          certificate_template,
          country: regionResponse.data.records,
		  // certificatebody:certificateBodyResponse.data.records,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  }

  // on file upload
  fileUpload = (e) => {
    /*this.setState({
            [e.target.id]: e.target.files[0]
        })*/
    this.setState({
      trainingreviewimport: e,
    });
  };

  addTrainingReview = async () => {
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = training_review_api;
    //const url = training_review_api + "/training_review";

    let form_data = new FormData();
    //form_data.append('trainingreviewimport', this.state.trainingreviewimport);
    form_data.append("training_id", this.state.selectedRecord._id);
    form_data.append(
      "trainingreviewimport",
      this.state.trainingreviewimport.originFileObj,
      this.state.trainingreviewimport.name
    );

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: form_data,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
		this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          //cmodal: false,
		  cmodali: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");

    /*try {
            var {data} = await Axios.get(Constant.apiBasePath + 'exportChapterData');
            if(data.status === Constant.statusSuccess) {
                var csvString = data.data;
                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
                a.setAttribute('download', 'chapters.csv');
                window.document.body.appendChild(a);
                a.click();
                window.location.reload();
            }
            else {
                alert(data.message);
                return false;
            }
        }
        catch(error) {
            console.log(error);
        }*/
  };
  addTraining = async () => {
    let queryParms = new URLSearchParams(window.location.search);
	  let _id = queryParms.get("identifier")
    let courseId = queryParms.get("cid");
    let regionId = queryParms.get("rid");

    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let finalRequest = { ...request, courseId, regionId}
    let { records } = this.state;
    const url = `${course_api}/region/schedule`;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: finalRequest,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        this.setState({
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
          cmodali: false,
          activeKey: "1",
          nextTabMsg: "",
        });
        this.onReload();
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editTraining = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      course_name,
      languages,
      region,
      delivery_method,
      country,
      // states,
      location,
      venue,
      currency,
      seats,
	  minseats,
      timezone,
      duration,
      examination_fee,
      course_fees,
      material_fees,
	  print_material_fees,
	  print_material_fees_30days,
      certificate_fees,
      start_time,
      end_time,
      start_date,
      result_publish_start,
      result_publish_end,
      certificate_publish_start,
      certificate_publish_end,
      releated_training,
      book_dis_fees_90,
      book_dis_fees_60,
      book_dis_fees_30,
      book_dis_fees_1,
      book_dis_public,
      book_dis_organization,
      instructor_1,
      instructor_2,
      instructor_3,
      partner,
      exam,
      repeat_exam,
      exam_2,
      repeat_exam_2,
      evaluation_template,
      lca_template,
      lca_template_2,
      class_date,
      pre_course_template,
      certificate_completion_template,
      certificate_attendance_template,
      certificatebody,
      proposalImage,
      states,
      maxseats
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = `${course_api}/region/schedule`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        course_name,
        languages,
        // region,
        states,
        examination_fee,
        delivery_method,
        country,
        location,
        venue,
        currency,
        seats,
		minseats,
        timezone,
        duration,
        course_fees,
        material_fees,
		print_material_fees,
		print_material_fees_30days,
        certificate_fees,
        start_time,
        end_time,
        start_date,
        // result_publish_start,
        // result_publish_end,
        // certificate_publish_start,
        // certificate_publish_end,
        releated_training,
        book_dis_fees_90,
        book_dis_fees_60,
        book_dis_fees_30,
        book_dis_fees_1,
        book_dis_public,
        book_dis_organization,
        instructor_1,
        instructor_2,
        instructor_3,
        partner,
        exam,
        repeat_exam,
        exam_2,
        repeat_exam_2,
        evaluation_template,
        lca_template,
        lca_template_2,
        pre_course_template,
        certificate_completion_template,
        certificate_attendance_template,
        certificatebody,
        class_date,
        proposalImage,
        maxseats
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
          cmodali: false,
          activeKey: "1",
          nextTabMsg: "",
        });
        this.onReload();
      } else if (data.status == 200) {
        message.success("Record Updated");
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
          cmodali: false,
          activeKey: "1",
          nextTabMsg: "",
        });
        this.onReload();
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${training_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  nextManage = ({ activeKey }) => {
  
    if (activeKey == "2") {
      const fields = [
        "languages",
        "duration",
        "start_date",
        "start_time",
        "end_time",
        // "result_publish_start",
        // "result_publish_end",
        // "certificate_publish_start",
        // "certificate_publish_end",
      ];
      const { val_result, reason } = this.checkNextValidation({
        fields,
      });

      // this.checkRegionValidation();
      if (val_result) this.setState({ activeKey: "2" });
      else this.setState({ validation: reason });
    } else if(activeKey == "1"){
      this.setState({ activeKey: "1" });
    }else if(activeKey == "3"){
      // const fields = [
      //   "course_name",
      //   "region",
      //   "languages",
      //   "duration",
      //   "start_date",
      //   "start_time",
      //   "end_time",
      //   "result_publish_start",
      //   "result_publish_end",
      //   "certificate_publish_start",
      //   "certificate_publish_end",
      //   "delivery_method",
      //   "seats",
      //   "timezone",
      //   "currency",
      //   "course_fees",
      //   "certificate_fees",
      // ];
      // const { val_result, reason } = this.checkNextValidation({
      //   fields,
      // });
      
      // if (val_result) this.setState({ activeKey: "3" });
      // else this.setState({ validation: reason });
      this.setState({ activeKey: "3" });
    }else{
      // const fields = [
      //   "course_name",
      //   "region",
      //   "languages",
      //   "duration",
      //   "start_date",
      //   "start_time",
      //   "end_time",
      //   "result_publish_start",
      //   "result_publish_end",
      //   "certificate_publish_start",
      //   "certificate_publish_end",
      //   "delivery_method",
      //   "seats",
      //   "timezone",
      //   "currency",
      //   "course_fees",
      //   "certificate_fees",
      //   "instructor_1",
      //   "instructor_2",
      //   "partner",
      // ];
      // const { val_result, reason } = this.checkNextValidation({
      //   fields,
      // });
    
      // if (val_result) this.setState({ activeKey: "4" });
      // else this.setState({ validation: reason });
      const { request, certificatebody } = this.state;
      if(request.certificatebody != undefined){
        const certificateRecord = certificatebody.filter((r) => r._id == request.certificatebody)[0];
        let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
        request["certificatebody"] = request.certificatebody;
        this.setState({ request , certificate_allow, evaluation_allow,exam_allow, lca_allow});
      }

      this.setState({ activeKey: "4" });
    }
  };

  renderState = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;

     let stateUrl = states_api + "/dropdown/gets/"+value;
     const stateResponse = await myAxios({
      method: "get",
      url: stateUrl,
      header: { Chabi },
    });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , states: stateResponse.data.records});
  }

  renderLocation = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;
    let url = location_api + "/countryBy?stateId="+value;
     const locationResponse = await myAxios({
       method: "post",
       url,
       header: { Chabi },
     });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , location: locationResponse.data.records});
  }

// on edit render venue
  rendervenue = async (obj) => {
    const { name, value } = obj;
    const { Chabi, record } = this.state;
    let venueUrl = venue_api + "/countryBy?location="+value;
     const venueResponse = await myAxios({
       method: "post",
       url: venueUrl,
       header: { Chabi },
     });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request, venue: venueResponse.data.records})
  }

  renderCertificateBody = async (obj) => {
    const { Chabi, records } = this.state;
    const certificateBodyUrl = certificatebody_api + "dropdown/get";
    const certificateBodyResponse = await myAxios({
      method: "get",
      url:certificateBodyUrl,
      header: { Chabi },
    });
    let recordsDt = records.filter((r) => r._id == obj)[0];
    let coutry = recordsDt.country;
    let location = recordsDt.location;
    this.renderState({name:"country", value:coutry})
    this.rendervenue({name: "location", value:location})
        const certificateRecord = certificateBodyResponse.data.records.filter((r) => r._id == recordsDt.certificatebody)[0];
    if(certificateRecord){
      let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord

      let validation_info = {
        evaluation_template: { valMet: vj_val.checkLength, valLen: 1 }
      }
      if(Boolean(exam_allow)){
        Object.assign(validation_info, {}, { exam: { valMet: vj_val.checkLength, valLen: 1 }, repeat_exam: { valMet: vj_val.checkLength, valLen: 1 },})
      }
      if(Boolean(lca_allow)){
        Object.assign(validation_info, {}, { lca_template: { valMet: vj_val.checkLength, valLen: 1 }})
      }
      if(Boolean(certificate_allow)){
        Object.assign(validation_info, {}, { certificate_completion_template: { valMet: vj_val.checkLength, valLen: 1 }, certificate_attendance_template: { valMet: vj_val.checkLength, valLen: 1 }})
      }

      this.setState({ certificatebody:certificateBodyResponse.data.records, certificate_allow, evaluation_allow,exam_allow,  validation_info: {...this.state.validation_info, ...validation_info}})
    }else{
      this.setState({ certificatebody:certificateBodyResponse.data.records, certificate_allow:'No', evaluation_allow:'No',exam_allow:'No', lca_allow:'No'})
    }
  }

  renderAddForm = async () => {
    let queryParms = new URLSearchParams(window.location.search);
	  let _id = queryParms.get("identifier")
    let courseId = queryParms.get("cid");
    let regionId = queryParms.get("rid");
    const { Chabi } = this.state;
    let venueUrl = course_api + "/mapping/schedule?courseId="+courseId+"&regionId="+regionId;
     const venueResponse = await myAxios({
       method: "get",
       url: venueUrl,
       header: { Chabi },
     });

     const regionInfoResponse = await myAxios({
      method: "post",
      url: region_api + "/regionDetails",
      header: { Chabi },
      request:{ _id : regionId}
    });
    const currency = currency_array.filter((r) => r.code === regionInfoResponse.data.records[0].currency)[0];

     let request = {}
     request['course_name'] = venueResponse.data.records.course.main_head
     request['region'] = venueResponse.data.records.region.region_name
     request['currency'] = currency.code
     this.setState({request})
  }

  renderAssementExamination = async (obj) => {
    const { name, value } = obj;
    const { Chabi, certificatebody } = this.state;
    const certificateRecord = certificatebody.filter((r) => r._id == value)[0];
    let { certificate_allow, evaluation_allow,exam_allow, lca_allow } = certificateRecord
   
    let validation_info = {
      evaluation_template: { valMet: vj_val.checkLength, valLen: 1 }
    }
    if(Boolean(exam_allow)){
      Object.assign(validation_info, {}, { exam: { valMet: vj_val.checkLength, valLen: 1 }, repeat_exam: { valMet: vj_val.checkLength, valLen: 1 },})
    }
    if(Boolean(lca_allow)){
      Object.assign(validation_info, {}, { lca_template: { valMet: vj_val.checkLength, valLen: 1 }})
    }
    if(Boolean(certificate_allow)){
      Object.assign(validation_info, {}, { certificate_completion_template: { valMet: vj_val.checkLength, valLen: 1 }, certificate_attendance_template: { valMet: vj_val.checkLength, valLen: 1 }})
    }

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , certificate_allow, evaluation_allow,exam_allow, lca_allow, validation_info: {...this.state.validation_info, ...validation_info}});
  }

  fileUpload = async (fl) => {
    let queryParms = new URLSearchParams(window.location.search);
    let courseId = queryParms.get("cid");
    let regionId = queryParms.get("rid");
    const { uploadFileList, Chabi } = this.state;
    const { file } = fl;
    const url = payment_api + "image_upload";
    const formData = new FormData();
    formData.append("type", "pdf"); //notice here
    formData.append("itemName", `${courseId}_${regionId}`); //notice here
    formData.append("folder", `${courseId}/${regionId}/propsalPdf`); //notice here
    formData.append("file", file.originFileObj, file.originFileObj.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 200){
        const { data: locationData } = data;
        this.setRequest({ name: "proposalImage", value: locationData.location });
        this.setState({ uploadFileList: locationData.location });
      }else{
        return message.error("image not uploaded")
      }
      
    }
  };

  bulkFileOneChange = (info) => {
    this.setState({uploadingLoader: true})
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      if(info.file.response){
        const { issueArr, errorRows, processRows} = info.file.response.data
        this.setState({uploadingLoader: false, uploadingIssues: issueArr, issueRows: errorRows  , processRow: processRows, bulkUploadDone: true})
      }
      // uploadingIssues
      // issueRows
      message.success(info.file.response.message);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      this.setState({uploadingLoader: false });
    }
  }

  setRequest = (obj) => {
    const { name, value } = obj;
    const { filtered_records, request, validation_info } = this.state;
    if(name == "delivery_method"){
      if(value == "623afef06fbc25650029f46e" || value == "623afee36fbc25650029f46c"){
             this.setState({
               validation_info : { 
                 ...validation_info, 
                 country: { valMet: vj_val.checkLength, valLen: 1 },
                 states: { valMet: vj_val.checkLength, valLen: 1 },
                 location: { valMet: vj_val.checkLength, valLen: 1 },
                 venue: { valMet: vj_val.checkLength, valLen: 1 },
           }
         })
      }

          }else if(value == "623afee96fbc25650029f46d" || value == '623afedf6fbc25650029f46b'){
      let valObj = this.state.validation_info
      delete valObj['venue']
      delete valObj['states']
      delete valObj['location']
      this.setState({
        validation_info : { 
          ...valObj, 
          country: { valMet: vj_val.checkLength, valLen: 1 },
          // states: { valMet: vj_val.checkLength, valLen: 1 },
          // location: { valMet: vj_val.checkLength, valLen: 1 },
        }
      })
    }
    request[name] = value;
    this.setState({ request });
  };

  render() {
    console.log("training State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      cmodali,
      request,
      filtered_records,
      learning_scheme,
      language,
      region,
      activeKey,
      seomodal,
      course,
      venue,
      location,
      delivery_method,
      nextTabMsg,
      organization,
      CDM,
      CDMI,
      coffcanvas,
      selectedRecord,
      organization2,
      region2,
      course2,
      delivery_method2,
      language2,
      consultant,
      Partner,
      Exam_template,
      evaluation_template,
      lca_template,
      pre_course_template,
      certificate_template,
      country,
	    certificatebody,
      states,
      certificate_allow, 
      evaluation_allow,
      exam_allow, 
      lca_allow,
      modelBulkUpload,
      currencyList

    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      width: 100,
      title: "Tranining Id",
      dataIndex: "_id",
    });
    
    columns.push({
      width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });

    const beforeUpload = (file) => {
      console.log("sdds",file)
      const isJpgOrPng = file.type === 'application/pdf';
      if (!isJpgOrPng) {
        message.error('You can only upload PDF file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );

    return (
      <>
        <PageHeading2 active="Course Region Schedule" page={["Course Region Schedule"]}>
          <AddNew
            disabled={!CDM}
            onClick={() => {
              this.renderAddForm()
              this.setState({ cmodal: !cmodal, edit: false, request: {}, certificate_allow:"No", evaluation_allow:"No",exam_allow:"No", lca_allow:"No" });
            }}
          />
          {/* <button
            className="btn btn-sm btn-primary float-right"
            style={{margin: 7}}
            onClick={() => {
              this.setState({ modelBulkUpload: !modelBulkUpload });
            }}
          >
            Bulk Upload
          </button> */}
        </PageHeading2>
        <MainArea>
          
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={(obj) => {
                this.renderCertificateBody(obj)
                this.loadRecord(obj)}}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodali}
          title="Import Training Review"
          close={this.close_modal_import}
          className="modal-form-course sws_training_pdf"
        >
          <FormContainer>
            <Row>
              <Col span={8}>
                {/* <FormInputGroup
                      type="file"
                      id="trainingreviewimport"
                      name="trainingreviewimport"
                      label="Upload Training Review Excel"
                      placeholder="Upload Training Review Excel"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    /> */}
                <FormFileUploadGroup
                  type="file"
                  id="trainingreviewimport"
                  name="trainingreviewimport"
                  label="Upload Training Review Excel"
                  placeholder="Upload Training Review Excel"
                  data={this.state}
                  required
                  fileUpload={this.fileUpload}
                />
              </Col>
            </Row>
            <FormButton
              submit={() => {
                //check validation status here
                this.addTrainingReview();
              }}
              submit_text="Submit"
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal_import}
            />
          </FormContainer>
        </CustomModal>
        <CustomModal
          key={`manageTranning_${request._id}`}
          status={cmodal}
          title="Manage Course Region Schedule"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Tabs
              defaultActiveKey={"1"}
              activeKey={activeKey}
              onChange={(activeKey) => {
                this.nextManage({ activeKey });
              }}
            >
              <TabPane tab="Info" key="1">
                <Row>
                  <Col span={24}>
                    <FormSelectGroup
                      allowClear
                      id="course_name"
                      name="course_name"
                      label="Course Name"
                      placeholder="Course Name"
                      data={this.state}
                      disabled
                      options={course}
                      option_value="_id"
                      option_text="main_head"
                      setValidation={this.setValidation}
                      sendValue={(obj) => {
                        const { name, value } = obj;
                        const selected_course = course.filter(
                          (c) => c._id == value
                        )[0];
                        const { languages, category, course_type, scheme } = selected_course;
                        let Exam_template_Match = Exam_template.filter(
                          (c) => c.exam_category.toString() == category && c.exam_course_type.toString() == course_type && c.exam_scheme.toString() == scheme
                        )
                        let lca_template_Match = lca_template.filter(
                          (c) => c.category.toString() == category && c.course_type.toString() == course_type && c.scheme.toString() == scheme
                        )
                        this.setState({ lca_template: lca_template_Match, Exam_template: Exam_template_Match});
                        request["languages"] = languages;
                        request[name] = value;
                        this.setState({ request });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                  <FormInputGroup
                      type="text"
                      id="region"
                      name="region"
                      label="Region"
                      placeholder="Region"
                      disabled
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormMultiSelectGroup
                      allowClear
                      id="languages"
                      name="languages"
                      label="Languages"
                      placeholder="Languages"
                      data={this.state}
                      required
                      options={language}
                      option_value="_id"
                      option_text="language"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormInputGroup
                      type="number"
                      id="duration"
                      name="duration"
                      label="Duration (In Days)"
                      placeholder="Duration"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <FormDateGroup
                      id="start_date"
                      name="start_date"
                      label="Start Date"
                      placeholder="Start Date"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormTimeGroup
                      id="start_time"
                      name="start_time"
                      label="Start Time"
                      placeholder="Start Time"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormTimeGroup
                      id="end_time"
                      name="end_time"
                      label="End time"
                      placeholder="End time"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={12}>
                    <FormDateGroup
                      key={request["start_date"]}
                      id="result_publish_start"
                      name="result_publish_start"
                      label="Result Publish Start Date"
                      placeholder="Result Publish Start Date"
                      data={this.state}
                      required
                      disabled={
                        !(request["start_date"] && request["start_date"].length)
                      }
                      disableDateBefore={request["start_date"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={12}>
                    <FormDateGroup
                      id="result_publish_end"
                      name="result_publish_end"
                      label="Result Publish End Date"
                      placeholder="Result Publish End Date"
                      data={this.state}
                      required
                      disabled={
                        !(
                          request["result_publish_start"] &&
                          request["result_publish_start"].length
                        )
                      }
                      disableDateBefore={request["result_publish_start"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
                {/* <Row className="mb-4">
                  <Col span={12}>
                    <FormDateGroup
                      id="certificate_publish_start"
                      name="certificate_publish_start"
                      label="Certificate Publish Start Date"
                      placeholder="Certificate Publish Start Date"
                      data={this.state}
                      required
                      disabled={
                        !(
                          request["result_publish_end"] &&
                          request["result_publish_end"].length
                        )
                      }
                      disableDateBefore={request["result_publish_end"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={12}>
                    <FormDateGroup
                      id="certificate_publish_end"
                      name="certificate_publish_end"
                      label="Certificate Publish End Date"
                      placeholder="Certificate Publish End Date"
                      data={this.state}
                      required
                      disabled={
                        !(
                          request["certificate_publish_start"] &&
                          request["certificate_publish_start"].length
                        )
                      }
                      disableDateBefore={request["certificate_publish_start"]}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
                <Row className="pl-2">
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    this.nextManage({ activeKey: "2" });
                  }}
                >
                  Next
                </button>
                <span className="d-inline-block ml-2 text-danger">
                  {nextTabMsg}
                </span>
                </Col>
                </Row>
              </TabPane>
              <TabPane tab="Details" key="2">
                <Row>
                  <Col span={12}>
                  <FormSelectGroup
                      id="delivery_method"
                      name="delivery_method"
                      label="Delivery Method"
                      placeholder="Delivery Method"
                      data={this.state}
                      options={delivery_method}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                      required
                    />
                  </Col>

                  {this.state.request.delivery_method == "623afef06fbc25650029f46e" ? 
                  <Col span={6}>
                  <FormDateGroup
                    id="class_date"
                    name="class_date"
                    label="Classroom Date"
                    placeholder="Classroom Date"
                    data={this.state}
                    required
                    setValidation={this.setValidation}
                    sendValue={this.setRequest}
                  />
                </Col>
                : null}
                  <Col span={this.state.request.delivery_method == "623afef06fbc25650029f46e" ? 6 : 12}>
                    <FormInputGroup
                      type="number"
                      id="maxseats"
                      name="maxseats"
                      label="Maximum Seats"
                      placeholder="Maximum Seats"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
					   <FormInputGroup
                      type="number"
                      id="minseats"
                      name="minseats"
                      label="Minimum Seats"
                      placeholder="Minimum Seats"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormSelectGroup
                      id="country"
                      name="country"
                      label="Country"
                      sub_label=""
                      placeholder="Country"
                      data={this.state}
                      options={country}
                      option_value="isoCode"
                      option_text="name"
                      setValidation={this.setValidation}
                      sendValue={this.renderState}
                    />
                  </Col>
                  {this.state.request.delivery_method != "623afedf6fbc25650029f46b" && this.state.request.delivery_method != "623afee96fbc25650029f46d" ?
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="states"
                      name="states"
                      label="States"
                      sub_label=""
                      placeholder="States"
                      data={this.state}
                      options={states}
                      option_value="_id"
                      option_text="state_name"
                      setValidation={this.setValidation}
                      sendValue={this.renderLocation}
                    />
                  </Col> : null}
                  {this.state.request.delivery_method != "623afedf6fbc25650029f46b" && this.state.request.delivery_method != "623afee96fbc25650029f46d" ?
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="location"
                      name="location"
                      label="Location"
                      sub_label=""
                      placeholder="Location"
                      data={this.state}
                      options={location}
                      option_value="_id"
                      option_text="location_name"
                      setValidation={this.setValidation}
                      sendValue={this.rendervenue}
                    />
                  </Col> : null }
                  {this.state.request.delivery_method != "623afedf6fbc25650029f46b" && this.state.request.delivery_method != "623afee96fbc25650029f46d" ?
                  <Col span={6}>
                    <FormSelectGroup
                      id="venue"
                      name="venue"
                      label="Venue"
                      sub_label=""
                      placeholder="Venue"
                      data={this.state}
                      options={venue}
                      option_value="_id"
                      option_text="venue_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  :  null}
                  <Col span={6}>
                    <FormSelectGroup
                      id="timezone"
                      name="timezone"
                      label="Timezone"
                      placeholder="Timezone"
                      data={this.state}
                      required
                      options={timezone_array}
                      option_value="name"
                      option_text="full_name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>

                  <Col span={6}>
                    <FormSelectGroup
                      id="currency"
                      name="currency"
                      label="Currency"
                      placeholder="Currency"
                      data={this.state}
                      disabled
                      options={currencyList}
                      option_value="code"
                      option_text="full_name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                    {/* <FormInputGroup
                      type="currency"
                      id="currency"
                      name="Currency"
                      label="Currency"
                      placeholder="Currency"
                      disabled
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    /> */}
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="course_fees"
                      name="course_fees"
                      label="Course Fees"
                      placeholder="Course Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="material_fees"
                      name="material_fees"
                      label="Material Fees"
                      sub_label="(Optional)"
                      placeholder="Material Fees"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
				  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="print_material_fees"
                      name="print_material_fees"
                      label="Printed Material Fees "
                      sub_label="(Optional)"
                      placeholder="Printed Material Fees"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
				  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="print_material_fees_30days"
                      name="print_material_fees_30days"
                      label="Printed Material Fees (Greater than 30days)"
                      sub_label="(Optional)"
                      placeholder="Printed Material Fees (Greater than 30days)"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="certificate_fees"
                      name="certificate_fees"
                      label="Certificate Fees"
                      placeholder="Certificate Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      id="examination_fee"
                      name="examination_fee"
                      label="Examination Fees"
                      placeholder="Examination Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormRadioGroup
                      label="Schedule Type"
                      name="book_dis_public"
                      data={this.state}
                      options={[
                        { value: 1, text: "Is Public" },
                        { value: 0, text: "Is Private" },
                      ]}
                      defaultValue={{ value: 1, text: "Is Public" }}
                      option_value="value"
                      option_text="text"
                      sendValue={this.setRequest}
                    />
                  </Col>
                  {request.book_dis_public == 0 ? (
                    <>
                    <Col span={12}>
                      <FormMultiSelectGroup
                        allowClear
                        id="book_dis_organization"
                        name="book_dis_organization"
                        label="Organization Name"
                        placeholder="Organization Name"
                        data={this.state}
                        required
                        options={organization}
                        option_value="_id"
                        option_text="organization_name"
                        sendValue={this.setRequest}
                      />
                    </Col>
                    <Col span={24}>
                    <label>Upload</label>
                    <Upload
                        maxCount={1}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={this.fileUpload}
                        accept="application/pdf"
                      >
                        {this.state.request.proposalImage ? (
                          <img
                            src={this.state.request.proposalImage}
                            alt="Pdf uploaded"
                            style={{
                              width: '100%',
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                  </Col>
                  </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_90"
                      label="90 Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_60"
                      label="60 Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_30"
                      label="30 Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInputGroup
                      type="number"
                      name="book_dis_fees_1"
                      label="Below Days Amount"
                      placeholder="Days Amount"
                      data={this.state}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                {/* <Row  className="mb-4">
                  <Col span={24}>
                    <FormMultiSelectGroup
                      allowClear
                      id="releated_training"
                      name="releated_training"
                      label="Releated Training"
                      placeholder="Releated Training"
                      data={this.state}required
                      options={records}
                      option_value="_id"
                      option_text="course_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
                <Row className="pl-2">
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    this.nextManage({ activeKey: "2" });
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-sm btn-outline-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "3" });
                  }}
                >
                  Next
                </button>
                <span className="d-inline-block ml-2 text-danger">
                  {nextTabMsg}
                </span>
                </Col>
                </Row>
              </TabPane>
              {/* Instructors and Partners */}
              <TabPane tab="Consultants and Partners" key="3">

                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="instructor_1"
                      name="instructor_1"
                      label="Consultant 1"
                      sub_label=""
                      placeholder="Choose Consultant 1"
                      data={this.state}
                      options={consultant}
                      option_value="_id"
                      option_text="name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                 
                </Row>
                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="instructor_2"
                      name="instructor_2"
                      label="Consultant 2"
                      placeholder="Choose Consultant 2"
                      data={this.state}
                      required
                      options={consultant}
                      option_value="_id"
                      option_text="name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="instructor_3"
                      name="instructor_3"
                      label="Consultant 3"
                      placeholder="Choose Consultant 3"
                      data={this.state}
                      required
                      options={consultant}
                      option_value="_id"
                      option_text="name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <FormSelectGroup
                      id="partner"
                      name="partner"
                      label="Partner"
                      placeholder="choose partner"
                      data={this.state}
                      required
                      options={Partner}
                      option_value="_id"
                      option_text="company_name"
                      // valMet={checkUuid}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                <Row className="pl-2">
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    this.nextManage({ activeKey: "2" });
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-sm btn-outline-primary ml-2"
                  onClick={() => {
                    this.nextManage({ activeKey: "4" });
                  }}
                >
                  Next
                </button>
                <span className="d-inline-block ml-2 text-danger">
                  {nextTabMsg}
                </span>
                </Col>
                </Row>
              </TabPane>
              {/* Assessment and Examination */}
              <TabPane tab="Assessment and Examination" key="4">
                <Row>
                  <Col span={24}>
                    <FormSelectGroup
                      id="certificatebody"
                      name="certificatebody"
                      label="Certificate Body"
                      placeholder="Certificate Body"
                      data={this.state}
                      options={certificatebody}
                      required
                      option_value="_id"
                      option_text="certificate_body_name"
                      setValidation={this.setValidation}
                      sendValue={this.renderAssementExamination}
                    />
                  </Col>
                  </Row>

                  {(exam_allow === "Yes") ?  
                   <Row>
				            <Col span={6}>
                      <FormSelectGroup
                        id="exam"
                        name="exam"
                        label="Exam"
                        placeholder="Exam"
                        data={this.state}
                        options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                        option_value="_id"
                        required={Boolean(exam_allow)}
                        option_text="exam_name"
                        setValidation={this.setValidation}
                        sendValue={this.setRequest}
                      />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="repeat_exam"
                      name="repeat_exam"
                      label="Repeat Exam"
                      placeholder="Repeat Exam"
                      data={this.state}
                      required={Boolean(exam_allow)}
                      options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                      option_value="_id"
                      option_text="exam_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      id="exam_2"
                      name="exam_2"
                      label="Exam 2"
                      placeholder="Exam 2"
                      sub_label="(Optional)"
                      data={this.state}
                      
                      options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                      option_value="_id"
                      option_text="exam_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="repeat_exam_2"
                      name="repeat_exam_2"
                      label="Repeat Exam 2"
                      placeholder="Repeat Exam 2"
                      sub_label="(Optional)"
                      data={this.state}
                      options={(Exam_template != undefined) ? [...Exam_template, { _id: "null", exam_name: "Does not apply"}] : []}
                      option_value="_id"
                      option_text="exam_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  </Row> : null}

                  <Row>
                 
                  {(evaluation_allow === "Yes") ?
                  <Col span={12}>
                    <FormSelectGroup
                      id="evaluation_template"
                      name="evaluation_template"
                      label="Evaluation Template"
                      placeholder="Evaluation Template"
                      data={this.state}
                      required={Boolean(evaluation_allow)}
                      options={(evaluation_template != undefined) ? [...evaluation_template]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> : null }

                  {(lca_allow === "Yes") ? 
                  <><Col span={6}>
                    <FormSelectGroup
                      id="lca_template"
                      name="lca_template"
                      label="LCA Template"
                      placeholder="LCA Template"
                      data={this.state}
                      required={Boolean(lca_allow)}
                      options={(lca_template != undefined) ? [...lca_template]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  {/* <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="lca_template_2"
                      name="lca_template_2"
                      label="LCA Template 2"
                      placeholder="LCA Template 2"
                      sub_label="(Optional)"
                      data={this.state}
                      options={(lca_template != undefined) ? [...lca_template]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> */}
                  </> : null }

                  </Row>

                  <Row>
                  {/* <Col span={12}>
                    <FormSelectGroup
                      id="pre_course_template"
                      name="pre_course_template"
                      label="Pre Course Template"
                      placeholder="Pre Course Template"
                      data={this.state}
                      options={(pre_course_template != undefined) ? [...pre_course_template, { _id: "null", template_name: "Does not apply"}]: []}
                      option_value="_id"
                      option_text="template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> */}
                  {(certificate_allow === "Yes") ? <>
                  <Col span={12}>
                    <FormSelectGroup
                      id="certificate_completion_template"
                      name="certificate_completion_template"
                      label="Certificate Template"
                      sub_label="(Successful Completion)" 
                      placeholder="Certificate Template"
                      data={this.state}
                      required={Boolean(certificate_allow)}
                      options={certificate_template}
                      option_value="_id"
                      option_text="certificate_template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                 
                  <Col span={12}>
                    <FormSelectGroup
                      id="certificate_attendance_template"
                      name="certificate_attendance_template"
                      label="Certificate Template"
                      sub_label="(Attendance Only)" 
                      placeholder="Certificate Template"
                      data={this.state}
                      required={Boolean(certificate_allow)}
                      options={certificate_template}
                      option_value="_id"
                      option_text="certificate_template_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> </>: null}
                  </Row>
                  <Row className="pl-2">
                  <Col span={24}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    this.nextManage({ activeKey: "3" });
                  }}
                >
                  Back
                </button>
                </Col></Row>
              </TabPane>
            </Tabs>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editTraining();
                else this.addTraining();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      course_name,
                      certificate_fees,
                      course_fees,
                      currency,
                      delivery_method,
                      duration,
                      languages,
                      region,
                      material_fees,
					  print_material_fees,
					  print_material_fees_30days,
                      seats,
                      start_date,
                      start_time,
                      timezone,
                    } = selectedRecord;
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {course2[course_name]}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">Seats</span>
                            <span className="d-block info-value">{seats}</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              Start Date
                            </span>
                            <span className="d-block info-value">
                              {start_date}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 mb-1">
                          <div className="col-4">
                            <span className="d-block info-heading">
                              start_time
                            </span>
                            <span className="d-block info-value">
                              {start_time}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              timezone
                            </span>
                            <span className="d-block info-value">
                              {timezone}
                            </span>
                          </div>
                          <div className="col-4 text-left">
                            <span className="d-block info-heading">Region</span>
                            <span className="d-block info-value">
                              {region2[region]}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 mb-1">
                          <div className="col-4">
                            <span className="d-block info-heading">
                              course_fees
                            </span>
                            <span className="d-block info-value">
                              {course_fees}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              certificate_fees
                            </span>
                            <span className="d-block info-value">
                              {certificate_fees}
                            </span>
                          </div>
                          <div className="col-4 text-left">
                            <span className="d-block info-heading">
                              material_fees
                            </span>
                            <span className="d-block info-value">
                              {material_fees}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 mb-1">
                          <div className="col-4">
                            <span className="d-block info-heading">
                              delivery_method
                            </span>
                            <span className="d-block info-value">
                              {delivery_method}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              languages
                            </span>
                            <span className="d-block info-value">
                              {languages}
                            </span>
                          </div>
                          <div className="col-4 text-left">
                            <span className="d-block info-heading">
                              duration
                            </span>
                            <span className="d-block info-value">
                              {duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tab="Learners" key="2">

			  {/*<div className="mt-2 mb-3">
                    <Link to="/notes" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faStreetView}/> View Course Notes</Link>
                    <Link to="/mail-class" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Mail Class</Link>
                    <Link to="/enroll_new_student" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faUserPlus}/> Enroll New Student</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faUserPlus}/> Invite New Student</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faPlusCircle}/> Add To Litmos</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Disable From Litmos</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faTrash}/> Delete From This Course</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faTrash}/> Delete From Litmos</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faPlusCircle}/> Add ATP Delegate No.</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faSignOutAlt}/> Sign Off</Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Publish All Results </Link>
                    <Link to="/evaluation_report" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Generate Evaluation Report </Link>
                    <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Release All Certificates</Link>
			  </div>*/}

                {selectedRecord.extra.learner.length ? (
                  <div
                    className="table-responsive"
                    style={{
                      background: "#ffffff",
                      marginBottom: "20px",
                    }}
                  >
                    {/* <table class="table table-bordered learner-table">
                      <thead class="border-none">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody className="table-bordered">
                        <tr>
                          <th scope="row">1</th>
                          <td className="">Mark</td>
                          <td>Otto</td>
                          <td>@mdo</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Larry</td>
                          <td>the Bird</td>
                          <td>@twitter</td>
                        </tr>
                      </tbody>
                    </table> */}

                    <table class="table table-bordered learner-table">
                      <thead class="border-none">
                        <tr>
						{/*<th scope="col">
                            <input type="checkbox"></input>
						</th>*/}
                          <th scope="col">Student Name</th>
                          <th scope="col">Results</th>
                          <th scope="col">Exam Code</th>
                          <th scope="col">Exam Result</th>
                          <th scope="col">CQI-IRCA UDN No</th>
                          <th scope="col">Status</th>
                          <th scope="col">Results Action</th>
                          <th scope="col">Certificate</th>
                          <th scope="col">Litmos</th>
                          {/*<th scope="col" style={{ width: "10%" }}>
                            Action
                          </th>*/}
                        </tr>
                      </thead>
                      <tbody className="table-bordered">
                        {selectedRecord?.extra?.learner.map((learner) => {
                          return (
                            <tr>
							{/*<td>
                                <input type="checkbox"></input>
							</td>*/}
                              <td>
                                {learner.first_name}{learner.last_name}
                                <br></br>
                                {learner.email}
                                <br></br>
                                <strong>ID: </strong>{learner._id}
									{/*<button className="btn btn-info btn-small">
                                  Move To Other Course
									</button>*/}
                              </td>
                              <td>
                                <strong>Pre Course:</strong> N/A<br></br>
                                <strong>Attendance:</strong> 100% <br></br>
                                <strong>LCA:</strong> 76%
                              </td>
                              <td>IRCA MD QMS Exam Paper 1</td>
                              <td>81.0</td>
                              <td>
                                233972<br></br>
                                {/*<button className="btn btn-info btn-small">
                                  Edit
                                </button>*/}
                              </td>
                              <td>
                                <strong>Course Status: </strong>{" "}
                                <span class="badge badge-success">
                                  Completed
                                </span>
                                <br></br>
                                <strong>Registration Status: </strong>
                                <span class="badge badge-success">
                                  {" "}
                                  Completed{" "}
                                </span>
                                <br></br>
                                <br></br>
                                <strong>Evaluation Status: </strong>
                                <a
                                  href="#"
                                  className="btn btn-success btn-small"
                                >
                                  Completed
                                </a>
                              </td>
                              <td>Published</td>
                              <td>
                                Certificate Number:<br></br>
                                <br></br>
                                <a
                                  href="#"
                                  className="btn btn-success btn-small"
                                >
                                  102741
                                </a>
                                <br></br>
                                <a
                                  href="#"
                                  className="btn btn-default btn-small"
                                >
                                  102741
                                </a>
                                <br></br>
                                <a
                                  href="#"
                                  className="btn btn-danger btn-small"
                                >
                                  Revoke
                                </a>
                              </td>
                              <td>
                                <span class="badge badge-danger">
                                  Not added in litmos
                                </span>
                              </td>
                              {/*<td>
                                <select>
                                  <option selected>Select Action</option>
                                  <option>Enter/Edit Attendance Result</option>
                                  <option>Enter/Edit LCA Result</option>
                                  <option>Enter Exam Grade</option>
                                  <option>Enter Certificate Details</option>
                                  <option>Edit Student A/C Details</option>
                                  <option>View Profile</option>
                                  <option>Reset Password</option>
                                  <option>Issue Student Guidebook</option>
                                  <option>Unlock Grading</option>
                                  <option>Remove From This Class</option>
                                </select>
                                <Link to="/attendance" className="btn btn-info btn-small mt-2">Attendance</Link>
                                <Link to="/certificates" className="btn btn-info btn-small mt-2">Certificates</Link>
                                <Link to="/delegates" className="btn btn-info btn-small mt-2">Delegates</Link>
                              </td>*/}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <MyEmpty />
                )}

                {/* {selectedRecord.extra.user_training.length ? (
                  <div className="bg-light p-2 mt-2">
                    <p className="info-main-heading mt-3">Training Details </p>
                    {selectedRecord.extra.user_training.map((t) => {
                      const {
                        status,
                        order_id,
                        user_id,
                        create_at,
                        training,
                        removed_by_admin,
                      } = t;
                      const {
                        _id: training_id,
                        certificate_fees,
                        course_fees,
                        course_name,
                        languages: lang,
                        delivery_method: dm,
                        is365,
                        material_fees,
                        start_date,
                        start_time,
                        timezone,
                      } = training;
                      return (
                        <div
                          style={{
                            background: "#ffffff",
                            marginBottom: "20px",
                            padding: "10px",
                          }}
                        >
                          <div className="row mt-1 mb-1">
                            <div className="col-4 text-left">
                              <span className="d-block info-heading">
                                Course
                              </span>
                              <span className="d-block info-value">
                                {course2[course_name]}
                              </span>
                            </div>
                            {is365 ? (
                              ""
                            ) : (
                              <div className="col-4">
                                <span className="d-block info-heading">
                                  Delivery Method
                                </span>
                                <span className="d-block info-value">
                                  {dm
                                    ? dm
                                        .split(",")
                                        .map((t) => delivery_method2[t])
                                        .toString(",")
                                    : ""}
                                </span>
                              </div>
                            )}
                            <div className="col-4">
                              <span className="d-block info-heading">
                                Languages
                              </span>
                              <span className="d-block info-value">
                                {lang
                                  ? lang
                                      .split(",")
                                      .map((t) => language2[t])
                                      .toString(",")
                                  : ""}
                              </span>
                            </div>
                          </div>
                          {is365 ? (
                            ""
                          ) : (
                            <div className="row mt-1 mb-1">
                              <div className="col-4 text-left">
                                <span className="d-block info-heading">
                                  Date
                                </span>
                                <span className="d-block info-value">
                                  {start_date}
                                </span>
                              </div>
                              <div className="col-4">
                                <span className="d-block info-heading">
                                  Time
                                </span>
                                <span className="d-block info-value">
                                  {start_time}
                                </span>
                              </div>
                              <div className="col-4">
                                <span className="d-block info-heading">
                                  Timezone
                                </span>
                                <span className="d-block info-value">
                                  {timezone}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-4 text-left">
                              <span className="d-block info-heading">
                                Course Fees
                              </span>
                              <span className="d-block info-value">
                                {course_fees}
                              </span>
                            </div>
                            <div className="col-4">
                              <span className="d-block info-heading">
                                Certificate Fees
                              </span>
                              <span className="d-block info-value">
                                {certificate_fees}
                              </span>
                            </div>
                            <div className="col-4">
                              <span className="d-block info-heading">
                                Material Fees
                              </span>
                              <span className="d-block info-value">
                                {material_fees}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-4 text-left">
                              {removed_by_admin ? (
                                <a className="btn btn-warning btn-sm">
                                  Removed
                                </a>
                              ) : (
                                <a
                                  className="btn btn-danger btn-sm"
                                  onClick={async () => {
                                    if (!window.confirm("Are you sure?"))
                                      return false;
                                    const { Chabi } = this.state;
                                    // const url = user_training_api + "/remove";
                                    const response = await myAxios({
                                      method: "put",
                                      // url,
                                      request: {
                                        order_id,
                                        training_id,
                                        user_id,
                                      },
                                      header: { Chabi },
                                    });
                                    console.log("response ", response);
                                    if (
                                      response.status == 200 &&
                                      response.data.status == 200
                                    ) {
                                      const selectedRecord2 =
                                        selectedRecord.extra.user_training.map(
                                          (t) => {
                                            if (
                                              t.order_id == order_id &&
                                              t.training._id == training_id
                                            ) {
                                              t["removed_by_admin"] = 1;
                                              t["removed"] = 1;
                                            }
                                            return t;
                                          }
                                        );
                                      selectedRecord["extra"]["user_training"] =
                                        selectedRecord2;
                                      this.setState({ selectedRecord });
                                    } else {
                                    }
                                  }}
                                >
                                  Remove Training
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <MyEmpty />
                )} */}
              </TabPane>
              <TabPane tab="Bulk Orders" key="3">
                {selectedRecord.extra.bulk_order.length ? (
                  <div className="bg-light p-2 mt-2">
                    <p className="info-main-heading mt-3">Order Details </p>
                    {selectedRecord.extra.bulk_order.map((t) => {
                      const {
                        amount,
                        create_at,
                        discount,
                        items,
                        is365,
                        organization_id,
                        payment_ref,
                        status,
                        tax_amount,
                        total_amount,
                        _id: bulk_order_id,
                      } = t;
                      return (
                        <div
                          style={{
                            background: "#ffffff",
                            marginBottom: "20px",
                            padding: "10px",
                          }}
                        >
                          <div className="row mt-1 mb-1">
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">Date</span>
                              <span className="d-block info-value">
                                {create_at}
                              </span>
                            </div>
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">
                                Order id
                              </span>
                              <span className="d-block info-value">
                                {bulk_order_id}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Payment Code
                              </span>
                              <span className="d-block info-value">
                                {payment_ref}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Status
                              </span>
                              <span className="d-block info-value">
                                {status}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-1 mb-1">
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">
                                Amount
                              </span>
                              <span className="d-block info-value">
                                {amount}
                              </span>
                            </div>
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">
                                Tax Amount
                              </span>
                              <span className="d-block info-value">
                                {tax_amount}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Discount
                              </span>
                              <span className="d-block info-value">
                                {discount}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Total Amount
                              </span>
                              <span className="d-block info-value">
                                {total_amount}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <MyEmpty />
                )}
              </TabPane>
              <TabPane tab="Purchase Order" key="4">
                {/* <Row className="border">
                  <Col spanb={8}>
                    <Row>
                      <Col span={24}>
                        <FormPasswordGroup
                          id="password"
                          name="password"
                          label="Password"
                          placeholder="Password"
                          value={request2["password"]}
                          sendValue={this.setRequest2}
                        />
                      </Col>
                      <Col span={24}>
                        <FormPasswordGroup
                          id="cpassword"
                          name="cpassword"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          value={request2["cpassword"]}
                          sendValue={this.setRequest2}
                        />
                      </Col>
                      <Col span={24} className="m-2">
                        {chPwdMsg == "1" ? (
                          <span
                            className="text-success"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Learner Password Changed
                          </span>
                        ) : chPwdMsg == "0" ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Somethign Wrong
                          </span>
                        ) : chPwdMsg == "-1" ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Password and Confirm Password must be same and not
                            blank
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={24} className="m-2">
                        <FormButton
                          reset={() => {
                            this.setState({ request2: {}, chPwdMsg: "" });
                          }}
                          submit={async () => {
                            console.log("request2 ", request2);
                            const { password, cpassword } = request2;
                            if (
                              password &&
                              cpassword &&
                              password.length >= 6 &&
                              password == cpassword
                            ) {
                              const url = learners_api + "/cpa";
                              const response = await myAxios({
                                method: "put",
                                url,
                                header: { Chabi },
                                request: {
                                  _id: selectedRecord._id,
                                  ...request2,
                                },
                              });
                              if (response.status == 200) {
                                this.setState({ request2: {}, chPwdMsg: "1" });
                              } else this.setState({ chPwdMsg: "0" });
                            } else this.setState({ chPwdMsg: "-1" });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col spanb={8}></Col>
                  <Col spanb={8}></Col>
                </Row> */}
              </TabPane>
              <TabPane tab="Payments" key="5">
                {/* <Row className="mb-4">
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="Training"
                      name="Training"
                      label="training"
                      placeholder="training"
                      // data={this.state}
                      // required
                      options={course_training}
                      option_value="_id"
                      option_text="course_name"
                      // setValidation={this.setValidation}
                      sendValue={({ value }) => {
                        this.setState({ training_id: value });
                      }}
                    />
                  </Col>
                  <FormButton
                    submit={async () => {
                      if (!window.confirm("Are you sure?")) return false;
                      const { selectedRecord, training_id, Chabi } = this.state;
                      if (training_id && training_id.length) {
                        const { _id: user_id } = selectedRecord;
                        const url = user_training_api + "/add";
                        const response = await myAxios({
                          method: "post",
                          url,
                          header: { Chabi },
                          request: { user_id, training_id },
                        });
                        console.log("add course ", response);
                        if (
                          response.status == 200 &&
                          response.data.status == 200
                        ) {
                          const { records } = response.data;
                          selectedRecord["extra"]["user_training"].push(
                            records
                          );
                          this.setState({ selectedRecord, training_id: "" });
                          alert("Training assigned to learner");
                        }
                      } else alert("please select training ");
                    }}
                  />
                </Row> */}
              </TabPane>
              <TabPane tab="Reviews" key="6">
                <AddNewImport
                  disabled={!CDMI}
                  onClick={() => {
                    this.setState({
                      cmodali: !cmodali,
                      edit: false,
                      request: {},
                    });
                  }}
                />
                <Reviews training_id={selectedRecord._id} />
              </TabPane>
              <TabPane tab="Mailer" key="7">
                {/* <Row className="mb-4">
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="Training"
                      name="Training"
                      label="training"
                      placeholder="training"
                      // data={this.state}
                      // required
                      options={course_training}
                      option_value="_id"
                      option_text="course_name"
                      // setValidation={this.setValidation}
                      sendValue={({ value }) => {
                        this.setState({ training_id: value });
                      }}
                    />
                  </Col>
                  <FormButton
                    submit={async () => {
                      if (!window.confirm("Are you sure?")) return false;
                      const { selectedRecord, training_id, Chabi } = this.state;
                      if (training_id && training_id.length) {
                        const { _id: user_id } = selectedRecord;
                        const url = user_training_api + "/add";
                        const response = await myAxios({
                          method: "post",
                          url,
                          header: { Chabi },
                          request: { user_id, training_id },
                        });
                        console.log("add course ", response);
                        if (
                          response.status == 200 &&
                          response.data.status == 200
                        ) {
                          const { records } = response.data;
                          selectedRecord["extra"]["user_training"].push(
                            records
                          );
                          this.setState({ selectedRecord, training_id: "" });
                          alert("Training assigned to learner");
                        }
                      } else alert("please select training ");
                    }}
                  />
                </Row> */}
              </TabPane>
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records });
            }}
          />
        ) : (
          ""
        )}

            {/* model for Region bulk upload */}
    <CustomModal
          key="regionListbulkupload"
          status={modelBulkUpload}
          title="Bulk upload"
          close={() => {
            this.setState({
            modelBulkUpload: false,
            bulkUploadDone:false,
            processRow:0,
            issueRows:0,
            uploadingIssues:[],
            
          })
          this.reloadPageData()
        }}
          className="modal-form-course"
        >
             <div style={{padding:10}}><a href={`${PORTAL.api_url}region_bulk_upload_sample`} target="_blank"><button disabled={this.state.uploadingLoader} className="btn btn-sm btn-primary float-right">Download Sample File</button></a></div>
             <div style={{paddingBottom: "50px",paddingTop: "30px"}}>
              {/* {!this.state.uploadingLoader ?  */}
              <Dragger {...{
                         name: 'file',
                         multiple: false,
                         action: `${bulk_upload_api}/course/region`,
                         showUploadList:false,
                         onChange : (info) => this.bulkFileOneChange(info),
                         onDrop : (e) => {
                           console.log('Dropped files', e.dataTransfer.files);
                         },
                         disabled: this.state.uploadingLoader
                 }}>
                  {!this.state.uploadingLoader ?
                  <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </> : <MyLoading /> }
              </Dragger>
              {/* : <MyLoading />} */}
              </div> 
              {this.state.bulkUploadDone ?
              <div className="upload-report" style={{paddingBottom:20}}>
                <p className="text-success"><b>Successfully Process Rows: {this.state.processRow}</b></p>
                <p className="text-danger"><b>Error occure : {this.state.issueRows}</b></p>
                <p className="text-danger"><b>Issues: </b>{
                this.state.uploadingIssues.length >= 1 ? <ul>{
                this.state.uploadingIssues.map( rec => {
                  return <li> Row {rec.index} Issue: {rec.problem}</li>
                })}</ul> : "-"}</p>
              </div> : null }
    </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Training);
