import React, { useState } from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import topnavcss from "./topnavdesktop.module.css";
import { PORTAL } from "../../../currrent_info";
import urls from "../../../urls";
// ===============================================================================
const { SubMenu } = Menu;
function TopNavDesktop(props) {
  
  const { loginStatus, loggedUser } = props;
  const [activeItem, setActiveItem] = useState("mail");
  let role = ""
  if(loggedUser.role == "data_analyst"){
    role = "Data Analyst"
  }else if(loggedUser.role == "marketing_and_sales"){
    role = "Marketing And Sales"
  }else if(loggedUser.role == "research_and_development"){
    role = "Research And Development"
  }else if(loggedUser.role == "consultants"){
    role = "Consultants"
  }else if(loggedUser.role == "partner"){
    role = "Partner"
  }else{
    role = "Finance"
  }
  return (
    <>
      <div
        className="brand-logo">
         <img src="logo.png"/>
        <div style={{position: 'absolute', left: '227px', top: '20px'}}>
        <h3> {role} Panel</h3>
      </div>
      </div>
      
      <Menu
        mode="horizontal"
        defaultSelectedKeys={["mail"]}
        className={`${topnavcss.topnavMenu}`}
      >
        {/* <Menu.Item key="mail" icon={<MailOutlined />}>
          Navigation One
        </Menu.Item> */}
        
        <Menu.SubMenu
          style={{ marginLeft: "auto" }}
          key="SubMenu"
          title={loggedUser.email}
          icon={<SettingOutlined />}
        >
          {loginStatus == true ? (
            <>
            
              {/* <Menu.Item key="two" icon={<AppstoreOutlined />}>
            Change Password
          </Menu.Item> */}
              <Menu.Item key="three" icon={<AppstoreOutlined />}>
                <Link className="logout" to={urls.LOGOUT.path}>
                  {urls.LOGOUT.text}
                </Link>
              </Menu.Item>
            </>
          ) : (
            ""
          )}
          {/* <Menu.ItemGroup title="Item Group">
            <Menu.Item key="four" icon={<AppstoreOutlined />}>
              Navigation Four
            </Menu.Item>
            <Menu.Item key="five" icon={<AppstoreOutlined />}>
              Navigation Five
            </Menu.Item>
          </Menu.ItemGroup> */}
        </Menu.SubMenu>
      </Menu>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
    role_modules: state.role_modules
  };
};
export default connect(mapStateToProps)(TopNavDesktop);
