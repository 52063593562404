import React from "react";
import FormLabel from "./FormLabel";
import FilterInput from "./FilterInput";
import { Row, Col } from "antd";
function FilterInputGroup(props) {
  const { groupClassName, label } = props;
  return (
    <Row className={`pl-2 pr-2 mt-2 ${groupClassName}`}>
      {label ? (
        <Col span={24} className="text-left">
          <FormLabel {...props} />
        </Col>
      ) : (
        ""
      )}
      <Col span={24} className="text-left ">
        <FilterInput {...props} />
      </Col>
    </Row>
  );
}
export default FilterInputGroup;
