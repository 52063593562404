import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Checkbox, Col, Row } from "antd";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { certificate_details_api } from "../../library/certificate_details_api";
import { certificateUpload_api } from "../../library/certificateUpload_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
import { SettingOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { PORTAL } from "../../currrent_info";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import { Upload,Pagination  } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

// ===============================================================================
class Certificate_details extends FormParent {
  constructor(props) {
    console.log("rendered re", props);
    super(props);
    this.state = {
      page_api: certificate_details_api,
      attandanceId: "",
      filter: {},
      records: {},
      request: {},
      validation: {},
      validation_info: {
        certificate_no: { valMet: vj_val.checkLength, valLen: 1 },
        learner_firstname: { valMet: vj_val.checkLength, valLen: 1 },
        learner_lastname: { valMet: vj_val.checkLength, valLen: 1 },
        certificate_template_override: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
      edit:false,
      isLock:false,
      checkedList:{},
      checkedCount:0,
      templateList:[],
      certificateImg:"",
      image_records: [],
      imagetype:"",
      page:1,
	    totalPage:1,
      dropdownStatus:"",
      buttonstatus:false,
      statusDropDown:[]
    };
  }

  componentDidMount = async () => {
    
    // 1- active attandance, 2- active for achement, 3 for non of active
    if(window.location.search != ""){
    let url= new URLSearchParams(window.location.search);
    
    let identified = url.get("identified")
    let pageObj = window.atob(window.atob(identified))
    this.setState({...JSON.parse(pageObj)})
   
    const { Chabi } = this.state;
    const Apiurl = certificate_details_api +"/previewData";
     const response = await myAxios({
      method: "post",
      url:Apiurl,
      header: { Chabi },
      request: {
        identified
    }
    });

    if(response.status == 200){
        this.setState({ records: response.data.records[0]})
    }
   
    // this.setState({ pageId, userId: userId[1], orderId: userId[0], orderType: userId[2]})

    // let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0], orderType: userId[2]})));



    // const response = await myAxios({
    //   method: "post",
    //   url:Apiurl,
    //   header: { Chabi },
    //   request: {
    //     identified
    // }
    // });
 
    // if (response.status == 200) {
    //   const { data } = response;
    //   let arraytitle = [];
    //   data.records[0].accrediation_name.map( _ => {
    //     arraytitle.push(_.title)
    //   })
      
    //   let totalAttandance = (data.records[0].attandance != null) ? ((data.records[0].attandance.totalCheck * 100) / data.records[0].attandance.attandance.length) : 0
    //   let totalLca_examResult = (data.records[0].lcaExamResult.length >= 1) ? data.records[0].lcaExamResult[0].count : 0;

    //   let min_attendance = (data.records[0].traning.courseData) ? data.records[0].traning.courseData.min_attendance ? parseInt(data.records[0].traning.courseData.min_attendance) : 0 : 0
      
    //   let min_attendancecheckbox = (data.records[0].traning.courseData) ? data.records[0].traning.courseData.min_attendancecheckbox ? data.records[0].traning.courseData.min_attendancecheckbox : "false" : "false"

    //   let min_grade = (data.records[0].traning.courseData) ? data.records[0].traning.courseData.min_grade ? parseInt(data.records[0].traning.courseData.min_grade) : 0 : 0

    //   let min_grade_passcheckbox = data.records[0].traning.courseData ? data.records[0].traning.courseData.min_gradecheckbox
    //   ? data.records[0].traning.courseData.min_gradecheckbox : "false" : "false"

    //   if(min_attendancecheckbox != "false" && min_grade_passcheckbox != "false"){
    //     if(totalAttandance >= min_attendance && totalLca_examResult >= min_grade){
    //       this.setState({dropdownStatus : 2, buttonstatus: false, statusDropDown: [{ value: 'Successfully Completed', name:"successfully-completed" }, {  value: 'Attendance Only', name:"attendance-only" }]})
    //     }else if(totalAttandance >= min_attendance){
    //       this.setState({dropdownStatus : 1, buttonstatus: false, statusDropDown: [{  value: 'Attendance Only', name:"attendance-only" }] })
    //     }else{
    //       this.setState({dropdownStatus : 1, buttonstatus: false, statusDropDown: [{  value: 'Attendance Only', name:"attendance-only" }]})
    //     }
        
    //   }else{
    //     if(totalAttandance >= 1){
    //       this.setState({dropdownStatus : 1, buttonstatus: false, statusDropDown: [{  value: 'Attendance Only', name:"attendance-only" }]})
    //     }else{
    //       this.setState({dropdownStatus : 3, buttonstatus: true, statusDropDown: []})
    //     }
    //   }
     
    //   this.setState({ records : data.records[0], request: data.records[0], templateList: responseq.data.records})
    //   this.setState({ request : {...data.records[0], locationDetails : (data.records[0].traning.locationData.length >= 1) ? `${data.records[0].traning.locationData[0].location_name}, ${data.records[0].traning.locationData[0].country}` : "",locationId : (data.records[0].traning.locationData.length >= 1) ? data.records[0].traning.locationData[0]._id : "", active: data.records[0].active.toString(), accrediation : arraytitle.join(",") }})
    // }
    // this.certificate_file(1);
    this.setState({ CDM: true });
    }else{
        alert("you want to access unregistered routes... pls press ok and go back");
        window.history.back();
        return false
    }

  };

  certificate_file = async (page) => {
    const { Chabi } = this.state;
    const url = certificateUpload_api+"/get?page="+page;

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi }
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
		this.setState({image_records:data.records,totalPage: data.totalPage, page:page});
      }
    } else console.log("Network Error");
  };

  pageReload = async () => {
    if(window.location.search != ""){
      let url= new URLSearchParams(window.location.search);
      let pageId = url.get("training")
      let userId = url.get("identifier")
       pageId = atob(atob(pageId))
       userId = atob(atob(userId))
      
      userId = userId.split("_");
      this.setState({ pageId, userId: userId[1], orderId: userId[0],orderType: userId[2]})
  
      let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0],orderType: userId[2]})));
  
      const { Chabi } = this.state;
      const Apiurl = certificate_details_api +"/getById";
      const responseq = await get_data({
        others: [],
        primary: "certificate_template",
        extra: { type: "normal" },
        Chabi,
      });
  
      const response = await myAxios({
        method: "post",
        url:Apiurl,
        header: { Chabi },
        request: {
          identified
      }
      });
   
      if (response.status == 200) {
        const { data } = response;
        let arraytitle = [];
        data.records[0].accrediation_name.map( _ => {
          arraytitle.push(_.title)
        })
        let totalAttandance = (data.records[0].attandance != null) ? ((data.records[0].attandance.totalCheck * 100) / data.records[0].attandance.attandance.length) : 0
        let totalLca_examResult = (data.records[0].lcaExamResult.length >= 1) ? data.records[0].lcaExamResult[0].count : 0;
  
        let min_attendance = (data.records[0].traning.courseData) ? data.records[0].traning.courseData.min_attendance ? parseInt(data.records[0].traning.courseData.min_attendance) : 0 : 0
        
        let min_attendancecheckbox = (data.records[0].traning.courseData) ? data.records[0].traning.courseData.min_attendancecheckbox ? data.records[0].traning.courseData.min_attendancecheckbox : "false" : "false"
  
        let min_grade = (data.records[0].traning.courseData) ? data.records[0].traning.courseData.min_grade ? parseInt(data.records[0].traning.courseData.min_grade) : 0 : 0
  
        let min_grade_passcheckbox = data.records[0].traning.courseData ? data.records[0].traning.courseData.min_gradecheckbox
        ? data.records[0].traning.courseData.min_gradecheckbox : "false" : "false"
  
        console.log({totalAttandance, totalLca_examResult, min_attendance, min_attendancecheckbox, min_grade, min_grade_passcheckbox})
        if(min_attendancecheckbox != "false" && min_grade_passcheckbox != "false"){
          if(totalAttandance >= min_attendance && totalLca_examResult >= min_grade){
            this.setState({dropdownStatus : 2, buttonstatus: false, statusDropDown: [{ value: 'Successfully Completed', name:"successfully-completed" }, {  value: 'Attendance Only', name:"attendance-only" }]})
          }else if(totalAttandance >= min_attendance){
            this.setState({dropdownStatus : 1, buttonstatus: false, statusDropDown: [{  value: 'Attendance Only', name:"attendance-only" }] })
          }else{
            this.setState({dropdownStatus : 1, buttonstatus: false, statusDropDown: [{  value: 'Attendance Only', name:"attendance-only" }]})
          }
          
        }else{
          if(totalAttandance >= 0){
            this.setState({dropdownStatus : 1, buttonstatus: false, statusDropDown: [{  value: 'Attendance Only', name:"attendance-only" }]})
          }else{
            this.setState({dropdownStatus : 3, buttonstatus: true, statusDropDown: []})
          }
        }

        this.setState({ records : data.records[0], request: data.records[0], templateList: responseq.data.records})
        this.setState({ request : {...data.records[0], active: data.records[0].active.toString(), accrediation : arraytitle.join(",") }})
      }
      this.setState({ CDM: true });
      }else{
          alert("you want to access unregistered routes... pls press ok and go back");
          window.history.back();
          return false
      }
  }

  submit = async () => {
    let { Chabi, request, records } = this.state;

    let totalAttandance = (records.attandance != null) ? ((records.attandance.totalCheck * 100) / records.attandance.attandance.length) : 0
    let totalLca_examResult = (records.lcaExamResult.length >= 1) ? records.lcaExamResult[0].count : 0;

    let min_attendance = (records.traning.courseData) ? records.traning.courseData.min_attendance ? parseInt(records.traning.courseData.min_attendance) : 0 : 0
    
    let min_attendancecheckbox = (records.traning.courseData) ? records.traning.courseData.min_attendancecheckbox ? records.traning.courseData.min_attendancecheckbox : "false" : "false"

    let min_grade = (records.traning.courseData) ? records.traning.courseData.min_grade ? parseInt(records.traning.courseData.min_grade) : 0 : 0

    let min_grade_passcheckbox = records.traning.courseData ? records.traning.courseData.min_gradecheckbox
    ? records.traning.courseData.min_gradecheckbox : "false" : "false"

    if(min_attendancecheckbox == "false" && min_grade_passcheckbox == "false"){
        alert("Please enter Attendance, LCA");
        return false;
    }

    
    
    let { _id, certificate_no, learner_firstname, learner_lastname, certificate_file, active, locationDetails, locationId, certificate_status, certificate_template_override } = request
    if(certificate_no == ""){
       alert("certificate number required to submit form");
       return false
    }
    this.setState({ CDM: false})
    const url = certificate_details_api+"/update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request:{ _id, certificate_no, learner_firstname, learner_lastname, certificate_file, active, locationDetails, locationId, certificate_status, certificate_template_override },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("records updated");
        this.pageReload()
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  imageManagerClick = (type) => {
	  if(type==='logo1'){
		this.setState({
            imagetype:type,
			imagecmodal: true,
        });
	  }
	}

  imageSelectClick = (type,imageFullName,imageName) => {
    console.log(type,imageFullName,imageName);
	  if(type==='logo1'){
		this.setState({ certificateImg:imageFullName, imagecmodal: false });
		const { request } = this.state;
		request['certificate_file'] = imageName;
		this.setState({ request });
	
	  }
	}
    
    imageclose_modal = () => {
		this.setState({
		  imagecmodal: false,
		});
	};

  genrateCertificate = async () => {
    this.setState({ CDM: false });
    let urlParms= new URLSearchParams(window.location.search);
    let identified = urlParms.get("identified")
    const { Chabi } = this.state;
    const url = certificate_details_api + "/listingCertificateGenrate";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
			request: { identified, learner_firstname: this.state.records.learner_firstname, learner_lastname: this.state.records.learner_lastname }});
       
    if (response.status == 200) {
      const { data } = response;
      message.success("Record updated");
      this.setState({ CDM: false });
			window.location.href = `/tranning_learner?training=${btoa(btoa(this.state.traningId))}&orderType=${btoa(btoa(this.state.orderType))}`
    }
  }

  render() {
    console.log("Training Enquiry State========> ", this.state);
    let {
      records,
      loginStatus,   
      CDM,
      request,
      templateList,
      certificateImg,
      imagecmodal,
      image_records,
      imagetype,
      page,
	  totalPage,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
   
    
    return (
      <>
        <PageHeading2
          active="Training"
          page={["Training", "Learners", "Certificates"]}
        ></PageHeading2>
        <MainArea>
          {!CDM ? (
            <div className="loader-main-container">
              <MyLoading />
            </div>
          ) : (
            <>
            <Row>
              <Col span={12}>
                <div className="p-4">
                  <h3 className="info-main-heading">Certificate Preview</h3>
                </div>
              </Col>
              <Col span={12}>
              <div className="p-4">
                  {this.state.edit ? <button className="btn-sm btn-primary btn float-right" onClick={() => this.setState({ edit: false})}>Preview Certificate</button> : <button className="btn-sm btn-primary btn float-right" onClick={() => this.setState({ edit: true})}>Edit Certificate</button> }
                  {this.state.edit == false ? <button className="btn-sm btn-outline-success btn float-right" style={{marginRight : 2}} onClick={() => this.genrateCertificate()}>Generate Certificate</button> : null }
                </div>
              </Col>
              </Row>
              <Row justify="center">
                <Col span={18}>
                  <div
                    style={{
                      width: 850,
                      marginLeft: "auto",
                      marginRight: "auto",
                      position: "relative",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <img
                        style={{ width: "100%" }}
                        src={
                          records.certificate_template_complate_data
                            .certificate_background_image
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 1,
                        marginBottom: 1,
                        position: "absolute",
                        left: 0,
                        top: 0,
                      }}
                    >
                      <table cellpadding="0" cellspacing="0">
                        <tr>
                          <td colspan="2">
                            <table cellpadding="0" cellspacing="0">
                              <tr>
                                <td style={{ padding: "10px 20px" }}>
                                  <img
                                    style={{ width: "200px" }}
                                    src={records.certificate_logo}
                                  />
                                </td>
                                <td style={{ padding: "10px 128px 10px 0px" }}>
                                  <img
                                    style={{
                                      width: "260px",
                                      marginTop: "20px",
                                    }}
                                    src={records.certificate_image}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td colspan="2">
                                  <p style={{ padding: "15px 40px 5px" }}>
                                    {
                                      records
                                        .certificate_template_complate_data
                                        .certification
                                    }{" "}
                                    hereby certifies the
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{ padding: "5px 40px 15px" }}
                                  colspan="2"
                                >
                                  <h4 style={{ fontSize: 30 }}>
                                    {records.course_name}{" "}
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <p>
                                    {" "}
                                    {
                                      records
                                        .certificate_template_complate_data
                                        .attended_text
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  {(this.state.edit) ?
                                  <>
                                  <input type="text" defaultValue={records.learner_firstname} onChange={(e) => this.setState({ records : { ...this.state.records, learner_firstname : e.target.value} })}/>
                                  <input type="text" defaultValue={records.learner_lastname} onChange={(e) => this.setState({ records : { ...this.state.records, learner_lastname : e.target.value} })}/>
                                  </> :
                                   <h1>
                                    {records.learner_firstname}{" "}
                                    {records.learner_lastname}{" "}
                                  </h1> }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>
                                    {
                                      records
                                        .certificate_template_complate_data
                                        .module_text
                                    }{" "}
                                  </p>
                                </td>
                                <td>
                                  <p> {records.accreditation_number} </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <p>
                                    Certified by{" "}
                                    {
                                      records
                                        .certificate_template_complate_data
                                        .certification
                                    }{" "}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p> {
                                      records
                                        .certificate_template_complate_data.location_text}</p>
                                </td>
                                <td>
                                  <p>{`${records.course_location}, ${countryListAlpha2[records.country]}`}</p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p>Course Start Date</p>
                                </td>
                                <td>
                                  <p>{
                                      records.start_date}</p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p> {
                                      records
                                        .certificate_template_complate_data.dates_text}</p>
                                </td>
                                <td>
                                  <p> { records.course_completed}</p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p>Total training hours</p>
                                </td>
                                <td>
                                  <p>{ records.timeDiff}</p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p> {
                                      records
                                        .certificate_template_complate_data.certified_number_text}</p>
                                </td>
                                <td>
                                 
                                  <p> {
                                      records
                                        .certificate_no}</p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p> {
                                      records
                                        .certificate_template_complate_data.atp_delegate_no_text}</p>
                                </td>
                                <td>
                                  <p>{records.learnerUdn ? records.learnerUdn.udn_number : "-"}</p>
                                </td>
                              </tr>
                              {records.type_certificate != "ATTANADANCE" ?
                              <tr>
                                <td>
                                  <p>CEU Points</p>
                                </td>
                                <td>
                                  <p>{records.ceu_point}</p>
                                </td>
                              </tr>
                              :null  }

                              <tr>
                                <td style={{padding:'20px 30px 15px'}}>
                                  <img
                                   style={{width: '150px'}}
                                    alt="signimagenot"
                                    src={records.signatorUrl}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <table>
                                  <tr>
                                    <td>
                                      <img
                                        alt=""
                                        style={{width: '150px'}}
                                        src={
                                          records.certificate_template_complate_data.certificate_logo}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        alt=""
                                        style={{width: '150px'}}
                                        src={
                                          records
                                            .certificate_template_complate_data.certificate_logo2}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        alt=""
                                        style={{width: '150px'}}
                                        src={
                                          records
                                            .certificate_template_complate_data.partner_image}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </tr>
                              <tr>
                                <td style={{padding:'100px 30px 25px'}} colspan="2">
                                  <p style={{textAlign: 'center', fontSize: 10, fontWeight:400, margin: '0px 0px 5px', lineHeight: 20, color: '#555'}}>{records.certificate_template_complate_data.footer_line2}</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </MainArea>
        <CustomModal
          status={imagecmodal}
          title="Image Manager"
          close={this.imageclose_modal}
          className="drawer-form-exam"
        >
          <Row className="certificate_template_img_container_row">
            <Col>
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${certificateUpload_api}image_upload`}
                beforeUpload={this.beforeUpload}
                onChange={(e) => this.handleChangeFiles(e, "name")}
              >
                {this.state.uploadimage ? (
                  <img
                    src={this.state.uploadimage}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  this.uploadButton
                )}
              </Upload>
              <button
                type="submit"
                className="btn btn-danger btn-submit"
                onClick={this.handleUploadSubmit}
              >
                SAVE
              </button>
            </Col>
          </Row>
          <div className="certificate_template_img_container_row swscertificate_template_img_container_row">
            {image_records.length > 0
              ? image_records.map((item) => {
                  return (
                    <div className="certificate_template_img_container partner_image swsimagelist">
                      <div className="swsdel">
                        <span
                          onClick={() => {
                            this.imageManagerDelete(
                              imagetype,
                              item._id,
                              item.upload_certificate_file
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-danger ml-2"
                            icon={faTrash}
                          />
                        </span>
                      </div>
                      <img
                        className="partner_image sws_imagelist"
                        onClick={() =>
                          this.imageSelectClick(
                            imagetype,
                            `${PORTAL.api_url}certificate_images/${item.upload_certificate_file}`,
                            item.upload_certificate_file
                          )
                        }
                        src={`${PORTAL.api_url}certificate_images/${item.upload_certificate_file}`}
                        alt="Image"
                      />
                    </div>
                  );
                })
              : ""}
            <Pagination
              current={page}
              onChange={this.nextPage}
              total={totalPage}
            />
          </div>
        </CustomModal>
        <style jsx global>{`
          .sws_imagelist {
            height: 100px;
            width: 100px;
          }
          .swsimagelist {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
          }
          .swsdel {
            position: absolute;
            right: 5px;
          }
          .swscertificate_template_img_container_row {
            display: inline-block;
            margin-top: 15px;
            padding-bottom: 15px;
          }
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Certificate_details);
