import React from "react";
import { connect } from "react-redux";
// import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col, Checkbox } from "antd";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { learners_api } from "../../library/learners_api";
import { enquiry_api } from "../../library/enquiry_api";
import { learner_move_course_api } from "../../library/learner_move_course_api";
import { evaluation_template_assesment_api } from "../../library/evaluation_template_assesment";
import { learner_udi_api } from "../../library/learner_udi_api"
import { tranning_result_api } from "../../library/tranning_result_api";
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
import { certificate_details_api } from "../../library/certificate_details_api";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormPasswordGroup from "../part/Form/FormPasswordGroup";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import Axios from "axios"
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
import { SettingOutlined, CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { back_api } from "../../library/back_api";
import { training_api } from '../../library/training_api'
import { Navigate } from "react-router-dom";
import { Pagination } from 'antd';
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormInputGroup from "../part/Form/FormInputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faTrash, faEnvelope, faUserPlus, faPlusCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Select } from 'antd';

// ===============================================================================

class Tranning_learner extends FormParent {
  constructor(props) {
    super(props);
    this.state = {

      page_api: enquiry_api,
      pageId:"",
      page:1,
      totalPage:1,
      filter: {},
      request2: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        version: { valMet: vj_val.checkLength, valLen: 1 },
        title: { valMet: vj_val.checkLength, valLen: 1 },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        description: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
      chPwdMsg: "",
      orderType:"",
	  cmodalMove: false,
	  selectedRecordMove: {},
	  course_training:[],
    selectedRows:[],
    allChecked:false,
    permission:{
      create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
    }
    };
  }
 

//   redirection with select
  seleteRedirectionHandler = (e,selectedRecord,isAttandancePublish) => {
    
		if(e.target.value.split("?")[0] == "/reset_password"){
      if (window.confirm('Are you sure?')){
				this.setState({selectedRecord, cmodal:true});
      }
		}else if(e.target.value.split("?")[0] == "/remove_from_this_class"){
      if (window.confirm('Are you sure?')){
				this.removethisCourse(selectedRecord);
      }
		}else if(e.target.value.split("?")[0] == "/send_evaluation_reminder"){
      if (window.confirm('Are you sure?')){
				this.sendEvaluationReminder(selectedRecord);
      }
		}else if(e.target.value.split("?")[0] == "/Lca_exam_result"){
      if(isAttandancePublish == null || isAttandancePublish.isPublish == 0){
          return alert("Attandance Not Publish Yet...!!"); 
        }else{
          let redirectionName = e.target.value;
			    window.location.href = redirectionName
        }   
    }else if(e.target.value.split("?")[0] == "/tranning_result"){
      // console.log(isAttandancePublish)
      if(isAttandancePublish == null || isAttandancePublish.isPublish == 0){
        return alert("Attandance Not Publish Yet...!!"); 
      }else{
        let redirectionName = e.target.value;
        window.location.href = redirectionName
      }   
    }else if(e.target.value.split("?")[0] == "/tranning_result_2"){
      if(isAttandancePublish == null || isAttandancePublish.isPublish == 0){
        return alert("Attandance Not Publish Yet...!!"); 
      }else{
        let redirectionName = e.target.value;
        window.location.href = redirectionName
      }   
    }else if(e.target.value.split("?")[0] == "/certificate_details"){
      if(isAttandancePublish == null || isAttandancePublish.isPublish == 0){
        return alert("Attandance Not Publish Yet...!!"); 
      }else{
        let redirectionName = e.target.value;
        window.location.href = redirectionName
      }   
    }else{
      if (window.confirm('Are you sure?')){
			let redirectionName = e.target.value;
			window.location.href = redirectionName
      }
		}
  }
  
	MoveOtherCourse=(selectedRecordMove)=>{
		if (window.confirm('Are you sure?'))
		{
			this.setState({selectedRecordMove, cmodalMove:true});
			this.trainingCourse();
		}
	}
	
	courseclose_modal = () => { 
    // console.log("xxxpppp");
		this.setState({
			cmodalMove: false,
			selectedRecordMove: {},
		});
		this.state={
			cmodalMove: false,
			selectedRecordMove: {},
		}
		// console.log("xxxpppp111", this.state.cmodalMove);
	};

  courseclose_udi_modal = () => {
		this.setState({
			cmodalUdi: false,
			selectedRecordMove: {},
		});
	};
  
	componentDidMount = async () => {
    const { Chabi } = this.state;
     //============= define permission =============
     let { loggedUser } = this.props
     if(loggedUser.roleId){
      const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"tranning_learner";
      let modulePermission = await myAxios({
        method: "get",
        url,
        header: { Chabi },
      });


     if(modulePermission.status == 200){
          if(modulePermission.data.records.length >= 1){
            console.log("ROLE APPLIED")
            let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
            this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
          }else{
            console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
          }
     }else{
      console.log("PERMISSION API CRASHED")
     }
     }

    //=============================================
		this.dataLoaded(1);
	};
	
	dataLoaded = async (page) => {

		let queryParms = new URLSearchParams(window.location.search);
		let pageId = queryParms.get("training")
    let orderType = queryParms.get("orderType");
    orderType = atob(atob(orderType))
		pageId = atob(atob(pageId))
		this.setState({ pageId, orderType})

		const { Chabi } = this.state;
		  const url = training_api + "/training_details_v3/"+pageId+"?orderType="+orderType+"&page="+page;
		 
      const response = await Axios.get(url, {	headers: { 
        token : Chabi, 
        Accept: "application/json",
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
       }})
	   
		  if (response.status == 200) {
			const { data } = response;
      if(data.status == 200){
        data.learner.map( _ => {
          // console.log(_)
           this.setState({ [_.orderId] : false})
        })
        this.setState({records: data.learner,totalPage: data.totalPage, selectedRows:[], allChecked:false , page:page, pagination: <Pagination current={page} onChange={this.nextPage} total={data.totalPage} /> })
      }else{
         alert(data.message)
         return window.location.href = "/training";
      }

		  }
		  this.setState({ CDM: true });
	};
  
	removethisCourse = async (selectedRecord) => {

		const { Chabi } = this.state;
        const url = learner_move_course_api + "/status/";
        const response = await myAxios({
            method: "put",
            url,
            header: { Chabi },
			request: {_id:selectedRecord.orderId,removed:1}
          });
       
          if (response.status == 200) {
            const { data } = response;
            message.success("Record Saved");
			this.dataLoaded(1);
          }
	};
	
	sendEvaluationReminder = async (selectedRecord) => { 
		const { Chabi } = this.state;
        const url = evaluation_template_assesment_api + "/send_reminder/";
        const response = await myAxios({
            method: "post",
            url,
            header: { Chabi },
			request: {first_name:selectedRecord.first_name,last_name:selectedRecord.last_name,email:selectedRecord.email,courseTitle:selectedRecord.result[0].trainingName}
          });
       
          if (response.status == 200) {
            const { data } = response;
            message.success("Mail Sent");
          }
	};

  publishHandler = async (_id) => {
   
      let { Chabi, request, attandanceId, records } = this.state;
      const url = tranning_result_api+"/update";
      const response = await myAxios({
        method: "put",
        url,
        header: { Chabi },
        request:{ status:1, type: "isPublish", _id: _id  },
      });
      if (response.status == 200) {
        const { data } = response;
        if (data.status == 420) this.setLogout();
        else if (data.status == 200) {
          message.success("records updated");
          this.setState({ CDM: false });
          this.dataLoaded(1);
          return true;
        } else {
          const { messages } = data;
          if (messages) {
            message.error(messages);
            return false;
          }
        }
      } else alert("Network Error !");
      
  }
	
	trainingCourse = async () => {

		const { Chabi } = this.state;
        const url = learner_move_course_api + "/training_list/";
        const response = await myAxios({
            method: "get",
            url,
            header: { Chabi },
		  });
       
          if (response.status == 200) {
            const { data } = response;
            // console.log("xcccccccc",data);
			
			let newArray=[];
			for(var i = 0; i< data.records.length; i++){
				var node = data.records[i];
				newArray.push({_id:node._id,trainingName:node.locationNames+' | '+node.start_date+' | '+node.trainingName})
			}
			
			this.setState({course_training: newArray })
          }
	};
	
	nextPage = (page) => {
		this.dataLoaded(page)
	}

  bulkDeleleteFromThisClass = async () => {
    const { Chabi, selectedRows } = this.state;
    if(selectedRows.length < 1) return alert("No record selected..!!");
    const url = learner_move_course_api + "/bulk_update_status/";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
			request: { selectedRow: selectedRows} });
       
    if (response.status == 200) {
      const { data } = response;
      message.success("Record updated");
      this.setState({ CDM: false });
			this.dataLoaded(1);
    }
  }

  bulkPublishFromThisClass = async () => {
    const { Chabi, selectedRows } = this.state;
    if(selectedRows.length < 1) return alert("No record selected..!!");
    const url = tranning_result_api + "/bulk_publish/";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
			request: { selectedRow: selectedRows} });
       
    if (response.status == 200) {
      const { data } = response;
      message.success("Record updated");
      this.setState({ CDM: false });
			this.dataLoaded(1);
    }
  }

  releaseRevokeCertificate = async (_id,active) => {
      let { Chabi } = this.state;
      const url = certificate_details_api+"/status";
      const response = await myAxios({
        method: "put",
        url,
        header: { Chabi },
        request:{ _id,  active},
      });
      if (response.status == 200) {
        const { data } = response;
        if (data.status == 420) this.setLogout();
        else if (data.status == 200) {
          message.success("records updated");
          this.setState({ CDM: false });
          this.dataLoaded(1);
          return true;
        } else {
          const { messages } = data;
          if (messages) {
            message.error(messages);
            return false;
          }
        }
      } else alert("Network Error !");
  }

  bulkReleaseCertificate = async () => {
    const { Chabi, selectedRows } = this.state;
    console.log("selected records",selectedRows);
    if(selectedRows.length < 1) return alert("No record selected..!!");
    const url = certificate_details_api + "/bulk_publish/";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
			request: { selectedRow: selectedRows} });
       
    if (response.status == 200) {
      const { data } = response;
      message.success("Record updated");
      this.setState({ CDM: false });
			this.dataLoaded(1);
    }
  }
  
  rowSelected = (obj) => {
    let { target, type, records} = obj;
      if(type == "bulk"){
        if(target.checked == true){
          records.map( _ => this.setState({ [_] : true}))
          this.setState({ selectedRows : records,allChecked:true})
        }else{
          records.map( _ => this.setState({ [_] : false}))
          this.setState({ selectedRows : [], allChecked:false})
        }
        
      }else{
        let allIds = this.state.selectedRows;
        if(target.checked == true){
            if(allIds.indexOf(records) == -1){ 
              allIds.push(records);
              this.setState({ selectedRows : allIds, [records] : true, allChecked:false})
            }
      }else{   // not checked
          if(allIds.indexOf(records) != -1){  
            allIds.splice(allIds.indexOf(records),1)
            this.setState({ selectedRows : allIds, [records] : false, allChecked:false})
          }
      }
      }
     
  }

  genrateCertificate = async (pageId,learnerId,orderId,orderType) => {
    let query = { traningId: pageId, userId: learnerId, orderId: orderId,orderType: orderType}
    let identified = btoa(btoa(JSON.stringify(query)));
    window.location.href = `certificate_preview?identified=${identified}`
    
    // const { Chabi } = this.state;
    // this.setState({ CDM: true });
    // const url = certificate_details_api + "/listingCertificateGenrate";
    // const response = await myAxios({
    //   method: "post",
    //   url,
    //   header: { Chabi },
		// 	request: { identified }});
       
    // if (response.status == 200) {
    //   const { data } = response;
    //   message.success("Record updated");
    //   this.setState({ CDM: false });
		// 	this.dataLoaded(1);
    // }
  }


  render() {
   
    console.log("Training Enquiry State ", this.state);
    let {
      records,
      request2,
      loginStatus,
      filtered_records,
      cmodal,
      selectedRecord,
	  cmodalMove,
	  selectedRecordMove,
	  course_training,
      comment,
      coffcanvas,
      region2,
      CDM,
      totalPage,
      chPwdMsg,
      Chabi,
      page,
      cmodalUdi,
      pagination,
      orderType,
      pageId,
      permission
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];

    return (
      <>
        <PageHeading2
          active="Training"
          page={["Training", "Learners"]}
        ></PageHeading2>
        <MainArea>
        

          <div className="p-2 mt-2">
          <h5>Course Title: <span style={{fontWeight:"normal"}}> {records[0]?.result[0]?.trainingName}</span></h5>
					<hr></hr>
            <div className="mt-3 mb-2">
              {permission.childModule && permission.childModule?.viewCourseNotes ? 
              <Link to="/notes" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faStreetView}/> View Course Notes</Link>
              : null }
              {permission.childModule && permission.childModule?.mailClass ? 
              <Link to={`/mail-class?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${records[0]?.orderId}`))}`} className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Mail Class</Link>
              : null}
              {/* <Link to="/enroll_new_student" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faUserPlus}/> Enroll New Student</Link> */}
              {/* <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faUserPlus}/> Invite New Student</Link> */}
              {/* <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faPlusCircle}/> Add To Litmos</Link> */}
              {/* <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Disable From Litmos</Link> */}
              
              {permission.childModule && permission.childModule?.deleteFromThisCourse ? 
              <button className="btn btn-info btn-small mr-2 mb-2" onClick={() => this.bulkDeleleteFromThisClass()}><FontAwesomeIcon className="ml-1" icon={faTrash}/> Delete From This Course</button>
              : null }
              {/* <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faTrash}/> Delete From Litmos</Link> */}
              {/* <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faPlusCircle}/> Add ATP Delegate No.</Link> */}
              {/* <Link to="" className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faSignOutAlt}/> Sign Off</Link> */}
              {permission.childModule && permission.childModule?.publishAllResult ? 
              <button className="btn btn-info btn-small mr-2 mb-2" onClick={() => this.bulkPublishFromThisClass()} ><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Publish All Results </button>
              : null }
              {permission.childModule && permission.childModule?.genrateEvaluationReport ? 
              <Link to={`/evaluation_report?tranining=${records[0]?.result[0]?._id}&&evaluation=${records[0]?.result[0]?.evaluation_template}`} className="btn btn-info btn-small mr-2 mb-2"><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Generate Evaluation Report </Link>
              : null }
              {permission.childModule && permission.childModule?.releaseAllCertificate ? 
              <button className="btn btn-info btn-small mr-2 mb-2" onClick={() => this.bulkReleaseCertificate()}><FontAwesomeIcon className="ml-1" icon={faEnvelope}/> Release All Certificates</button>
              : null }
              </div>
          <div
                    className="table-responsive"
                    style={{
                      background: "#ffffff",
                      marginBottom: "20px",
                    }}
                  >

                    <table className="table table-bordered learner-table">
                      <thead className="border-none">
                        <tr>
                          <th scope="col">
                          <Checkbox id="" checked={this.state.allChecked} onChange={(e) => this.rowSelected({target: e.target, type: "bulk", records: records.map( _ => _.orderId)})} />
                          </th>
                          <th scope="col">Learner Name</th>
                          <th scope="col">Results</th>
                          <th scope="col">Exam Code</th>
                          <th scope="col">Exam Result</th>
                          <th scope="col">CQI-IRCA UDN No</th>
                          <th scope="col">Status</th>
                          <th scope="col">Results Action</th>
                          <th scope="col">Certificate</th>
                          <th scope="col">Litmos</th>
                          {permission.childModule && permission.childModule?.action ?
                          <th scope="col" style={{ width: "10%" }}>
                            Action
                          </th>
                          : null }
                        </tr>
                      </thead>
                      <tbody className="table-bordered">
                      
         {!CDM ? (
           <tr>
             <td colSpan={11}>
           <div className="loader-main-container">
            <MyLoading />
            </div>
            </td>
            </tr>
          ) : records && records.length ?
                        records.map((learner) => {
                         
                          let lcaExamCheckPass = [];
                          let percentage = 0

                          if(learner.attandance != null){
                          learner.attandance.attandance.map( (_,i) => {
                            if(_.checked == true){
                              percentage += 1 
                            }
                            if(learner.attandance.attandance.length-1 == i){
                              percentage = Math.round((percentage * 100)/learner.attandance.attandance.length)
                            }
                          })
                           }
                          let lca_marks = 0;
                          if(learner.Lca_exam_results.length >=1){
                            learner.Lca_exam_results[0].sectionResult.map( (section,i) => {
                              lcaExamCheckPass.push(section.gradeMarks >= 7 ? true : false)
                              lca_marks += section.gradeMarks
                              if(learner.Lca_exam_results[0].sectionResult.length-1 == i){
                             
                                lca_marks = (lca_marks * 100) / (learner.Lca_exam_results[0].sectionResult.length * 10)
                              }
                            })
                          }

                          let certificate = ""
                          let publishButtonActive = true;
                          let msgPaymentDue = "Payment Due Pending"
                          let ordeerOBj = learner.order
                          if(ordeerOBj.isfullPayment != 1  && ordeerOBj.total_amount > (ordeerOBj.amount + ordeerOBj.partial_amount)){
                            publishButtonActive = false
                          }

                          if(learner.certificate != null){
                              if(learner.certificate.certificate_no != null && learner.certificate.certificate_no != ""){
                                
                                certificate =  (publishButtonActive) ? <>
                                <a
                                  href={(learner.certificate.certificate_with_template_url != "" && learner.certificate.certificate_with_template_url != undefined) ? learner.certificate.certificate_with_template_url : "#" }
                                  className="btn btn-success btn-small"
                                  target="_blank"
                                >
                                  {(learner.certificate.certificate_no != null) ? learner.certificate.certificate_no : "" }
                                </a>
                                <br></br>
                                <a
                                   href={(learner.certificate.certificate_without_template_url != "" && learner.certificate.certificate_without_template_url != undefined) ? learner.certificate.certificate_without_template_url : "#" }
                                  className="btn btn-default btn-small"
                                  target="_blank"
                                >
                                  {(learner.certificate.certificate_no != null) ? learner.certificate.certificate_no : "" }
                                </a>
                                <br></br>
                                <span>Date of Certificate Genrate : {learner.certificate.create_at}</span>
                                {permission.childModule && permission.childModule?.certificate ?
                                <button
                                  href="#"
                                  className="btn btn-danger btn-small"
                                  onClick={() => this.releaseRevokeCertificate( learner.certificate._id,(learner.certificate.active == 1) ? 0 : 1)}
                                >
                                  
                                  {(learner.certificate.active == 1) ? "Revoke" : "Release" }
                                </button>
                                : null}
                             
                                </> : <button className="btn btn-danger btn-small" onClick={() => alert(msgPaymentDue)}>Generate Certificate</button>
                              }else{
                                if(publishButtonActive) {
                                     if(learner.result[0].courseData.min_attendancecheckbox == "true"){
                                       if(parseInt(learner.result[0].courseData.min_attendance.split("%")[0]) <= percentage){
                                         if(permission.childModule && permission.childModule?.certificate){
                                          certificate = <button className="btn btn-sm btn-success"  onClick={() => this.genrateCertificate(pageId,learner._id,learner.orderId,orderType)}>Generate Certificate</button>
                                         }else{
                                          certificate = "-"
                                         }
                                         
                                       }else{
                                         certificate = <><span>Not Applicable</span><br /><span><b>Min Attendance</b>{`${learner.result[0].courseData.min_attendance}`}</span></>
                                       }
                                     }else{
                                      if(permission.childModule && permission.childModule?.certificate){
                                        certificate = <button className="btn btn-sm btn-success"  onClick={() => this.genrateCertificate(pageId,learner._id,learner.orderId,orderType)}>Generate Certificate</button>
                                      }else{
                                        certificate = "-"
                                      }
                                      }
                                }else{
                                  certificate = <button className="btn btn-danger btn-small" onClick={() => alert(msgPaymentDue)}>Generate Certificate</button>
                                }
                               
                               
                              }
                            }else{
                              // if certificate null
                              if(publishButtonActive) {
                               
                                if(learner.result[0].courseData.min_attendancecheckbox == "true"){
                                  if(permission.childModule && permission.childModule?.certificate){
                                  certificate = <button className="btn btn-sm btn-success"  onClick={() => this.genrateCertificate(pageId,learner._id,learner.orderId,orderType)}>Generate Certificate</button>
                                }else{
                                  certificate = <><span>Not Applicable</span><br /><span><b>Min Attendance: </b>{`${learner.result[0].courseData.min_attendance}`}</span></>
                                }
                          }else{
                            if(permission.childModule && permission.childModule?.certificate){
                            certificate = <button className="btn btn-sm btn-success"  onClick={() => this.genrateCertificate(pageId,learner._id,learner.orderId,orderType)}>Generate Certificate</button>
                            }else{
                              certificate = <button className="btn btn-sm btn-warning"  onClick={() => alert("Permission Not give")}>Generate Certificate</button>
                            }
                          }
                        
                        }else{
                          certificate = <button className="btn btn-danger btn-small" onClick={() => alert(msgPaymentDue)}>Generate Certificate</button>
                        }

                        // console.log("lcapass", lcaExamCheckPass);

                            }

                          return (
                            <tr>
                              <td>
                                <Checkbox id={learner.orderId} checked={this.state[learner.orderId]} onChange={(e) => this.rowSelected({target: e.target, type: "single", records: learner.orderId})} />

                              </td>
                              <td>
                                {learner.first_name}{learner.last_name}
                                <br></br>
                                {learner.email}
                                <br></br>
                                <strong>ID: </strong>{learner._id}
                                {permission.childModule && permission.childModule?.moveToOtherCourse ? 
                                <button className="btn btn-info btn-small" onClick={(e) => this.MoveOtherCourse(learner)}>
                                  Move To Other Course
                                </button> : null }
                              </td>
                              <td>
                                <strong>Course:</strong> {(learner.preCourseMarksExist == true) ? learner.preCourseMarks : "-"}<br></br>
                                <strong>Attendance:</strong> {percentage}% <br></br>
                                <strong>LCA:</strong> {lca_marks.toFixed(2)}%
                              </td>
                              <td>{learner.result[0].exam_template != undefined ? learner.result[0].exam_template.exam_name : "-"}
                              <hr />
                              {learner.result[0].exam_2_template != undefined ? learner.result[0].exam_2_template.exam_name : "-"}
                              </td>
                              <td>{(learner.learner_exam_result != null) ? learner.learner_exam_result.total_exam_marks : "-" }
                              <hr />
                              {(learner.learner_exam_2_result != null && learner.learner_exam_2_result.total_exam_marks != 0) ? learner.learner_exam_2_result.total_exam_marks : "-" }
                              </td>
                              <td>
                                {learner.learnerUdn != null ? learner.learnerUdn.udn_number : "-"}<br></br>
                                {permission.childModule && permission.childModule?.cqi_udn_no ? 
                                <button className="btn btn-info btn-small" onClick={() => { this.setState({cmodalUdi : true, selectedRecord: learner, request: { udn_number: learner.learnerUdn != null ? learner.learnerUdn.udn_number : ""}})}}>
                                  Edit
                                </button>
                                : null }
                              </td>
                              <td>
                                <strong>Course Status: </strong>{" "}
                                  {learner.order.course_completed ? learner.order.course_completed == 1 ? <span className="badge badge-success">Completed</span> : <span className="badge badge-danger">Not Completed</span> : <span className="badge badge-danger">Not Completed</span>}
                                
                                <br></br>
                                <strong>Registration Status: </strong>
                                <span className="badge badge-success">
                                  {" "}
                                  Completed{" "}
                                </span>
                                <br></br>
                                <br></br>
                                <strong>Evaluation Status: </strong>

                                { learner.evalautionStatus != null ? (learner.evalautionStatus.eval_status === "1") ? 
                                 <a
                                 href="#"
                                 className="btn btn-success btn-small"
                               >
                                 Completed
                               </a> 
                               : 
                               <a
                                 href="#"
                                 className="btn btn-danger btn-small"
                               >
                                 Not Completed
                               </a>
                               : 
                               <a
                                 href="#"
                                 className="btn btn-danger btn-small"
                               >
                                 Not Completed
                               </a>
                              }
                               
                              </td>
                              <td>{ publishButtonActive == true ? 
                                           (learner.learner_exam_result != null) ? (learner.learner_exam_result.isPublish == 0) ? "Not Published" : "Published" : "-"  
                                           : null
                                  } 
                    
                              {publishButtonActive == true ?
                                  (learner.learner_exam_result != null) ? 
                                       (learner.learner_exam_result.isPublish == 0) ?   <button className="btn btn-sm btn-primary" onClick={() => this.publishHandler(learner.learner_exam_result._id)}>Publish</button>  : null 
                                 : null 
                                : <button className="btn btn-sm btn-danger" onClick={() => alert(msgPaymentDue)}>Publish</button>} 
                              </td>
                              <td>
                               
                                <br></br>
                               {certificate}
                              </td>
                              <td>
                                <span className="badge badge-danger">
                                  Not added in litmos
                                </span>
                              </td>
                              {permission.childModule && permission.childModule?.action ?
                              <td>
                                <select id="action" onChange={(e) => this.seleteRedirectionHandler(e,learner,learner.attandance)}>
                                  <option selected>Select Action</option>

                                  {/* {permission.childModule && permission.childModule?.action_attandance ? (learner.result[0].pre_course_template != "" && learner.result[0].pre_course_template != undefined && learner.result[0].pre_course_template != null) ? <option value={`/tranning_pre_course_result?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}`}>View Pre Course Result</option>: null : null } */}

                                  {permission.childModule && permission.childModule?.action_attandance ? <option value={`/tranning_learner_attendance?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}`}>Enter/Edit Attendance Result</option> : null }

                                  {permission.childModule && permission.childModule?.action_lca_result ? learner.result[0].lca_template != undefined && learner.result[0].lca_template != "" ? <option value={`/Lca_exam_result?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}&moduletype=1`}>Enter/Edit LCA Result</option> : null : null}

                                  {/* {learner.result[0].lca_template_2 != undefined && learner.result[0].lca_template_2 != "" ? <option value={`/Lca_exam_result?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}&moduletype=2`}>Enter/Edit Additional LCA Result</option> : null} */}
                                  {/* exam 1 exam result */}

                                  {permission.childModule && permission.childModule?.action_exam_result ?  learner.result[0].exam_template != undefined && learner.result[0].exam_template != "" ? <option value={`/tranning_result?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}&moduletype=1`}>Enter Exam Grade</option> : null : null}

                                  {/* exam 1 repeat exam result */}
                                  {permission.childModule && permission.childModule?.action_exam_result ? ( learner.learner_exam_result != null  &&  learner.result[0].repeat_exam != "null" && learner.learner_exam_result.total_exam_marks != 0 && learner.learner_exam_result.total_exam_marks < learner.learner_exam_result.total_min_grade)  ? <option value={`/tranning_result?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}&moduletype=2`}>Enter Exam Resit Grade</option> : null : null }

                                  {/* exam 2 result */}
                                  {permission.childModule && permission.childModule?.action_exam_result ?  learner.result[0].exam_2_template != undefined && learner.result[0].repeat_exam_2 != "null" && learner.result[0].exam_template != "" ? <option value={`/tranning_result_2?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}&moduletype=1`}>Enter Exam 2 Grade</option> : null : null}

                                  {/* exam 2 repeat exam result */}
                                  {permission.childModule && permission.childModule?.action_exam_result ? ( learner.learner_exam_2_result != null  &&  learner.learner_exam_2_result.total_exam_marks != 0 && learner.learner_exam_2_result.total_exam_marks < learner.learner_exam_2_result.total_min_grade)  ? <option value={`/tranning_result_2?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}&moduletype=2`}>Enter Exam 2 Resit Grade</option> : null : null}

                                  {permission.childModule && permission.childModule?.action_certificate_details ? publishButtonActive == true ? <option value={`/certificate_details?training=${btoa(btoa(pageId))}&identifier=${btoa(btoa(`${learner.orderId}_${learner._id}_${learner.orderType}`))}`}>Edit Certificate Details</option> : null : null}
                                  {permission.childModule && permission.childModule?.action_viewProfile ? <option value={`/Learner_edit?learner=${btoa(btoa(learner._id))}`}>Edit Student A/C Details</option> : null }

                                  {permission.childModule && permission.childModule?.action_student_details ? <option value={`/learner_details?learner=${btoa(btoa(learner._id))}`}>View Profile</option> : null }

                                  {permission.childModule && permission.childModule?.action_reset_password ? <option value="/reset_password">Reset Password</option> : null }

                                  {/* <option value="">Issue Student Guidebook</option>
                                  <option value="">Unlock Grading</option> */}

                                  {permission.childModule && permission.childModule?.action_removethisCourse ? <option value="/remove_from_this_class">Remove From This Class</option> : null }

								                  {permission.childModule && permission.childModule?.action_evalution_completion_reminder ? <option value="/send_evaluation_reminder">Send Evaluation Completion Reminder</option> : null }

                                </select>
                              </td>
                              : null }
                            </tr>
                          )
                        }): 
                        <tr>
                      <td colSpan={11}>
                        <MyEmpty />
                        </td></tr>
                        }
                      </tbody>
                    </table>
                  
                    {pagination != undefined ? pagination: ""}
                  </div>
          </div>
                  
                
        </MainArea>
        <CustomModal
          key={selectedRecord._id}
          status={cmodal}
          title="Reset Password"
          close={this.close_modal}
          className=""
        >
           <Row>
                  <Col spanb={24}>
                    <Row>
                      <Col span={24}>
                        <div className="ml-05 mr-05">
                        <FormPasswordGroup
                          id="password"
                          name="password"
                          label="Password"
                          placeholder="Password"
                          value={request2["password"]}
                          sendValue={this.setRequest2}
                        />
                        </div>
                      </Col>
                      <Col span={24}>
                      <div className="ml-05 mr-05">
                        <FormPasswordGroup
                          id="cpassword"
                          name="cpassword"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          value={request2["cpassword"]}
                          sendValue={this.setRequest2}
                        />
                        </div>
                      </Col>
                      <Col span={24} className="m-1">
                        {chPwdMsg == "1" ? (
                          <span
                            className="text-success"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Learner Password Changed
                          </span>
                        ) : chPwdMsg == "0" ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Somethign Wrong
                          </span>
                        ) : chPwdMsg == "-1" ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Password and Confirm Password must be same and not
                            blank
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={24}>
                        <div className="ml-05 mr-05">
                        <FormButton
                          reset={() => {
                            this.setState({ request2: {}, chPwdMsg: "" });
                          }}
                          submit={async () => {
                            // console.log("request2 ", request2);
                            const { password, cpassword } = request2;
                            if (
                              password &&
                              cpassword &&
                              password.length >= 6 &&
                              password == cpassword
                            ) {
                              const url = learners_api + "/cpa";
                              const response = await myAxios({
                                method: "put",
                                url,
                                header: { Chabi },
                                request: {
                                  _id: selectedRecord._id,
                                  ...request2,
                                },
                              });
                              if (response.status == 200) {
                                this.setState({ request2: {}, chPwdMsg: "1" });
                                setTimeout( () => {
                                  this.setState({ cmodal: false });
                                },1000)
                              } else this.setState({ chPwdMsg: "0" });
                            } else this.setState({ chPwdMsg: "-1" });
                          }}
                        />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col spanb={8}></Col>
                  <Col spanb={8}></Col> */}
                </Row>
        </CustomModal>
		<CustomModal
          key={"move"+selectedRecordMove._id}
          status={cmodalMove}
          title="Move This Student To Other Course"
          close={this.courseclose_modal}
          className="modal-form-course"
        >
		
			<Row className="mt-2 mb-2">
                <Col span={24}>
					Student Name: <strong> {selectedRecordMove.first_name}  {selectedRecordMove.last_name}</strong>
				</Col>
			</Row>
				  
			<Row className="mb-1">
      <Col span={24}>
        <div className="ml-05">
                    <FormSelectGroup
                      allowClear
                      id="training"
                      name="training"
                      label="Select Course"
                      placeholder="training"
                      // data={this.state}
                      // required
                      options={course_training}
                      option_value="_id"
                      option_text="trainingName"
                      // setValidation={this.setValidation}
                      sendValue={({ value }) => {
                        this.setState({ training_id: value });
                      }}
                    />
					</div>
                    </Col>
                    </Row>  
         <Row className="mb-1"> 
         <Col span={24}> 
         
				<FormButton
                    submit={async () => {
                      if (!window.confirm("Are you sure?")) return false;
                      const { selectedRecord, training_id, Chabi } = this.state;
					  //console.log("xxxxxx",{ _id:selectedRecordMove.orderId,user_id:selectedRecordMove._id,course_id:selectedRecordMove.result[0].courseId, product_id:training_id });return false;
                      if (training_id && training_id.length) {
                        const { _id: user_id } = selectedRecord;
                        const url = learner_move_course_api + "/";
                        const response = await myAxios({
                          method: "put",
                          url,
                          header: { Chabi },
                          request: { _id:selectedRecordMove.orderId,user_id:selectedRecordMove._id,course_id:selectedRecordMove.result[0].courseId, product_id:training_id },
                        });
                        // console.log("add course ", response);
                        if (
                          response.status == 200 &&
                          response.data.status == 200
                        ) {
                          const { records } = response.data;
                          this.setState({cmodalMove: false})
                          this.dataLoaded(1)
                          /*selectedRecord["extra"]["user_training"].push(
                            records
                          );
                          this.setState({ selectedRecord, training_id: "" });*/
                          alert("Training assigned to learner");
                        }
                      } else alert("please select training ");
                    }}
                  />
                  </Col>     
			</Row>
		</CustomModal>

    <CustomModal
          key={selectedRecordMove._id}
          status={cmodalUdi}
          title="Edit UDI NO"
          close={this.courseclose_udi_modal}
          className="modal-form-course"
        >
			      <Row className="">  
              <FormInputGroup
                      id="udn_number"
                      name="udn_number"
                      label="UDN NO"
                      placeholder="UDN NO"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />     
            </Row>   
          <Row className="mb-4">  
				  <FormButton
                    submit={async () => {
                      const { selectedRecord, Chabi } = this.state;
                        let saveValue = { order_id : selectedRecord.orderId, order_type : (selectedRecord.orderType == "pre-order") ? "1" : "2", training_id: selectedRecord.result[0]._id, course_id: selectedRecord.result[0].courseId,learner_id:selectedRecord._id, udn_number: this.state.request.udn_number }
                        const url = learner_udi_api + "/";
                        const response = await myAxios({
                          method: "post",
                          url,
                          header: { Chabi },
                          request: saveValue,
                        });
                        // console.log("add course ", response);
                        if (
                          response.status == 200 &&
                          response.data.status == 200
                        ) {
                          const { records } = response.data;
                          alert(response.data.message);
                          this.setState({ cmodalUdi: false, CDM: false})
                          this.dataLoaded(1)
                        }

                    }}
                  />
               </Row>   
			
		</CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tranning_learner);
