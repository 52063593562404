import React from "react";
import FormLabel from "./FormLabel/";
import FormEmail from "./FormEmail";
import { Row, Col } from "antd";
import FormLabel2 from "./FormLabel2";
import FormValidationError from "./FormValidationError";
function FormEmailGroup(props) {
  const { groupClassName, label, emailExists } = props;
  return (
    <Row className={`pl-2 pr-2 mt-2 ${groupClassName}`}>
      {label ? (
        <Col span={24} className="text-left">
          <FormLabel {...props} />
        </Col>
      ) : (
        ""
      )}
      <Col span={24} className="text-left ">
        <FormEmail {...props} />
      </Col>
      {emailExists ? <FormLabel2 {...props} /> : ""}
      <FormValidationError {...props} />
    </Row>
  );
}
export default FormEmailGroup;
