// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side {
  background: unset !important;
  height: 100vh !important;
  overflow: scroll;
}
.side-menu {
  height: 100%;
  border-radius: 0;
  background-color: unset !important;
  /* overflow: auto; */
  padding-right: 5px;
  border: unset !important;
}
.side-menu :nth-child(1) {
  margin-top: 0 !important;
}
.layout2 {
  background-color: unset !important;
  margin-top: 65px;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/MySider/style.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,wBAAwB;EACxB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kCAAkC;EAClC,oBAAoB;EACpB,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,kCAAkC;EAClC,gBAAgB;AAClB","sourcesContent":[".side {\n  background: unset !important;\n  height: 100vh !important;\n  overflow: scroll;\n}\n.side-menu {\n  height: 100%;\n  border-radius: 0;\n  background-color: unset !important;\n  /* overflow: auto; */\n  padding-right: 5px;\n  border: unset !important;\n}\n.side-menu :nth-child(1) {\n  margin-top: 0 !important;\n}\n.layout2 {\n  background-color: unset !important;\n  margin-top: 65px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
