import { faL } from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogout } from "../../Action/userAction";
import { PORTAL } from "../../currrent_info";
import { landingform_api } from "../../library/landingform_api";
import { myAxios } from "../../library/networkFunction";
import Context from "@ckeditor/ckeditor5-core/src/context";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import { Upload, Col } from 'antd';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MyLoading from "../part/MyLoading";

// const QuillNoSSRWrapper = dynamic(import('react-quill'), {
//   ssr: false,
//   loading: () => <p>Loading ...</p>,
// })
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code",
];
export class Landingform extends Component {
  constructor(props) {
    super(props);
    console.log("landin form props", props);
	this.state = {
	  loader:false,
      Chabi: props.Chabi,
      _id: props.data._id,
      logo_image_view : props.data.logo_image ? `${PORTAL.api_url}uploaded_images/${props.data.logo_image}` : "",
      partner_image_view : props.data.partner_image ? `${PORTAL.api_url}uploaded_images/${props.data.partner_image}` : "",
      banner_image_view : props.data.banner_image ? `${PORTAL.api_url}uploaded_images/${props.data.banner_image}` : "",
      auditor_certification_image_view : props.data.auditor_certification_image ? `${PORTAL.api_url}uploaded_images/${props.data.auditor_certification_image}` : "",
      proposal_image_view : props.data.proposal_image ? `${PORTAL.api_url}uploaded_images/${props.data.proposal_image}` : "",
      about_image_view : props.data.about_image ? `${PORTAL.api_url}uploaded_images/${props.data.about_image}` : "",
      logo_image_error: false,
      ValidationMessage:"",
      partner_image_error: false,
      banner_image_error : false,
      auditor_certification_image_error : false,
      proposal_image_error : false,
      about_image_error : false,
    };
   
    this.updateState = this.updateState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleCHangeCheck = this.handleCHangeCheck.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleEdit = async (event) => {
    this.defaultValidation();
	  this.setState({loader:true});
    const {
      Chabi,
      logo_image,
      partner_image,
      title,
      description,
      course_overview,
      short_description,
      banner_image,
      limited_offer,
      days,
      hours,
      minutes,
      seconds,
      banner_learn_more,
      auditor_certification_image,
      auditor_certification_image_view,
      auditor_certification_title,
      auditor_certification_description,
      auditor_certification_learn_more,
      proposal_image,
      proposal_description,
      about_image,
      about_title,
      about_description,
      about_learn_more,
      footer_contact_phone,
      footer_contact_address,
      _id,
    } = this.state;
    event.preventDefault();
  
    if(logo_image == undefined || logo_image == ""){
      this.applyValidation(`logo_image_error`, "logo image required")
      return false;
    }
    
    if(title == undefined || title == ""){
      this.applyValidation(`title_error`, "title required")
      return false;
    }

    if(partner_image == undefined || partner_image == ""){
      this.applyValidation(`partner_image_error`, "Training partner image required")
      return false;
    }

    if(short_description == undefined || short_description == ""){
      this.applyValidation(`short_description_error`, "short description required")
      return false;
    }

    if(course_overview == undefined || course_overview == ""){
      this.applyValidation(`course_overview_error`, "course overview required")
      return false;
    }

     if(banner_image == undefined || banner_image == ""){
      this.applyValidation(`banner_image_error`, "Banner image required")
      return false;
    }

    if(limited_offer == undefined || limited_offer == ""){
      this.applyValidation(`limited_offer_error`, "limited_offer required")
      return false;
    }

    if(course_overview == undefined || course_overview == ""){
      this.applyValidation(`course_overview_error`, "course overview required")
      return false;
    }
    
    
    if(days == undefined || days == ""){
      this.applyValidation(`days_error`, "days required")
      return false;
    }

    if(hours == undefined || hours == ""){
      this.applyValidation(`hours_error`, "hours required")
      return false;
    }

    if(minutes == undefined || minutes == ""){
      this.applyValidation(`minutes_error`, "minutes required")
      return false;
    }

    if(seconds == undefined || seconds == ""){
      this.applyValidation(`seconds_error`, "seconds required")
      return false;
    }

    if(description == undefined || description == ""){
      this.applyValidation(`description_error`, "description required")
      return false;
    }

    if(banner_learn_more == undefined || banner_learn_more == ""){
      this.applyValidation(`banner_learn_more_error`, "banner learn more required")
      return false;
    }
    
    // auditor section
    if(auditor_certification_image_view == undefined || auditor_certification_image_view == ""){
      console.log(auditor_certification_image_view);
      this.applyValidation(`auditor_certification_image_error`, "Auditor certification training image required")
      return false;
    }

    if(auditor_certification_title == undefined || auditor_certification_title == ""){
      this.applyValidation(`auditor_certification_title_error`, "auditor certification title required")
      return false;
    }

    if(auditor_certification_description == undefined || auditor_certification_description == ""){
      this.applyValidation(`auditor_certification_description_error`, "auditor certification description required")
      return false;
    }

    if(auditor_certification_learn_more == undefined || auditor_certification_learn_more == ""){
      this.applyValidation(`auditor_certification_learn_more_error`, "auditor certification learn more required")
      return false;
    }

    // about section
    if(proposal_image == undefined || proposal_image == ""){
      this.applyValidation(`proposal_image_error`, "Proposal image required")
      return false;
    }

    if(proposal_description == undefined || proposal_description == ""){
      this.applyValidation(`proposal_description_error`, "proposal description required")
      return false;
    }

    if(about_image == undefined || about_image == ""){
      this.applyValidation(`about_image_error`, "About image required")
      return false;
    }

    if(about_title == undefined || about_title == ""){
      this.applyValidation(`about_title_error`, "about title required")
      return false;
    }

    if(about_description == undefined || about_description == ""){
      this.applyValidation(`about_description_error`, "about description required")
      return false;
    }

    if(about_learn_more == undefined || about_learn_more == ""){
      this.applyValidation(`about_learn_more_error`, "about learn more required")
      return false;
    }
    
  // footer section

  if(footer_contact_phone == undefined || footer_contact_phone == ""){
    this.applyValidation(`footer_contact_phone_error`, "footer contact phone required")
    return false;
  }

  if(footer_contact_address == undefined || footer_contact_address == ""){
    this.applyValidation(`footer_contact_address_error`, "footer contact address required")
    return false;
  }

    const landingdata = {
      _id: this.state._id,
      logo_image: this.state.logo_image
        ? this.state.logo_image
        : this.state.logo_image,
      title: this.state.title ? this.state.title : this.state.title,
      partner_image: this.state.partner_image,
      description: this.state.description ? this.state.description : this.state.description,
      course_overview: this.state.course_overview ? this.state.course_overview : this.state.course_overview,
      short_description: this.state.short_description ? this.state.short_description : this.state.short_description,
      banner_image: this.state.banner_image,
      limited_offer: this.state.limited_offer ? this.state.limited_offer : this.state.limited_offer,
      days: this.state.days ? this.state.days : this.state.days,
      hours: this.state.hours ? this.state.hours : this.state.hours,
      minutes: this.state.minutes ? this.state.minutes : this.state.minutes,
      seconds: this.state.seconds ? this.state.seconds : this.state.seconds,
      auditor_certification_image: this.state.auditor_certification_image,
      auditor_certification_title: this.state.auditor_certification_title ? this.state.auditor_certification_title : this.state.auditor_certification_title,
      auditor_certification_description: this.state
        .auditor_certification_description
        ? this.state.auditor_certification_description
        : this.state.auditor_certification_description,
      proposal_image: this.state.proposal_image,
      proposal_description: this.state.proposal_description
        ? this.state.proposal_description
        : this.state.proposal_description,
      about_image: this.state.about_image,
      about_title: this.state.about_title
        ? this.state.about_title
        : this.state.about_title,
      about_description: this.state.about_description
        ? this.state.about_description
        : this.state.about_description,
      footer_contact_phone: this.state.footer_contact_phone
        ? this.state.footer_contact_phone
        : this.state.footer_contact_phone,
      footer_contact_address: this.state.footer_contact_address
        ? this.state.footer_contact_address
        : this.state.footer_contact_address,
      banner_learn_more: this.state.banner_learn_more
        ? this.state.banner_learn_more
        : this.state.banner_learn_more,
      auditor_certification_learn_more: this.state
        .auditor_certification_learn_more
        ? this.state.auditor_certification_learn_more
        : this.state.auditor_certification_learn_more,
      about_learn_more: this.state.about_learn_more
        ? this.state.about_learn_more
        : this.state.about_learn_more,
      // uploadImageList: [],
      //   popular_courses_multi_image: this.state.popular_courses_multi_image,
    };

    const url = landingform_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: landingdata,
    });
  
      // refresh data
  this.props.reloadData();
    if (response.status === 200) {
		this.setState({loader:false});
      this.props.onClose();
    }
  };

  handleSubmit = async (event) => {
	  this.setState({loader:true});

    const {
      Chabi,
      logo_image,
      partner_image,
      title,
      description,
      course_overview,
      short_description,
      banner_image,
      limited_offer,
      days,
      hours,
      minutes,
      seconds,
      banner_learn_more,
      auditor_certification_image,
      auditor_certification_image_view,
      auditor_certification_title,
      auditor_certification_description,
      auditor_certification_learn_more,
      proposal_image,
      proposal_description,
      about_image,
      about_title,
      about_description,
      about_learn_more,
      footer_contact_phone,
      footer_contact_address,
      // uploadImageList: [],
    } = this.state;
    event.preventDefault();
  
    if(logo_image == undefined || logo_image == ""){
      this.applyValidation(`logo_image_error`, "logo image required")
      return false;
    }
    
    if(title == undefined || title == ""){
      this.applyValidation(`title_error`, "title required")
      return false;
    }

    if(partner_image == undefined || partner_image == ""){
      this.applyValidation(`partner_image_error`, "Training partner image required")
      return false;
    }

    if(short_description == undefined || short_description == ""){
      this.applyValidation(`short_description_error`, "short description required")
      return false;
    }

    if(course_overview == undefined || course_overview == ""){
      this.applyValidation(`course_overview_error`, "course overview required")
      return false;
    }

     if(banner_image == undefined || banner_image == ""){
      this.applyValidation(`banner_image_error`, "Banner image required")
      return false;
    }

    if(limited_offer == undefined || limited_offer == ""){
      this.applyValidation(`limited_offer_error`, "limited_offer required")
      return false;
    }

    if(course_overview == undefined || course_overview == ""){
      this.applyValidation(`course_overview_error`, "course overview required")
      return false;
    }
    
    
    if(days == undefined || days == ""){
      this.applyValidation(`days_error`, "days required")
      return false;
    }

    if(hours == undefined || hours == ""){
      this.applyValidation(`hours_error`, "hours required")
      return false;
    }

    if(minutes == undefined || minutes == ""){
      this.applyValidation(`minutes_error`, "minutes required")
      return false;
    }

    if(seconds == undefined || seconds == ""){
      this.applyValidation(`seconds_error`, "seconds required")
      return false;
    }

    if(description == undefined || description == ""){
      this.applyValidation(`description_error`, "description required")
      return false;
    }

    if(banner_learn_more == undefined || banner_learn_more == ""){
      this.applyValidation(`banner_learn_more_error`, "banner learn more required")
      return false;
    }
    
    // auditor section
    if(auditor_certification_image_view == undefined || auditor_certification_image_view == ""){
      console.log(auditor_certification_image_view);
      this.applyValidation(`auditor_certification_image_error`, "Auditor certification training image required")
      return false;
    }

    if(auditor_certification_title == undefined || auditor_certification_title == ""){
      this.applyValidation(`auditor_certification_title_error`, "auditor certification title required")
      return false;
    }

    if(auditor_certification_description == undefined || auditor_certification_description == ""){
      this.applyValidation(`auditor_certification_description_error`, "auditor certification description required")
      return false;
    }

    if(auditor_certification_learn_more == undefined || auditor_certification_learn_more == ""){
      this.applyValidation(`auditor_certification_learn_more_error`, "auditor certification learn more required")
      return false;
    }

    // about section
    if(proposal_image == undefined || proposal_image == ""){
      this.applyValidation(`proposal_image_error`, "Proposal image required")
      return false;
    }

    if(proposal_description == undefined || proposal_description == ""){
      this.applyValidation(`proposal_description_error`, "proposal description required")
      return false;
    }

    if(about_image == undefined || about_image == ""){
      this.applyValidation(`about_image_error`, "About image required")
      return false;
    }

    if(about_title == undefined || about_title == ""){
      this.applyValidation(`about_title_error`, "about title required")
      return false;
    }

    if(about_description == undefined || about_description == ""){
      this.applyValidation(`about_description_error`, "about description required")
      return false;
    }

    if(about_learn_more == undefined || about_learn_more == ""){
      this.applyValidation(`about_learn_more_error`, "about learn more required")
      return false;
    }
    
  // footer section

  if(footer_contact_phone == undefined || footer_contact_phone == ""){
    this.applyValidation(`footer_contact_phone_error`, "footer contact phone required")
    return false;
  }

  if(footer_contact_address == undefined || footer_contact_address == ""){
    this.applyValidation(`footer_contact_address_error`, "footer contact address required")
    return false;
  }


    const landingdata = {
      logo_image: this.state.logo_image
        ? this.state.logo_image : "",
      partner_image: this.state.partner_image
      ? this.state.partner_image
      : "",
      proposal_image: this.state.proposal_image ? this.state.proposal_image : "",
      proposal_description: this.state.proposal_description,
      title: this.state.title,
      description: this.state.description,
      course_overview: this.state.course_overview,
      short_description: this.state.short_description,
      banner_image: this.state.banner_image
      ? this.state.banner_image
      : "",
      limited_offer: this.state.limited_offer,
      days: this.state.days,
      hours: this.state.hours,
      minutes: this.state.minutes,
      seconds: this.state.seconds,
      auditor_certification_image: this.state.auditor_certification_image
      ? this.state.auditor_certification_image
      : "",
      auditor_certification_title: this.state.auditor_certification_title,
      auditor_certification_description:
        this.state.auditor_certification_description,
      about_image: this.state.about_image
      ? this.state.about_image
      : "",
      about_title: this.state.about_title,
      about_description: this.state.about_description,
      footer_contact_phone: this.state.footer_contact_phone,
      footer_contact_address: this.state.footer_contact_address,
      banner_learn_more: this.state.banner_learn_more,
      auditor_certification_learn_more:
        this.state.auditor_certification_learn_more,
      about_learn_more: this.state.about_learn_more,
    };
    const url = landingform_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: landingdata,
    });
      // refresh data
  this.props.reloadData();
    if (response.status === 200) {
      console.log("first");
	  this.setState({
		loader:false,
        logo_image: "",
        title: "",
        partner_image: "",
        description: "",
        course_overview: "",
        short_description: "",
        banner_image: "",
        limited_offer: "",
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
        auditor_certification_image: "",
        auditor_certification_title: "",
        auditor_certification_description: "",
        proposal_image: "",
        proposal_description: "",
        about_image: "",
        about_title: "",
        about_description: "",
        footer_contact_phone: "",
        footer_contact_address: "",
        uploadImageList: [],
        logo_image_view:"",
        partner_image_view:"",
        banner_image_view:"",
        auditor_certification_image_view:"",
        proposal_image_view:"",
        about_image_view:"",
      });
      message.success("Record Save");
    }
    
  };

  defaultValidation(){
      this.setState({
      title_error: false,
      description_error: false,
      course_overview_error: false,
      short_description_error: false,
      logo_image_error: false,
      partner_image_error: false,
      banner_image_error: false,
      auditor_certification_image_error: false,
      proposal_image_error: false,
      about_image_error: false,
      limited_offer_error: false,
      days_error: false,
      hours_error: false,
      minutes_error:false,
      seconds_error: false,
   
      auditor_certification_title_error:false,
      auditor_certification_description_error: false,
      proposal_description_error:  false,
      about_image_error: false,
      about_title_error: false,
      about_description_error: false,
      footer_contact_phone_error: false,
      footer_contact_address_error:  false,
      banner_learn_more_error: false,
      auditor_certification_learn_more_error:  false,
      about_learn_more_error:  false,
      ValidationMessage: ""
    });
  }

  applyValidation(key,ValidationMessage){
    this.setState({
    title_error: (key == "title_error") ? true : false,
    description_error: (key == "description_error") ? true : false,
    course_overview_error: (key == "course_overview_error") ? true : false,
    short_description_error: (key == "short_description_error") ? true : false,
    logo_image_error: (key == "logo_image_error") ? true : false,
    partner_image_error: (key == "partner_image_error") ? true : false,
    banner_image_error: (key == "banner_image_error") ? true : false,
    auditor_certification_image_error: (key == "auditor_certification_image_error") ? true : false,
    proposal_image_error: (key == "proposal_image_error") ? true : false,
    about_image_error: (key == "about_image_error") ? true : false,
    limited_offer_error: (key == "limited_offer_error") ? true : false,
    days_error: (key == "days_error") ? true : false,
    hours_error: (key == "hours_error") ? true : false,
    minutes_error:(key == "minutes_error") ? true : false,
    seconds_error: (key == "seconds_error") ? true : false,
 
    auditor_certification_title_error: (key == "auditor_certification_title_error") ? true : false,
    auditor_certification_description_error: (key == "auditor_certification_description_error") ? true : false,
    proposal_description_error: (key == "proposal_description_error") ? true : false,
    about_image_error: (key == "about_image_error") ? true : false,
    about_title_error: (key == "about_title_error") ? true : false,
    about_description_error: (key == "about_description_error") ? true : false,
    footer_contact_phone_error: (key == "footer_contact_phone_error") ? true : false,
    footer_contact_address_error: (key == "footer_contact_address_error") ? true : false,
    banner_learn_more_error: (key == "banner_learn_more_error") ? true : false,
    auditor_certification_learn_more_error: (key == "auditor_certification_learn_more_error") ? true: false,
    about_learn_more_error: (key == "about_learn_more_error") ? true: false,
    ValidationMessage: ValidationMessage
  });
}

  handleChange(e) {
    console.log("e==================>?", e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFileChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  handleCHangeCheck = (e) => {
    let dataArr = [...this.state.popular_courses_delivery_method];
    let checkIfValueExist = dataArr.includes(e.target.value);
    if (checkIfValueExist) {
      const newArr = dataArr.filter((object) => {
        return object !== e.target.value;
      });
      this.setState({
        popular_courses_delivery_method: newArr.join(),
      });
    } else {
      dataArr.push(e.target.value);
      this.setState({
        popular_courses_delivery_method: dataArr.join(),
      });
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  // image uploading
  handleChangeFiles = (info,name) => {
    this.getBase64(info.file.originFileObj, (url) => {
      console.log(url, info.file.response.records[0].logo_image);
      if(name == "logo_image"){
        this.setState({ loading : false, logo_image_view : url, logo_image: info.file.response.records[0].logo_image})
      }else if(name == "partner_image"){
        this.setState({ loading : false, partner_image_view : url, partner_image: info.file.response.records[0].partner_image})
      }else if(name == "banner_image"){
        this.setState({ loading : false, banner_image_view : url, banner_image: info.file.response.records[0].banner_image})
      }else if(name == "auditor_certification_image"){
        this.setState({ loading : false, auditor_certification_image_view : url, auditor_certification_image: info.file.response.records[0].auditor_certification_image})
      }else if(name == "proposal_image"){
        this.setState({ loading : false, proposal_image_view : url, proposal_image: info.file.response.records[0].proposal_image})
      }else if(name == "about_image"){
        this.setState({ loading : false, about_image_view : url, about_image: info.file.response.records[0].about_image})
      }
    });
}

beforeUpload = (file) => {
  console.log("called");
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
};

uploadButton = (
  <div>
    {/* {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} */}
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

  updateState() {
    // Changing state
    this.setState({
      flag: true,
      count: this.state.count + 1,
      inputs: [...this.state.inputs, {}],
    });
  }
  componentDidMount() {
    this.setState({
      ...this.props.data,
    });
  }
  render() {
    return (
      <>
        <section className="wrap bg-light">
			{this.state.loader ? (
				<div className="swsloader">
						{/*<img
							src="loader.gif"
							alt="loader"
						/>*/}
						<MyLoading />
				</div>
			) : "" }
          <form
          // onSubmit={this.handleSubmit}
          >
            <div className="container-fluid">
              <div className="card mb-4">
                <div className="card-header">Banner Management</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Logo</label>
                          <Upload
                             name="logo_image"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload_array`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"logo_image")}
                            >
                              {this.state.logo_image_view ? (
                               <img
                                 src={this.state.logo_image_view}
                                 alt="logo"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                            </Upload>
                            <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.logo_image_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <input
                            // src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="logo_image"
                            className="form-control form-ctrl"
                            // imageCount="1"
                            // data={this.state}
                            // required
                            // value={this.state.logo_image.name}
                            onChange={this.handleFileChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="title"
                            className="form-control form-ctrl"
                            placeholder="Type here"
                            value={this.state.title}
                            // value={this.state.title}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.title_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Upload Approved Training Partner image</label>
                          <Upload
                             name="partner_image"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload_array`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"partner_image")}
                            >
                              {this.state.partner_image_view ? (
                               <img
                                 src={this.state.partner_image_view}
                                 alt="logo"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                            </Upload>
                            <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.partner_image_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <input
                            // src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="partner_image"
                            className="form-control form-ctrl"
                            // placeholder="Type here"
                            // data={this.state}
                            // value={this.state.partner_image}
                            onChange={this.handleFileChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        {/* <div className="form-group">
                          <label>Description</label>
                         
                          <textarea
                            className="form-control form-ctrl"
                            rows={4}
                            name="description"
                            value={this.state.description}
                            // value={this.state.description}
                            onChange={this.handleChange}
                          />
                        </div> */}
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Short Description </label>

                          <ReactQuill
                            value={this.state.short_description || ""}
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                short_description: e,
                              });
                            }}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.short_description_error == true ? this.state.ValidationMessage : null}
                          </Col>
                          {/* <textarea
                            className="form-control form-ctrl"
                            rows={4}
                            name="short_description"
                            value={this.state.short_description}
                            // value={this.state.short_description}
                            onChange={this.handleChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Course Overview</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            placeholder="Type here"
                            name="course_overview"
                            value={this.state.course_overview}
                            // value={this.state.course_overview}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.course_overview_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Upload Banner Image</label>
                          <Upload
                             name="banner_image"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload_array`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"banner_image")}
                            >
                              {this.state.banner_image_view ? (
                               <img
                                 src={this.state.banner_image_view}
                                 alt="logo"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                            </Upload>
                            <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.banner_image_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <input
                            // src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="banner_image"
                            // value={this.state.banner_image}
                            className="form-control form-ctrl"
                            // placeholder="Type here"
                            onChange={this.handleFileChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Limited Offer</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="limited_offer"
                            value={this.state.limited_offer}
                            placeholder="Type here"
                            // value={this.state.limited_offer}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.limited_offer_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>Days</label>
                          <input
                            type="day"
                            className="form-control form-ctrl"
                            name="days"
                            placeholder=""
                            value={this.state.days}
                            // value={this.state.days}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.days_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>Hours</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="hours"
                            placeholder=""
                            value={this.state.hours}
                            // value={this.state.hours}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.hours_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>Minutes</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="minutes"
                            placeholder=""
                            value={this.state.minutes}
                            // value={this.state.minutes}
                            onChange={this.handleChange}
                          />
                          {this.state.minutes_error == true ? this.state.ValidationMessage : null}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>Seconds</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="seconds"
                            placeholder=""
                            value={this.state.seconds}
                            // value={this.state.seconds}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.seconds_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Description</label>
                          <ReactQuill
                            value={this.state.description || ""}
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                description: e,
                              });
                            }}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.description_error == true ? this.state.ValidationMessage : null}
                          </Col>
                          {/* <textarea
                            className="form-control form-ctrl"
                            name="description"
                            rows={3}
                            value={this.state.description}
                            // value={this.state.description}
                            onChange={this.handleChange}
                          /> */}
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Banner Management Learn More</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="banner_learn_more"
                            placeholder=""
                            // value={this.state.proposal_description}
                            value={this.state.banner_learn_more}
                            onChange={this.handleChange}
                          />
                           <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.banner_learn_more_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header">
                  Who needs iso 13485:2016 auditor certification training?
                </div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Upload Image</label>
                          <Upload
                             name="auditor_certification_image"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload_array`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"auditor_certification_image")}
                            >
                              {this.state.auditor_certification_image_view ? (
                               <img
                                 src={this.state.auditor_certification_image_view}
                                 alt="logo"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                            </Upload>
                            <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.auditor_certification_image_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <input
                            // src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="auditor_certification_image"
                            className="form-control form-ctrl"
                            // placeholder="Type here"
                            // value={
                            //   this.state.auditor_certification_image
                            // }
                            onChange={this.handleFileChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="auditor_certification_title"
                            placeholder="Type here"
                            value={this.state.auditor_certification_title}
                            // value={this.state.auditor_certification_title}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.auditor_certification_title_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Description</label>
                          <ReactQuill
                            value={this.state.auditor_certification_description || ""}
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                auditor_certification_description: e,
                              });
                            }}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.auditor_certification_description_error == true ? this.state.ValidationMessage : null}
                          </Col>
                          {/* <textarea
                            className="form-control form-ctrl"
                            name="auditor_certification_description"
                            rows={4}
                            value={this.state.auditor_certification_description}
                            // value={this.state.auditor_certification_description}
                            onChange={this.handleChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Auditor Certificate Learn More</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="auditor_certification_learn_more"
                            placeholder=""
                            // value={this.state.proposal_description}
                            value={this.state.auditor_certification_learn_more}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.auditor_certification_learn_more_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mb-4">
                <div className="card-header">Most Popular Courses</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="popular_courses_title"
                            placeholder="Type here"
                            value={this.state.popular_courses_title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Popular Courses</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="popular_courses"
                            placeholder="Type here"
                            value={this.state.popular_courses}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="AddMore-item">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Upload Image</label>
                            <input
                              src={`${PORTAL.api_url}uploaded_images/`}
                              type="file"
                              name="popular_courses_image"
                              className="form-control form-ctrl"
                              onChange={this.handleFileChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>
                              Upload Approved Training Partner image
                            </label>
                            <input
                              src={`${PORTAL.api_url}uploaded_images/`}
                              type="file"
                              name="popular_courses_partner_image"
                              className="form-control form-ctrl"
                              onChange={this.handleFileChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Heading</label>
                            <input
                              type="text"
                              className="form-control form-ctrl"
                              name="popular_courses_heading"
                              placeholder="Type here"
                              value={this.state.popular_courses_heading}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Exam Included</label>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="popular_courses_exam_include"
                                    value="Yes"
                                    onChange={this.handleChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="popular_courses_exam_include"
                                    value="No"
                                    onChange={this.handleChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Duration</label>
                            <select
                              name="popular_courses_duration"
                              value={this.state.popular_courses_duration}
                              onChange={this.handleChange}
                              className="form-select form-ctrl"
                              aria-label="Default select example"
                            >
                              <option selected="">Select</option>
                              <option value={1}>1 Day</option>
                              <option value={2}>2 Day</option>
                              <option value={3}>3 Day</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Time Zone</label>
                            <input
                              type="time"
                              className="form-control form-ctrl"
                              placeholder="Type here"
                              name="popular_courses_time_zone"
                              value={this.state.popular_courses_time_zone}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label>Delivery Methods</label>
                            <div className="row">
                              <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    name="popular_courses_delivery_method"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="Virtual"
                                    onChange={this.handleCHangeCheck}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineCheckbox1"
                                  >
                                    Virtual
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    name="popular_courses_delivery_method"
                                    value="Classroom"
                                    onChange={this.handleCHangeCheck}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineCheckbox2"
                                  >
                                    Classroom
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    name="popular_courses_delivery_method"
                                    value="Blended"
                                    onChange={this.handleCHangeCheck}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineCheckbox3"
                                  >
                                    Blended
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                              {this.state.inputs.map(() => (
                                <div className="form-group">
                                  <label>Upload Image</label>
                                  <input
                                    src={`${PORTAL.api_url}uploaded_images/`}
                                    type="file"
                                    name="popular_courses_multi_image"
                                    className="form-control form-ctrl"
                                    onChange={this.handleFileChange}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="AddMore"
                      onClick={this.updateState}
                      className="AddMore-btn"
                    >
                      +
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card mb-4">
                <div className="card-header">
                  If you require multiple delegates to attend the same training
                  or you have multiple training requirements and need a
                  proposal/quote or discount
                </div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Upload Image</label>
                          <Upload
                             name="proposal_image"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload_array`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"proposal_image")}
                            >
                              {this.state.proposal_image_view ? (
                               <img
                                 src={this.state.proposal_image_view}
                                 alt="logo"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                            </Upload>
                            <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.proposal_image_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <input
                            // src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="proposal_image"
                            className="form-control form-ctrl"
                            // placeholder="Type here"
                            // value={this.state.proposal_image}
                            onChange={this.handleFileChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>
                            If you require multiple delegates to attend the same
                            training or you have multiple training requirements
                            and need a proposal/quote or discount
                          </label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="proposal_description"
                            placeholder="Type here"
                            // value={this.state.proposal_description}
                            value={this.state.proposal_description}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                          {this.state.proposal_description_error == true ? this.state.ValidationMessage : null}
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mb-4">
                <div className="card-header">Most Popular Bundles Package</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="popular_bundles_title"
                            placeholder="Type here"
                            value={this.state.popular_bundles_title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>ISO</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="iso"
                            placeholder="Type here"
                            value={this.state.iso}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Courses</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="popular_bundles_courses"
                            placeholder="Type here"
                            value={this.state.popular_bundles_courses}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Upload Image</label>
                          <input
                            src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="popular_bundles_image"
                            className="form-control form-ctrl"
                            // placeholder="Type here"
                            onChange={this.handleFileChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control form-ctrl"
                            rows={4}
                            value={""}
                            name="popular_bundles_description"
                            value={this.state.popular_bundles_description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card mb-4">
                <div className="card-header">
                  About the Kelmac Group Academy
                </div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Upload Image</label>
                          <Upload
                             name="about_image"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload_array`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"about_image")}
                            >
                              {this.state.about_image_view ? (
                               <img
                                 src={this.state.about_image_view}
                                 alt="logo"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                            </Upload>
                            <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.about_image_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <input
                            // src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="about_image"
                            className="form-control form-ctrl"
                            // placeholder="Type here"
                            // value={this.state.about_image}
                            onChange={this.handleFileChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="about_title"
                            placeholder="Type here"
                            value={this.state.about_title}
                            // value={this.state.about_title}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.about_title_error == true ? this.state.ValidationMessage : null}
                            </Col>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Description</label>
                          <ReactQuill
                            value={this.state.about_description || ""}
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                about_description: e,
                              });
                            }}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.bout_description_error == true ? this.state.ValidationMessage : null}
                            </Col>
                          {/* <textarea
                            className="form-control form-ctrl"
                            rows={4}
                            name="about_description"
                            value={this.state.about_description}
                            // value={this.state.about_description}
                            onChange={this.handleChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>About Kelmac Learn More</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="about_learn_more"
                            placeholder=""
                            value={this.state.about_learn_more}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.about_learn_more_error == true ? this.state.ValidationMessage : null}
                            </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div class="card mb-4">
  <div class="card-header">
Why Choose Us
  </div>
  <div class="card-body">
  <div class="form-box">
	   <div class="row">
	    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
		<div class="form-group">
	   <label>Title</label>
	   <input type="text" class="form-control form-ctrl" placeholder="Type here">
	   </div>
		</div>
	 <div class="col-12 col-sm-12 col-md-12 col-lg-12">
	  <div class="form-group">
	   <label>Description</label>
	   <textarea class="form-control form-ctrl" rows="4"></textarea>
	   </div>
	   </div>
	   </div>
	   <div class="AddMore-item">
	   <div class="row">
	   <div class="col-12 col-sm-12 col-md-6 col-lg-6">
		<div class="form-group">
	   <label>Icon image</label>
	   <input type="file" class="form-control form-ctrl" placeholder="Type here">
	   </div>
		</div>
		 <div class="col-12 col-sm-12 col-md-6 col-lg-6">
		<div class="form-group">
	   <label>Title</label>
	   <input type="text" class="form-control form-ctrl" placeholder="Type here">
	   </div>
		</div>
	 <div class="col-12 col-sm-12 col-md-12 col-lg-12">
	  <div class="form-group">
	   <label>Description</label>
	   <textarea class="form-control form-ctrl" rows="4"></textarea>
	   </div>
	   </div>
	   </div>
	   </div>
	   <div id="AddMore2" class="AddMore-btn"><i class="fa fa-plus"></i></div>
	   <div class="row">
	   <div class="col-12 col-sm-12 col-md-6 col-lg-6">
	   <div class="form-group">
	   <label>Upload Banner </label>
	   <input type="file" class="form-control form-ctrl" placeholder="Type here">
	   </div>
	   </div>
	   </div>
	   <hr/>
	   <div class="row">
	   <div class="col-12 col-sm-12 col-md-4 col-lg-4">
	   <div class="form-group">
	   <label>Countries </label>
	   <input type="text" class="form-control form-ctrl" placeholder="">
	   </div>
	   </div>
	   <div class="col-12 col-sm-12 col-md-4 col-lg-4">
	   <div class="form-group">
	   <label>Clients </label>
	   <input type="text" class="form-control form-ctrl" placeholder="">
	   </div>
	   </div>
	   <div class="col-12 col-sm-12 col-md-4 col-lg-4">
	   <div class="form-group">
	   <label>Certified Courses</label>
	   <input type="text" class="form-control form-ctrl" placeholder="">
	   </div>
	   </div>
	   </div>
	   
  </div>
  </div>
  </div>*/}
              {/* <div className="card mb-4">
                <div className="card-header">What Our Clients Say About Us</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>User Name</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="about_us_username"
                            placeholder=""
                            value={this.state.about_us_username}

                            // value={this.state.about_us_username}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Designation</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="about_us_designation"
                            placeholder=""
                            value={this.state.about_us_designation}

                            // value={this.state.about_us_designation}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control form-ctrl"
                            rows={4}
                            name="about_us_description"
                            value={this.state.about_us_description}

                            // value={this.state.about_us_description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mb-4">
                <div className="card-header">Trusted By</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Upload Brand Logo</label>
                          <input
                            src={`${PORTAL.api_url}uploaded_images/`}
                            type="file"
                            name="trusted_image"
                            className="form-control form-ctrl"
                            onChange={this.handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mb-4">
                <div className="card-header">Frequently Asked Questions</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Question</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="faq_question"
                            placeholder="Type here"
                            value={this.state.faq_question}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Answer</label>
                          <textarea
                            className="form-control form-ctrl"
                            name="faq_answer"
                            rows={6}
                            value={""}
                            value={this.state.faq_answer}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card mb-4">
                <div className="card-header">Footer Contact</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Phone No.</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="footer_contact_phone"
                            placeholder="Type here"
                            value={this.state.footer_contact_phone}
                            // value={this.state.footer_contact_phone}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.footer_contact_phone_error == true ? this.state.ValidationMessage : null}
                            </Col>
                        </div>
                      </div>
                      {/*<div class="col-12 col-sm-12 col-md-6 col-lg-6">
		<div class="form-group">
	   <label>Email Id</label>
	   <input type="text" class="form-control form-ctrl" placeholder="Type here">
	   </div>
		</div>*/}
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Address</label>
                          <textarea
                            className="form-control form-ctrl"
                            name="footer_contact_address"
                            rows={4}
                            value={this.state.footer_contact_address}
                            // value={this.state.footer_contact_address}
                            onChange={this.handleChange}
                          />
                          <Col
                              span={24}
                              className="text-left text-danger"
                              style={{ fontSize: "12px", height: "19px" }}
                            >
                              {this.state.footer_contact_address_error == true ? this.state.ValidationMessage : null}
                            </Col>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-danger btn-submit"
                      onClick={
                        this.state._id ? this.handleEdit : this.handleSubmit
                      }
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        {/* <footer className="footer_footer__jn5KQ">
          <section className="cpyrgt-wrap">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                  <div className="row">
                    <div className="col-12 col-sm-7 col-md-7 col-lg-9 col-xl-9">
                      <p>© Copyright Kelmac Group. All Rights Reserved</p>{" "}
                    </div>
                    <div className="col-12 col-sm-5  col-md-5 col-lg-3 col-xl-3">
                      <p>
                        Powered By{" "}
                        <a
                          href="https://www.b2cinfosolutions.com/"
                          target="_blank"
                        >
                          {" "}
                          B2C Info Solutions
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer> */}

        <style jsx global>{`
          @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
          @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
          body {
            font-family: "Open Sans", sans-serif;
            /*font-family: 'Josefin Sans', sans-serif;*/
            color: #323031;
          }
          .media {
          }
          .media .media-left,
          .media .media-body,
          .media .media-right {
            display: table-cell;
            vertical-align: top;
          }
          .media .media-left {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
          }
          .media .media-body {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            width: 10000px;
          }
          .bg-light {
            background-color: #f7f7f7 !important;
          }
          .sticky {
            position: fixed !important;
            top: 0px;
            z-index: 999;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-name: slideInDown;
            animation-name: slideInDown;
            box-shadow: 0px 6px 5px 0px rgb(0 0 0 / 7%);
          }
          .btn-radius {
            border-radius: 30px;
          }
          .btn-danger {
            background-color: #de1936;
            border-color: #de1936;
            font-size: 14px;
            font-weight: 600;
          }
          .btn-danger:hover {
            background-color: #e10525;
            border-color: #e10525;
          }
          .btn-outline-danger {
            color: #c6001d;
            border-color: #c6001d;
          }
          .btn-outline-danger:hover {
            color: #fff;
            border-color: #c6001d;
            background-color: #e10525;
          }
          .owl-nav {
          }
          .owl-nav button span {
            /*font-size: 38px;
           line-height: 26px;
           font-weight: 500;
           width: 35px;
           height: 35px;
           background: #DE1936;
           border-radius: 50%;
           display: block;
           color: #fff;*/
          }
          .owl-nav button span img {
            width: 34px;
          }
          .owl-nav button span:hover {
            /*border: 1px solid #E93131;
           color: #E93131;*/
          }
          .owl-nav .owl-next,
          .owl-nav .owl-prev {
            position: absolute;
            top: 45%;
            left: -50px;
          }
          .owl-nav .owl-next {
            right: -50px;
            left: initial;
          }
          .owl-nav .owl-prev {
          }
          .list-point {
            margin-bottom: 35px;
          }
          .list-point li:before {
            content: "";
            position: absolute;
            left: 2px;
            top: 11px;
            width: 5px;
            height: 5px;
            background-color: #333;
            border-radius: 50%;
          }
          .list-point li {
            line-height: 22px;
            font-size: 14px;
            padding-left: 25px;
            position: relative;
            font-weight: 400;
            margin-bottom: 10px;
            color: #333;
          }
          p {
            color: #000;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 10px;
          }
          a {
            color: #333;
            text-decoration: none;
          }
          .text-red {
            color: #de1936;
          }

          /*========= Header ==========*/
          header {
            width: 100%;
          }
          .sticky .navbar-light .navbar-brand img {
            max-height: 40px;
          }
          .navbar-light .navbar-brand img {
            max-height: 50px;
          }
          .navbar-expand-lg .navbar-nav .nav-link {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            padding: 10px 25px;
          }
          .navbar-nav .nav-link.topnav_appointment-btn__nrlLl {
            background: #de1936;
            color: #fff !important;
            border-radius: 30px;
            padding: 5px 15px;
            margin-left: 10px;
            margin-top: 5px;
            font-weight: 400;
          }

          /*========= Section ==========*/
          .form-control[type="file"] {
            padding-top: 9px;
          }
          .container-fluid {
            padding-left: 0px;
            padding-right: 0px;
          }
          .wrap {
            position: relative;
          }
          .form-icon,
          .form_icon {
            position: absolute;
            right: 10px;
            bottom: 8px;
            font-size: 18px;
          }
          .form_icon {
            right: inherit;
            left: 10px;
          }
          .form-check-input:checked {
            background-color: #de1936;
            border-color: #de1936;
          }
          .form-box {
            position: relative;
          }
          .form-box .form-group label {
            margin-bottom: 5px;
            font-size: 14px;
          }
          .form-check-group .form-check-inline {
            margin-right: 0px;
            width: 24%;
            margin-bottom: 15px;
            margin-top: 15px;
            display: inline-flex;
            padding-left: 2em;
          }
          .form-check-group .form-check-inline:last-child {
          }
          .form-check-group .form-check-input {
            width: 1.2em;
            height: 1.2em;
            margin-left: -2em;
            border-radius: 0px;
            border: 1px solid #333;
          }
          .form-check-group .form-check label {
            font-size: 14px;
            margin-left: 10px;
          }
          .form-check-group .form-check-input:checked {
            background-color: #000;
            border-color: #000;
          }
          .group-btns {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 20px;
          }
          .group-btns .btn {
            border-radius: 30px;
            margin-right: 10px;
            padding: 10px 35px;
            font-size: 14px;
            font-weight: 600;
          }
          .group-btns .btn:last-child {
            margin-right: 0px;
          }
          .btn-block {
            display: block;
            width: 100%;
          }
          .form-box .form-group {
            margin-bottom: 20px;
            position: relative;
          }
          .form-box .form-ctrl {
            border: 1px solid #d1d1d1;
            font-size: 14px;
            height: 42px;
            background-color: #fff;
            border-radius: 0px;
          }
          .btn-submit {
            padding: 10px 20px;
          }
          .SubscribeArticles {
          }
          .SubscribeArticles .title {
          }
          .SubscribeArticles .form-box .form-ctrl {
            padding-left: 40px;
          }
          .form-box textarea.form-ctrl {
            height: auto;
          }

          /*====================================================
           53. FIRST FOOTER SECTION.
         ====================================================*/
          .first_footer_section {
            padding-bottom: 25px;
          }

          .cpyrgt-wrap {
            position: relative;
            background-color: #323031;
            padding: 15px 0px 5px;
          }
          .cpyrgt-wrap p {
            color: #fff;
            font-size: 12px;
          }
          .cpyrgt-wrap p a {
            color: #ebebeb;
            font-weight: 700;
            text-decoration: underline;
          }
          .cpyrgt-wrap p a:hover {
            color: #fff;
            text-decoration: none;
          }

          /*============================*/
          .card {
            border: none;
            box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 13%);
          }
          .card-header {
            padding: 15px 20px;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            font-size: 18px;
            font-weight: 600;
          }
          .card-body {
            padding: 15px 20px;
          }
          .AddMore-btn {
            margin-bottom: 20px;
            color: #fff;
            font-size: 27px;
            font-weight: 900;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            background-color: #de1936;
            display: block;
            width: 26px;
            text-align: center;
            height: 26px;
            line-height: 26px;
            border-radius: 4px;
            cursor: pointer;
          }
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Landingform);
