import React from "react";
import { Row, Col } from "antd";
import styles from "./styles.module.css";
function MainArea(props) {
  return (
    <Row className={`${styles["main-area"]}`}>
      <Col span={24}>{props.children}</Col>
    </Row>
  );
}
export default MainArea;
