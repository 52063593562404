import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Checkbox } from "antd";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { leraner_attendance_api } from "../../library/leraner_attendance_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
import { SettingOutlined, CheckOutlined, CloseOutlined, ConsoleSqlOutlined } from "@ant-design/icons";

// ===============================================================================
class Tranning_learner_attendance extends FormParent {
  constructor(props) {
    console.log("rendered re", props);
    super(props);
    this.state = {
      page_api: leraner_attendance_api,
      attandanceId: "",
      filter: {},
      records: {},
      request: {},
      validation: {},
      validation_info: {
        version: { valMet: vj_val.checkLength, valLen: 1 },
        title: { valMet: vj_val.checkLength, valLen: 1 },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        description: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
      edit:false,
      isLock:false,
      checkedList:{},
      checkedCount:0,
    };
  }

  //************* edit mode and view mode on off */
  viewModeToeditMode = () => {
    // let { comment, attandance } = this.state.records;
    // console.log(comment, attandance);
    this.pageReload()
    this.setState({edit: true, CDM: false});
  }   

  viewModeToViewMode = () => {
    this.pageReload()
    this.setState({edit: false, CDM: false});
  }

  statusHandler = async (status, type) => {
    let { Chabi, request, attandanceId } = this.state;
    const url = leraner_attendance_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request:{ status, type, attandanceId  },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("records updated");
        return window.history.back()
      } else if(data.status == 4000){
        message.success("no records to update");
        return window.history.back()
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  }

  lockHandler = () => {
    alert("working");
  }
 
  componentDidMount = async () => {
    if(window.location.search != ""){
    let url= new URLSearchParams(window.location.search);
    let pageId = url.get("training")
    let userId = url.get("identifier")
     pageId = window.atob(window.atob(pageId))
     userId = window.atob(window.atob(userId))
     console.log(pageId,userId);
    userId = userId.split("_");
    this.setState({ pageId, userId: userId[1], orderId: userId[0], orderType: userId[2]})
    
    let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0], orderType: userId[2]})));

    const { Chabi } = this.state;
    const Apiurl = leraner_attendance_api +"/get";
    const response = await myAxios({
      method: "post",
      url:Apiurl,
      header: { Chabi },
      request: {
        identified
    }
    });
 
    if (response.status == 200) {
      const { data } = response;

      let { comment, attandance, totalCheck, isFirst, traning} = data.records[0];
      let updateButtonAlert = true;
      let currDt = new Date();
      let recDt =  traning.ended_on;
     
      recDt = recDt.split("T")[0]
      recDt = recDt.split("-")
      console.log("recDt",recDt)
      recDt = new Date(`${recDt[0]}-${recDt[1]}-${recDt[2]}`);
      console.log("recDt",recDt)
      let diff = (recDt.getTime() - currDt.getTime())
      let divDiff = 1000 * 60 * 60 * 24
      console.log(diff/ divDiff)
      if(diff/ divDiff < 1){
        updateButtonAlert = false
      }

      this.setState({updateButtonAlert, checkedCount: totalCheck,isFirst, attandanceId: data.records[0]._id, records: data.records[0], totalPage: 5, page:1, request: { comment, attandance } })
      data.records[0].attandance.map( _ => {
          this.setState({ [`day_${_.day}`] : _.checked});
      })
    }
    this.setState({ CDM: true });
    }else{
        alert("you want to access unregistered routes... pls press ok and go back");
        window.history.back();
        return false
    }

  };

  pageReload = async () => {
    if(window.location.search != ""){
      let url= new URLSearchParams(window.location.search);
      let pageId = url.get("training")
      let userId = url.get("identifier")
       pageId = atob(atob(pageId))
       userId = atob(atob(userId))
      
      userId = userId.split("_");
      this.setState({ pageId, userId: userId[1], orderId: userId[0], orderType: userId[2]})
  
      let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0], orderType: userId[2]})));
  
      const { Chabi } = this.state;
      const Apiurl = leraner_attendance_api +"/get";
      const response = await myAxios({
        method: "post",
        url:Apiurl,
        header: { Chabi },
        request: {
          identified
      }
      });
   
      if (response.status == 200) {
        const { data } = response;
        let { comment, attandance, totalCheck, isFirst, traning } = data.records[0];
        let updateButtonAlert = false;
        let currDt = new Date();
        let recDt =  traning.ended_on;
     
        recDt = recDt.split("T")[0]
        recDt = recDt.split("-")
        console.log("recDt",recDt)
        recDt = new Date(`${recDt[0]}-${recDt[1]}-${recDt[2]}`);
        console.log("recDt",recDt)
        let diff = (recDt.getTime() - currDt.getTime())
        let divDiff = 1000 * 60 * 60 * 24
        console.log(diff/ divDiff)
        if(diff/ divDiff < 1){
          updateButtonAlert = false
        }

        this.setState({updateButtonAlert, records: data.records[0], checkedCount: totalCheck,isFirst, totalPage: 5, page:1, request: { comment, attandance } })
        data.records[0].attandance.map( _ => {
            this.setState({ [`day_${_.day}`] : _.checked});
        })
      }
      this.setState({ CDM: true });
      }else{
          alert("you want to access unregistered routes... pls press ok and go back");
          window.history.back();
          return false
      }
  }

  submit = async () => {
    let { Chabi, request, orderId, pageId, userId, orderType } = this.state;
    let attandance = request.attandance
    let attArr = attandance.map( rec => rec.checked == true ? true : false)
    console.log(request)
    if(attArr.includes(false)){
      if(request.comment == undefined || request.comment == ""){
        alert("Comment reqired ..!!")
        return false;
      }
      
    }
    const url = leraner_attendance_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request:{ ...request, totalCheck: this.state.checkedCount, orderId, courseId:pageId, learnerId:userId  },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("records updated");
        return window.history.back()
      } else if(data.status == 4000){
        message.success("no records to update");
        return window.history.back()
      } else{
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  checboxHandler = (e) => {
    let ele = e.target;
    let index = +ele.value-1
    this.setState({ [`day_${ele.value}`] : ele.checked});
    let arrayI = []
   
    this.state.request.attandance.map( (_ , i) => {
     
      if(i == index){
        arrayI.push({ day : String(index+1), checked: ele.checked, _id: _._id})
      }else{
        arrayI.push(_)
      }     
      if(i == this.state.request.attandance.length-1){
        let checkountCount = 0
         arrayI.map( _ => {
          checkountCount = (_.checked == true) ? checkountCount + 1 : checkountCount;
        })
        console.log(checkountCount);
        this.setState({checkedCount: checkountCount, request : { attandance : arrayI, comment: this.state.request.comment}})
      }
    })  
}

textAreaHandler = (e) => {
    this.setState({ request : {comment: e.target.value , attandance : this.state.request.attandance}});
}


  render() {
    console.log("Training Enquiry State========> ", this.state);
    let {
      records,
      loginStatus,
      filtered_records,
      cmodal,
      selectedRecord,
      comment,
      coffcanvas,
      region2,
      CDM,
      edit,
      isLock
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
   
    
    return (
      <>
        <PageHeading2
          active="Training"
          page={["Training", "Learners", "Attendance"]}
        ></PageHeading2>
        <MainArea>
            {CDM ? 
        <div className="p-4">
                  <p className="info-main-heading">Attendance</p>
                 {(edit) ?
                  <div className="row mt-1 mb-1">
                    <div className="col-12 text-left">
                     
                      <table className="table table-bordered attendence-table">
                      <tr>
                          <td>Course:</td>
                          <td>{(records.traning.courseData != undefined) ? records.traning.courseData.main_head : "-"}</td>
                        </tr>
                        <tr>
                          <td>Instance:</td>
                          <td>{(records.traning != undefined) ? records.traning._id : "-"}</td>
                        </tr>
                        <tr>
                          <td>Learner:</td>
                          <td>{("first_name" in records.learner) ? `${records.learner.first_name} ${records.learner.last_name}` : "-"}</td>
                        </tr>
                        <tr>
                          <td>Attendance:</td>
                          <td>{(records.totalCheck != undefined) ? `${Math.round((records.totalCheck * 100) / records.attandance.length)}%` : "-"}</td>
                        </tr>
                        <tr>
                          <td>Status:</td>
                          <td><span className="text-danger p-0">{(records.isPublish != undefined && records.isPublish == 0) ? "Not Published" : "Published"}</span></td>
                        </tr>
                        <tr>
                          <td>Comments:</td>
                          <td><textarea className="form-control" defaultValue={("comment" in records) ? records.comment : ""}  onChange={(e) => this.textAreaHandler(e)} placeholder="Type here...."></textarea></td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="p-0">
                            <table className="table table-bordered daysTbl m-0">
                            <tr>{
                                  (records.attandance.length > 0) ? records.attandance.map( _ => {
                                    return <td>Day {_.day}</td>
                                  }) : null
                                }
                              </tr>
                              <tr>
                              {
                                  (records.attandance.length > 0) ? records.attandance.map( _ => {
                                    return  (_.checked == true) ? <td><span><Checkbox value={_.day} defaultChecked={this.state[`day_${_.day}`]} onChange={this.checboxHandler}></Checkbox></span></td> : <td><span><Checkbox defaultChecked={this.state[`day_${_.day}`]} value={_.day} onChange={this.checboxHandler}></Checkbox></span></td>
                                  }) : null
                                }
                              </tr>
                            </table>
                          </td>
                         
                        </tr>
                        
                      </table>
                      <div className="row mt-1">
                        <div className="col-12 text-left">
                          <button type="submit" className="btn btn-primary btn-sm" onClick={() => this.submit()}>Save</button>
                          <button type="submit" className="btn btn-danger btn-sm ml-2" onClick={() => this.viewModeToViewMode()}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                : 
                  <div className="row mt-1 mb-1">
                    <div className="col-12 text-left">
                      <table className="table table-bordered attendence-table">
                        <tr>
                          <td>Course:</td>
                          <td>{(records.traning.courseData != undefined) ? records.traning.courseData.main_head : "-"}</td>
                        </tr>
                        <tr>
                          <td>Instance:</td>
                          <td>{(records.traning != undefined) ? records.traning._id : "-"}</td>
                        </tr>
                        <tr>
                          <td>Learner:</td>
                          <td>{("first_name" in records.learner) ? `${records.learner.first_name} ${records.learner.last_name}` : "-"}</td>
                        </tr>
                        <tr>
                          <td>Attendance:</td>
                          <td>{(records.totalCheck != undefined) ? `${Math.round((records.totalCheck * 100) / records.attandance.length)}%` : "-"}</td>
                        </tr>
                        <tr>
                          <td>Status:</td>
                          <td><span className="text-danger p-0">{(records.isPublish != undefined && records.isPublish == 0) ? "Not Published" : "Published"}</span></td>
                        </tr>
                        <tr>
                          <td>Comments:</td>
                          <td>{("comment" in records) ? records.comment : "-"}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="p-0">
                            <table className="table table-bordered daysTbl m-0">
                              
                            <tr>{
                                  (records.attandance.length > 0) ? records.attandance.map( _ => {
                                    return <td>Day {_.day}</td>
                                  }) : null
                                }
                              </tr>
                              <tr>
                              {
                                  (records.attandance.length > 0) ? records.attandance.map( _ => {
                                    return  (_.checked == true) ? <td><span><CheckOutlined /></span></td> : <td><span><CloseOutlined /></span></td>
                                  }) : null
                                }
                                
                               
                              </tr>
                            </table>
                          </td>
                         
                        </tr>
                        
                      </table>
                      <div className="row mt-1">
                        <div className="col-12 text-left">
                          <button type="submit" className="btn btn-primary btn-sm" onClick={() => this.statusHandler((records.isPublish == 1) ? 0 : 1, "isPublish")}>{(records.isPublish == 0) ? "Publish" : "UnPublish"}</button>
                          {this.state.updateButtonAlert ? <button type="submit" className="btn btn-primary btn-sm ml-2" onClick={() => alert("Training Not Completed yet or You Can Update After 24 Hours Of Completion Of The Training")}>Update</button> : <button type="submit" className="btn btn-primary btn-sm ml-2" disabled={this.state.isFirst} onClick={() => this.viewModeToeditMode()}>Update</button>}
                         {/* <button type="submit" className="btn btn-primary btn-sm ml-2" onClick={() => this.statusHandler((records.isLock == 1) ? 0 : 1, "isLock")}>{(records.isLock == 0) ? "Lock" : "UnLock"}</button> */}
                         
                        </div>
                      </div>
                   
                    </div>
                  </div>
                 }
                </div> : <MyLoading /> }
              
        </MainArea>
        <CustomModal
          status={cmodal}
          title="Action"
          close={this.close_modal}
          className="modal-form-comment"
        >
          <FormContainer>
            <FormTextareaGroup
              name="comment"
              label="Comment"
              placeholder="Comment"
              value={comment}
              sendValue={({ value: comment }) => {
                this.setState({ comment });
              }}
            />
            <FormButton
              submit={this.submit}
              submit_text="Submit"
              cancel={() => {
                this.setState({ cmodal: false, comment: "" });
              }}
              cancel_text="Close"
            />
          </FormContainer>
        </CustomModal>

        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Contact Us - Enquiry"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            {[selectedRecord].map((selectedRecord) => {
              const {
                full_name,
                email,
                company,
                country,
                details,
                mobile,
                position,
                region,
              } = selectedRecord;
              return (
                <div className="p-2">
                  <p className="info-main-heading">Enquiry Details</p>
                  <div className="row mt-1 mb-1">
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Name</span>
                      <span className="d-block info-value">{full_name}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Email</span>
                      <span className="d-block info-value">{email}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Mobile</span>
                      <span className="d-block info-value">{mobile}</span>
                    </div>
                  </div>
                  <div className="row mt-1 mb-1">
                    <div className="col-4">
                      <span className="d-block info-heading">Organization</span>
                      <span className="d-block info-value">{company}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Country</span>
                      <span className="d-block info-value">
                        {countryListAlpha2[country]}
                      </span>
                    </div>
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Region</span>
                      <span className="d-block info-value">
                        {region2[region]}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-1 mb-1">
                    <div className="col">
                      <span className="d-block info-heading">Details</span>
                      <span className="d-block info-value">{details}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tranning_learner_attendance);
