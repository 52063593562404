import React from "react";
import { Spin } from "antd";
function MyLoading(props) {
  return (
    <div style={{ height: "50vh", lineHeight: "50vh", textAlign: "center" }}>
      <Spin tip="Loading..."></Spin>
    </div>
  );
}
export default MyLoading;
