// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .custom-switch.ant-switch-checked {
  background-color: green !important;
} */
.styles_custom-switch__FX1mL[aria-checked="true"],
.styles_custom-switch__FX1mL[aria-checked="1"] {
  background-color: green !important;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/Display/CustomSwitch/styles.module.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;;EAEE,kCAAkC;AACpC","sourcesContent":["/* .custom-switch.ant-switch-checked {\n  background-color: green !important;\n} */\n.custom-switch[aria-checked=\"true\"],\n.custom-switch[aria-checked=\"1\"] {\n  background-color: green !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-switch": `styles_custom-switch__FX1mL`
};
export default ___CSS_LOADER_EXPORT___;
