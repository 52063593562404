// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__9ft6J {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background: #888;
}

`, "",{"version":3,"sources":["webpack://./src/component/part/Footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".footer {\n  height: 40px;\n  line-height: 40px;\n  text-align: center;\n  color: #fff;\n  background: #888;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__9ft6J`
};
export default ___CSS_LOADER_EXPORT___;
