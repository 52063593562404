import Dashboard from "./component/pages/Dashboard";
import Logout from "./component/pages/Logout";
import NotFound from "./component/pages/NotFound";
import Login from "./component/pages/Login";
import Comming_soon from "./component/pages/Comming_soon";

// data analytics
import Training from "./component/pages/Training";
import Ticket from "./component/pages/Ticket";
import Purchase_order from "./component/pages/Purchase_order";
import Payment_orders from "./component/pages/Payment_orders";
import Reports from "./component/pages/Reports"

import Course from "./component/pages/Course";
import Course_schedule from './component/pages/Course_schedule';
import Clients from "./component/pages/Clients";
import Insight from "./component/pages/Insight";
import Insight_category from "./component/pages/Insight_category";
import Testimonial from "./component/pages/Testimonial";
import Venue from "./component/pages/Venue";
import Learners from "./component/pages/Learners";

import Learning_scheme from "./component/pages/Learning_scheme";
import Lca_templates_ass from "./component/pages/Lca_templates_ass";
import Exam_section_table_ass from "./component/pages/Exam_section_table_ass";
import Exam_ass from "./component/pages/Exam_ass";
import Test_ass from "./component/pages/Test_ass";
import News_update from "./component/pages/News_update";
import Tranning_learner_attendance from "./component/pages/Tranning_learner_attendance";
import Lca_exam_result from './component/pages/Lca_exam_result'


import Evaluation_question_option from "./component/pages/Evaluation_question_option";
import Evaluation_templates_ass from "./component/pages/Evaluation_templates_ass";
import Evaluation_question_ass from "./component/pages/Evaluation_question_ass";
import Consultants from "./component/pages/Consultants";
import Partners from "./component/pages/Partners";
import Tranning_learner from "./component/pages/Tranning_learner";
import Tranning_result from "./component/pages/Tranning_result";
import Tranning_result_2 from './component/pages/Tranning_exam_2_result'
import Certificate_preview from './component/pages/Certificate_preview';
import In_complete_training from "./component/pages/In_complete_training";
import In_complete_training1 from "./component/pages/In_complete_training1";
import Training_learner_list from "./component/pages/Training_learner_list";
import Evaluation_result from "./component/pages/Evaluation_result";
import Evaluation_result1 from "./component/pages/Evaluation_result1";
import Exam_result_listing from "./component/pages/Exam_result_listing";
import Exam_result_listing1 from "./component/pages/Exam_result_listing1";
import Training_schedule from "./component/pages/Training_schedule"
import Learner_order from './component/pages/LearnerOrder'
import Organization_order from './component/pages/OrganizationOrder'
import Analytics_course from "./component/pages/Analytics_course";
import Training_tickets from './component/pages/Training_tickets';
import ViewFeedbackForm from "./component/pages/ViewFeedbackForm";
import Sales_target_report from "./component/pages/Sales_target_report"
import Revenue from "./component/pages/Revenue"
import RevenueList from "./component/pages/RevenueListing"
import CourseRevenueList from "./component/pages/CourseRevenueList";
import SchemaRevenueList from "./component/pages/SchemaRevenueList";
import CouponDiscountList from "./component/pages/couponRevenue"
import CouponOrderList from "./component/pages/CouponOrderList";

let urls = { 
  DASHBOARD: {
    to: () => `/dashboard`,
    path: "/dashboard",
    component: Dashboard,
    text: "Dashboard",
  },
 
  TRAINING: {
    to: () => `/training`,
    path: "/training",
    component: Training,
    text: "Training OnGoing",
  },
  TRANNING_LEARNER: {
    to: () => `/tranning_learner`,
    path: "/tranning_learner",
    component: Tranning_learner,
    text: "Training Learner",
  },

  TICKET: {
    to: () => `/ticket`,
    path: "/ticket",
    component: Ticket,
    text: "Ticket",
  },
  PURCHASE_ORDER: {
    to: () => `/purchase_order`,
    path: "/purchase_order",
    component: Purchase_order,
    text: "Purchase Order",
  },
  PAYMENT_ORDERS: {
    to: () => `/payment_orders`,
    path: "/payment_orders",
    component: Payment_orders,
    text: "Payments Orders",
  },
  REPORTS: {
    to: () => `/reports`,
    path: "/reports",
    component: Reports,
    text: "Reports",
  },

  COURSE: {
    to: () => `/course`,
    path: "/course",
    component: Course,
    text: "Course",
  },
  COURSE_SCHEDULE: {
    to: () => `/course_schedule`,
    path: "/course_schedule",
    component: Course_schedule,
    text: "course_schedule",
  },
  CLIENTS: {
    to: () => `/clients`,
    path: "/clients",
    component: Clients,
    text: "Clients",
  },
  INSIGHT: {
    to: () => `/insight`,
    path: "/insight",
    component: Insight,
    text: "Insight",
  },
  INSIGHT_CATEGORY: {
    to: () => `/insight_category`,
    path: "/insight_category",
    component: Insight_category,
    text: "Category",
  },
  TESTIMONIAL: {
    to: () => `/testimonial`,
    path: "/testimonial",
    component: Testimonial,
    text: "Testimonial",
  },
  VENUE: {
    to: () => `/venue`,
    path: "/venue",
    component: Venue,
    text: "Venue",
  },
  LEARNERS: {
    to: () => `/learners`,
    path: "/learners",
    component: Learners,
    text: "Learners",
  },
  LEARNING_SCHEME: {
    to: () => `/learning_scheme`,
    path: "/learning_scheme",
    component: Learning_scheme,
    text: "Scheme",
  },
  LCA_TAMPLATES: {
    to: () => `/lca_templates`,
    path: "/lca_templates",
    component: Lca_templates_ass,
    text: "LCA Templates",
  },
  EXAM_SECTION_ASS: {
    to: () => `/exam_sections`,
    path: "/exam_section_ass",
    component: Exam_section_table_ass,
    text: "Exam Section",
  },

  TEST_ASS: {
    to: () => `/tests`,
    path: "/test_ass",
    component: Test_ass,
    text: "Tests",
  },
  EXAM_ASS: {
    to: () => `/exams`,
    path: "/exam_ass",
    component: Exam_ass,
    text: "Exams",
  },
  NEWSUPDATE: {
    to: () => `/news_update`,
    path: "/news_update",
    component: News_update,
    text: "News & Update",
  },

  EVALUATION_TAMPLATES_ASS: {
    to: () => `/evaluation_templates`,
    path: "/evaluation_templates",
    component: Evaluation_templates_ass,
    text: "Evaluation Templates",
  },
  EVALUATION_QUESTIONS_ASS: {
    to: () => `/evaluation_questions`,
    path: "/evaluation_questions_ass",
    component: Evaluation_question_ass,
    text: "Evaluation Questions",
  },
  EVALUATION_QUESTION_OPTION: {
    to: () => `/evaluation_question_option`,
    path: "/evaluation_question_option",
    component: Evaluation_question_option,
    text: "Evaluation Question Option",
  },
  CONSULTANTS: {
    to: () => `/consultants`,
    path: "/consultants",
    component: Consultants,
    text: "Consultants",
  },
  PARTNERS: {
    to: () => `/partners`,
    path: "/partners",
    component: Partners,
    text: "Partners",
  },
  TRANNING_RESULT: {
    to: () => `/tranning_result`,
    path: "/tranning_result",
    component: Tranning_result,
    text: "Training Result",
  },
  TRANNING_RESULT_2: {
    to: () => `/tranning_result_2`,
    path: "/tranning_result_2",
    component: Tranning_result_2,
    text: "Training Result 2",
  },
  CERTIFICATE_PREVIEW: {
    to: () => `/certificate_preview`,
    path: "/certificate_preview",
    component: Certificate_preview,
    text: "Certificates Preview",
  },
  TRANNING_LEARNER_ATTENDANCE: {
    to: () => `/tranning_learner_attendance`,
    path: "/tranning_learner_attendance",
    component: Tranning_learner_attendance,
    text: "Training Learner Attendance",
  },
  LCA_EXAM_RESULT: {
    to: () => `/Lca_exam_result`,
    path: "/Lca_exam_result",
    component: Lca_exam_result,
    text: "Lca Exam Result",
  },

  LOGIN: {
    to: () => `/`,
    path: "/",
    component: Login,
    text: "Login",
  },
  LOGOUT: {
    to: () => `/logout`,
    path: "/logout",
    component: Logout,
    text: "Logout",
  },
  NOT_FOUND: {
    to: () => ``,
    path: "",
    component: NotFound,
  },
  COMMING_SOON: {
    to: () => ``,
    path: "",
    component: Comming_soon,
  },

  // dasboard tabs
  
    IN_COMPLETE_TRAIINING: {
    to: () => `/in_complete_training`,
    path: "/in_complete_training",
    component: In_complete_training,
    text: "In complete training",
  },
  TRAINING_LEARNER_LIST: {
    to: () => `/tranining_learner_list`,
    path: "/tranining_learner_list",
    // component: Training_learner_list,
    component: In_complete_training1,
    text: "Training learner list",
  },
  EVALAUTION_RESULT_LIST: {
    to: () => `/evalaution_result_list`,
    path: "/evalaution_result_list",
    // component: Evaluation_result,
    component: Evaluation_result1,
    text: "Evalaution Result list",
  },
  EVALAUTION_RESULT_LIST_UPDATE: {
    to: () => `/evalaution_result_update`,
    path: "/evalaution_result_update",
    component: Evaluation_result,
    text: "Evalaution Result update",
  },
  EXAM_RESULT_LIST: {
    to: () => `/exam_result_list`,
    path: "/exam_result_list",
    // component: Exam_result_listing,
    component: Exam_result_listing1,
    text: "Exam Result List",
  },
  TRAINING_SCHEDULE:{
    to: () => '/training_schedule',
    path: "/training_schedule",
    component: Training_schedule,
    text: "Training Shedule"
  },
  LEARNER_ORDER: {
    to: () => '/learner_order',
    path: "/learner_order",
    component: Learner_order,
    text: "Learner order"
  },
  ORGANIZATION_ORDER: {
    to: () => '/organization_order',
    path: "/organization_order",
    component: Organization_order,
    text: "Organization order"
  },
  ANALYTICS_COURSE: {
    to: () => `/analytics-course`,
    path: "/analytics-course",
    component: Analytics_course,
    text: "Analytics Course",
  },
  TRAINING_TICKET: {
    to: () => `/training_ticket`,
    path: "/training_ticket",
    component: Training_tickets,
    text: "Training Ticket",
  },
  VIEW_FEEDBACKFORM: {
    to : () => '/viewfeedbackform',
    path: "/viewfeedbackform",
    component: ViewFeedbackForm,
    text: "View Feedback form",
  },
  SALES_TARGET_REPORT: {
    to : () => '/sales_target_report',
    path: "/sales_target_report",
    component: Sales_target_report,
    text: "Sales target report",
  },
  REVENUE: {
    to : () => '/revenue-report',
    path: "/revenue-report",
    component: Revenue,
    text: "Revenue report",
  },
  REVENUELIST: {
    to : () => '/revenue-list',
    path: "/revenue-list",
    component: RevenueList,
    text: "Revenue list",
  },
  COURSEWISEREVENUELIST: {
    to : () => '/course-revenue-list',
    path: "/course-revenue-list",
    component: CourseRevenueList,
    text: "Course Revenue",
  },
  SCHEMAWISEREVENUELIST: {
    to : () => '/schema-revenue-list',
    path: "/schema-revenue-list",
    component: SchemaRevenueList,
    text: "Scheme Revenue",
  },
  COUPONREVENUELIST: {
    to : () => '/coupon-discount-list',
    path: "/coupon-discount-list",
    component: CouponDiscountList,
    text: "Coupon Discounts",
  },
  COUPONORDERLIST: {
    to : () => '/coupon-order-list',
    path: "/coupon-order-list",
    component: CouponOrderList,
    text: "Coupon Order list",
  },

  
};
export default urls;
