import React from "react";
function AddNew(props) {
  const { onClick, disabled } = props;
  return (
    <button
      disabled={disabled}
      className={`add-btn ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      Add New
    </button>
  );
}
export default AddNew;
