import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { venue_api } from "../../library/venue_api";
import { location_api } from "../../library/location_api";
import { country_api } from "../../library/country_api";
import { states_api } from '../../library/states_api';
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import { get_location } from "../server_data/list_data";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Venue extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: venue_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        venue_name: { valMet: vj_val.checkLength, valLen: 1 },
        location: { valMet: vj_val.checkLength, valLen: 1 },
        country: { valMet: vj_val.checkLength, valLen: 1 },
        address1: { valMet: vj_val.checkLength, valLen: 1 },
        postcode: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
      location: [],
      countryList:[],
      states:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    venue_name: {
      title: "Venue",
      // width: 300,
      className: "venue_name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    location_name: {
      title: "Location",
      // width: 300,
      className: "location",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    country: {
      title: "Country",
      // width: 300,
      className: "country",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => countryListAlpha2[text],
    },
    state_name: {
      title: "State",
      // width: 300,
      className: "State",
      sorter: true,
      ellipsis: true,
      order: 4,

    },
    phone: {
      title: "Phone",
      // width: 300,
      className: "phone",
      order: 5,
    },
    fax: {
      title: "Fax",
      // width: 300,
      className: "fax",
      order: 6,
    },
    email: {
      title: "Email",
      // width: 300,
      className: "email",
      order: 7,
    },
    website: {
      title: "Website",
      // width: 300,
      className: "website",
      order: 8,
      sorter: true,
      ellipsis: true,
    },
    active: {
      title: "Status",
      width: 80,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 9,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
         //============= define permission =============
         let { loggedUser } = this.props
         if(loggedUser.roleId){
          const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"venue";
          let modulePermission = await myAxios({
            method: "get",
            url,
            header: { Chabi },
          });
         if(modulePermission.status == 200){
              if(modulePermission.data.records.length >= 1){
                console.log("ROLE APPLIED")
                let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
                this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
              }else{
                console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
              }
         }else{
          console.log("PERMISSION API CRASHED")
         }
         }
        //=============================================

    const urlGet = country_api+"/dropdown/state/plan/get";
    const countryList = await myAxios({
      method: "get",
      url: urlGet,
      header: { Chabi },
    });

    const response = await myAxios({
      method: "get",
      url: venue_api,
      header: { Chabi },
    });

    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({
          records,
          countryList: countryList.data.records
        });
      }
    } else console.log("Network Error");
  };
  addVenue = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = venue_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add venue ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editVenue = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      venue_name,
      _id,
      country,
      address1,
      address2,
      address3,
      email,
      fax,
      location,
      phone,
      postcode,
      website,
      stateId
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = venue_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        venue_name,
        country,
        address1,
        address2,
        address3,
        email,
        fax,
        location,
        phone,
        postcode,
        website,
        stateId
      },
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["venue_name"] = venue_name;
            rec["country"] = country;
            rec["address1"] = address1;
            rec["address2"] = address2;
            rec["address3"] = address3;
            rec["email"] = email;
            rec["fax"] = fax;
            rec["location"] = location;
            rec["phone"] = phone;
            rec["postcode"] = postcode;
            rec["website"] = website;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${venue_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  renderState = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;

     let stateUrl = states_api + "/dropdown/gets/"+value;
     const stateResponse = await myAxios({
      method: "get",
      url: stateUrl,
      header: { Chabi },
    });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , states: stateResponse.data.records});
  }

  renderLocation = async (obj) => {
    const { name, value } = obj;
    const { Chabi } = this.state;
    let url = location_api + "/countryBy?stateId="+value;
    const locationResponse = await myAxios({
      method: "post",
      url,
      header: { Chabi },
    });

    const { request } = this.state;
    request[name] = value;
    this.setState({ request , location: locationResponse.data.records});
  }

  renderEditForm = async (id) => {
    const record = this.state.records.filter((r) => r._id == id)[0];
    const { Chabi } = this.state;
    let url = location_api + "/countryBy?country="+record.country;
     const locationResponse = await myAxios({
       method: "post",
       url,
       header: { Chabi },
     });
     this.setState({ location: locationResponse.data.records});
  }

  render() {
    let {
      records,
      edit,
      loginStatus,
      cmodal,
      coffcanvas,
      request,
      filtered_records,
      selectedRecord,
      location,
      countryList,
      states,
      permission
    } = this.state;
    console.log("Venue ", this.state);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      order: 10,
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={() => {
              this.setState({ coffcanvas: true, selectedRecord });
            }}
          >
            Details
          </span>
        );
      },
    });
    let final_country = [];
    if (location) {
      final_country = location.map((l) => l.country);
      final_country = country_array.filter((ca) => {
        return final_country.includes(ca.country_code);
      });
    }
    return (
      <>
        <PageHeading2
          active="Venue"
          page={["Website Administration", "Localization", "Venue"]}
        >
          {permission.create ? 
          <button
            className="add-btn btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> : null }
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
                loadData={permission.update ? (obj) => {
                  this.renderEditForm(obj)
                  this.loadRecord(obj)} : null}
                removeData={permission.itemRemoved ? this.removeRecord : null}
                activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
              // onRowClick={(selectedRecord) => {
              //   this.setState({ coffcanvas: true, selectedRecord });
              // }}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={"m"+request._id}
          status={cmodal}
          title="Add / Edit Venue"
          close={this.close_modal}
          className="modal-form-venue"
        >
          <FormContainer>
            <Row>
              <Col span={8}>
                <FormInputGroup
                  id="venue_name"
                  name="venue_name"
                  label="Venue"
                  placeholder="Venue"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
            
                <FormSelectGroup
                  id="country"
                  name="country"
                  label="Country"
                  placeholder="Country"
                  data={this.state}
                  required
                  // options={country_array}
                  options={countryList}
                  option_value="isoCode"
                  option_text="name"
                  setValidation={this.setValidation}
                  sendValue={this.renderState}
                  allowClear
                />
              </Col>
              <Col span={6}>
                    <FormSelectGroup
                      allowClear
                      id="stateId"
                      name="stateId"
                      label="States"
                      sub_label="(Optional)"
                      placeholder="States"
                      data={this.state}
                      options={states}
                      option_value="_id"
                      option_text="state_name"
                      setValidation={this.setValidation}
                      sendValue={this.renderLocation}
                    />
                  </Col>

              <Col span={8}>
                <FormSelectGroup
                  allowClear
                  id="location"
                  name="location"
                  label="Location"
                  placeholder="Location"
                  data={this.state}
                  required
                  options={location}
                  option_value="_id"
                  option_text="location_name"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormTextareaGroup
                  id="address1"
                  name="address1"
                  label="Address 1"
                  placeholder="Address 1"
                  rows="3"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormTextareaGroup
                  id="address2"
                  name="address2"
                  label="Address 2"
                  placeholder="Address 2"
                  sub_label="(Optional)"
                  rows="3"
                  data={this.state}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormTextareaGroup
                  id="address3"
                  name="address3"
                  label="Address 3"
                  placeholder="Address 3"
                  sub_label="(Optional)"
                  rows="3"
                  data={this.state}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormInputGroup
                  id="postcode"
                  name="postcode"
                  label="Postcode"
                  placeholder="Postcode"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  type="number"
                  id="phone"
                  name="phone"
                  label="Phone"
                  sub_label="(Optional)"
                  placeholder="Phone"
                  data={this.state}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  type="number"
                  id="fax"
                  name="fax"
                  label="Fax"
                  sub_label="(Optional)"
                  placeholder="Fax"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <FormInputGroup
                  id="email"
                  name="email"
                  label="Email"
                  sub_label="(Optional)"
                  placeholder="Email"
                  data={this.state}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={12}>
                <FormInputGroup
                  id="website"
                  name="website"
                  label="Website"
                  sub_label="(Optional)"
                  placeholder="Website"
                  data={this.state}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editVenue();
                else this.addVenue();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        <CustomOffCanvas
          key={request._id}
          status={coffcanvas}
          placement={"right"}
          title="Venue Details"
          close={() => this.setState({ coffcanvas: false })}
          className="drawer-form-venue"
        >
          <Row className="single-record-row heading">
            <Col span={8}>Venue</Col>
            <Col span={8}>Location</Col>
            <Col span={8}>Country</Col>
          </Row>
          <Row className="single-record-row value">
            <Col span={8}>{selectedRecord["venue_name"]}</Col>
            <Col span={8}>{selectedRecord["location"]}</Col>
            <Col span={8}>{selectedRecord["country"]}</Col>
          </Row>
          <Row className="single-record-row  heading mt-5">
            <Col span={8}>Address 1</Col>
            <Col span={8}>Address 2</Col>
            <Col span={8}>Address 3</Col>
          </Row>
          <Row className="single-record-row value">
            <Col span={8}>{selectedRecord["address1"]}</Col>
            <Col span={8}>{selectedRecord["address2"]}</Col>
            <Col span={8}>{selectedRecord["address2"]}</Col>
          </Row>
          <Row className="single-record-row heading  mt-5">
            <Col span={8}>Postcode</Col>
            <Col span={8}>Phone</Col>
            <Col span={8}>Fax</Col>
          </Row>
          <Row className="single-record-row value">
            <Col span={8}>{selectedRecord["postcode"]}</Col>
            <Col span={8}>{selectedRecord["phone"]}</Col>
            <Col span={8}>{selectedRecord["fax"]}</Col>
          </Row>
          <Row className="single-record-row  heading  mt-5">
            <Col span={8}>Email</Col>
            <Col span={8}>Website</Col>
          </Row>
          <Row className="single-record-row value">
            <Col span={8}>{selectedRecord["email"]}</Col>
            <Col span={8}>{selectedRecord["website"]}</Col>
          </Row>
        </CustomOffCanvas>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Venue);
