import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { test_assesment_api } from "../../library/test_assesment_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { get_region } from "../server_data/list_data";
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Test_ass extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: test_assesment_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        test_name: { valMet: vj_val.checkLength, valLen: 1 },
        min_marks: { valMet: vj_val.checkLength, valLen: 1 },
        max_marks: { valMet: vj_val.checkLength, valLen: 1 }
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
    };
  }

 
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    test_name: {
      title: "Test Name",
      // width: 300,
      className: "test_name",
      sorter: true,
      // ellipsis: true,
      order: 1,
    },
    min_marks: {
      title: "Min Marks",
      // width: 300,
      className: "min_marks",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    max_marks: {
      title: "Max Marks",
      // width: 300,
      className: "max_marks",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    active: {
      title: "Active",
      width: 150,
      className: "active",
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
          if (text)
            return <span className="text-success font-weight-bold">Active</span>;
          return <span className="text-danger font-weight-bold">Deactive</span>;
        },
      order: 4,
    },
  };
  componentDidMount = async () => {
    const { Chabi, id } = this.state;
    const url = test_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
    this.setState({ CDM: true});
 
  };

  pageReload = async () => {
    const { Chabi, id } = this.state;
    const url = test_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
  }

  addPartner = async () => {

    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = test_assesment_api + "create";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });

    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReload()
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };

  editPartner = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    let { _id, test_name, min_marks, max_marks} = request
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = test_assesment_api + "update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id,  test_name, min_marks, max_marks},
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            // rec["location_name"] = location_name;
            // rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = test_assesment_api +"status";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      CDM,
      
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });


 
    return (
      <>
       <PageHeading2 active="Tests" page={["Tests"]}>
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> 
          </PageHeading2>
        <MainArea>
        {!CDM ? (
            <MyLoading />
          ) :records && records.length && columns && columns.length > 0 ? (
          <MyDataTable
            rowKey="_id"
            columns={columns}
            dataSource={records}
            loadData={this.loadRecord}
            removeData={this.removeRecord}
            activeData={this.activeRecord}
            pagination={true}
          />
          ): (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Tests"
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            <FormInputGroup
              id="test_name"
              name="test_name"
              label="Test Name"
              placeholder="e.g."
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormInputGroup
              id="min_marks"
              name="min_marks"
              label="Min Required Marks"
              placeholder="e.g. 123"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormInputGroup
              id="max_marks"
              name="max_marks"
              label="Max Marks"
              placeholder="e.g. 123"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />

            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editPartner();
                else this.addPartner();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>

        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Test_ass);
