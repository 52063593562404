// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formbutton_save-button__z3mjP,
.formbutton_reset-button__xb1Qu,
.formbutton_cancel-button__UiqiI {
  border: unset;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
}
/* ======================== */
.formbutton_save-button__z3mjP {
  background: #c6001d;
  color: #fff;
}
.formbutton_reset-button__xb1Qu {
  background: rgb(29, 97, 29);
  color: #fff;
}
.formbutton_cancel-button__UiqiI {
}
/* ==================== */
`, "",{"version":3,"sources":["webpack://./src/component/part/Form/formbutton.module.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA,6BAA6B;AAC7B;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,2BAA2B;EAC3B,WAAW;AACb;AACA;AACA;AACA,yBAAyB","sourcesContent":[".save-button,\n.reset-button,\n.cancel-button {\n  border: unset;\n  border-radius: 5px;\n  font-size: 12px;\n  font-weight: 500;\n  padding: 5px 10px;\n}\n/* ======================== */\n.save-button {\n  background: #c6001d;\n  color: #fff;\n}\n.reset-button {\n  background: rgb(29, 97, 29);\n  color: #fff;\n}\n.cancel-button {\n}\n/* ==================== */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"save-button": `formbutton_save-button__z3mjP`,
	"reset-button": `formbutton_reset-button__xb1Qu`,
	"cancel-button": `formbutton_cancel-button__UiqiI`
};
export default ___CSS_LOADER_EXPORT___;
