import React from "react";
import { message } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
// ===============================================================================
class SeoBox extends FormParent {
  constructor(props) {
    super(props);
    const { state: parentState, record } = props;
    const { seo_title, seo_keyword, seo_description, seo_slug, _id } = record;
    const { page_api, Chabi, doUserLogout } = parentState;
    this.state = {
      page_api,
      request: { seo_title, seo_keyword, seo_description, seo_slug, _id },
      validation: {},
      validation_info: {
        seo_slug: { valMet: vj_val.checkLength, valLen: 1 },
        seo_title: { valMet: vj_val.checkLength, valLen: 1 },
        seo_keyword: { valMet: vj_val.checkLength, valLen: 1 },
        seo_description: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: Chabi,
      doUserLogout: doUserLogout,
      cmodal: true,
    };
  }
  editSeo = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, doUserLogout, Chabi, page_api } = this.state;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = page_api + "/seo";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({ validation: {}, cmodal: false });
      } else if (data.status == 200) {
        message.success("Record Updated");
        this.props.updateSeo(request);
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  render() {
    let { cmodal, request } = this.state;
    return (
      <>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Seo"
          close={this.props.setSeoModal}
          className="drawer-form-seo"
        >
          <FormContainer>
            <FormInputGroup
              id="seo_slug"
              name="seo_slug"
              label="Slug"
              placeholder="Slug"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormInputGroup
              id="seo_title"
              name="seo_title"
              label="Title"
              placeholder="Title"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormInputGroup
              id="seo_keyword"
              name="seo_keyword"
              label="Keyword"
              placeholder="Keyword"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormTextareaGroup
              id="seo_description"
              name="seo_description"
              label="Description"
              placeholder="Description"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormButton submit={this.editSeo} />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
export default SeoBox;
