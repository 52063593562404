// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .ant-modal-content .ant-modal-body {
  
}
.modal-form-venue {
  width: 1000px !important;
}
.modal-form-region {
  width: 700px !important;
}
.modal-form-course {
  width: 1000px !important;
}
.modal-form-industries {
  width: 1000px !important;
}
.modal-form-coupon {
  width: 1000px !important;
}
.modal-form-learning_scheme {
  width: 1000px !important;
}
.modal-form-career {
  width: 1200px !important;
}
.modal-form-course_category {
  width: 800px !important;
}

.modal-form-contact_details {
  width: 1000px !important;
}
.modal-form-insight {
  width: 1000px !important;
}
.modal-form-news_update {
  width: 1000px !important;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/CustomModal/styles.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".custom-modal .ant-modal-content .ant-modal-body {\n  \n}\n.modal-form-venue {\n  width: 1000px !important;\n}\n.modal-form-region {\n  width: 700px !important;\n}\n.modal-form-course {\n  width: 1000px !important;\n}\n.modal-form-industries {\n  width: 1000px !important;\n}\n.modal-form-coupon {\n  width: 1000px !important;\n}\n.modal-form-learning_scheme {\n  width: 1000px !important;\n}\n.modal-form-career {\n  width: 1200px !important;\n}\n.modal-form-course_category {\n  width: 800px !important;\n}\n\n.modal-form-contact_details {\n  width: 1000px !important;\n}\n.modal-form-insight {\n  width: 1000px !important;\n}\n.modal-form-news_update {\n  width: 1000px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
