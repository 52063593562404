import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col, Checkbox, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { Tabs } from "antd";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CustomSwitch from "../part/Display/CustomSwitch";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { course_api } from "../../library/course_api";
import { coursemaster_api } from "../../library/coursemaster_api";
import { region_api } from "../../library/region_api";
import { bulk_upload_api } from "../../library/bulk_upload_api";

// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import FormTextEditorGroup from "../part/Form/FormTextEditorGroup";
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import MyLoading from "../part/MyLoading";
import AddNew from "../part/AddNew";
import FormCheckboxSingleGroup from "../part/Form/FormCheckboxSingleGroup";
import FormBannerImageUploadGroup from "../part/Form/FormBannerImageUploadGroup";

import MyToolTip from "../part/MyToolTip/MyToolTip";
import { PlusOutlined } from '@ant-design/icons';
import { object } from "joi";
const { TabPane } = Tabs;
const { Dragger } = Upload;
// ===============================================================================
class Course extends FormParent {
 
  constructor(props) {

    super(props);
    this.state = {
      min_grade_passcheckbox: "false",
      min_gradecheckbox: "false",
      min_attendancecheckbox: "false",
      min_attendance: "",
      min_grade: "",
      page_api: course_api,
      filter: {},
      records: [],
      request: { region_info: {} },
      validation: {},
      validation_info: {
        image: { valMet: vj_val.checkImage },
        // file: { valMet: vj_val.checkImage },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        category: { valMet: vj_val.checkLength, valLen: 1 },
        // course_master: { valMet: vj_val.checkLength, valLen: 1 },
		course_type: { valMet: vj_val.checkLength, valLen: 1 },
        languages: { valMet: vj_val.checkLength, valLen: 1 },
        accreditation: { valMet: vj_val.checkLength, valLen: 1 },
        main_head: { valMet: vj_val.checkLength, valLen: 1 },
        banner: { valMet: vj_val.checkImage },
        // ============================================================
        // ============================================================
      },
      region1_validation_info: {
        fees: { valMet: vj_val.checkNumber },
        duration: { valMet: vj_val.checkNumber },
        delivery_method: { valMet: vj_val.checkLength, valLen: 1 },
      },
	    Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
	    cmodal_course: false,
	    cmodal_course_region: false,
      seomodal: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      regionEnabled: true,
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
	    delivery_method:[],
	    region:[],
	    is_featured:"",
      modelBulkUpload:false,
      uploadingIssues:[],
      issueRows:0,
      processRow:0,
      bulkUploadDone:false,
      uploadingLoader:false,
      modelRegionBulkUpload:false,
      uploadBannerImageList:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }
   displayFieldsRegion = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 1,
    },
   
    course_name: {
      title: "Course Name",
      //width: 400,
      className: "course_name",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
	/*duration: {
      title: "Duration",
      width: 100,
      className: "course_name",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
	duration_in_hours: {
      title: "Duration In hours",
      width: 100,
      className: "course_name",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
	duration: {
      title: "Fees",
      width: 50,
      className: "course_name",
      sorter: true,
      ellipsis: true,
      order: 5,
    },*/
	region: {
      title: "Region",
      //width: 400,
      className: "course_name",
      sorter: true,
      ellipsis: true,
      order: 6,
    },
   };
  displayFields = {
    sno: {
      title: "SN",
      width: 60,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    _id: {
      title: "Course Id",
      width: 200,
      defaultSortOrder: "descend",
      order: 1,
    },
    main_head: {
      title: "Course Name",
      //width: 400,
      className: "main_head",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    scheme: {
      title: "Curriculum",
      //width: 200,
      className: "scheme",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => this.state.learning_scheme2[text],
    },
    category: {
      title: "Topic",
     // width: 100,
      className: "category",
      sorter: true,
      ellipsis: true,
      order: 4,
      custom: (text, record) => this.state.course_category2[text],
    },
    active: {
      title: "Status",
      //width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 6,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${course_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = course_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  fileUpload = async (fl) => {
    const { uploadFileList, Chabi } = this.state;
    const dont_run = uploadFileList.some((upl) => upl.uid && upl.uid == fl.uid);
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = course_api + "/file_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "file", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadFileList.push(final_obj);
      this.setState({ uploadFileList });
      this.setValidation({ name: "file", res: true });
    }
  };
  courseMaster = async () => {
    const { Chabi, id } = this.state;
    const url = coursemaster_api+"dropdown/get";

    const response = await myAxios({
      method: "get",
      url,
      header: { Chabi },
      //request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        //let {records } = data;
        //records = add_sno(records);
        this.setState({course_master:data.records});
      }
    } else console.log("Network Error");
    this.setState({ CDM: true});
 
  };

  bannerUpload = async (fl) => {
    const { uploadBannerImageList, Chabi } = this.state;
    const dont_run = uploadBannerImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = course_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "banner", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadBannerImageList.push(final_obj);
      this.setState({ uploadBannerImageList });
      this.setValidation({ name: "banner", res: true });
    }
  };
  
  componentDidMount = async () => {
    // console.clear()
    //============= define permission =============
    this.getRolePermission("course")
    //=============================================
    let queryParms = new URLSearchParams(window.location.search);
		let type = queryParms.get("type")
    if(type == undefined){
      type = 4
    }

    this.courseMaster();
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: [
        "learning_scheme",
        "course_category",
        "course_type",
        "language",
        "accreditation",
        "delivery_method",
        "region",
      ],
      primary: "",
      Chabi,
    });

    const course = await myAxios({
      method: "post",
      url: course_api + "/getCountresforFilter/"+type,
      header: { Chabi },
    });

    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          accreditation,
          records,
          learning_scheme,
          course_category,
          course_type,
          language,
          delivery_method,
          region,
        } = data;
       
        if(course.status == 200){
          const { data } = course;
          if (data.status == 420) this.setLogout();
          else if (data.status == 200) {
            records = add_sno(data.records);
          }
        }
       
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course_category2 = create_object({
          arr: course_category,
          title: "main_head",
        });
        let course_type2 = create_object({
          arr: course_type,
          title: "title",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let accreditation2 = create_object({
          arr: accreditation,
          title: "title",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
          learning_scheme,
          learning_scheme2,
          course_category,
          course_category2,
          course_type,
          course_type2,
          language,
          language2,
          accreditation,
          accreditation2,
          delivery_method,
          delivery_method2,
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
  
  
	addNewCourse = async () => {
		this.setState({ showErr: true });
		const { request, Chabi } = this.state;

		
		
		let newValues={
			courseId:request._id,
			regionId : request.regionId,
			fees : request.fees,
			duration : request.duration ,
			duration_in_hours : request.duration_hours ,
			delivery_method : request?.delivery_method ,
			releated_course : request?.releated_course ,
			is_featured : request?.is_featured ,
			course_name : request?.course_name ,
			short_description : request?.short_description ,
			description : request?.description ,
			prior_knowledge : request?.prior_knowledge ,
			course_program : request?.course_program ,
			why_our_course : request?.why_our_course ,
			basic_course_requirments : request?.basic_course_requirments ,
      virtual_technology_requirments : request?.virtual_technology_requirments,
			key_course_learning_outcome : request?.key_course_learning_outcome,
			course_basics : request?.course_basics,
			learner_profile : request?.learner_profile
		}
		
		let { records } = this.state;
		const url = course_api+'/mapping/region/create';
		const response = await myAxios({
		  method: "post",
		  url,
		  header: { Chabi },
		  request:newValues,
		});
		if (response.status == 200) {
		  const { data } = response;
		  if (data.status == 420) this.setLogout();
		  else if (data.status == 200) {
			message.success("Record Saved");
			//const { records: r } = data;
			//records.push({ ...r[0] });
			//records = add_sno(records);
			this.setState({
			  //records,
			  request: {},
			  validation: {},
			  uploadImageList: [],
			  uploadFileList: [],
			  activeKey: "1",
			  selectedTab: undefined,
			  cmodal_course: false,
			  nextTabMsg: "",
			});
		  } else {
			const { error, message } = data;
			if (message) return console.log(message);
		  }
		} else alert("Network Error !");	
	}
	
	editNewCourse = async () => {
		this.setState({ showErr: true });
		const { request, Chabi } = this.state;
		
		let newValues={
			_id:request._id,
			courseId:request.courseId,
			regionId : request.regionId,
			fees : request.fees,
			duration : request.duration ,
			duration_in_hours : request.duration_hours ,
			delivery_method : request?.delivery_method ,
			releated_course : request?.releated_course ,
			is_featured : request?.is_featured ,
			course_name : request?.course_name ,
			short_description : request?.short_description ,
			description : request?.description ,
			prior_knowledge : request?.prior_knowledge ,
			course_program : request?.course_program ,
			why_our_course : request?.why_our_course ,
			basic_course_requirments : request?.basic_course_requirments ,
      virtual_technology_requirments : request?.virtual_technology_requirments,
			key_course_learning_outcome : request?.key_course_learning_outcome,
			course_basics : request?.course_basics,
			learner_profile : request?.learner_profile,
		}
		
		let { records } = this.state;
		const url = course_api+'/mapping/region';
		const response = await myAxios({
		  method: "put",
		  url,
		  header: { Chabi },
		  request:newValues,
		});
		if (response.status == 200) {
		  const { data } = response;
		  if (data.status == 420) this.setLogout();
		  else if (data.status == 200) {
			message.success("Record Saved");
			//const { records: r } = data;
			//records.push({ ...r[0] });
			//records = add_sno(records);
			this.setState({
			  //records,
			  request: {},
			  validation: {},
			  uploadImageList: [],
			  uploadFileList: [],
			  activeKey: "1",
			  selectedTab: undefined,
			  cmodal_course: false,
			  nextTabMsg: "",
			});
			//this.RegionListCourse(request);
		  } else {
			const { error, message } = data;
			if (message) return console.log(message);
		  }
		} else alert("Network Error !");	
	}
	
	RegionListCourse = async (selectedData) => {
		this.setState({ showErr: true });
		const { request, Chabi } = this.state;
		let { regionRecords } = this.state;
		//const url = course_api+'/mapping/region?courseId=6295ef77378fb3e4a006f376';
		const url = course_api+'/mapping/region?courseId='+selectedData._id;
		const response = await myAxios({
		  method: "get",
		  url,
		  header: { Chabi },
		});

		if (response.status == 200) {
		  const { data } = response;
		  if (data.status == 420) this.setLogout();
		  else if (data.status == 200) {
			this.setState({
			  regionRecords:data.records,
			});
		  } else {
			const { error, message } = data;
			if (message) return console.log(message);
		  }
		} else alert("Network Error !");	
	}
	
  addCourse = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = course_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });

    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: { region_info: {} },
          validation: {},
          uploadImageList: [],
          uploadFileList: [],
          activeKey: "1",
          selectedTab: undefined,
          cmodal: false,
          nextTabMsg: "",
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editCourse = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      main_head,
      scheme,
      image,
      file,
      category,
      languages,
      // course_master,
	  course_type,
    course_order,
      accreditation,
      accreditation_number,
      region_info,
      releated_course,
      ceu_point,
      banner
    } = request;
 
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = course_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        main_head,
        image,
        file,
        scheme,
        category,
        languages,
		// course_master,
        course_type,
        accreditation,
        accreditation_number,
        region_info,
        course_order,
        releated_course,
        ceu_point,
        banner
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: { region_info: {} },
          cmodal: false,
          nextTabMsg: "",
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["main_head"] = main_head;
            rec["image"] = image;
            rec["file"] = file;
            rec["scheme"] = scheme;
            rec["category"] = category;
            rec["languages"] = languages;
            // rec["course_master"] = course_master;
			rec["course_type"] = course_type;
            rec["accreditation"] = accreditation;
            rec["accreditation_number"] = accreditation_number;
            rec["course_order"] = course_order;
            rec["region_info"] = region_info;
            rec["releated_course"] = releated_course;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: { region_info: {} },
          cmodal: false,
          activeKey: "1",
          selectedTab: undefined,
          nextTabMsg: "",
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${course_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  nextManage = ({ activeKey }) => {
    const { region } = this.state;
    if (activeKey == "2") {
      const fields = [
        "image",
        "file",
        "scheme",
        "category",
        "course_master",
		"course_type",
        "languages",
        "accreditation",
      ];
      const { val_result, reason } = this.checkNextValidation({
        fields,
      });
      // this.checkRegionValidation();
      if (val_result) this.setState({ activeKey: "2" });
      else this.setState({ validation: reason });
    } else this.setState({ activeKey: "1" });
  };

  reloadPageData = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: [
        "learning_scheme",
        "course_category",
        "course_type",
        "language",
        "accreditation",
        "delivery_method",
        "region",
      ],
      primary: "course",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          accreditation,
          records,
          learning_scheme,
          course_category,
          course_type,
          language,
          delivery_method,
          region,
        } = data;
        records = add_sno(records);
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course_category2 = create_object({
          arr: course_category,
          title: "main_head",
        });
        let course_type2 = create_object({
          arr: course_type,
          title: "title",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let accreditation2 = create_object({
          arr: accreditation,
          title: "title",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
          learning_scheme,
          learning_scheme2,
          course_category,
          course_category2,
          course_type,
          course_type2,
          language,
          language2,
          accreditation,
          accreditation2,
          delivery_method,
          delivery_method2,
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  }

  save_course_min_requirement = async () => {
    const {
      Chabi,
      request,
      selectedRecord,
      min_grade_passcheckbox,
      min_gradecheckbox,
      min_attendance,
      min_grade,
      min_attendancecheckbox,
    } = this.state;
    let { records } = this.state;
    const { _id } = selectedRecord;
    // const { min_attendancecheckbox } = request;

    //console.log("xxxxxxxxxxxxxxxxxxxsssssssssss",_id,min_attendance, min_grade,min_grade_passcheckbox,min_gradecheckbox,min_attendancecheckbox);

    const url = `${course_api}/m`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        min_attendance,
        min_grade,
        min_grade_passcheckbox,
        min_gradecheckbox,
        min_attendancecheckbox,
      },
    });

    
    if (response.status == 200) {
      const { data } = response;
      message.success("Record Saved");
      this.setState({
        coffcanvas: false,
        selectedRecord: {},
        chPwdMsg: "",
      });
      this.setState({ CDM: false });
      this.reloadPageData()
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  save_mailer = async () => {
    const { Chabi, selectedRecord } = this.state;
    let { records } = this.state;
    const { _id, mailer } = selectedRecord;
    const url = `${course_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, mailer },
    });

    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r["mailer"] = mailer;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  reloadRegion = async (record) => {
    const { Chabi } = this.state
    const url = region_api+ "/region/country/"+record.regionId
   const trainingResponse = await myAxios({
     method: "get",
     url,
     header: { Chabi },
   });
   if(trainingResponse.status == 200){
        if(trainingResponse.data.records.length >= 1){

       }else{
        return alert("No country found in this region...!!!");
       }
      
   }else{
    return alert("API THROW ERROR");
   }
  }

  courseRegionActive = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${course_api}/mapping/region/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        this.RegionListCourse(this.state.request);
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  }

  setRequestCustom = (obj) => {
   
    const { name, value } = obj;
    const { filtered_records, request } = this.state;
    request[name] = value;
    if(request.main_head != "" || request.main_head != undefined){
      request["main_head"] = `${(name == "category") ? this.state.course_category.find( rec => rec._id == value).main_head : this.state.request.category ? this.state.course_category.find( rec => rec._id == this.state.request.category).main_head : ""} ${(name == "course_type") ? this.state.course_type.find( rec => rec._id == value).title : this.state.request.course_type ? this.state.course_type.find( rec => rec._id == this.state.request.course_type).title : ""}`;
      this.setState({ request });
    }else{
      this.setState({ request });
    }
    
  };

  bulkFileOneChange = (info) => {
    this.setState({uploadingLoader: true})
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      if(info.file.response){
        const { issueArr, errorRows, processRows} = info.file.response.data
        this.setState({uploadingLoader: false, uploadingIssues: issueArr, issueRows: errorRows  , processRow: processRows, bulkUploadDone: true})
      }
      // uploadingIssues
      // issueRows
      message.success(info.file.response.message);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      this.setState({uploadingLoader: false });
    }
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  render() {
    console.log("course State ", this.state);
    var html = [];
    for (var i = 100; i >= 1; i--) {
      html.push({ id: `${i},${i}%`, title: i + "%" });
    }

    let {
      records,
	  regionRecords,
      edit,
      page_api,
      loginStatus,
      cmodal,
	  cmodal_course,
	  cmodal_course_region,
      request,
	  releated_course,
      filtered_records,
      learning_scheme,
      course_category,
      course_type,
      language,
      accreditation,
      delivery_method,
	  is_featured,
      region,
      selectedTab,
      activeKey,
      seomodal,
      validation_info,
      region1_validation_info,
      nextTabMsg,
      CDM,
      selectedRecord,
      coffcanvas,
	  course_master,
    modelBulkUpload,
    modelRegionBulkUpload,
    permission
    
    } = this.state;
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
	
	const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
	
    const columnsRegion = createColumn({
      records:regionRecords,
      displayFields: this.displayFieldsRegion,
      //fastEdit: this.fastEdit,
    });

    if(permission.childModule && permission.childModule?.seo){
    columns.push({
      width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
  }

  if(permission.childModule && permission.childModule?.scheduleList){
    columnsRegion.push({
      //width: 100,
      title: "Schedule",
      dataIndex: "Schedule",
      render: (text, record) => {
        return (
          <button 
            className="btn btn-sm btn-primary"
            onClick={async () => {
               const { Chabi } = this.state
                const url = region_api+ "/region/country/"+record.regionId
               //const url = course_api + "/region/schedule?courseId="+record.courseId+"&regionId="+record.regionId;
               const trainingResponse = await myAxios({
                 method: "get",
                 url,
                 header: { Chabi },
               });
               if(trainingResponse.status == 200){
                    if(trainingResponse.data.records.length >= 1){
                    let identifier = `/course_schedule?identifier=${record._id}&cid=${record.courseId}&rid=${record.regionId}`
                    window.location.href = identifier
                   }else{
                    return alert("No country found in this region...!!!");
                   }
                  
               }else{
                return alert("API THROW ERROR");
               }
              
            }}
          >
            Schedule List
          </button>
        );
      },
    });
  }

  if(permission.childModule && permission.childModule?.copyToRegionAll){
    columnsRegion.push({
      //width: 100,
      title: "Copy",
      dataIndex: "Copy",
      render: (text, record) => {
       
        return (
          <button 
            className="btn btn-sm btn-primary"
            onClick={async () => {
               const { Chabi } = this.state
                const url = `${course_api}/region/copy_to_all?regionId=${record.regionId}&courseId=${record.courseId}`
              
               const trainingResponse = await myAxios({
                 method: "put",
                 url,
                 header: { Chabi },
               });
            
               if(trainingResponse.status == 200){
                    if(trainingResponse.data.status == 200){
                      message.success("Copy To All : Done")
                      this.RegionListCourse(this.state.request);
                    }else{
                      return message.warning(trainingResponse.data.message)
                    }
               }else{
                return alert("API THROW ERROR");
               }
              
            }}
          >
            Copy To All Region
          </button>
        );
      },
    });
  }


    if(permission.childModule && permission.childModule?.details){
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={() => this.setState({ coffcanvas: true, selectedRecord })}
            className="btn btn-primary btn-sm"
          >
            Details
          </span>
        );
      },
    });
  }

  if(permission.childModule && permission.childModule?.region ||  permission.childModule?.regionList){
    columns.push({
      //width: 100,
      title: "Course Region",
      render: (text, record) => {
        return (
		<> 
        {permission.childModule?.region ? 
           record.courseRegion.length == 0 ?
             <a className="btn btn-sm btn-outline-primary mb-1 mr-2"
             onClick={() => {
         // render data
               request = { ...request, ...record };
               this.setState({ cmodal_course: true, request, edit: true });
             }}
           >
             Region 
           </a>
           : null : null  
      }
          
      { permission.childModule?.regionList ? 
		  <a className="btn-sm btn btn-primary"
            onClick={async () => { 
              request = { ...request, ...record };
			  this.setState({ cmodal_course_region: true, request, edit: true });
			  this.RegionListCourse(record);
            }}
          >
            Region List
          </a> 
          : null }
		  </>
        );
      },
    })
  } 

   
    
    return (
      <>
        <PageHeading2 active="Course" page={["Course"]}>
        {/* <button
            className="btn btn-sm btn-primary"
            style={{marginRight:2}}
            onClick={() => {
              this.setState({ modelBulkUpload: !modelBulkUpload });
            }}
          >
            Bulk Upload
          </button> */}
          {permission.create ? 
          <AddNew
          groupClassName="btn2"
            disabled={!CDM}
            onClick={() => {
              this.setState({
                cmodal: !cmodal,
                edit: false,
                request: { region_info: {} },
              });
            }}
          /> : null
        }
          
        </PageHeading2>
        <MainArea>
          {records && records.length ? (
            <>
              <Row style={{ marginTop: "20px" }}>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="active"
                    name="active"
                    label="Status"
                    placeholder="Status"
                    options={[
                      { text: "Active", value: "1" },
                      { text: "Deactive", value: "0" },
                    ]}
                    option_value="value"
                    option_text="text"
                    sendValue={this.set_filter}
                  />
                </Col>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="scheme"
                    name="scheme"
                    label="Curriculum"
                    placeholder="Curriculum"
                    options={learning_scheme}
                    option_value="_id"
                    option_text="main_head"
                    sendValue={this.set_filter}
                  />
                </Col>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="category"
                    name="category"
                    label="Topic"
                    placeholder="Topic"
                    options={course_category}
                    option_value="_id"
                    option_text="main_head"
                    sendValue={this.set_filter}
                  />
                </Col>
                
             
                <Col span={2} className="m-2" style={{ paddingTop: "28px" }}>
                  <button className="filter-btn  btn btn-sm btn-primary" onClick={this.run_filter}>
                    Filter
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={permission.update ? this.loadRecord : null}
              removeData={permission.itemRemoved ? this.removeRecord : null}
              activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Course"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Tabs
              key={request._id}
              defaultActiveKey={"1"}
              activeKey={activeKey}
              onChange={(activeKey) => {
                this.nextManage({ activeKey });
              }}
            >
              <TabPane tab="Info" key="1">
                <div className="row">
                  <div className="col-8">
                    <FormImageUploadGroup
                      // src={`${page_api}/image/`}
                      src={`${PORTAL.api_url}uploaded_images/`}
                      id="image"
                      name="image"
                      label="Course Logo"
                      sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
                      imageCount="1"
                      data={this.state}
                      required
                      imageUpload={this.imageUpload}
                      changeUploadImageList={this.changeUploadImageList}
                    />
                  </div>
                  <div className="col-4">
                  <FormBannerImageUploadGroup
                      // src={`${page_api}/image/`}
                      src={`${PORTAL.api_url}uploaded_images/`}
                      id="banner"
                      name="banner"
                      label="Course banner"
                      sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 1600x678 pixel)"
                      imageCount="1"
                      data={this.state}
                      required
                      imageUpload={this.bannerUpload}
                      changeUploadImageList={this.changeUploadBannerImageList}
                    />
                  </div>
                  <div className="col-4">
                    <FormFileUploadGroup
                      src={`${page_api}/file/`}
                      accept=".pdf"
                      id="file"
                      name="file"
                      label="Brochure"
                      sub_label="(Size below 5Mb, Accept pdf)"
                      FileCount="1"
                      data={this.state}
                      // required
                      fileUpload={this.fileUpload}
                      changeUploadFileList={this.changeUploadFileList}
                    />
                  </div>
                </div>
                <Row>
				  <Col span={12}>
                    <FormSelectGroup
                      allowClear
                      id="scheme"
                      name="scheme"
                      label="Curriculum"
                      placeholder="Curriculum"
                      data={this.state}
                      required
                      options={learning_scheme}
                      option_value="_id"
                      option_text="main_head"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
				  {/* <Col span={12}>
                    <FormSelectGroup
                      allowClear
                      id="course_master"
                      name="course_master"
                      label="Course"
                      placeholder="Course"
                      data={this.state}
                      required
                      options={course_master}
                      option_value="_id"
                      option_text="course_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequestCustom}
                    />
                  </Col> */}
				  
                  <Col span={12}>
                    <FormSelectGroup
                      allowClear
                      id="category"
                      name="category"
                      label="Topic"
                      placeholder="Topic"
                      data={this.state}
                      required
                      options={
                        request.scheme
                          ? course_category.filter(
                              (cc) => cc.learning_scheme == request.scheme
                            )
                          : []
                      }
                      option_value="_id"
                      option_text="main_head"
                      setValidation={this.setValidation}
                      sendValue={this.setRequestCustom}
                    />
                  </Col>
                  <Col span={12}>
                    <FormSelectGroup
                      allowClear
                      id="course_type"
                      name="course_type"
                      label="Course Type"
                      placeholder="Course Type"
                      data={this.state}
                      required
                      options={course_type}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequestCustom}
                    />
                  </Col>
				  <Col span={16}>
				   <FormInputGroup
					  id="main_head"
					  name="main_head"
					  label="Course Name"
					  placeholder="Course Name"
					  data={this.state}
					  required
					  setValidation={this.setValidation}
					  sendValue={this.setRequest}
					/>
					</Col>
          <Col span={8}>
                    <FormMultiSelectGroup
                      id="languages"
                      name="languages"
                      label="Languages"
                      placeholder="Languages"
                      data={this.state}
                      required
                      options={language}
                      option_value="_id"
                      option_text="language"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  {/* <Col span={8}>
                    <FormInputGroup
                      id="reference_code"
                      name="reference_code"
                      label="Reference Code"
                      placeholder="Reference Code"
                      data={this.state}required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> */}
                </Row>
                <Row>
                <Col span={8}>
                    <FormInputGroup
                      type="text"
                      id="ceu_point"
                      name="ceu_point"
                      label="CEU Point"
                      sub_label="(Optional)"
                      placeholder="CEU Point"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormMultiSelectGroup
                      id="accreditation"
                      name="accreditation"
                      label="Accreditation"
                      placeholder="Accreditation"
                      data={this.state}
                      required
                      options={accreditation}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormInputGroup
                      type="number"
                      id="accreditation_number"
                      name="accreditation_number"
                      label="Accreditation Number"
                      sub_label="(Optional)"
                      placeholder="Accreditation Number"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
				  
                </Row>
                
                {/*<div>
                  <button
                    className="add-btn"
                    onClick={() => {
                      this.nextManage({ activeKey: "2" });
                    }}
                  >
                    Next
                  </button>
                  <span className="d-inline-block ml-2 text-danger">
                    {nextTabMsg}
                  </span>
                </div>*/}
              </TabPane>
              {/*<TabPane tab="Details" key="2">
                <FormInputGroup
                  id="main_head"
                  name="main_head"
                  label="Main Head"
                  placeholder="Main Head"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
                <button
                  className="add-btn"
                  onClick={() => {
                    this.nextManage({ activeKey: "1" });
                  }}
                >
                  Back
                </button>
              </TabPane>*/}
            </Tabs>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editCourse();
                else this.addCourse();
              }}
              reset={() => this.setState({ request: { region_info: {} } })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      main_head,
                      languages,
                      category,
                      course_type,
                      scheme,
                      mailer,
                      min_attendance,
                      min_grade,
                      min_grade_passcheckbox,
                      min_gradecheckbox,
                      min_attendancecheckbox,
                    } = selectedRecord;
					
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {main_head}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tab="Minimum Requirement" key="2">
              
                <div className="bg-light p-2 mt-2">
                <div className="row align-items-center">
                <div className="col-12 col-sm-6 col-md-6">
                 <Checkbox 
                 onChange={(obj) => {
                         let min_attendancecheckbox = `${obj.target.checked}`
                         this.setState({ selectedRecord : {...this.state.selectedRecord, min_attendancecheckbox}, min_attendancecheckbox:min_attendancecheckbox })
                  }}  
                  checked={
                    selectedRecord.min_attendancecheckbox === "true"? true: false
                  }>Minimum attendance required to pass course</Checkbox>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6">
                  <div className="pl-2 pr-2 mt-2">
                    <div className="form-group">
                      <select
                        // allowClear
                        name="min_attendance"
                        className="form-select form-ctrl"
                        placeholder="Min Attendance"
                        defaultValue={
                          selectedRecord.min_attendance
                            ? selectedRecord.min_attendance
                            : ""
                        }
                        // option_value="id"
                        // option_text="title"
                        setValidation={this.setValidation}
                        onChange={(e) => {
                  
                          this.setState({ min_attendance: e.target.value });
                        }}
                      >
                        {/* {selectedRecord.min_attendance ? (
                          <> */}
                        <option selected>
                          {selectedRecord.min_attendance}
                        </option>
                        {/* </>
                        ) : (
                          <>
                            <option selected></option>
                          </>
                        )} */}
                        {html.map((t, id) => {
                          return (
                            <option key={id} value={t.title}>
                              {t.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="bg-light p-2 mt-2">
                <div className="row align-items-center">
                <div className="col-12 col-sm-6 col-md-6">
                   <Checkbox 
                 onChange={(obj) => {
                         let min_gradecheckbox = `${obj.target.checked}`
                         this.setState({ selectedRecord : {...this.state.selectedRecord, min_gradecheckbox}, min_gradecheckbox:  min_gradecheckbox})
                  }}  
                  checked={
                    selectedRecord.min_gradecheckbox === "true"? true: false
                  }>Minimum grade required to pass LCA</Checkbox>
</div>
<div className="col-12 col-sm-6 col-md-6">
                  <div className="pl-2 pr-2 mt-2">
                    <div className="form-group">
                      <select
                        // allowClear
                        name="min_grade"
                        className="form-select form-ctrl"
                        placeholder="Min Grade"
                        defaultValue={
                          selectedRecord.min_grade
                            ? selectedRecord.min_grade
                            : ""
                        }
                        // option_value="id"
                        // option_text="title"
                        setValidation={this.setValidation}
                        onChange={(e) => {
                          this.setState({ min_grade: e.target.value });
                        }}
                      >
                        {/* {selectedRecord.min_grade ? (
                          <> */}
                        <option selected>{selectedRecord.min_grade}</option>
                        {/* </>
                        ) : (
                          <>
                            <option selected></option>
                          </>
                        )} */}
                        {/* <option selected>{selectedRecord?.min_grade}</option> */}
                        {html.map((t, id) => {
                          return (
                            <option key={id} value={t.title}>
                              {t.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                </div>
                </div>
                {}
                <div className="bg-light p-2 mt-2">
                  <FormCheckboxSingleGroup
                    id="min_grade_passcheckbox"
                    name="min_grade_passcheckbox"
                    label="Minimum grade required to pass written examination: "
                    sub_label=""
                    defaultValue={
                      selectedRecord.min_grade_passcheckbox == "true" ? "true" : ""
                    }
                    options={{ text: "(Determined by exam)", value: "true" }}
                    option_value="value"
                    option_text="text"
                    sendValue={(obj) => {
                      this.setState({ min_grade_passcheckbox: obj.value });
                    }}
                  />
                </div>
                {/* <div className="bg-light p-2 mt-2">
                  <input
                    className=""
                    name="min_grade_passcheckbox"
                    type="checkbox"
                    id="min_grade_passcheckbox"
                    // defaultValue="option1"
                    label="Determined by exam"
                    value={selectedRecord.min_grade_passcheckbox}
                    // checked={
                    //   this.state.popular_courses_delivery_method
                    // }
                    // onChange={this.handleCHangeCheck}
                  />
                  <label>Determined by exam</label>
                </div> */}
                <FormButton submit={this.save_course_min_requirement} />
              </TabPane>
              <TabPane tab="Mailer" key="7">
                {[selectedRecord].map((selectedRecord) => {
                  const {
                    main_head,
                    languages,
                    category,
                    course_type,
                    scheme,
                    mailer,
                  } = selectedRecord;
                  return (
                    <>
                      <FormTextEditorGroup
                        id="mailer"
                        name="mailer"
                        label="Content"
                        placeholder="Content"
                        data={this.state}
                        value={mailer}
                        sendValue={({ value }) => {
                          const selectedRecord2 = selectedRecord;
                          selectedRecord2["mailer"] = value;
                          this.setState({ selectedRecord: selectedRecord2 });
                        }}
                      />
                      <FormButton submit={this.save_mailer} />
                    </>
                  );
                })}
              </TabPane>
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records, seomodal: false });
            }}
          />
        ) : (
          ""
        )}
		
		<CustomModal
          key={`modal-form-region${request._id}`}
          status={cmodal_course_region}
          title="Manage Course Region List"
          close={this.close_modal_course_region}
          className="modal-form-insight"
        >
          {permission.childModule && permission.childModule.bulkUpload ? 
          <button
            className="btn btn-sm btn-primary float-right"
            style={{margin: 10}}
            onClick={() => {
              this.setState({ modelRegionBulkUpload: !modelRegionBulkUpload, cmodal_course_region:false });
            }}
          >
            Bulk Upload
          </button>
          : null}

			{!CDM ? (
				<MyLoading />
			  ) : regionRecords && regionRecords.length && columnsRegion && columnsRegion.length > 0 ? (
			<> 
				<MyDataTable
				  rowKey="_id"
				  columns={columnsRegion}
				  dataSource={regionRecords}
				  loadData={permission.childModule && permission.childModule?.edit ? this.loadRecordRegion : null}
				  //removeData={this.removeRecord}
				  activeData={permission.childModule && permission.childModule?.activeDeactive ? this.activeRegionRecord : null}
				  pagination={true}
				/>
				</>
			  ) : (
				<MyEmpty />
			  )}
		</CustomModal>
		
		<CustomModal
          key={`modal-form-insight${request._id}`}
          status={cmodal_course}
          title="Manage Course Region"
          close={this.close_modal_course}
          className="modal-form-insight"
        >
		
		<FormContainer>
		<Row>
				  <Col span={12}>
					<FormSelectGroup
					  id="regionId"
					  name="regionId"
					  label="Region"
					  placeholder="Region"
					  //value={request.regionId}
					  data={this.state}
					  required
					  options={region}
					  option_value="_id"
					  option_text="region_name"
					  sendValue={this.setRequest}
					  //disabled={!active}
					/>
				  </Col>
		
		{/*<Row>
				  <Col span={24}>
					<FormSelectGroup
					  id="delivery_method"
					  name="delivery_method"
					  label="Delivery Method"
					  placeholder="Delivery Method"
					  //value={dl}
					  data={this.state}
					  required
					  options={delivery_method}
					  option_value="_id"
					  option_text="title"
					  sendValue={this.setRequest}
					  //disabled={!active}
					/>
				  </Col>
				</Row>
			<Row>
				  <Col span={4}>
					<FormInputGroup
					  id="fees"
					  name="fees"
					  label="Fees"
					  placeholder="Fees"
					  data={this.state}
					  sendValue={this.setRequest}
					  required
					  //disabled={!active}
					/>
				  </Col>
				  <Col span={6}>
					<FormInputGroup
					  type="number"
					  id="duration"
					  name="duration"
					  label="Duration (In Days)"
					  placeholder="Duration"
					  data={this.state}
					  sendValue={this.setRequest}
					  required
					  //disabled={!active}
					/>
				  </Col>
				  <Col span={12}>
					<FormInputGroup
					  type="number"
					  id="duration_hours"
					  name="duration_hours"
					  label="Duration (In Hours)"
					  placeholder="Duration"
					  data={this.state}
					  sendValue={this.setRequest}
					  required
					  //disabled={!active}
					/>
				  </Col>
				  </Row>
			*/}
				  
			
				  <Col span={12}>
					<FormMultiSelectGroup
					  id="releated_course"
					  name="releated_course"
					  label="Related Course"
					  placeholder="Related Course"
					  value={releated_course}
					  data={this.state}
					  sub_label="(Optional)"
					  options={records}
					  option_value="_id"
					  option_text="main_head"
					  sendValue={this.setRequest}
					  //disabled={!active}
					/>
				  </Col>
				  <Col span={12}>
				  <FormInputGroup
						  id="course_name"
						  name="course_name"
						  label="Course Name"
						  placeholder="Course Name"
						  required
						  data={this.state}   
						  sendValue={this.setRequest}
						  
						/>

				  </Col>
				  <Col span={12}>
					<FormCheckboxSingleGroup
					  id="is_featured"
					  name="is_featured"
					  label="Is Featured"
					  sub_label="(Optional)"
					  defaultValue={is_featured}
					  options={{ text: "Yes", value: "yes" }}
					  option_value="value"
					  option_text="text"
					  sendValue={this.setRequest}
					  //disabled={!active}
					/>
				  </Col>
				  
				  </Row>
				  
				
				<FormTextareaGroup
				  id="short_description"
				  name="short_description"
				  label="Short Description"
				  sub_label="(Optional)"
				  placeholder="Short Description"
				  data={this.state}
				  sendValue={this.setRequest}
				  
				/>
				<FormTextEditorGroup
				  id="description"
				  name="description"
				  label="Description"
				  sub_label="(Optional)"
				  placeholder="Description"
				  data={this.state}
				  sendValue={this.setRequest}
				  
				  
				/>
								
				<FormTextEditorGroup
				  id="prior_knowledge"
				  name="prior_knowledge"
				  label="Prior Knowledge"
				  sub_label="(Optional)"
				  placeholder="Prior Knowledge"
				  data={this.state}
				  sendValue={this.setRequest}
				/>
				<FormTextEditorGroup
				  id="course_program"
				  name="course_program"
				  label="Course Program"
				  sub_label="(Optional)"
				  placeholder="Course Program"
				  data={this.state}
				  sendValue={this.setRequest}
				/>
				<FormTextEditorGroup
				  id="why_our_course"
				  name="why_our_course"
				  label="Why Our Course"
				  sub_label="(Optional)"
				  placeholder="Why Our Course"
				  data={this.state}
				  sendValue={this.setRequest}
				  
				/>
				<FormTextEditorGroup
				  id="basic_course_requirments"
				  name="basic_course_requirments"
				  label="Basic Course Requirments"
				  sub_label="(Optional)"
				  placeholder="Basic Course Requirments"
				  data={this.state}
				  sendValue={this.setRequest}
				/>
				<FormTextEditorGroup
				  id="virtual_technology_requirments"
				  name="virtual_technology_requirments"
				  label="Virtual Technology Requirments"
				  sub_label="(Optional)"
				  placeholder="Virtual Technology Requirments"
				  data={this.state}
				  sendValue={this.setRequest}
				/>
				
				<FormButton
				  submit={() => {
					//check validation status here
					if(request.courseId)
					{
						this.editNewCourse();
					}else{
						this.addNewCourse();
					}
					
				  }}
				  //reset={() => this.setState({ request: {} })}
				  cancel={this.close_modal}
				/>
				</FormContainer>
		</CustomModal>

    {/* model for bulk upload */}
    <CustomModal
          key="bulkUpload"
          status={modelBulkUpload}
          title="Bulk upload"
          close={() => {
            this.setState({
            modelBulkUpload: false,
            bulkUploadDone:false,
            processRow:0,
            issueRows:0,
            uploadingIssues:[]
          })
          this.reloadPageData()
        }}
          className="modal-form-course"
        >
             <div style={{padding:10}}><a href={`${PORTAL.api_url}bulk_upload_course_excel_sample`} target="_blank"><button disabled={this.state.uploadingLoader} className="btn btn-sm btn-primary float-right">Download Sample File</button></a></div>
             <div style={{paddingBottom: "50px",paddingTop: "30px"}}>
              {/* {!this.state.uploadingLoader ?  */}
              <Dragger {...{
                         name: 'file',
                         multiple: false,
                         action: `${bulk_upload_api}/course`,
                         showUploadList:false,
                         onChange : (info) => this.bulkFileOneChange(info),
                         onDrop : (e) => {
                           console.log('Dropped files', e.dataTransfer.files);
                         },
                         disabled: this.state.uploadingLoader
                 }}>
                  {!this.state.uploadingLoader ?
                  <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </> : <MyLoading /> }
              </Dragger>
              {/* : <MyLoading />} */}
              </div> 
              {this.state.bulkUploadDone ?
              <div className="upload-report" style={{paddingBottom:20}}>
                <p className="text-success"><b>Successfully Process Rows: {this.state.processRow}</b></p>
                <p className="text-danger"><b>Error occure : {this.state.issueRows}</b></p>
                <p className="text-danger"><b>Issues: </b>{
                this.state.uploadingIssues.length >= 1 ? <ul>{
                this.state.uploadingIssues.map( rec => {
                  return <li> Row {rec.index} Issue: {rec.problem}</li>
                })}</ul> : "-"}</p>
              </div> : null }
    </CustomModal>

    {/* model for Region bulk upload */}
    <CustomModal
          key="regionListbulkupload"
          status={modelRegionBulkUpload}
          title="Region Bulk upload"
          close={() => {
            this.setState({
            modelRegionBulkUpload: false,
            bulkUploadDone:false,
            processRow:0,
            issueRows:0,
            uploadingIssues:[],
            
          })
          this.reloadPageData()
        }}
          className="modal-form-course"
        >
             <div style={{padding:10}}><a href={`${PORTAL.api_url}region_bulk_upload_sample`} target="_blank"><button disabled={this.state.uploadingLoader} className="btn btn-sm btn-primary float-right">Download Sample File</button></a></div>
             <div style={{paddingBottom: "50px",paddingTop: "30px"}}>
              {/* {!this.state.uploadingLoader ?  */}
              <Dragger {...{
                         name: 'file',
                         multiple: false,
                         action: `${bulk_upload_api}/course/region`,
                         showUploadList:false,
                         onChange : (info) => this.bulkFileOneChange(info),
                         onDrop : (e) => {
                           console.log('Dropped files', e.dataTransfer.files);
                         },
                         disabled: this.state.uploadingLoader
                 }}>
                  {!this.state.uploadingLoader ?
                  <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </> : <MyLoading /> }
              </Dragger>
              {/* : <MyLoading />} */}
              </div> 
              {this.state.bulkUploadDone ?
              <div className="upload-report" style={{paddingBottom:20}}>
                <p className="text-success"><b>Successfully Process Rows: {this.state.processRow}</b></p>
                <p className="text-danger"><b>Error occure : {this.state.issueRows}</b></p>
                <p className="text-danger"><b>Issues: </b>{
                this.state.uploadingIssues.length >= 1 ? <ul>{
                this.state.uploadingIssues.map( rec => {
                  return <li> Row {rec.index} Issue: {rec.problem}</li>
                })}</ul> : "-"}</p>
              </div> : null }
    </CustomModal>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
    role_modules: state.role_modules
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Course);
