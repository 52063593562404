import React from "react";
import { Col } from "antd";
function FormLabel2(props) {
  const { emailExists, mobileExists } = props;
  return (
    <Col span={24} className="text-left ">
      <label style={{ color: "#f00", fontWeight: "bold" }}>
        {emailExists ? "Email Already Exists" : ""}
        {mobileExists ? "Mobile Already Exists" : ""}
      </label>
    </Col>
  );
}
export default FormLabel2;
