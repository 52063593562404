import React from "react";
import { Empty, Button } from "antd";
function MyEmpty(props) {
  const { onClick, message } = props;
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{ height: 60 }}
      description={<span>{message ? message : "No Data"}</span>}
    >
      {onClick ? (
        <Button type="primary" onClick={onClick}>
          Create Now
        </Button>
      ) : (
        ""
      )}
    </Empty>
  );
}
export default MyEmpty;
