import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";
export class FormDate extends Component {
  format = "DD/MM/YYYY";
  componentDidMount() {
    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, "0");
    // var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // var yyyy = today.getFullYear();
    // today = dd + "/" + mm + "/" + yyyy;
    // console.log("today ", today);
    // const { name, data, sendValue, setValidation } = this.props;
    // const { request, validation_info } = data;
    // const valMet = validation_info[name]
    //   ? validation_info[name].valMet
    //   : undefined;
    // if (request[name]) sendValue({ name, value: request[name] });
    // else sendValue({ name, value: today });
    // if (valMet) setValidation({ name, res: true });
  }
  render() {
    const {
      disabled,
      name,
      placeholder,
      inputClassName, // css class
      sendValue, //method
      setValidation, // method
      data,
      showToday, //display current date if true
    } = this.props;

    let {
      disableDateBefore, //"2024-12-31"
      disableDateAfter, //"2024-12-31"
    } = this.props;
    const { validation, showErr, request, validation_info } = data;
    let valMet;
    let valLen;
    if (validation_info) {
      valMet = validation_info[name] ? validation_info[name].valMet : undefined;
      valLen = validation_info[name] ? validation_info[name].valLen : undefined;
    }
    let defaultValue = "";
    const local_props = {};
    if (request[name]) defaultValue = moment(request[name], this.format);
    else if (showToday) defaultValue = moment(moment(), this.format);

    if (disableDateBefore)
      disableDateBefore = moment(disableDateBefore, this.format);
    if (disableDateAfter)
      disableDateAfter = moment(disableDateAfter, this.format);
    return (
      <>
        <DatePicker
          disabled={disabled}
          key={request[name]}
          defaultValue={defaultValue}
          {...local_props}
          format={this.format}
          style={{ width: "100%" }}
          // defaultPickerValue={moment()}
          onChange={(value) => {
            const date = new Date(value);
            let month = date.getMonth() + 1;
            const year = date.getFullYear();
            let day = date.getDate();
            if (day < 10) day = "0" + day;
            if (month < 10) month = "0" + month;
            value = `${day}/${month}/${year}`;
            if (valMet) {
              const error = valMet({ value, valLen });
              if (error) setValidation({ name, res: false, msg: error });
              else setValidation({ name, res: true });
            }
            sendValue({ name, value });
          }}
          // peekNextMonth
          // showMonthDropdown
          // showYearDropdown
          // dropdownMode="select"
          className={`${inputClassName} ${
            showErr && validation[name] && validation[name].msg
              ? "validation-error"
              : ""
          }`}
          placeholder={placeholder}
          disabledDate={(d) => {
            if (disableDateAfter) return d.isAfter(disableDateAfter);
            else if (disableDateBefore) return d.isBefore(disableDateBefore);
            else return !d;
            //   !d || d.isAfter("2024-12-31") || d.isSameOrBefore(disableDateBefore)
          }}
        />
      </>
    );
  }
}
export default FormDate;
