import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { exam_template_assesment_api } from "../../library/exam_template_assesment_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import { get_data } from "../server_data/get_data";
import AddNew from "../part/AddNew";
import { myAxios } from "../../library/networkFunction";
import Exam_assesment_test_list from "./Exam_assesment_test_list";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import { permission_api } from "../../library/permission_api";

// ===============================================================================
class Evaluation_templates_ass_copy extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: exam_template_assesment_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        exam_name: { valMet: vj_val.checkLength, valLen: 1 },
        exam_scheme: { valMet: vj_val.checkLength, valLen: 1 },
        exam_category: { valMet: vj_val.checkLength, valLen: 1 },
        exam_course_type: { valMet: vj_val.checkLength, valLen: 1 },
        archived: { valMet: vj_val.checkLength, valLen: 1 },
        total: { valMet: vj_val.checkNumber, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      seomodal: false,
      popularcourse: false,
      popularbundle: false,
      trustedby: false,
      faq: false,
      inputs: [],
      activeKey: "1",
      learning_scheme:[],
      course_category:[],
      course_type:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
    // this.updateState = this.updateState.bind(this);
    // this.close = this.close.bind(this);
  }


  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    _id: {
      title: "Exam ID",
      // width: 300,
      className: "_id",
      sorter: true,
      // ellipsis: true,
      order: 1,
    },
    exam_name: {
      title: "Exam name",
      width: 400,
      className: "exam_name",
      sorter: true,
      // ellipsis: true,
      order: 2,
    },
    archived: {
      title: "Archived",
      width: 100,
      className: "archived",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    active: {
      title: "Active",
      width: 150,
      className: "active",
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
          if (text)
            return <span className="text-success font-weight-bold">Active</span>;
          return <span className="text-danger font-weight-bold">Deactive</span>;
        },
      order: 4,
    },
  };


  componentDidMount = async () => {
    console.clear()
    const { Chabi } = this.state;
    //============= define permission =============
      let { loggedUser } = this.props
     if(loggedUser.roleId){
      const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"exam";
		  let modulePermission = await myAxios({
		    method: "get",
		    url,
		    header: { Chabi },
		  });
     if(modulePermission.status == 200){
          if(modulePermission.data.records.length >= 1){
            console.log("ROLE APPLIED")
            let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
            this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
          }else{
            console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
          }
     }else{
      console.log("PERMISSION API CRASHED")
     }
     }

    //=============================================

    const url = exam_template_assesment_api+"get";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
    });
    console.log(response);
    let { records } = response.data;
    records = add_sno(records);

    this.setState({
      records,
    });
    this.setState({ CDM: true});

    const responseDropDown = await get_data({
      others: [
        "learning_scheme",
        "course_category",
        "course_type",
        "language",
        "accreditation",
        "delivery_method",
        "region",
      ],
      primary: "course",
      Chabi,
    });
    if (responseDropDown.status == 200) {
      const { data } = responseDropDown;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
         
          learning_scheme,
          course_category,
          course_type,
          language,
      
          region,
        } = data;

        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course_category2 = create_object({
          arr: course_category,
          title: "main_head",
        });
        let course_type2 = create_object({
          arr: course_type,
          title: "title",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });

        this.setState({
          learning_scheme,
          learning_scheme2,
          course_category,
          course_category2,
          course_type,
          course_type2,
          language,
          language2,

        });
      }
    } else console.log("Network Error");

  };

  pageReload = async () => {
    const { Chabi, id } = this.state;
    //============= define permission =============
    let { loggedUser } = this.props
    if(loggedUser.roleId){
     const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"exam";
     let modulePermission = await myAxios({
       method: "get",
       url,
       header: { Chabi },
     });
    if(modulePermission.status == 200){
         if(modulePermission.data.records.length >= 1){
           console.log("ROLE APPLIED")
           let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
           this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
         }else{
           console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
         }
    }else{
     console.log("PERMISSION API CRASHED")
    }
    }

   //=============================================

    const url1 = exam_template_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url:url1,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
  }
  
  addPartner = async () => {

    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = exam_template_assesment_api + "create";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });

    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReload()
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };

  editPartner = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    let { _id} = request
    delete request['sno']
    delete request['removed']
    delete request['active']
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = exam_template_assesment_api + "update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            // rec["location_name"] = location_name;
            // rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = exam_template_assesment_api +"status";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  switchHandlerActiveDeactiveSubscriber = (e) => {
      console.log(e)
  }
  
  render() {
    let {
      records,
      loginStatus,
      edit,
      cmodal,
      request,
      filtered_records,
      region,
      seomodal,
      popularcourse,
      course,
      CDM,
      learning_scheme,
      course_category,
      course_type,
      permission
    } = this.state;
    console.log(
      "🚀 ~ file: Evaluation_templates.js ~ line 96 ~ Evaluation_templates ~ render ~ records",
      records
    );


    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    if(permission.childModule && permission.childModule?.testList){
    columns.push({
      width: 150,
      title: "Tests",
      dataIndex: "tests",
      render: (text, record) => {
        console.log(
          "🚀 ~ file: Evaluation_templates.js ~ line 190 ~ Evaluation_templates ~ render ~ record",
          record._id
        );
        return (
          <button
          className="btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ popularcourse: record });
              this.setState({ id: record._id });
              this.setState({ _id: record._id });
            }}
          >
            Test List
          </button>
        );
      },
    });
  }
   
    let filter_region = [];
    let selected_region_info;
    if (request.course_name) {
      selected_region_info = course.filter((ls) => {
        return ls._id == request.course_name;
      })[0]["region_info"];
      const selected_region = Object.keys(selected_region_info).filter(
        (sri) => selected_region_info[sri].active
      );
      filter_region = region.filter((r1) => {
        return selected_region.includes(r1._id);
      });
    }
    return (
      <>
        <PageHeading2 active="Exam Templates" page={["Exam Templates"]}>
        {permission.create ? 
          <AddNew
            // disabled={!CDM}
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          />
          : null }
        </PageHeading2>
        <MainArea>
        {!CDM ? (
            <MyLoading />
          ) :records && records.length && columns && columns.length > 0 ? (
          <MyDataTable
            rowKey="_id"
            columns={columns}
            dataSource={records}
            loadData={permission.update ? this.loadRecord : null}
            removeData={permission.itemRemoved ? this.removeRecord : null}
            activeData={permission.activeDeactive ? this.activeRecord : null}
            pagination={true}
          />
          ): (
            <MyEmpty />
          )}
        </MainArea>
      
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Exam"
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="exam_name"
              name="exam_name"
              label="Exam Name"
              placeholder="Exam Name"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
           <div className="col-12 col-sm-6 col-md-6 col-lg-6">
             <FormSelectGroup
               allowClear
               id="exam_scheme"
               name="exam_scheme"
               label="Scheme"
               placeholder="Scheme"
               data={this.state}
               required
               options={learning_scheme}
               option_value="_id"
               option_text="main_head"
               setValidation={this.setValidation}
               sendValue={this.setRequest}
             />
 </div>
           <div className="col-12 col-sm-6 col-md-6 col-lg-6">
               <FormSelectGroup
                  allowClear
                  id="exam_category"
                  name="exam_category"
                  label="Category"
                  placeholder="Category"
                  data={this.state}
                  required
                  options={
                    request.exam_scheme
                      ? course_category.filter(
                          (cc) => cc.learning_scheme == request.exam_scheme
                        )
                      : []
                  }
                  option_value="_id"
                  option_text="main_head"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
 </div>
           <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <FormSelectGroup
                      allowClear
                      id="exam_course_type"
                      name="exam_course_type"
                      label="Course Type"
                      placeholder="Course Type"
                      data={this.state}
                      required
                      options={course_type}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
 </div>
           <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormSelectGroup
              id="archived"
              name="archived"
              label="Archived"
              placeholder="Archived"
              data={this.state}required
              options={[{ value: 'Yes' }, {  value: 'No' }]}
              option_value="value"
              option_text="value"
              // valMet={checkUuid}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
             </div>
           <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="total"
              name="total"
              label="Total"
              placeholder="Total"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
            </div>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editPartner();
                else this.addPartner();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>

        </CustomModal>
      

        {popularcourse ? (
          <div className="popularbundle-modal position-fixed top-0 bottom-0 left-0 right-0 d-flex justify-content-center align-items-center">
            {/* <button
              className="modalclose"
              onClick={() => {
                this.setState({ popularcourse: false });
              }}
              >
              X
            </button> */}
            <Exam_assesment_test_list
              onClose={() => {
                this.setState({ popularcourse: null,cmodal:false });
              }}
              permission={permission.childModule}
              popularcourse={this.state.popularcourse}
              id={this.state.id}
            />
          </div>
        ) : (
          ""
        )}

        <style jsx global>{`
          .trustedby-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
        `}</style>
   
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Evaluation_templates_ass_copy);
