import React from "react";
import { Radio } from "antd";
function FormRadio(props) {
  const {
    name,
    sendValue, //method
    setValidation, // method
    data,
    options,
    option_value,
    option_text,
    defaultValue,
  } = props;
  const { request, validation_info } = data;
  let valMet;
  if (validation_info)
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
  const local_props = {};
  if (typeof request[name] != "undefined") local_props["value"] = request[name];
  else if (defaultValue) {
    local_props["value"] = defaultValue[option_value];
    sendValue({ name, value: local_props["value"] });
    if (valMet) setValidation({ name, res: true });
  }
  return (
    <>
      <Radio.Group
        onChange={(e) => {
          const value = e.target.value;
          if (valMet) {
            const error = valMet(value);
            if (error) setValidation({ name, res: false, msg: error });
            else setValidation({ name, res: true });
          }
          sendValue({ name, value });
        }}
        {...local_props}
      >
        {options.map((op) => {
          return (
            <Radio className="ml-2" value={op[option_value]}>
              {op[option_text]}
            </Radio>
          );
        })}
      </Radio.Group>
    </>
  );
}
export default FormRadio;
