import React, { Component } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./form-image-upload.module.css";
export class FormFileUpload extends Component {
  constructor(props) {
    super(props);
  }
  handleChange = async (obj) => {
    const { fileUpload } = this.props;
    const { fileList } = obj;
    fileList.forEach((fl) => {
      fileUpload(fl);
    });
  };
  removeFile = (file) => {
    const { data, changeUploadFileList } = this.props;
    let { uploadFileList } = data;
    uploadFileList = uploadFileList.filter((f) => f.uid != file.uid);
    changeUploadFileList(uploadFileList);
  };
  componentDidMount = () => {
    const { name, data, src, changeUploadFileList } = this.props;
    const { request } = data;
    if (request[name]) {
      const prevalue = request[name];
      changeUploadFileList([
        {
          uid: "-1",
          name: prevalue,
          status: "done",
          url: src + prevalue,
        },
      ]);
    }
  };
  render() {
    const { name, data, value, FileCount, accept } = this.props;
    const { request, uploadFileList } = data;
    let prevalue = "";
    if (value) prevalue = value;
    else if (request && request[name]) prevalue = request[name];
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        key={uploadFileList.length}
        accept={accept}
        beforeUpload={() => false}
        listType="picture-card"
        fileList={uploadFileList}
        onPreview={this.handlePreview}
        onChange={this.handleChange}
        onRemove={this.removeFile}
      >
        {uploadFileList.length >= FileCount ? null : uploadButton}
      </Upload>
    );
  }
}
export default FormFileUpload;
