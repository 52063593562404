export const language_obj = {
  af: "Afrikaans",
  sq: "Albanian",
  an: "Aragonese",
  ar: "Arabic (Standard)",
  "ar-dz": "Arabic (Algeria)",
  "ar-bh": "Arabic (Bahrain)",
  "ar-eg": "Arabic (Egypt)",
  "ar-iq": "Arabic (Iraq)",
  "ar-jo": "Arabic (Jordan)",
  "ar-kw": "Arabic (Kuwait)",
  "ar-lb": "Arabic (Lebanon)",
  "ar-ly": "Arabic (Libya)",
  "ar-ma": "Arabic (Morocco)",
  "ar-om": "Arabic (Oman)",
  "ar-qa": "Arabic (Qatar)",
  "ar-sa": "Arabic (Saudi Arabia)",
  "ar-sy": "Arabic (Syria)",
  "ar-tn": "Arabic (Tunisia)",
  "ar-ae": "Arabic (U.A.E.)",
  "ar-ye": "Arabic (Yemen)",
  hy: "Armenian",
  as: "Assamese",
  ast: "Asturian",
  az: "Azerbaijani",
  eu: "Basque",
  bg: "Bulgarian",
  be: "Belarusian",
  bn: "Bengali",
  bs: "Bosnian",
  br: "Breton",
  bg: "Bulgarian",
  my: "Burmese",
  ca: "Catalan",
  ch: "Chamorro",
  ce: "Chechen",
  zh: "Chinese",
  "zh-hk": "Chinese (Hong Kong)",
  "zh-cn": "Chinese (PRC)",
  "zh-sg": "Chinese (Singapore)",
  "zh-tw": "Chinese (Taiwan)",
  cv: "Chuvash",
  co: "Corsican",
  cr: "Cree",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch (Standard)",
  "nl-be": "Dutch (Belgian)",
  en: "English",
  "en-au": "English (Australia)",
  "en-bz": "English (Belize)",
  "en-ca": "English (Canada)",
  "en-ie": "English (Ireland)",
  "en-jm": "English (Jamaica)",
  "en-nz": "English (New Zealand)",
  "en-ph": "English (Philippines)",
  "en-za": "English (South Africa)",
  "en-tt": "English (Trinidad & Tobago)",
  "en-gb": "English (United Kingdom)",
  "en-us": "English (United States)",
  "en-zw": "English (Zimbabwe)",
  eo: "Esperanto",
  et: "Estonian",
  fo: "Faeroese",
  fa: "Farsi",
  fj: "Fijian",
  fi: "Finnish",
  fr: "French (Standard)",
  "fr-be": "French (Belgium)",
  "fr-ca": "French (Canada)",
  "fr-fr": "French (France)",
  "fr-lu": "French (Luxembourg)",
  "fr-mc": "French (Monaco)",
  "fr-ch": "French (Switzerland)",
  fy: "Frisian",
  fur: "Friulian",
  gd: "Gaelic (Scots)",
  "gd-ie": "Gaelic (Irish)",
  gl: "Galacian",
  ka: "Georgian",
  de: "German (Standard)",
  "de-at": "German (Austria)",
  "de-de": "German (Germany)",
  "de-li": "German (Liechtenstein)",
  "de-lu": "German (Luxembourg)",
  "de-ch": "German (Switzerland)",
  el: "Greek",
  gu: "Gujurati",
  ht: "Haitian",
  he: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  iu: "Inuktitut",
  ga: "Irish",
  it: "Italian (Standard)",
  "it-ch": "Italian (Switzerland)",
  ja: "Japanese",
  kn: "Kannada",
  ks: "Kashmiri",
  kk: "Kazakh",
  km: "Khmer",
  ky: "Kirghiz",
  tlh: "Klingon",
  ko: "Korean",
  "ko-kp": "Korean (North Korea)",
  "ko-kr": "Korean (South Korea)",
  la: "Latin",
  lv: "Latvian",
  lt: "Lithuanian",
  lb: "Luxembourgish",
  mk: "FYRO Macedonian",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  mi: "Maori",
  mr: "Marathi",
  mo: "Moldavian",
  nv: "Navajo",
  ng: "Ndonga",
  ne: "Nepali",
  no: "Norwegian",
  nb: "Norwegian (Bokmal)",
  nn: "Norwegian (Nynorsk)",
  oc: "Occitan",
  or: "Oriya",
  om: "Oromo",
  fa: "Persian",
  "fa-ir": "Persian/Iran",
  pl: "Polish",
  pt: "Portuguese",
  "pt-br": "Portuguese (Brazil)",
  pa: "Punjabi",
  "pa-in": "Punjabi (India)",
  "pa-pk": "Punjabi (Pakistan)",
  qu: "Quechua",
  rm: "Rhaeto-Romanic",
  ro: "Romanian",
  "ro-mo": "Romanian (Moldavia)",
  ru: "Russian",
  "ru-mo": "Russian (Moldavia)",
  sz: "Sami (Lappish)",
  sg: "Sango",
  sa: "Sanskrit",
  sc: "Sardinian",
  gd: "Scots Gaelic",
  sd: "Sindhi",
  si: "Singhalese",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somani",
  sb: "Sorbian",
  es: "Spanish",
  "es-ar": "Spanish (Argentina)",
  "es-bo": "Spanish (Bolivia)",
  "es-cl": "Spanish (Chile)",
  "es-co": "Spanish (Colombia)",
  "es-cr": "Spanish (Costa Rica)",
  "es-do": "Spanish (Dominican Republic)",
  "es-ec": "Spanish (Ecuador)",
  "es-sv": "Spanish (El Salvador)",
  "es-gt": "Spanish (Guatemala)",
  "es-hn": "Spanish (Honduras)",
  "es-mx": "Spanish (Mexico)",
  "es-ni": "Spanish (Nicaragua)",
  "es-pa": "Spanish (Panama)",
  "es-py": "Spanish (Paraguay)",
  "es-pe": "Spanish (Peru)",
  "es-pr": "Spanish (Puerto Rico)",
  "es-es": "Spanish (Spain)",
  "es-uy": "Spanish (Uruguay)",
  "es-ve": "Spanish (Venezuela)",
  sx: "Sutu",
  sw: "Swahili",
  sv: "Swedish",
  "sv-fi": "Swedish (Finland)",
  "sv-sv": "Swedish (Sweden)",
  ta: "Tamil",
  tt: "Tatar",
  te: "Teluga",
  th: "Thai",
  tig: "Tigre",
  ts: "Tsonga",
  tn: "Tswana",
  tr: "Turkish",
  tk: "Turkmen",
  uk: "Ukrainian",
  hsb: "Upper Sorbian",
  ur: "Urdu",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapuk",
  wa: "Walloon",
  cy: "Welsh",
  xh: "Xhosa",
  ji: "Yiddish",
  zu: "Zulu",
};
export const language_array = [
  {
    language_code: "af",
    country_name: "Afrikaans",
  },
  {
    language_code: "sq",
    country_name: "Albanian",
  },
  {
    language_code: "an",
    country_name: "Aragonese",
  },
  {
    language_code: "ar",
    country_name: "Arabic (Standard)",
  },
  {
    language_code: "ar-dz",
    country_name: "Arabic (Algeria)",
  },
  {
    language_code: "ar-bh",
    country_name: "Arabic (Bahrain)",
  },
  {
    language_code: "ar-eg",
    country_name: "Arabic (Egypt)",
  },
  {
    language_code: "ar-iq",
    country_name: "Arabic (Iraq)",
  },
  {
    language_code: "ar-jo",
    country_name: "Arabic (Jordan)",
  },
  {
    language_code: "ar-kw",
    country_name: "Arabic (Kuwait)",
  },
  {
    language_code: "ar-lb",
    country_name: "Arabic (Lebanon)",
  },
  {
    language_code: "ar-ly",
    country_name: "Arabic (Libya)",
  },
  {
    language_code: "ar-ma",
    country_name: "Arabic (Morocco)",
  },
  {
    language_code: "ar-om",
    country_name: "Arabic (Oman)",
  },
  {
    language_code: "ar-qa",
    country_name: "Arabic (Qatar)",
  },
  {
    language_code: "ar-sa",
    country_name: "Arabic (Saudi Arabia)",
  },
  {
    language_code: "ar-sy",
    country_name: "Arabic (Syria)",
  },
  {
    language_code: "ar-tn",
    country_name: "Arabic (Tunisia)",
  },
  {
    language_code: "ar-ae",
    country_name: "Arabic (U.A.E.)",
  },
  {
    language_code: "ar-ye",
    country_name: "Arabic (Yemen)",
  },
  {
    language_code: "hy",
    country_name: "Armenian",
  },
  {
    language_code: "as",
    country_name: "Assamese",
  },
  {
    language_code: "ast",
    country_name: "Asturian",
  },
  {
    language_code: "az",
    country_name: "Azerbaijani",
  },
  {
    language_code: "eu",
    country_name: "Basque",
  },
  {
    language_code: "bg",
    country_name: "Bulgarian",
  },
  {
    language_code: "be",
    country_name: "Belarusian",
  },
  {
    language_code: "bn",
    country_name: "Bengali",
  },
  {
    language_code: "bs",
    country_name: "Bosnian",
  },
  {
    language_code: "br",
    country_name: "Breton",
  },
  {
    language_code: "my",
    country_name: "Burmese",
  },
  {
    language_code: "ca",
    country_name: "Catalan",
  },
  {
    language_code: "ch",
    country_name: "Chamorro",
  },
  {
    language_code: "ce",
    country_name: "Chechen",
  },
  {
    language_code: "zh",
    country_name: "Chinese",
  },
  {
    language_code: "zh-hk",
    country_name: "Chinese (Hong Kong)",
  },
  {
    language_code: "zh-cn",
    country_name: "Chinese (PRC)",
  },
  {
    language_code: "zh-sg",
    country_name: "Chinese (Singapore)",
  },
  {
    language_code: "zh-tw",
    country_name: "Chinese (Taiwan)",
  },
  {
    language_code: "cv",
    country_name: "Chuvash",
  },
  {
    language_code: "co",
    country_name: "Corsican",
  },
  {
    language_code: "cr",
    country_name: "Cree",
  },
  {
    language_code: "hr",
    country_name: "Croatian",
  },
  {
    language_code: "cs",
    country_name: "Czech",
  },
  {
    language_code: "da",
    country_name: "Danish",
  },
  {
    language_code: "nl",
    country_name: "Dutch (Standard)",
  },
  {
    language_code: "nl-be",
    country_name: "Dutch (Belgian)",
  },
  {
    language_code: "en",
    country_name: "English",
  },
  {
    language_code: "en-au",
    country_name: "English (Australia)",
  },
  {
    language_code: "en-bz",
    country_name: "English (Belize)",
  },
  {
    language_code: "en-ca",
    country_name: "English (Canada)",
  },
  {
    language_code: "en-ie",
    country_name: "English (Ireland)",
  },
  {
    language_code: "en-jm",
    country_name: "English (Jamaica)",
  },
  {
    language_code: "en-nz",
    country_name: "English (New Zealand)",
  },
  {
    language_code: "en-ph",
    country_name: "English (Philippines)",
  },
  {
    language_code: "en-za",
    country_name: "English (South Africa)",
  },
  {
    language_code: "en-tt",
    country_name: "English (Trinidad & Tobago)",
  },
  {
    language_code: "en-gb",
    country_name: "English (United Kingdom)",
  },
  {
    language_code: "en-us",
    country_name: "English (United States)",
  },
  {
    language_code: "en-zw",
    country_name: "English (Zimbabwe)",
  },
  {
    language_code: "eo",
    country_name: "Esperanto",
  },
  {
    language_code: "et",
    country_name: "Estonian",
  },
  {
    language_code: "fo",
    country_name: "Faeroese",
  },
  {
    language_code: "fa",
    country_name: "Persian",
  },
  {
    language_code: "fj",
    country_name: "Fijian",
  },
  {
    language_code: "fi",
    country_name: "Finnish",
  },
  {
    language_code: "fr",
    country_name: "French (Standard)",
  },
  {
    language_code: "fr-be",
    country_name: "French (Belgium)",
  },
  {
    language_code: "fr-ca",
    country_name: "French (Canada)",
  },
  {
    language_code: "fr-fr",
    country_name: "French (France)",
  },
  {
    language_code: "fr-lu",
    country_name: "French (Luxembourg)",
  },
  {
    language_code: "fr-mc",
    country_name: "French (Monaco)",
  },
  {
    language_code: "fr-ch",
    country_name: "French (Switzerland)",
  },
  {
    language_code: "fy",
    country_name: "Frisian",
  },
  {
    language_code: "fur",
    country_name: "Friulian",
  },
  {
    language_code: "gd",
    country_name: "Scots Gaelic",
  },
  {
    language_code: "gd-ie",
    country_name: "Gaelic (Irish)",
  },
  {
    language_code: "gl",
    country_name: "Galacian",
  },
  {
    language_code: "ka",
    country_name: "Georgian",
  },
  {
    language_code: "de",
    country_name: "German (Standard)",
  },
  {
    language_code: "de-at",
    country_name: "German (Austria)",
  },
  {
    language_code: "de-de",
    country_name: "German (Germany)",
  },
  {
    language_code: "de-li",
    country_name: "German (Liechtenstein)",
  },
  {
    language_code: "de-lu",
    country_name: "German (Luxembourg)",
  },
  {
    language_code: "de-ch",
    country_name: "German (Switzerland)",
  },
  {
    language_code: "el",
    country_name: "Greek",
  },
  {
    language_code: "gu",
    country_name: "Gujurati",
  },
  {
    language_code: "ht",
    country_name: "Haitian",
  },
  {
    language_code: "he",
    country_name: "Hebrew",
  },
  {
    language_code: "hi",
    country_name: "Hindi",
  },
  {
    language_code: "hu",
    country_name: "Hungarian",
  },
  {
    language_code: "is",
    country_name: "Icelandic",
  },
  {
    language_code: "id",
    country_name: "Indonesian",
  },
  {
    language_code: "iu",
    country_name: "Inuktitut",
  },
  {
    language_code: "ga",
    country_name: "Irish",
  },
  {
    language_code: "it",
    country_name: "Italian (Standard)",
  },
  {
    language_code: "it-ch",
    country_name: "Italian (Switzerland)",
  },
  {
    language_code: "ja",
    country_name: "Japanese",
  },
  {
    language_code: "kn",
    country_name: "Kannada",
  },
  {
    language_code: "ks",
    country_name: "Kashmiri",
  },
  {
    language_code: "kk",
    country_name: "Kazakh",
  },
  {
    language_code: "km",
    country_name: "Khmer",
  },
  {
    language_code: "ky",
    country_name: "Kirghiz",
  },
  {
    language_code: "tlh",
    country_name: "Klingon",
  },
  {
    language_code: "ko",
    country_name: "Korean",
  },
  {
    language_code: "ko-kp",
    country_name: "Korean (North Korea)",
  },
  {
    language_code: "ko-kr",
    country_name: "Korean (South Korea)",
  },
  {
    language_code: "la",
    country_name: "Latin",
  },
  {
    language_code: "lv",
    country_name: "Latvian",
  },
  {
    language_code: "lt",
    country_name: "Lithuanian",
  },
  {
    language_code: "lb",
    country_name: "Luxembourgish",
  },
  {
    language_code: "mk",
    country_name: "FYRO Macedonian",
  },
  {
    language_code: "ms",
    country_name: "Malay",
  },
  {
    language_code: "ml",
    country_name: "Malayalam",
  },
  {
    language_code: "mt",
    country_name: "Maltese",
  },
  {
    language_code: "mi",
    country_name: "Maori",
  },
  {
    language_code: "mr",
    country_name: "Marathi",
  },
  {
    language_code: "mo",
    country_name: "Moldavian",
  },
  {
    language_code: "nv",
    country_name: "Navajo",
  },
  {
    language_code: "ng",
    country_name: "Ndonga",
  },
  {
    language_code: "ne",
    country_name: "Nepali",
  },
  {
    language_code: "no",
    country_name: "Norwegian",
  },
  {
    language_code: "nb",
    country_name: "Norwegian (Bokmal)",
  },
  {
    language_code: "nn",
    country_name: "Norwegian (Nynorsk)",
  },
  {
    language_code: "oc",
    country_name: "Occitan",
  },
  {
    language_code: "or",
    country_name: "Oriya",
  },
  {
    language_code: "om",
    country_name: "Oromo",
  },
  {
    language_code: "fa-ir",
    country_name: "Persian/Iran",
  },
  {
    language_code: "pl",
    country_name: "Polish",
  },
  {
    language_code: "pt",
    country_name: "Portuguese",
  },
  {
    language_code: "pt-br",
    country_name: "Portuguese (Brazil)",
  },
  {
    language_code: "pa",
    country_name: "Punjabi",
  },
  {
    language_code: "pa-in",
    country_name: "Punjabi (India)",
  },
  {
    language_code: "pa-pk",
    country_name: "Punjabi (Pakistan)",
  },
  {
    language_code: "qu",
    country_name: "Quechua",
  },
  {
    language_code: "rm",
    country_name: "Rhaeto-Romanic",
  },
  {
    language_code: "ro",
    country_name: "Romanian",
  },
  {
    language_code: "ro-mo",
    country_name: "Romanian (Moldavia)",
  },
  {
    language_code: "ru",
    country_name: "Russian",
  },
  {
    language_code: "ru-mo",
    country_name: "Russian (Moldavia)",
  },
  {
    language_code: "sz",
    country_name: "Sami (Lappish)",
  },
  {
    language_code: "sg",
    country_name: "Sango",
  },
  {
    language_code: "sa",
    country_name: "Sanskrit",
  },
  {
    language_code: "sc",
    country_name: "Sardinian",
  },
  {
    language_code: "sd",
    country_name: "Sindhi",
  },
  {
    language_code: "si",
    country_name: "Singhalese",
  },
  {
    language_code: "sr",
    country_name: "Serbian",
  },
  {
    language_code: "sk",
    country_name: "Slovak",
  },
  {
    language_code: "sl",
    country_name: "Slovenian",
  },
  {
    language_code: "so",
    country_name: "Somani",
  },
  {
    language_code: "sb",
    country_name: "Sorbian",
  },
  {
    language_code: "es",
    country_name: "Spanish",
  },
  {
    language_code: "es-ar",
    country_name: "Spanish (Argentina)",
  },
  {
    language_code: "es-bo",
    country_name: "Spanish (Bolivia)",
  },
  {
    language_code: "es-cl",
    country_name: "Spanish (Chile)",
  },
  {
    language_code: "es-co",
    country_name: "Spanish (Colombia)",
  },
  {
    language_code: "es-cr",
    country_name: "Spanish (Costa Rica)",
  },
  {
    language_code: "es-do",
    country_name: "Spanish (Dominican Republic)",
  },
  {
    language_code: "es-ec",
    country_name: "Spanish (Ecuador)",
  },
  {
    language_code: "es-sv",
    country_name: "Spanish (El Salvador)",
  },
  {
    language_code: "es-gt",
    country_name: "Spanish (Guatemala)",
  },
  {
    language_code: "es-hn",
    country_name: "Spanish (Honduras)",
  },
  {
    language_code: "es-mx",
    country_name: "Spanish (Mexico)",
  },
  {
    language_code: "es-ni",
    country_name: "Spanish (Nicaragua)",
  },
  {
    language_code: "es-pa",
    country_name: "Spanish (Panama)",
  },
  {
    language_code: "es-py",
    country_name: "Spanish (Paraguay)",
  },
  {
    language_code: "es-pe",
    country_name: "Spanish (Peru)",
  },
  {
    language_code: "es-pr",
    country_name: "Spanish (Puerto Rico)",
  },
  {
    language_code: "es-es",
    country_name: "Spanish (Spain)",
  },
  {
    language_code: "es-uy",
    country_name: "Spanish (Uruguay)",
  },
  {
    language_code: "es-ve",
    country_name: "Spanish (Venezuela)",
  },
  {
    language_code: "sx",
    country_name: "Sutu",
  },
  {
    language_code: "sw",
    country_name: "Swahili",
  },
  {
    language_code: "sv",
    country_name: "Swedish",
  },
  {
    language_code: "sv-fi",
    country_name: "Swedish (Finland)",
  },
  {
    language_code: "sv-sv",
    country_name: "Swedish (Sweden)",
  },
  {
    language_code: "ta",
    country_name: "Tamil",
  },
  {
    language_code: "tt",
    country_name: "Tatar",
  },
  {
    language_code: "te",
    country_name: "Teluga",
  },
  {
    language_code: "th",
    country_name: "Thai",
  },
  {
    language_code: "tig",
    country_name: "Tigre",
  },
  {
    language_code: "ts",
    country_name: "Tsonga",
  },
  {
    language_code: "tn",
    country_name: "Tswana",
  },
  {
    language_code: "tr",
    country_name: "Turkish",
  },
  {
    language_code: "tk",
    country_name: "Turkmen",
  },
  {
    language_code: "uk",
    country_name: "Ukrainian",
  },
  {
    language_code: "hsb",
    country_name: "Upper Sorbian",
  },
  {
    language_code: "ur",
    country_name: "Urdu",
  },
  {
    language_code: "ve",
    country_name: "Venda",
  },
  {
    language_code: "vi",
    country_name: "Vietnamese",
  },
  {
    language_code: "vo",
    country_name: "Volapuk",
  },
  {
    language_code: "wa",
    country_name: "Walloon",
  },
  {
    language_code: "cy",
    country_name: "Welsh",
  },
  {
    language_code: "xh",
    country_name: "Xhosa",
  },
  {
    language_code: "ji",
    country_name: "Yiddish",
  },
  {
    language_code: "zu",
    country_name: "Zulu",
  },
];
const language_obj2 = {
  af: {
    language_code: "af",
    country_name: "Afrikaans",
  },
  sq: {
    language_code: "sq",
    country_name: "Albanian",
  },
  an: {
    language_code: "an",
    country_name: "Aragonese",
  },
  ar: {
    language_code: "ar",
    country_name: "Arabic (Standard)",
  },
  "ar-dz": {
    language_code: "ar-dz",
    country_name: "Arabic (Algeria)",
  },
  "ar-bh": {
    language_code: "ar-bh",
    country_name: "Arabic (Bahrain)",
  },
  "ar-eg": {
    language_code: "ar-eg",
    country_name: "Arabic (Egypt)",
  },
  "ar-iq": {
    language_code: "ar-iq",
    country_name: "Arabic (Iraq)",
  },
  "ar-jo": {
    language_code: "ar-jo",
    country_name: "Arabic (Jordan)",
  },
  "ar-kw": {
    language_code: "ar-kw",
    country_name: "Arabic (Kuwait)",
  },
  "ar-lb": {
    language_code: "ar-lb",
    country_name: "Arabic (Lebanon)",
  },
  "ar-ly": {
    language_code: "ar-ly",
    country_name: "Arabic (Libya)",
  },
  "ar-ma": {
    language_code: "ar-ma",
    country_name: "Arabic (Morocco)",
  },
  "ar-om": {
    language_code: "ar-om",
    country_name: "Arabic (Oman)",
  },
  "ar-qa": {
    language_code: "ar-qa",
    country_name: "Arabic (Qatar)",
  },
  "ar-sa": {
    language_code: "ar-sa",
    country_name: "Arabic (Saudi Arabia)",
  },
  "ar-sy": {
    language_code: "ar-sy",
    country_name: "Arabic (Syria)",
  },
  "ar-tn": {
    language_code: "ar-tn",
    country_name: "Arabic (Tunisia)",
  },
  "ar-ae": {
    language_code: "ar-ae",
    country_name: "Arabic (U.A.E.)",
  },
  "ar-ye": {
    language_code: "ar-ye",
    country_name: "Arabic (Yemen)",
  },
  hy: {
    language_code: "hy",
    country_name: "Armenian",
  },
  as: {
    language_code: "as",
    country_name: "Assamese",
  },
  ast: {
    language_code: "ast",
    country_name: "Asturian",
  },
  az: {
    language_code: "az",
    country_name: "Azerbaijani",
  },
  eu: {
    language_code: "eu",
    country_name: "Basque",
  },
  bg: {
    language_code: "bg",
    country_name: "Bulgarian",
  },
  be: {
    language_code: "be",
    country_name: "Belarusian",
  },
  bn: {
    language_code: "bn",
    country_name: "Bengali",
  },
  bs: {
    language_code: "bs",
    country_name: "Bosnian",
  },
  br: {
    language_code: "br",
    country_name: "Breton",
  },
  my: {
    language_code: "my",
    country_name: "Burmese",
  },
  ca: {
    language_code: "ca",
    country_name: "Catalan",
  },
  ch: {
    language_code: "ch",
    country_name: "Chamorro",
  },
  ce: {
    language_code: "ce",
    country_name: "Chechen",
  },
  zh: {
    language_code: "zh",
    country_name: "Chinese",
  },
  "zh-hk": {
    language_code: "zh-hk",
    country_name: "Chinese (Hong Kong)",
  },
  "zh-cn": {
    language_code: "zh-cn",
    country_name: "Chinese (PRC)",
  },
  "zh-sg": {
    language_code: "zh-sg",
    country_name: "Chinese (Singapore)",
  },
  "zh-tw": {
    language_code: "zh-tw",
    country_name: "Chinese (Taiwan)",
  },
  cv: {
    language_code: "cv",
    country_name: "Chuvash",
  },
  co: {
    language_code: "co",
    country_name: "Corsican",
  },
  cr: {
    language_code: "cr",
    country_name: "Cree",
  },
  hr: {
    language_code: "hr",
    country_name: "Croatian",
  },
  cs: {
    language_code: "cs",
    country_name: "Czech",
  },
  da: {
    language_code: "da",
    country_name: "Danish",
  },
  nl: {
    language_code: "nl",
    country_name: "Dutch (Standard)",
  },
  "nl-be": {
    language_code: "nl-be",
    country_name: "Dutch (Belgian)",
  },
  en: {
    language_code: "en",
    country_name: "English",
  },
  "en-au": {
    language_code: "en-au",
    country_name: "English (Australia)",
  },
  "en-bz": {
    language_code: "en-bz",
    country_name: "English (Belize)",
  },
  "en-ca": {
    language_code: "en-ca",
    country_name: "English (Canada)",
  },
  "en-ie": {
    language_code: "en-ie",
    country_name: "English (Ireland)",
  },
  "en-jm": {
    language_code: "en-jm",
    country_name: "English (Jamaica)",
  },
  "en-nz": {
    language_code: "en-nz",
    country_name: "English (New Zealand)",
  },
  "en-ph": {
    language_code: "en-ph",
    country_name: "English (Philippines)",
  },
  "en-za": {
    language_code: "en-za",
    country_name: "English (South Africa)",
  },
  "en-tt": {
    language_code: "en-tt",
    country_name: "English (Trinidad & Tobago)",
  },
  "en-gb": {
    language_code: "en-gb",
    country_name: "English (United Kingdom)",
  },
  "en-us": {
    language_code: "en-us",
    country_name: "English (United States)",
  },
  "en-zw": {
    language_code: "en-zw",
    country_name: "English (Zimbabwe)",
  },
  eo: {
    language_code: "eo",
    country_name: "Esperanto",
  },
  et: {
    language_code: "et",
    country_name: "Estonian",
  },
  fo: {
    language_code: "fo",
    country_name: "Faeroese",
  },
  fa: {
    language_code: "fa",
    country_name: "Persian",
  },
  fj: {
    language_code: "fj",
    country_name: "Fijian",
  },
  fi: {
    language_code: "fi",
    country_name: "Finnish",
  },
  fr: {
    language_code: "fr",
    country_name: "French (Standard)",
  },
  "fr-be": {
    language_code: "fr-be",
    country_name: "French (Belgium)",
  },
  "fr-ca": {
    language_code: "fr-ca",
    country_name: "French (Canada)",
  },
  "fr-fr": {
    language_code: "fr-fr",
    country_name: "French (France)",
  },
  "fr-lu": {
    language_code: "fr-lu",
    country_name: "French (Luxembourg)",
  },
  "fr-mc": {
    language_code: "fr-mc",
    country_name: "French (Monaco)",
  },
  "fr-ch": {
    language_code: "fr-ch",
    country_name: "French (Switzerland)",
  },
  fy: {
    language_code: "fy",
    country_name: "Frisian",
  },
  fur: {
    language_code: "fur",
    country_name: "Friulian",
  },
  gd: {
    language_code: "gd",
    country_name: "Scots Gaelic",
  },
  "gd-ie": {
    language_code: "gd-ie",
    country_name: "Gaelic (Irish)",
  },
  gl: {
    language_code: "gl",
    country_name: "Galacian",
  },
  ka: {
    language_code: "ka",
    country_name: "Georgian",
  },
  de: {
    language_code: "de",
    country_name: "German (Standard)",
  },
  "de-at": {
    language_code: "de-at",
    country_name: "German (Austria)",
  },
  "de-de": {
    language_code: "de-de",
    country_name: "German (Germany)",
  },
  "de-li": {
    language_code: "de-li",
    country_name: "German (Liechtenstein)",
  },
  "de-lu": {
    language_code: "de-lu",
    country_name: "German (Luxembourg)",
  },
  "de-ch": {
    language_code: "de-ch",
    country_name: "German (Switzerland)",
  },
  el: {
    language_code: "el",
    country_name: "Greek",
  },
  gu: {
    language_code: "gu",
    country_name: "Gujurati",
  },
  ht: {
    language_code: "ht",
    country_name: "Haitian",
  },
  he: {
    language_code: "he",
    country_name: "Hebrew",
  },
  hi: {
    language_code: "hi",
    country_name: "Hindi",
  },
  hu: {
    language_code: "hu",
    country_name: "Hungarian",
  },
  is: {
    language_code: "is",
    country_name: "Icelandic",
  },
  id: {
    language_code: "id",
    country_name: "Indonesian",
  },
  iu: {
    language_code: "iu",
    country_name: "Inuktitut",
  },
  ga: {
    language_code: "ga",
    country_name: "Irish",
  },
  it: {
    language_code: "it",
    country_name: "Italian (Standard)",
  },
  "it-ch": {
    language_code: "it-ch",
    country_name: "Italian (Switzerland)",
  },
  ja: {
    language_code: "ja",
    country_name: "Japanese",
  },
  kn: {
    language_code: "kn",
    country_name: "Kannada",
  },
  ks: {
    language_code: "ks",
    country_name: "Kashmiri",
  },
  kk: {
    language_code: "kk",
    country_name: "Kazakh",
  },
  km: {
    language_code: "km",
    country_name: "Khmer",
  },
  ky: {
    language_code: "ky",
    country_name: "Kirghiz",
  },
  tlh: {
    language_code: "tlh",
    country_name: "Klingon",
  },
  ko: {
    language_code: "ko",
    country_name: "Korean",
  },
  "ko-kp": {
    language_code: "ko-kp",
    country_name: "Korean (North Korea)",
  },
  "ko-kr": {
    language_code: "ko-kr",
    country_name: "Korean (South Korea)",
  },
  la: {
    language_code: "la",
    country_name: "Latin",
  },
  lv: {
    language_code: "lv",
    country_name: "Latvian",
  },
  lt: {
    language_code: "lt",
    country_name: "Lithuanian",
  },
  lb: {
    language_code: "lb",
    country_name: "Luxembourgish",
  },
  mk: {
    language_code: "mk",
    country_name: "FYRO Macedonian",
  },
  ms: {
    language_code: "ms",
    country_name: "Malay",
  },
  ml: {
    language_code: "ml",
    country_name: "Malayalam",
  },
  mt: {
    language_code: "mt",
    country_name: "Maltese",
  },
  mi: {
    language_code: "mi",
    country_name: "Maori",
  },
  mr: {
    language_code: "mr",
    country_name: "Marathi",
  },
  mo: {
    language_code: "mo",
    country_name: "Moldavian",
  },
  nv: {
    language_code: "nv",
    country_name: "Navajo",
  },
  ng: {
    language_code: "ng",
    country_name: "Ndonga",
  },
  ne: {
    language_code: "ne",
    country_name: "Nepali",
  },
  no: {
    language_code: "no",
    country_name: "Norwegian",
  },
  nb: {
    language_code: "nb",
    country_name: "Norwegian (Bokmal)",
  },
  nn: {
    language_code: "nn",
    country_name: "Norwegian (Nynorsk)",
  },
  oc: {
    language_code: "oc",
    country_name: "Occitan",
  },
  or: {
    language_code: "or",
    country_name: "Oriya",
  },
  om: {
    language_code: "om",
    country_name: "Oromo",
  },
  "fa-ir": {
    language_code: "fa-ir",
    country_name: "Persian/Iran",
  },
  pl: {
    language_code: "pl",
    country_name: "Polish",
  },
  pt: {
    language_code: "pt",
    country_name: "Portuguese",
  },
  "pt-br": {
    language_code: "pt-br",
    country_name: "Portuguese (Brazil)",
  },
  pa: {
    language_code: "pa",
    country_name: "Punjabi",
  },
  "pa-in": {
    language_code: "pa-in",
    country_name: "Punjabi (India)",
  },
  "pa-pk": {
    language_code: "pa-pk",
    country_name: "Punjabi (Pakistan)",
  },
  qu: {
    language_code: "qu",
    country_name: "Quechua",
  },
  rm: {
    language_code: "rm",
    country_name: "Rhaeto-Romanic",
  },
  ro: {
    language_code: "ro",
    country_name: "Romanian",
  },
  "ro-mo": {
    language_code: "ro-mo",
    country_name: "Romanian (Moldavia)",
  },
  ru: {
    language_code: "ru",
    country_name: "Russian",
  },
  "ru-mo": {
    language_code: "ru-mo",
    country_name: "Russian (Moldavia)",
  },
  sz: {
    language_code: "sz",
    country_name: "Sami (Lappish)",
  },
  sg: {
    language_code: "sg",
    country_name: "Sango",
  },
  sa: {
    language_code: "sa",
    country_name: "Sanskrit",
  },
  sc: {
    language_code: "sc",
    country_name: "Sardinian",
  },
  sd: {
    language_code: "sd",
    country_name: "Sindhi",
  },
  si: {
    language_code: "si",
    country_name: "Singhalese",
  },
  sr: {
    language_code: "sr",
    country_name: "Serbian",
  },
  sk: {
    language_code: "sk",
    country_name: "Slovak",
  },
  sl: {
    language_code: "sl",
    country_name: "Slovenian",
  },
  so: {
    language_code: "so",
    country_name: "Somani",
  },
  sb: {
    language_code: "sb",
    country_name: "Sorbian",
  },
  es: {
    language_code: "es",
    country_name: "Spanish",
  },
  "es-ar": {
    language_code: "es-ar",
    country_name: "Spanish (Argentina)",
  },
  "es-bo": {
    language_code: "es-bo",
    country_name: "Spanish (Bolivia)",
  },
  "es-cl": {
    language_code: "es-cl",
    country_name: "Spanish (Chile)",
  },
  "es-co": {
    language_code: "es-co",
    country_name: "Spanish (Colombia)",
  },
  "es-cr": {
    language_code: "es-cr",
    country_name: "Spanish (Costa Rica)",
  },
  "es-do": {
    language_code: "es-do",
    country_name: "Spanish (Dominican Republic)",
  },
  "es-ec": {
    language_code: "es-ec",
    country_name: "Spanish (Ecuador)",
  },
  "es-sv": {
    language_code: "es-sv",
    country_name: "Spanish (El Salvador)",
  },
  "es-gt": {
    language_code: "es-gt",
    country_name: "Spanish (Guatemala)",
  },
  "es-hn": {
    language_code: "es-hn",
    country_name: "Spanish (Honduras)",
  },
  "es-mx": {
    language_code: "es-mx",
    country_name: "Spanish (Mexico)",
  },
  "es-ni": {
    language_code: "es-ni",
    country_name: "Spanish (Nicaragua)",
  },
  "es-pa": {
    language_code: "es-pa",
    country_name: "Spanish (Panama)",
  },
  "es-py": {
    language_code: "es-py",
    country_name: "Spanish (Paraguay)",
  },
  "es-pe": {
    language_code: "es-pe",
    country_name: "Spanish (Peru)",
  },
  "es-pr": {
    language_code: "es-pr",
    country_name: "Spanish (Puerto Rico)",
  },
  "es-es": {
    language_code: "es-es",
    country_name: "Spanish (Spain)",
  },
  "es-uy": {
    language_code: "es-uy",
    country_name: "Spanish (Uruguay)",
  },
  "es-ve": {
    language_code: "es-ve",
    country_name: "Spanish (Venezuela)",
  },
  sx: {
    language_code: "sx",
    country_name: "Sutu",
  },
  sw: {
    language_code: "sw",
    country_name: "Swahili",
  },
  sv: {
    language_code: "sv",
    country_name: "Swedish",
  },
  "sv-fi": {
    language_code: "sv-fi",
    country_name: "Swedish (Finland)",
  },
  "sv-sv": {
    language_code: "sv-sv",
    country_name: "Swedish (Sweden)",
  },
  ta: {
    language_code: "ta",
    country_name: "Tamil",
  },
  tt: {
    language_code: "tt",
    country_name: "Tatar",
  },
  te: {
    language_code: "te",
    country_name: "Teluga",
  },
  th: {
    language_code: "th",
    country_name: "Thai",
  },
  tig: {
    language_code: "tig",
    country_name: "Tigre",
  },
  ts: {
    language_code: "ts",
    country_name: "Tsonga",
  },
  tn: {
    language_code: "tn",
    country_name: "Tswana",
  },
  tr: {
    language_code: "tr",
    country_name: "Turkish",
  },
  tk: {
    language_code: "tk",
    country_name: "Turkmen",
  },
  uk: {
    language_code: "uk",
    country_name: "Ukrainian",
  },
  hsb: {
    language_code: "hsb",
    country_name: "Upper Sorbian",
  },
  ur: {
    language_code: "ur",
    country_name: "Urdu",
  },
  ve: {
    language_code: "ve",
    country_name: "Venda",
  },
  vi: {
    language_code: "vi",
    country_name: "Vietnamese",
  },
  vo: {
    language_code: "vo",
    country_name: "Volapuk",
  },
  wa: {
    language_code: "wa",
    country_name: "Walloon",
  },
  cy: {
    language_code: "cy",
    country_name: "Welsh",
  },
  xh: {
    language_code: "xh",
    country_name: "Xhosa",
  },
  ji: {
    language_code: "ji",
    country_name: "Yiddish",
  },
  zu: {
    language_code: "zu",
    country_name: "Zulu",
  },
};
