import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message } from "antd";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { tranning_result_api } from "../../library/tranning_result_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
import { SettingOutlined, CheckOutlined } from "@ant-design/icons";
// ===============================================================================
class Tranning_result extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: tranning_result_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        version: { valMet: vj_val.checkLength, valLen: 1 },
        title: { valMet: vj_val.checkLength, valLen: 1 },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        description: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
      edit: false,
      isPublishActive: true,
      section:{},
      EditeleArr:{},
      examType:"",
      overallPercentage: 0.7

    };
  }

  //************* edit mode and view mode on off */
  viewModeToeditMode = async (obj) => {

    let {exam_id , editType} = obj

    this.setState({edit: true, CDM: false });
    const { Chabi} = this.state;
    const Apiurl = tranning_result_api +"/ById/"+exam_id;
    const response = await myAxios({
      method: "get",
      url:Apiurl,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        if(data.records.length >= 1){

        data.records[0].sections.map( (section,i) => {
          if(editType == "lead"){
            this.setState({ [section.sectionId] : { value : section.lead_tutor_marks, status: (parseInt(section.lead_tutor_marks) >= parseInt(section.min_marks)) ? "Pass" : "Fail", lead_section_comment: section.lead_section_comment, support_section_comment: section.support_section_comment}})
          }else if(editType == "support"){
            this.setState({ [section.sectionId] : { value : section.support_tutor_marks, status: (parseInt(section.support_tutor_marks) >= parseInt(section.min_marks)) ? "Pass" : "Fail", lead_section_comment: section.lead_section_comment, support_section_comment: section.support_section_comment}})
          }else{
            this.setState({ [section.sectionId] : { value : (section.tutor_3_marks != 0) ? section.tutor_3_marks : "", status: (parseInt(section.tutor_3_marks) >= parseInt(section.min_marks)) ? "Pass" : "Fail", lead_section_comment: section.lead_section_comment, support_section_comment: section.support_section_comment, tutor_3_comment: section.tutor_3_comment}})
          }
        })
           this.setState({ selectedRecord : data.records[0], editType: editType})
          }
       
      }
 
    } else console.log("Network Error");

    this.setState({ CDM: true});
  }   

  viewModeToViewMode = () => {
    this.reloadPage();
    this.setState({edit: false});
  }

  publishHandler = async (status, type) => {
    if(window.confirm("Are you sure ?")){
      let { Chabi, request, attandanceId, records } = this.state;
      const url = tranning_result_api+"/update";
      const response = await myAxios({
        method: "put",
        url,
        header: { Chabi },
        request:{ status, type: "isPublish", _id: records[0]._id  },
      });
      if (response.status == 200) {
        const { data } = response;
        if (data.status == 420) this.setLogout();
        else if (data.status == 200) {
          message.success("records updated");
          return window.history.back()
        }else if(data.status == 4000){
          message.success("no records to update");
          return window.history.back()
        } else {
          const { messages } = data;
          if (messages) {
            message.error(messages);
            return false;
          }
        }
      } else alert("Network Error !");
    }
      
  }

  marksHandler = (obj) => {
    
      let { target, name, min, edittype,max} = obj;
      let value = target.value;
      if(parseInt(value) > parseInt(max)){
        alert(`Grade Obtained must be less than and equal to max marks : ${max}`);
      
        this.setState({[name] : { value : this.state[name].value}});
        return false
      }
      this.setState({[name] : { value : value, status: (parseInt(value) >= parseInt(min)) ? "Pass" : "Fail"}})
  }

  reloadPage = async () => {
    let consultantId = this.props.loggedUser.mappingId
    const { Chabi, overallPercentage, pageId , userId, orderId, orderType,examType} = this.state;
    let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId, orderId: orderId, orderType})));
    const Apiurl = tranning_result_api +"/get";
    const response = await myAxios({
      method: "post",
      url:Apiurl,
      header: { Chabi },
      request: {
        identified,
        examType
    }
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let overallPassing = overallPercentage * data.records[0].total_min_grade
      let EditeleArr = data.records.map( exam => {
        //================== update button active based on type of ====================
        let trainingData = exam.trainingData
        let consultantType = ""
     
        if(trainingData.instructor_1 == consultantId){
          consultantType = "lead"
        }else if(trainingData.instructor_2 == consultantId){
          consultantType = "support"
        }else if(trainingData.instructor_3 == consultantId){
          consultantType = "third"
        }

        let isPublishActive = [];
        let totalexam = 0;
        let gradObttotal = 0;
        let TBAShow = false;
        let isPassed = []
        let isIndependeGraderAddMarks = false
        return  <div className="row mt-1 mb-1">
        <div className="col-12 text-left">
       
          <table className="table table-bordered attendence-table">
            <tr>
              <td>Template:</td>
              <td>{exam.templateData.exam_name}</td>
            </tr>
            <tr>
              <td>Attempt:</td>
              <td>{exam.attempt}</td>
            </tr>
            <tr>
              <td>Grader:</td>
                <td>{consultantType == "lead" ? "Lead consultant" : (consultantType == "support") ? "Support Consultant" : "	Independent Grader" }</td>
            </tr>
            <tr>
              <td>Delegate Name:</td>
              <td>{`${exam.learnersData.first_name} ${exam.learnersData.last_name}`}</td>
            </tr>
            <tr>
                  <td>Status:</td>
                  <td>{exam.isPublish == 1 ? "Published" : "Not Published"}</td>
                </tr>
            <tr>
              <td colSpan={2} className="p-0">
                <table className="table table-bordered daysTbl m-0">
                  <tr>
                    <td>Section</td>
                    <td>Min Grade</td>
                    <td>Grade Obtained</td>
                    <td>Section Comments</td>
                    <td>Lead Consultant</td>
                    <td>Support Consultant</td>
                    <td>Independent Grader</td>
                  </tr>
                  {
                    exam.sections.map( (section) => {
                      if(section.tutor_3_marks != 0 && section.tutor_3_marks != null){
                        isIndependeGraderAddMarks = true
                        TBAShow = false
                    }else if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
         
                      TBAShow = true
                     }
                      totalexam = +totalexam + +section.min_marks;
                      
                      let currMarks = (section.tutor_3_marks != 0 && section.tutor_3_marks != null) ? section.tutor_3_marks : section.lead_tutor_marks
                      gradObttotal = +gradObttotal + +currMarks
                      
                      /************ check every section pass sepratly ****************
                       * condition : every section pass sepratorly if not pass every section then result fail
                       */
                      if(+section.min_marks < +currMarks){
                        isPassed.push(false);
                      }else{
                        isPassed.push(true);
                      }


                        /************ check publish button active or not 
                       * condition : lead tutor and support tutor both same then button active
                       * condition : lead tutor and support tutor marks diffrent then third tutor add marks whatever entered marks are final and button active
                       */
                         if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
                          if(section.tutor_3_marks == 0 && section.tutor_3_marks != null){
                            isPublishActive.push(false)
                          }else{
                            isPublishActive.push(true)
                          }
                        }


                      let makks = "TBA";
                      if(section.tutor_3_marks != 0 && section.tutor_3_marks != null){
                        makks = section.tutor_3_marks
                      }else if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
                        makks = "TBA"
                      }else{
                        makks = section.lead_tutor_marks
                       
                      }

                      return  <tr>
                      <td>{section.section}</td>
                      <td>{section.min_marks}</td>
                      <td>{makks}</td>
                      <td>{section.lead_section_comment == null ? section.support_section_comment ? null : 'TBA' : section.lead_section_comment}</td>
                      
                      <td>{(section.lead_tutor_marks == 0) ? "TBA" : section.lead_tutor_marks}</td>
                      <td>{(section.support_tutor_marks == 0) ? "TBA" : section.support_tutor_marks}</td>
                      <td>{consultantType == "third" ? (section.lead_tutor_marks != 0 && section.support_tutor_marks != 0 && section.lead_tutor_marks != section.support_tutor_marks) ?  <button type="submit" class="btn btn-warning btn-sm ml-2" onClick={() => this.viewModeToeditMode({ exam_id : exam._id, editType : "third"})}>Independent Grader Update</button> : "-" : "-"}</td>
                    </tr>
                    })
                  }
                  <tr>
                    <td>Total : </td>
                    <td>{totalexam}</td>
                    <td>{TBAShow ? "TBA" : gradObttotal}</td>
                  </tr>
                  <tr>
                    <td>Result : </td>
                    
                    <td>{TBAShow ? "TBA" : isPassed.includes(false) ? gradObttotal >= totalexam ? gradObttotal >= overallPassing ? "Pass" : "Fail" : "Fail": "Fail"}</td>
                  </tr>
                </table>
              </td>
             
            </tr>
            
          </table>
          <div className="row mt-1">
            <div className="col-12 text-left">
              <button type="submit" className="btn btn-primary btn-sm" disabled={isPublishActive.includes(false) ? true : false} onClick={() => this.publishHandler((exam.isPublish == 1) ? 0 : 1, "isPublish")}>{(exam.isPublish == 0) ? "Publish" : "UnPublish"}</button>
              {consultantType == "lead" ? <button type="submit" className="btn btn-primary btn-sm ml-2" disabled={isIndependeGraderAddMarks} onClick={() => this.viewModeToeditMode({ exam_id : exam._id, editType : "lead"})}>Update</button> : null }
              {consultantType == "support" ? <button type="submit" className="btn btn-primary btn-sm ml-2" disabled={isIndependeGraderAddMarks} onClick={() => this.viewModeToeditMode({ exam_id : exam._id, editType : "support"})}>Update</button> : null }
            </div>
          </div>
         
        </div>
      </div>
   })
   this.setState({ records : data.records, viewEle : EditeleArr})
  }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  }


  // ================== save marks
  saveMarks = async () => {
    let {selectedRecord, editType, Chabi} = this.state;
    let { _id } = selectedRecord;
    let updateArr = [];
    selectedRecord.sections.map( _ => {
      let ele = _.sectionId.toString();
      let val = (editType == "lead") ? { lead_tutor_marks: parseInt(this.state[ele].value), lead_section_comment: this.state[ele].lead_section_comment} : (editType == "support") ? { support_tutor_marks: parseInt(this.state[ele].value), support_section_comment: this.state[ele].support_section_comment} : { tutor_3_marks: parseInt(this.state[ele].value), tutor_3_comment: this.state[ele].tutor_3_comment}
      updateArr.push({..._, ...val})
    })

    const url = `${tranning_result_api}/${_id}`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { updateArr: updateArr, editType },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("record updated")
         this.setState({ edit: false, selectedRecord: {}, editType: ""})
         this.reloadPage()
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  }

  componentDidMount = async () => {
    let consultantId = this.props.loggedUser.mappingId
    if(window.location.search != ""){
    let url= new URLSearchParams(window.location.search);
    let pageId = url.get("training")
    let userId = url.get("identifier")
    let examType = url.get("moduletype")
     pageId = atob(atob(pageId))
     userId = atob(atob(userId))
    
    userId = userId.split("_");
    this.setState({ pageId, userId: userId[1], orderId: userId[0],orderType: userId[2], examType})
    let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0],orderType: userId[2]})));
    
    const { Chabi, overallPercentage  } = this.state;
    const Apiurl = tranning_result_api +"/get";
    const response = await myAxios({
      method: "post",
      url:Apiurl,
      header: { Chabi },
      request: {
        identified,
        examType
    }
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let overallPassing = overallPercentage * data.records[0].total_min_grade
      let eleArr = data.records.map( exam => {
        //================== update button active based on type of ====================
        let trainingData = exam.trainingData
        let consultantType = ""
     
        if(trainingData.instructor_1 == consultantId){
          consultantType = "lead"
        }else if(trainingData.instructor_2 == consultantId){
          consultantType = "support"
        }else if(trainingData.instructor_3 == consultantId){
          consultantType = "third"
        }

        let isPublishActive = [];
        let totalexam = 0;
        let gradObttotal = 0;
        let TBAShow = false;
        let isPassed = [];
        let isIndependeGraderAddMarks = false
        return  <div className="row mt-1 mb-1">
        <div className="col-12 text-left">
       
          <table className="table table-bordered attendence-table">
            <tr>
              <td>Template:</td>
              <td>{exam.templateData.exam_name}</td>
            </tr>
            <tr>
              <td>Attempt:</td>
              <td>{exam.attempt}</td>
            </tr>
            <tr>
              <td>Grader:</td>
              <td>{consultantType == "lead" ? "Lead consultant" : (consultantType == "support") ? "Support Consultant" : "	Independent Grader" }</td>
            </tr>
            <tr>
              <td>Delegate Name:</td>
              <td>{`${exam.learnersData.first_name} ${exam.learnersData.last_name}`}</td>
            </tr>
            <tr>
                  <td>Status:</td>
                  <td>{exam.isPublish == 1 ? "Published" : "Not Published"}</td>
                </tr>
            <tr>
              <td colSpan={2} className="p-0">
                <table className="table table-bordered daysTbl m-0">
                  <tr>
                    <td>Section</td>
                    <td>Min Grade</td>
                    <td>Grade Obtained</td>
                    <td>Section Comments</td>
                    <td>Lead Consultant</td>
                    <td>Support Consultant</td>
                    <td>Independent Grader</td>
                  </tr>
                  {
                    exam.sections.map( (section) => {
                      if(section.tutor_3_marks != 0 && section.tutor_3_marks != null){
                        isIndependeGraderAddMarks = true
                        TBAShow = false
                    }else if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
                    
                      TBAShow = true
                     }
                      totalexam = +totalexam + +section.min_marks;
                      
                      let currMarks = (section.tutor_3_marks != 0 && section.tutor_3_marks != null) ? section.tutor_3_marks : section.lead_tutor_marks
                    // console.log(currMarks, section.tutor_3_marks, section.lead_tutor_marks)
                      gradObttotal = +gradObttotal + +currMarks

                      /************ check every section pass sepratly ****************
                       * condition : every section pass sepratorly if not pass every section then result fail
                       */
                      if(+section.min_marks < +currMarks){
                        isPassed.push(false);
                      }else{
                        console.log("True")
                      }
                      

                      /************ check publish button active or not 
                       * condition : lead tutor and support tutor both same then button active
                       * condition : lead tutor and support tutor marks diffrent then third tutor add marks whatever entered marks are final and button active
                       */
                      if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
                        if(section.tutor_3_marks == 0 && section.tutor_3_marks != null){
                          isPublishActive.push(false)
                        }else{
                          isPublishActive.push(true)
                        }
                      }


                      let makks = "TBA";
                      if(section.tutor_3_marks != 0 && section.tutor_3_marks != null){
                       
                        makks = section.tutor_3_marks
                        console.log("log 3tutor", makks)
                      }else if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
                        makks = "TBA"
                      }else{
                        makks = section.lead_tutor_marks
                       
                      }

                      return  <tr>
                      <td>{section.section}</td>
                      <td>{section.min_marks}</td>
                      <td>{makks}</td>
                      <td>{section.lead_section_comment == null ? section.support_section_comment ? null : 'TBA' : section.lead_section_comment}</td>
                      
                      <td>{(section.lead_tutor_marks == 0) ? "TBA" : section.lead_tutor_marks}</td>
                      <td>{(section.support_tutor_marks == 0) ? "TBA" : section.support_tutor_marks}</td>
                      <td>{consultantType == "third" ? (section.lead_tutor_marks != 0 && section.support_tutor_marks != 0 && section.lead_tutor_marks != null && section.support_tutor_marks != null && section.lead_tutor_marks != section.support_tutor_marks) ?  (section.tutor_3_marks == 0 || section.tutor_3_marks == null) ? <button type="submit" class="btn btn-warning btn-sm ml-2" onClick={() => this.viewModeToeditMode({ exam_id : exam._id, editType : "third"})}>Independent Grader Update</button>: "Independent Grader marks updated" : "-" : "-"} </td>
                    </tr>
                    })
                  }
                  <tr>
                    <td>Total : </td>
                    <td>{totalexam}</td>
                    <td>{TBAShow ? "TBA" : gradObttotal}</td>
                  </tr>
                  <tr>
                    <td>Result : </td>
                    <td>{TBAShow ? "TBA" : isPassed.includes(false) ? gradObttotal >= totalexam ? gradObttotal >= overallPassing ? "Pass" : "Fail" : "Fail": "Fail"}</td>
                  </tr>
                </table>
              </td>
             
            </tr>
            
          </table>

          <div className="row mt-1">
            <div className="col-12 text-left">
              <button type="submit" className="btn btn-primary btn-sm" disabled={isPublishActive.includes(false) ? true : false} onClick={() => this.publishHandler((exam.isPublish == 1) ? 0 : 1, "isPublish")}>{(exam.isPublish == 0) ? "Publish" : "UnPublish"}</button>
              {consultantType == "lead" ? <button type="submit" className="btn btn-primary btn-sm ml-2" disabled={isIndependeGraderAddMarks} onClick={() => this.viewModeToeditMode({ exam_id : exam._id, editType : "lead"})}>Update</button> : null }
              {consultantType == "support" ?<button type="submit" className="btn btn-primary btn-sm ml-2" disabled={isIndependeGraderAddMarks} onClick={() => this.viewModeToeditMode({ exam_id : exam._id, editType : "support"})}>Update</button> : null}
            </div>
          </div>
         
        </div>
      </div>
   })
   this.setState({ records : data.records, viewEle : eleArr})
  }
 
    } else console.log("Network Error");
    this.setState({ CDM: true });
    }else{
        alert("you want to access unregistered routes... pls press ok and go back");
        window.history.back();
    }
  };

  submit = async () => {
    let { selectedRecord, comment, Chabi, records } = this.state;
    if (!comment.length) return;
    const _id = selectedRecord["_id"];
    const status = +!parseInt(selectedRecord["status"]);
    const url = `${tranning_result_api}/comment`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, comment, status },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) {
            r["comment"] = comment;
            r["status"] = status;
          }
          return r;
        });
        this.setState({
          records,
          cmodal: false,
          selectedRecord: {},
          comment: "",
        });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("Training Enquiry State ", this.state);
    let {
      records,
      loginStatus,
      filtered_records,
      cmodal,
      selectedRecord,
      comment,
      coffcanvas,
      region2,
      CDM,
      edit,
      viewEle,
      isPublishActive,
      editviewEle,
      EditeleArr,
      editType
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    let totalexam = 0;
    let gradObttotal = 0;
    let TBAShow = false;
    let isPassed = []
    return (
      
      <>
        <PageHeading2
          active="Training"
          page={["Training", "Learners", "Exam Results"]}
        ></PageHeading2>
        <MainArea>
        {CDM ? 
        <div className="p-4">
        <p className="info-main-heading">{(edit) ? "Update" : null} {this.state.examType != "" && this.state.examType == 2 ? "Repeat" : null} Exam Results</p>
                 {(edit) ?
                  <div className="row mt-1 mb-1">
                   
                  <div className="col-12 text-left">
                 
                    <table className="table table-bordered attendence-table">
                      <tr>
                        <td>Template:</td>
                        <td>{selectedRecord.templateData.exam_name}</td>
                      </tr>
                      <tr>
                        <td>Attempt:</td>
                        <td>{selectedRecord.attempt}</td>
                      </tr>
                      <tr>
                        <td>Grader:</td>
                        <td>{(editType == "lead") ? "Support Consultant" : (editType == "support") ? "Support Consultant" : "	Independent Grader" }</td>
                      </tr>
                      <tr>
                        <td>Delegate Name:</td>
                        <td>{`${selectedRecord.learnersData.first_name} ${selectedRecord.learnersData.last_name}`}</td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td>{selectedRecord.isPublish == 1 ? "Published" : "Not Published"}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="p-0">
                          <table className="table table-bordered daysTbl m-0">
                            <tr>
                              <td>Section</td>
                              <td>Min Grade</td>
                              <td>Grade Obtained</td>
                              <td>Result</td>
                              <td>Section Comments</td>
                              {(editType == "lead") ? <td>Support Consultant</td> : null }
                              {(editType == "support") ?<td>Lead Consultant</td> : null }
                              {(editType == "third") ?<td>Third Tutor</td> : null }
                              
                            </tr>
                            {
                              selectedRecord.sections.map( (section) => {
                               let status = this.state[section.sectionId];
                           
                               if(section.lead_tutor_marks != section.support_tutor_marks || section.lead_tutor_marks == 0 || section.support_tutor_marks == 0){
                                TBAShow = true
                               } 
                                totalexam = +totalexam + +section.min_marks;
                                gradObttotal = +gradObttotal + +section.lead_tutor_marks
                                /************ check every section pass sepratly ****************
                                 * condition : every section pass sepratorly if not pass every section then result fail
                                 */
                                console.log(section.min_marks,section.lead_tutor_marks);
                                if(+section.min_marks < +section.lead_tutor_marks){
                                  isPassed.push(false);
                                }else{
                                  isPassed.push(true);
                                }

                                return  <tr>
                                <td>{section.section}</td>
                                <td>{section.min_marks}</td>
                                
                                <td>{
                                (editType == "lead") ? <input type="text" className="form-control" name={section.sectionId} value={this.state[section.sectionId].value} max={section.max_marks} onChange={(e) => this.marksHandler({ target: e.target, name: section.sectionId, min: section.min_marks, edittype: "lead", max: section.max_marks})}/> 
                                :
                                (editType == "support") ? 
                                <input type="text" className="form-control" max={section.max_marks} name={section.sectionId} value={this.state[section.sectionId].value} onChange={(e) => this.marksHandler({ target: e.target, name: section.sectionId, min: section.min_marks, edittype: "support", max: section.max_marks})}/> 
                                : 
                                (section.lead_tutor_marks != section.support_tutor_marks) ? 
                                <input type="text" className="form-control" max={section.max_marks} name={section.sectionId} value={this.state[section.sectionId].value} onChange={(e) => this.marksHandler({ target: e.target, name: section.sectionId, min: section.min_marks, edittype: "third", max: section.max_marks})}/> : section.lead_tutor_marks}</td>
                             
                                <td>{status.status}</td>
                                  {console.log(section.tutor_3_marks)}
                                <td>{ status.status == "Pass" ? "-" : 
                                   (editType == "third") ? +this.state[section.sectionId].value == 0 || +section.tutor_3_marks >= +section.min_marks ? "-" : <input type="text" value={this.state[section.sectionId].tutor_3_comment} onChange={(e) => this.setState({ [section.sectionId] : { ...this.state[section.sectionId], ["tutor_3_comment"] : e.target.value}})} className="form-control"/> : <input type="text" value={editType == "lead" ? this.state[section.sectionId].lead_section_comment : this.state[section.sectionId].support_section_comment} onChange={(e) => this.setState({ [section.sectionId] : { ...this.state[section.sectionId], [editType == "lead" ? 'lead_section_comment' : 'support_section_comment'] : e.target.value}})} className="form-control"/>  }</td>
                                
                                {(editType == "lead") ? <td>{(editType == "lead") ? section.support_tutor_marks : section.lead_tutor_marks}</td> : null}
                                
                                {(editType != "lead") ? <td>{(editType != "lead") ? section.lead_tutor_marks : section.support_tutor_marks}</td> : null }
                              </tr>
                              })
                            }
                          <tr>
                            <td>Total : </td>
                            <td>{totalexam}</td>
                            <td>{TBAShow ? "TBA" : gradObttotal}</td>
                          </tr>
                          <tr>
                            <td>Result : </td>
                            <td>{TBAShow ? "TBA" : !isPassed.includes(false) ? gradObttotal >= totalexam ? "Pass" : "Fail" : "Fail"}</td>
                          </tr>
                          </table>
                        </td>
                       
                      </tr>
                      
                    </table>
                    <div className="row mt-1">
                      <div className="col-12 text-left">
                       
                        <button type="submit" className="btn btn-primary btn-sm" onClick={() => this.saveMarks()}>Save</button>
                        <button type="submit" className="btn btn-danger btn-sm ml-2" onClick={() => this.viewModeToViewMode()}>Cancel</button>
                      </div>
                    </div>
                   
                  </div>
                </div>
                :
                <>
                 {viewEle}
                </>
                   
                    }
                </div>
             : <MyLoading /> }
        </MainArea>
		 <style jsx global>{`
			.btn-warning {
			  background-color: #ffca2c !important;
			}
		 `}</style>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tranning_result);
