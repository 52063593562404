import React from "react";
import { Layout, Row, Col, Menu, Breadcrumb } from "antd";
import { connect, useSelector } from "react-redux";
// ===============================================================================
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
// ===============================================================================
import TopNavDesktop from "./part/TopNavDesktop";
// import Gmail from "./Gmail";
import "../assets/custom-all.css";
import urls from "../urls";
import { PORTAL } from "../currrent_info";
import MySider from "./part/MySider";
import Footer from "./part/Footer";
// ===============================================================================
const { Header, Content } = Layout;
function LmsPortal(props) {
  const { loginStatus, role_modules  } = props;
  return (
    <Layout style={{ backgroundColor: "unset" }}>
      <Router basename={PORTAL.basename}>
        {/* it will hide on mobile screen */}
        {loginStatus ? (
          <Header
            style={{
              backgroundColor: "#e9ecef",
              position: "fixed",
              width: "100%",
              left: 0,
              top: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            
            {/* <span className=" d-none d-lg-block "> */}
            <TopNavDesktop />
            {/* </span> */}
          </Header>
        ) : (
          ""
        )}
        <Layout className="layout2">
          {loginStatus ? <MySider /> : ""}
          
          <Content style={{ backgroundColor: "#e9ecef" }}>
            <Routes>
              <Route
                exact
                path={urls.LOGIN.path}
                element={<urls.LOGIN.component />}
              />

              <Route
                path={urls.DASHBOARD.path}
                element={<urls.DASHBOARD.component />}
              />

              <Route
                path={urls.IN_COMPLETE_TRAIINING.path}
                element={<urls.IN_COMPLETE_TRAIINING.component />}
              />

              <Route
                path={urls.TRAINING_LEARNER_LIST.path}
                element={<urls.TRAINING_LEARNER_LIST.component />}
              />

               <Route
                path={urls.EVALAUTION_RESULT_LIST.path}
                element={<urls.EVALAUTION_RESULT_LIST.component />}
              />

               <Route
                path={urls.EVALAUTION_RESULT_LIST_UPDATE.path}
                element={<urls.EVALAUTION_RESULT_LIST_UPDATE.component />}
              />

               <Route
                path={urls.EXAM_RESULT_LIST.path}
                element={<urls.EXAM_RESULT_LIST.component />}
              />

               <Route
                path={urls.VIEW_FEEDBACKFORM.path}
                element={<urls.VIEW_FEEDBACKFORM.component />}
              />

              <Route
                path={urls.SALES_TARGET_REPORT.path}
                element={<urls.SALES_TARGET_REPORT.component />}
              />


              <Route
                path={urls.TRAINING_SCHEDULE.path}
                element={<urls.TRAINING_SCHEDULE.component />}
              />
              <Route
                path={urls.LEARNER_ORDER.path}
                element={<urls.LEARNER_ORDER.component />}
              />
              <Route
                path={urls.ORGANIZATION_ORDER.path}
                element={<urls.ORGANIZATION_ORDER.component />}
              />
              <Route
                path={urls.ANALYTICS_COURSE.path}
                element={<urls.ANALYTICS_COURSE.component />}
              />

               <Route
                path={urls.TRAINING_TICKET.path}
                element={<urls.TRAINING_TICKET.component />}
              />

              <Route
                path={urls.REVENUE.path}
                element={<urls.REVENUE.component />}
              />
              <Route
                path={urls.REVENUELIST.path}
                element={<urls.REVENUELIST.component />}
              />

              <Route
                path={urls.COURSEWISEREVENUELIST.path}
                element={<urls.COURSEWISEREVENUELIST.component />}
              />

              <Route
                path={urls.SCHEMAWISEREVENUELIST.path}
                element={<urls.SCHEMAWISEREVENUELIST.component />}
              />

              <Route
                path={urls.COUPONREVENUELIST.path}
                element={<urls.COUPONREVENUELIST.component />}
              />

              <Route
                path={urls.COUPONORDERLIST.path}
                element={<urls.COUPONORDERLIST.component />}
              />

              <Route
                path={urls.LOGOUT.path}
                element={<urls.LOGOUT.component />}
              />

             

               {
                role_modules && role_modules.length >= 1 ? 
                role_modules.map( module => {
                  let component = <urls.NOT_FOUND.component />
                  if(module.module.url == "training"){
                    component = <urls.TRAINING.component />
                  }else if(module.module.url == "ticket"){
                    component = <urls.TICKET.component />
                  }else if(module.module.url == "payment_orders"){
                    component = <urls.PAYMENT_ORDERS.component />
                  }else if(module.module.url == "reports"){
                    component = <urls.COMMING_SOON.component />   // module under developemnt
                  }else if(module.module.url == "tranning_resit_request"){
                    component = <urls.COMMING_SOON.component />   // module under developemnt
                  }else if(module.module.url == "course"){
                    component = <urls.COURSE.component />   
                  }else if(module.module.url == "course_schedule"){
                    component = <urls.COURSE_SCHEDULE.component />   
                  }else if(module.module.url == "clients"){
                    component = <urls.CLIENTS.component />   
                  }else if(module.module.url == "insight"){
                    component = <urls.INSIGHT.component />   
                  }else if(module.module.url == "insight_category"){
                    component = <urls.INSIGHT_CATEGORY.component />  
                  }else if(module.module.url == "testimonial"){
                    component = <urls.TESTIMONIAL.component />  
                  }else if(module.module.url == "venue"){
                    component = <urls.VENUE.component />  
                  }else if(module.module.url == "learners"){
                    component = <urls.LEARNERS.component />  
                  }else if(module.module.url == "consulting_scheme"){
                    component = <urls.LEARNING_SCHEME.component />  
                  }else if(module.module.url == "lca_templates"){
                    component = <urls.LCA_TAMPLATES.component />  
                  }else if(module.module.url == "exam"){
                    component = <urls.EXAM_ASS.component />  
                  }else if(module.module.url == "exam_section"){
                    component = <urls.EXAM_SECTION_ASS.component />  
                  }else if(module.module.url == "news_update"){
                    component = <urls.NEWSUPDATE.component />  
                  }else if(module.module.url == "evaluation_templates"){
                    component = <urls.EVALUATION_TAMPLATES_ASS.component />  
                  }else if(module.module.url == "evaluation_questions_ass"){
                    component = <urls.EVALUATION_QUESTIONS_ASS.component />  
                  }else if(module.module.url == "evaluation_question_option"){
                    component = <urls.EVALUATION_QUESTION_OPTION.component />  
                  }else if(module.module.url == "consultants"){
                    component = <urls.CONSULTANTS.component />  
                  }else if(module.module.url == "partners"){
                    component = <urls.PARTNERS.component />  
                  }else if(module.module.url == "tranning_learner"){
                    component = <urls.TRANNING_LEARNER.component />  
                  }else if(module.module.url == "tranning_result"){
                    component = <urls.TRANNING_RESULT.component />  
                  }else if(module.module.url == "tranning_result_2"){
                    component = <urls.TRANNING_RESULT_2.component />
                  }else if(module.module.url == "tranning_learner_attendance"){
                    component = <urls.TRANNING_LEARNER_ATTENDANCE.component />   
                  }else if(module.module.url == "Lca_exam_result"){
                    component = <urls.LCA_EXAM_RESULT.component />  
                  }else if(module.module.url == "certificate_preview"){
                    component = <urls.CERTIFICATE_PREVIEW.component />  
                  }else if(module.module.url == "learning_pathways"){
                    component = <urls.COMMING_SOON.component />   // module under developemnt
                  }else if(module.module.url == "consultant_performance_review_data"){
                    component = <urls.COMMING_SOON.component />   // module under developemnt
                  }
                  return <Route path={module.module.url} element={component} />
                }) : null
              } 
               <Route
                path={urls.NOT_FOUND.path}
                element={<urls.NOT_FOUND.component />}
              /> 
            </Routes>
          </Content>
        </Layout>
      </Router>
    </Layout>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
    role_modules: state.role_modules
  };
};
export default connect(mapStateToProps)(LmsPortal);
