import React from "react";
import { Tooltip } from "antd";
function MyToolTip(props) {
  const { title, children, placement, color } = props;
  return (
    <Tooltip
      title={title}
      placement={placement ? placement : "top"}
      color={color ? color : ""}
    >
      {children}
    </Tooltip>
  );
}
export default MyToolTip;
