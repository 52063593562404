import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Checkbox, Radio  } from "antd";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { lca_exam_result_api } from "../../library/lca_exam_result_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
import { SettingOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

// ===============================================================================
class Lca_exam_result extends FormParent {
  constructor(props) {
    //console.log("rendered re", props);
    super(props);
    this.state = {
      page_api: lca_exam_result_api,
      pageId: "",
      _id:"",
      filter: {},
      records: {},
      request: {},
      validation: {},
      validation_info: {
        version: { valMet: vj_val.checkLength, valLen: 1 },
        title: { valMet: vj_val.checkLength, valLen: 1 },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        description: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
      edit:false,
      isLock:false,
      checkedList:{},
      checkedCount:0,
      viewData:{},
      examResult:[],
      exam_type:""
    };
  }

  //************* edit mode and view mode on off */
  viewModeToeditMode = () => {
    this.renderFormData()
    this.setState({edit: true, CDM: false});
  }   

  viewModeToViewMode = () => {
    this.onPageRaload()
    this.setState({edit: false, CDM: false});
  }

  statusHandler = async (status, type) => {
    let { Chabi, _id } = this.state;
    const url = lca_exam_result_api+"status";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request:{ status, type, _id: _id  },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("records updated");
        return window.history.back()
      }else if(data.status == 4000){
        message.success("no records to update");
        return window.history.back()
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  }

 
  componentDidMount = async () => {
    if(window.location.search != ""){
    let url= new URLSearchParams(window.location.search);
    let pageId = url.get("training")
    let userId = url.get("identifier")
     pageId = atob(atob(pageId))
     userId = atob(atob(userId))
    
    userId = userId.split("_");
    let exam_type = url.get("moduletype")
    this.setState({ pageId, userId: userId[1], orderId: userId[0], orderType: userId[2], exam_type})

    let identified = btoa(btoa(JSON.stringify({ 
      traningId: pageId, 
      userId: userId[1], 
      orderId: userId[0], 
      orderType: userId[2]
    })));

    const { Chabi } = this.state;
    const Apiurl = lca_exam_result_api +"get";
    const response = await myAxios({
      method: "post",
      url:Apiurl,
      header: { Chabi },
      request: {
        identified,
        exam_type: +exam_type
    }
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 200){
        let publisherArr = []
        let { learner, examResult,trainingData, order} = data.records[0]
        this.setState({ _id : examResult[0]._id});
        let eleArr = examResult.map( exam => {
          exam.days.exam_section.map( section => publisherArr.push(section.gradeMarks == 0 ? false : true))
         
            return   <>
            <h4 className="daystitle">Day {exam.days.day}</h4>
            <table class="table table-bordered daysTbl m-0 mb-3">
            <tr>
                <td>Seq.</td>
                <td>Ref. No.</td>
                <td>Topic</td>
                <td>CU</td>
                <td className="text-center">Grade
                  <table class="gradeTbl">
                    <tr>
                      <td>10</td>
                      <td>9</td>
                      <td>8</td>
                      <td>7</td>
                      <td>6</td>
                      <td>5</td>
                      <td>4</td>
                      <td>3</td>
                      <td>2</td>
                      </tr>
                  </table>
                </td>
              </tr>
                { exam.days.exam_section.map( (exam_result_section) => {
                    return <>
              <tr>
                <td>{exam_result_section.sequence}</td>
                <td>{exam_result_section.ref_number}</td>
                <td style={{width:"30%"}}>{exam_result_section.examination_topic}</td>
                <td>{exam_result_section.cu_name}</td>
                <td className="text-center" style={{width:"30%"}}>
                  <table class="gradeTbl">
                    <tr>
                        {exam_result_section.gradeMarks == 0 ? exam_result_section.grade.map( (grade,index) => {
                            return <td><input type="radio" disabled checked={grade}></input></td>
                        }) : exam_result_section.gradeMarks >= 7 ? exam_result_section.grade.map( (grade,index) => {
                          return <td><input type="radio" disabled checked={grade}></input></td>
                      }) : <button className="btn btn-sm btn-warning" onClick={() => this.renderReattemptFormData(exam_result_section._id)}>Update Reattempt Marks</button>}
                      </tr>
                  </table>
                </td>
              </tr>
                    </>
                })}
            </table>
            </>

        });
            
            
          let finalEle =   <div className="row mt-1 mb-1">
            <div className="col-12 text-left">
                <table class="table table-bordered attendence-table">
                  <tr>
                    <td>Course Name:</td>
                    <td>{trainingData[0].courseData.main_head}</td>
                  </tr>
                  <tr>
                    <td>Student Name:</td>
                    <td>{`${learner.first_name} ${learner.last_name}`}</td>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <td>{`${trainingData[0].deliveryMethods.title},${countryListAlpha2[learner.country]}`}</td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td>{trainingData[0].is365 == 1 ? order.split("T")[0] : `${trainingData[0].dateArr.start.day+"-"+trainingData[0].dateArr.start.month+"-"+trainingData[0].dateArr.start.year	} to ${trainingData[0].dateArr.end.day+"-"+trainingData[0].dateArr.end.month+"-"+trainingData[0].dateArr.end.year	}`}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{(examResult[0].isPublish == 0) ? <span className="text-danger">Not Published</span> : <span className="text-success">Published</span>}</td>
                  </tr>

                </table>
                {eleArr}
                <div className="row mt-1">
                  <div className="col-12 text-left">
                    {/* {console.log("arr", publisherArr)} */}
                    <button type="submit" disabled={!publisherArr.includes(true)} className="btn btn-primary btn-sm" onClick={() => this.statusHandler((examResult[0].isPublish == 1) ? 0 : 1, "isPublish")}>{(examResult[0].isPublish == 0) ? "Publish" : "UnPublish"}</button>
                    
                    <button type="submit" className="btn btn-primary btn-sm ml-2" onClick={() => this.statusHandler((examResult[0].isLock == 1) ? 0 : 1, "isLock")} disabled={!publisherArr.includes(true) || examResult[0].isPublish == 1 ? true : false}>{(examResult[0].isLock == 0) ? "Lock" : "UnLock"}</button>
                   
                    <button type="submit" className="btn btn-primary btn-sm ml-2" onClick={() => this.viewModeToeditMode({ examResult : examResult[0]})}>Update</button>
                  </div>
                </div>
              
            </div>
          </div>
    
        this.setState({ records : data.records, viewData: finalEle});
      }else{
        alert(data.message)
      }
    }
 
    this.setState({ CDM: true });
    }else{
        alert("you want to access unregistered routes... pls press ok and go back");
        window.history.back();
        return false
    }

  };

  // function calling for reload page data
  onPageRaload = async () => {
    if(window.location.search != ""){
      let url= new URLSearchParams(window.location.search);
      let pageId = url.get("training")
      let userId = url.get("identifier")
       pageId = atob(atob(pageId))
       userId = atob(atob(userId))
      
      userId = userId.split("_");
      let exam_type = url.get("moduletype")
      this.setState({ 
        pageId, 
        userId: userId[1], 
        orderId: userId[0], 
        orderType: userId[2], 
        exam_type
      })
  
      let identified = btoa(btoa(JSON.stringify({ 
        traningId: pageId, 
        userId: userId[1], 
        orderId: userId[0], 
        orderType: userId[2]
      })));
  
      const { Chabi } = this.state;
      const Apiurl = lca_exam_result_api +"get";
      const response = await myAxios({
        method: "post",
        url:Apiurl,
        header: { Chabi },
        request: {
          identified,
          exam_type
      }
      });
      if (response.status == 200) {
        const { data } = response;
        if(data.status == 200){
          let { learner, examResult,trainingData, order} = data.records[0]
          let eleArr = examResult.map( exam => {
              //console.log("exam",exam)
              return   <>
              <h4 className="daystitle">Day {exam.days.day}</h4>
              <table class="table table-bordered daysTbl m-0 mb-3">
              <tr>
                  <td>Seq.</td>
                  <td>Ref. No.</td>
                  <td>Topic</td>
                  <td>CU</td>
                  <td className="text-center">Grade
                    <table class="gradeTbl">
                      <tr>
                        <td>10</td>
                        <td>9</td>
                        <td>8</td>
                        <td>7</td>
                        <td>6</td>
                        <td>5</td>
                        <td>4</td>
                        <td>3</td>
                        <td>2</td>
                        </tr>
                    </table>
                  </td>
                </tr>
                  { exam.days.exam_section.map( (exam_result_section) => {
                      return <>
                <tr>
                  <td>{exam_result_section.sequence}</td>
                  <td>{exam_result_section.ref_number}</td>
                  <td style={{width:"30%"}}>{exam_result_section.examination_topic}</td>
                  <td>{exam_result_section.cu_name}</td>
                  <td className="text-center" style={{width:"30%"}}>
                    <table class="gradeTbl">
                      <tr>
                      {exam_result_section.gradeMarks == 0 ? exam_result_section.grade.map( (grade,index) => {
                            return <td><input type="radio" disabled checked={grade}></input></td>
                        }) : exam_result_section.gradeMarks >= 7 ? exam_result_section.grade.map( (grade,index) => {
                          return <td><input type="radio" disabled checked={grade}></input></td>
                      }) : <button className="btn btn-sm btn-warning" onClick={() => this.renderReattemptFormData(exam_result_section._id)}>Update Reattempt Marks</button>}
                        </tr>
                    </table>
                  </td>
                </tr>
                      </>
                  })}
              </table>
              </>
  
          });
              
              
            let finalEle =   <div className="row mt-1 mb-1">
              <div className="col-12 text-left">
                  <table class="table table-bordered attendence-table">
                    <tr>
                      <td>Course Name:</td>
                      <td>{trainingData[0].courseData.main_head}</td>
                    </tr>
                    <tr>
                      <td>Student Name:</td>
                      <td>{`${learner.first_name} ${learner.last_name}`}</td>
                    </tr>
                    <tr>
                      <td>Location:</td>
                      <td>{`${trainingData[0].deliveryMethods.title},${countryListAlpha2[learner.country]}`}</td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td>{trainingData[0].is365 == 1 ? order.split("T")[0] : `${trainingData[0].dateArr.start.day+"-"+trainingData[0].dateArr.start.month+"-"+trainingData[0].dateArr.start.year	} to ${trainingData[0].dateArr.end.day+"-"+trainingData[0].dateArr.end.month+"-"+trainingData[0].dateArr.end.year	}`}</td>
                    </tr>
                    <tr>
                      <td>Status:</td>
                      <td>{(examResult[0].isPublish == 0) ? <span className="text-danger">Not Published</span> : <span className="text-success">Published</span>}</td>
                    </tr>
  
                  </table>
                  {eleArr}
                  <div className="row mt-1">
                    <div className="col-12 text-left">
                    <button type="submit" className="btn btn-primary btn-sm" onClick={() => this.statusHandler((examResult[0].isPublish == 1) ? 0 : 1, "isPublish")}>{(examResult[0].isPublish == 0) ? "Publish" : "UnPublish"}</button>
                    
                    <button type="submit" className="btn btn-primary btn-sm ml-2" onClick={() => this.statusHandler((examResult[0].isLock == 1) ? 0 : 1, "isLock")} disabled={examResult[0].isPublish == 1 ? true : false}>{(examResult[0].isLock == 0) ? "Lock" : "UnLock"}</button>
                   
                    <button type="submit" className="btn btn-primary btn-sm ml-2" onClick={() => this.viewModeToeditMode({ examResult : examResult[0]})}>Update</button>
                    </div>
                  </div>
                
              </div>
            </div>
      
          this.setState({ records : data.records, viewData: finalEle});
        }else{
          alert(data.message)
        }
      }
   
      this.setState({ CDM: true });
      }else{
          alert("you want to access unregistered routes... pls press ok and go back");
          window.history.back();
          return false
      }
  }

  renderFormData = async () => {
   
    if(window.location.search != ""){
      let url= new URLSearchParams(window.location.search);
      let pageId = url.get("training")
      let userId = url.get("identifier")
       pageId = atob(atob(pageId))
       userId = atob(atob(userId))
      
      userId = userId.split("_");
      let exam_type = url.get("moduletype")
      this.setState({  pageId, 
        userId: userId[1], 
        orderId: userId[0], 
        orderType: userId[2], 
        exam_type
      })
  
      // let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0]})));
      let identified = btoa(btoa(JSON.stringify({ 
        traningId: pageId, 
        userId: userId[1], 
        orderId: userId[0], 
        orderType: userId[2]
      })));
  
  
      const { Chabi } = this.state;
      const Apiurl = lca_exam_result_api +"get";
      const response = await myAxios({
        method: "post",
        url:Apiurl,
        header: { Chabi },
        request: {
          identified,
          exam_type
      }
      });
   
      if (response.status == 200) {
        const { data } = response;
      if(data.status == 200){
        let { learner, examResult,trainingData} = data.records[0]
        let maxI = []
        for(let i = 0; i < examResult.length; i++){
            let days = examResult[i].days.exam_section;
            for(let j = 0; j < days.length; j++){
               let exam_sections = days[j];
               maxI.push(exam_sections);
            }
        }

        this.setState({ records : data.records, request : {examResult:[], examSection: maxI } });
      }else{
        alert(data.message)
      }
         this.setState({ CDM: true });
      }
  }else{
    alert("you want to access unregistered routes... pls press ok and go back");
    window.history.back();
    return false
}
}

renderReattemptFormData = async (sectionId) => {
   //console.log(sectionId);
  // return false
  if(window.location.search != ""){
    let url= new URLSearchParams(window.location.search);
    let pageId = url.get("training")
    let userId = url.get("identifier")
     pageId = atob(atob(pageId))
     userId = atob(atob(userId))
    
    userId = userId.split("_");
    let exam_type = url.get("moduletype")
    // this.setState({ pageId, userId: userId[1], orderId: userId[0], exam_type})
    this.setState({  pageId, 
      userId: userId[1], 
      orderId: userId[0], 
      orderType: userId[2], 
      exam_type
    })

    // let identified = btoa(btoa(JSON.stringify({ traningId: pageId, userId: userId[1], orderId: userId[0]})));
    let identified = btoa(btoa(JSON.stringify({ 
      traningId: pageId, 
      userId: userId[1], 
      orderId: userId[0], 
      orderType: userId[2]
    })));


    const { Chabi } = this.state;
    const Apiurl = lca_exam_result_api +"get";
    const response = await myAxios({
      method: "post",
      url:Apiurl,
      header: { Chabi },
      request: {
        identified,
        exam_type
    }
    });
 
    if (response.status == 200) {
      const { data } = response;
    if(data.status == 200){
      let { learner, examResult,trainingData} = data.records[0]
      let maxI = []
      let examRsultArr = []
      for(let i = 0; i < examResult.length; i++){
          let days = examResult[i].days.exam_section;
          let arr = []
          for(let j = 0; j < days.length; j++){
             let exam_sections = days[j];
             maxI.push({...exam_sections, edit: exam_sections._id == sectionId ? true : false});
             arr.push({...exam_sections, edit: exam_sections._id == sectionId ? true : false})
          }
          examRsultArr.push({...examResult[i], days: { exam_section: arr}})
      }
      this.setState({
         edit: true, 
         CDM: false, 
        //  records: examRsultArr, 
         records : [{...data.records[0], examResult:examRsultArr }], 
         request : {examResult:[], examSection: maxI } });
    }else{
      alert(data.message)
    }
       this.setState({ CDM: true });
    }
}else{
  alert("you want to access unregistered routes... pls press ok and go back");
  window.history.back();
  return false
}
}

  submit = async () => {
    let updateArr = this.state.request.examResult.map( _ => {
       return _[0]
    })
    //console.log(updateArr);
    let { Chabi, request, orderId, pageId, userId } = this.state;
    const url = lca_exam_result_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request:{ updateArr  },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("records updated");
        return window.history.back()
      }else if(data.status == 4000){
        message.success("no records to update");
        return window.history.back()
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  checboxHandler = (e) => {
    let ele = e.target;
    let eleVal = ele.value.split("_");
     let index = eleVal[2]
    let id = eleVal[1]

    // let index = ele.value.index
    // let id = ele.value.id
    this.setState({ [`${id}_${index}`] : ele.checked});
    let updateX = []
    this.state.request.examSection.map( (_ , i) => {
         if(_._id == id){
          let gradeArr = [false,false,false,false,false,false,false,false,false].map( (gradeVal,i) => (i == +index) ? true : false)
          updateX.push({..._, grade: gradeArr})
         }
    })  

    if(this.state.request.examResult.length >= 1){
      let isPushed = false;  // this variable create for if it's true then at last index 
    for(let i = 0; i < this.state.request.examResult.length; i++){ 
      let stateVal = this.state.request.examResult[i];
      if(stateVal[0]._id == updateX[0]._id){
        let examRes = [...this.state.request.examResult]
        examRes[i] = updateX
        this.setState({ request : { examSection : this.state.request.examSection, examResult : examRes}})
        isPushed = true
      }

      if(i == this.state.request.examResult.length-1){
        if(!isPushed){
        this.setState({ request : { examSection : this.state.request.examSection, examResult : [...this.state.request.examResult, updateX]}})
        }
      }
    }
  }else{
    this.setState({ request : { examSection : this.state.request.examSection, examResult : [...this.state.request.examResult, updateX]}})
  }
    
}


  render() {
    //console.log("Training Enquiry State========> ", this.state);
    let {
      records,
      loginStatus,
      filtered_records,
      cmodal,
      selectedRecord,
      comment,
      coffcanvas,
      region2,
      CDM,
      edit,
      isLock,
      viewData,
      examResult,
      exam_type
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
   
    
    return (
      <>
        <PageHeading2
          active="Training"
          page={["Training", "Learners", "LCA Result"]}
        ></PageHeading2>
        <MainArea>
            {CDM ? 
           (records.length >= 1)  ? 
           <div className="p-4">
           <p className="info-main-heading">LCA Result</p>
           {(edit) ? 
           <div className="row mt-1 mb-1">
             <div className="col-12 text-left">
             
                 <table class="table table-bordered attendence-table">
                 <tr>
                    <td>Course Name:</td>
                    <td>{records[0].trainingData[0].courseData.main_head}</td>
                  </tr>
                  <tr>
                    <td>Student Name:</td>
                    <td>{`${records[0].learner.first_name} ${records[0].learner.last_name}`}</td>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <td>{`${records[0].trainingData[0].deliveryMethods.title},${countryListAlpha2[records[0].learner.country]}`}</td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td>{records[0].trainingData[0].is365 == 1 ? records[0].order.split("T")[0] :  `${records[0].trainingData[0].dateArr.start.day+"-"+records[0].trainingData[0].dateArr.start.month+"-"+records[0].trainingData[0].dateArr.start.year	} to ${records[0].trainingData[0].dateArr.end.day+"-"+records[0].trainingData[0].dateArr.end.month+"-"+records[0].trainingData[0].dateArr.end.year	}`}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{(records[0].examResult[0].isPublish == 0) ? <span className="text-danger">Not Published</span> : <span className="text-success">Published</span>}</td>
                  </tr>

                 </table>
                {
            records[0].examResult.map( exam => {
            return   <>
            <h4 className="daystitle">Day {exam.days.day}</h4>
            <table class="table table-bordered daysTbl m-0 mb-3">
            <tr>
                <td>Seq.</td>
                <td>Ref. No.</td>
                <td>Topic</td>
                <td>CU</td>
                <td className="text-center">Grade
                  <table class="gradeTbl">
                    <tr>
                      <td>10</td>
                      <td>9</td>
                      <td>8</td>
                      <td>7</td>
                      <td>6</td>
                      <td>5</td>
                      <td>4</td>
                      <td>3</td>
                      <td>2</td>
                      </tr>
                  </table>
                </td>
              </tr>
                { exam.days.exam_section.map( (exam_result_section) => {
                    return <>
              <tr>
                <td>{exam_result_section.sequence}</td>
                <td>{exam_result_section.ref_number}</td>
                <td style={{width:"30%"}}>{exam_result_section.examination_topic}</td>
                <td>{exam_result_section.cu_name}</td>
                <td className="text-center" style={{width:"30%"}}>
                  <table class="gradeTbl">
                    <tr className="widthdiv">

                      {
                      (exam_result_section.edit == undefined) ?  <Radio.Group onChange={(e) => this.checboxHandler(e)} defaultValue={exam_result_section.grade.indexOf(true) != -1 ? `${exam.days._id}_${exam_result_section._id}_${exam_result_section.grade.indexOf(true)}` : ""}>
                       { exam_result_section.grade.map( (grade,index) => {
                          return <td><Radio id={index} value={`${exam.days._id}_${exam_result_section._id}_${index}`}/></td>
                        })}
                      </Radio.Group> :
                      (exam_result_section.edit) ? <Radio.Group onChange={(e) => this.checboxHandler(e)} defaultValue={exam_result_section.grade.indexOf(true) != -1 ? `${exam.days._id}_${exam_result_section._id}_${exam_result_section.grade.indexOf(true)}` : ""}>
                        {exam_result_section.grade.map( (grade,index) => {
                            // return <td><Radio checked={grade} id={index} value={{parentId: exam.days._id, id: exam_result_section._id, index}}/></td>
                            // console.log(grade)
                              return <td><Radio id={index} value={`${exam.days._id}_${exam_result_section._id}_${index}`}/></td>
                            })}
                        </Radio.Group> : "-"
                        
                      }
                    
                      </tr>
                  </table>
                </td>
              </tr>
                    </>
                })}
            </table>
            </>

        })}
                 <div className="row mt-1">
                   <div className="col-12 text-left">
                     <button type="submit" className="btn btn-primary btn-sm" onClick={() => this.submit()}>Save</button>
                     <button type="submit" className="btn btn-danger btn-sm ml-2" onClick={() => this.viewModeToViewMode()}>Cancel</button>
                   </div>
                 </div>
              
             </div>
           </div> : viewData }

         </div> : <MyEmpty />: <MyLoading /> }
              
        </MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Lca_exam_result);
