import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { pre_order_api } from "../../library/pre_order_api";
import { learners_api } from "../../library/learners_api";
import { country_api } from "../../library/country_api";
import { states_api } from "../../library/states_api";
import { payment_api} from '../../library/payment_api';
import { dashboard_api } from "../../library/dashboard_api";
import { permission_api } from "../../library/permission_api";
import { sales_target_api } from "../../library/sales_target_api";
import axios from "axios"
import { Row, Col, Select, DatePicker, Input, message, Upload, Image   } from "antd";
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
import moment from "moment";
// ===============================================================================
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormDateGroup from "../part/Form/FormDateGroup";
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import CustomOffCanvas from "../part/CustomOffCanvas";
import { get_data } from "../server_data/get_data";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { currency_obj } from "../local_data/currency";
import { vj_val } from "../../library/validation_function";
import { PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
// ===============================================================================

class Revenue extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: pre_order_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      uploadImageList: [],
      validation: {},
      validation_info: {
        paymentDate: { valMet: vj_val.checkLength, valLen: 1 },
        paymentAmt: { valMet: vj_val.checkLength, valLen: 1 },
        transactionId: { valMet: vj_val.checkLength, valLen: 1 },
      },
      cmodal: false,
      coffcanvas: false,
	    poffcanvas: false,
      selectedRecord: {},
      serchTxt: "",
      startDt:"",
      endDt:"",
      orderType:["1","2"],
      countryList:[],
      stateList:[],
      paymentCmodel:false,
      uploadFileList:"",
      paymentHistory:[],
      CDMI:false,
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      },
      region:[],
      learning_scheme:[],
      dahboardString:""
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 50,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    create_at: {
      title: "Order Meta",
      width: 300,
      // sorter: true,
      ellipsis: true,
      order: 1,
      custom: (text, record) => {
        return <>
           <span><b>Booking Type:</b></span> <span>{(record.payment_mode == "2") ? "PO Order" : "Online Payment"}</span>
           <br />
           <span><b>Booking Id:</b></span>{record._id}
           <br />
           <span><b>Booking Date:</b></span> <span>{record.create_at}</span>
     </>
      }
    },
    currency: {
        title: "Course Meta",
        width: 350,
        ellipsis: false,
        order: 2,
        custom: (text, record) => {
            let items = record.orderType == "1" ? record.items[0] : record.product
            let deliveryMethod = record.orderType == "1" ? record.items[0].delivery_method : record.product.delivery_method
            const delivery_method_name = this.state.delivery_method.filter((r) => r._id == deliveryMethod)[0];
          return <>
             <span><b>Training: </b>{items.course_title}</span> 
                <br />
                <span><b>Start Date:</b></span> <span>{items.is365 == 1 ? moment(record.create_at).format("DD/MM/YYYY") : items.start_date}</span>
                <br />
                <span><b>Delivery method:</b></span>{delivery_method_name?.title}

          </>
          }
      },
    
      orderBy: {
      title: "Learner",
      width: 250,
      // sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => {
        let items = record.orderType == "1" ? record.learners : record.learners
        return items.map(rec => {
          return <>
          <span><b>Name:</b> {`${rec?.first_name} ${rec.last_name}`}</span><br />
          <span><b>Email:</b> {`${rec?.email}`}</span>
        </>
        })
        
      },
    },
    amount:{
      title: "Amount",
      width: 150,
      className: "partial_amount",
      order: 7,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        const { currency, total_amount } = record;
        return `${currency ? currency == "INR" ? <FontAwesomeIcon icon={faIndianRupeeSign} style={{fontWeight:100, fontSize:10}} /> : currency_obj[currency].symbol_native : ""} ${total_amount}`
      }
    },
  
    status: {
      title: "Payment Status",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 8,
      custom: (text, record) => {
        return record.payment_status == 1 ? <span className="text-success">success</span> : <span className="text-danger">Pending</span>
      }
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    //============= define permission =============
    let queryParms = new URLSearchParams(window.location.search);
		let regionId = queryParms.get("regionId")
		let gte = queryParms.get("gte")
		let lt = queryParms.get("lt")
		let type = queryParms.get("type")
		let pageregion = queryParms.get("region")
		let year = queryParms.get("year")
        let filterType = queryParms.get("filterType")
    let month = queryParms.get("month")

   //=============================================

    const url = `${dashboard_api}/getRevenueReport?regionId=${regionId}&gte=${gte}&lt=${lt}&filterType=${filterType}`;
    const order = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });
   

    const urlGet = country_api+"dropdown/state/get";
    const countryList = await myAxios({
      method: "get",
      url: urlGet,
      header: { Chabi },
    });

    const response = await get_data({
      others: [
        "region",
        "organization",
        "course",
        "language",
        "delivery_method",
        "learning_scheme",
      ],
      primary: "",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          region,
          records,
          organization,
          course,
          language,
          delivery_method,
          learning_scheme,
        } = data;
        const learner_ids = records.map(({ user_id }) => user_id);
        const url = learners_api + "/learner_list";
        const learner_ids_response = await myAxios({
          method: "post",
          url,
          request: { learner_ids },
          header: { Chabi },
        });
        records = add_sno(order.data.records);
        let learner2 = create_object({
          arr: learner_ids_response.data.records,
          title: "first_name",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let learning_scheme2 = create_object({
            arr: learning_scheme,
            title: "main_head",
          });

        this.setState({
          records,
          region,
          learner2,
          region2,
          organization2,
          course2,
          language2,
          delivery_method2,
          delivery_method,
          learning_scheme,
          learning_scheme2,
          countryList: countryList.data.records,
          dahboardString: `${type == "yearly" ? "Yearly-"+pageregion : pageregion}${month != undefined ? "-"+month : ""}-${year}`
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
 
  fiterResult = async (type) => {
    let queryParms = new URLSearchParams(window.location.search);
		let regionId = queryParms.get("regionId")
		let gte = queryParms.get("gte")
		let lt = queryParms.get("lt")
        let filterType = queryParms.get("filterType")
    const { Chabi, yearlyYear } = this.state;

    if(yearlyYear == undefined || yearlyYear.length == 0){
      gte = queryParms.get("gte")
      lt = queryParms.get("lt")
    }else{
      gte = yearlyYear[0] == "" ? gte : new Date(yearlyYear[0]).toISOString()
      lt = yearlyYear[1] == "" ? lt : new Date(yearlyYear[1]).toISOString()
    }
    this.setState({ CDM: false });

    const url = `${dashboard_api}/getRevenueReport?regionId=${regionId}&gte=${gte}&lt=${lt}&filterType=${filterType}`;
    // const url = type != "export" ? `${sales_target_api}/salesTargetReport?regionId=${regionId}&gte=${gte}&lt=${lt}` : `${sales_target_api}/exportToExcel?regionId=${regionId}&gte=${gte}&lt=${lt}`;
    const response = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });
    if (response.status == 200) {
      if(type == "export"){
        console.log(response.data)
        const url = window.URL.createObjectURL([response.data]);
        const link = document.createElement("a");
        link.href = url;
        link.download = "salesReport.xlsx";
        document.body.appendChild(link);
        link.click();
        // Clean up the URL object when the download is complete
        URL.revokeObjectURL(url);
        return false
      }
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
          let {
            records,
          } = data;
          records = add_sno(records);
          this.setState({ records })
        
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  exportToExcel = async () => {

    let queryParms = new URLSearchParams(window.location.search);
		let regionId = queryParms.get("regionId")
		let gte = queryParms.get("gte")
		let lt = queryParms.get("lt")
        let filterType = queryParms.get("filterType")
    const { Chabi, yearlyYear } = this.state;

    if(yearlyYear == undefined || yearlyYear.length == 0){
      gte = queryParms.get("gte")
      lt = queryParms.get("lt")
    }else{
      gte = yearlyYear[0] == "" ? gte : new Date(yearlyYear[0]).toISOString()
      lt = yearlyYear[1] == "" ? lt : new Date(yearlyYear[1]).toISOString()
    }
    this.setState({ CDM: false });

    const urls = `${dashboard_api}/exportRevenueReport?regionId=${regionId}&gte=${gte}&lt=${lt}&filterType=${filterType}`;

    const response = await axios({
      url: urls,
      method: "get", // Changed to POST
      responseType: "blob",
      headers: {
        token: Chabi,
        "Content-Type": "application/json", // Added content type for POST request
      },
    });
  
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.download = "revenue.xlsx";
    document.body.appendChild(link);
    link.click();
    // Clean up the URL object when the download is complete
    URL.revokeObjectURL(url);
    this.setState({ CDM: true });
    message.success("report downloaded")
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }

  countryHandler = async (value) => {
    const { request } = this.state;   
      let stateList = []
      let { Chabi , records} = this.state;
      const urlGet = states_api+"dropDown/get?countryCode="+value;
        const countryList = await myAxios({
          method: "get",
          url: urlGet,
          header: { Chabi },
        });
        stateList = countryList.data.records;
        let data = request;
        delete data['states']
        this.setState({ country : value, stateList });
  }


  reloadTable = async () => {
    this.setState({ CDMI: false });
    const { Chabi, serchTxt, startDt, endDt, orderType, country, stateId } = this.state;
 
    const url = `${pre_order_api}`;
    const order = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {serchTxt, startDt, endDt, orderType,  country, stateId, payment_status: 1}
    });

    if (order.status == 200) {
      const { data } = order;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(order.data.records);
        this.setState({ records })
      }
    }else console.log("Network Error");
    this.setState({ CDM: true });
  }


  render() {
    const { RangePicker } = DatePicker;
    console.log("Pre Order State ", this.state);
    let {
      records,
      loginStatus,
      filtered_records,
      selectedRecord,
      CDM,
      permission,
      region,
      dahboardString
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    return (
      <>
        <PageHeading2
          active="Sales target details"
          page={["Dahboard", "Sales target details"]}
        ></PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
          
            <Col span={4} style={{marginLeft: 10}}>
              <label>Order Date</label>
              <RangePicker onChange={(e) => this.dateChanger(e)}/>
            </Col>
            <Col span={4} style={{marginLeft: 10}}>
            <label>Search by Payment Ref/Order No.</label>
            <Input placeholder="Search by payment ref and order number" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
            <label>Search Learner by Email</label>
            <Input placeholder="Search Learner by Email" onChange={(e) => this.setState({ searchEmail: e.target.value})}/>
            </Col>

            <Col span={4} style={{marginLeft: 10}}>
              <label>Scheme</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose Scheme"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_scheme : e})}
               options={learning_scheme.map(_ => { return { value : _._id, label: _.main_head}})}
            />
            </Col>

            <Col span={4} style={{marginLeft: 10}}>
              <label>Region</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Region"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_region : e})}
               options={region.map( rec => {return { label:rec.region_name , value:rec._id }})}
            />
            </Col>

            <Col span={4} style={{marginLeft: 10}}>
              <label>Country</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Country"
              //  defaultValue={[""]}
               onChange={(e) => this.countryHandler(e)}
               options={countryList.map( rec => {return { label:rec.country_name , value:rec.isoCode }})}
            />
            </Col>
            <Col span={4} style={{marginLeft: 10}}>
              <label>State</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose State"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ stateId: e})}
               options={stateList.map( rec => {return { label:rec.name , value:rec._id }})}
            />
            </Col>
         
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()} style={{ marginTop: "21px" }}>
                Submit
              </button>
            </Col>
          </Row> */}
          <Row>
            <h3>{dahboardString}</h3>
          </Row>
          <Row style={{ marginTop: "20px", marginBottom: 20}}>
                   
					<Col span={6} style={{ marginLeft: 10 }}>
                      <RangePicker 
                      onChange={(e,value) => this.setState({ yearlyYear: value })} 
                      placeholder="Choose Year" style={{ width: 350 }}/>
          </Col>
					<Col className="m-6">
					<button className="btn btn-sm btn-primary" onClick={() => this.fiterResult("filter")}>Submit</button>
					<button className="ml-2 btn-sm btn btn-success" onClick={() => this.exportToExcel("export")}>Export To Excel</button>
                    <button className="ml-2 btn-sm btn btn-warning" onClick={() => window.print()}>Generate Report</button>
                    </Col>

                    {/* end of filter sections */}
                  </Row>
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
