import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col, Alert } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { consultant_api } from "../../library/consultant_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { get_region } from "../server_data/list_data";
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Consultants extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: consultant_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        name: { valMet: vj_val.checkLength, valLen: 1 },
        email: { valMet: vj_val.checkLength, valLen: 1 },
        mobile: { valMet: vj_val.checkLength, valLen: 1 },
        country: { valMet: vj_val.checkLength, valLen: 1 },
        training_consultant: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    name: {
      title: "Name",
      // width: 300,
      className: "name",
      sorter: true,
      // ellipsis: true,
      order: 1,
    },
    email: {
      title: "Email",
      // width: 300,
      className: "email",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    mobile: {
      title: "Mobile",
      // width: 300,
      className: "mobile_number",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    country: {
      title: "Country",
      // width: 300,
      className: "country",
      sorter: true,
      ellipsis: true,
      order: 4,
      custom: (text, record) => countryListAlpha2[text],
    },
    training_consultant: {
      title: "Training conultant",
      // width: 300,
      className: "training_consultant",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => record.training_consultants_details.title,
    },
  };

  componentDidMount = async () => {
    const { Chabi } = this.state;
    const response = await get_data({
      others: ["Consultant_grader","region"],
      primary: "consultant",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let { region, records, Consultant_grader } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
    let { loggedUser } = this.props;
    console.log("records", records)
    let record = records.filter((r) => r.email == loggedUser.email)[0];
   let request = { ...this.state.request, ...record };
   

        this.setState({
          Consultant_grader,
          region,
          region2,
          records,
          request
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true});
  };

  pageReload = async () => {
    const { Chabi } = this.state;
    const response = await get_data({
      others: ["Consultant_grader","region"],
      primary: "consultant",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let { region, records, Consultant_grader } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          Consultant_grader,
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
  }


  addConsultant = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = consultant_api+"/create";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add location ", response);
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReload();
        const { records: r } = data;
        // records.push({ ...r[0] });
        // records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return alert(message);
      }
    } else alert("Network Error !");
  };
  

  editLocation = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { _id, name, email, mobile, country, training_consultant } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = consultant_api+"/update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, name, email, mobile, country, training_consultant},
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        this.pageReload();
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            // rec["location_name"] = location_name;
            // rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${consultant_api}/status`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
      this.pageReload();
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  loadRecord = async () => {
    let { loggedUser } = this.props;
    let { records, request } = this.state;
    const record = records.filter((r) => r.email == loggedUser.email)[0];
    request = { ...request, ...record };
    this.setState({
      edit: true,
      request,
      cmodal: true,
    });
  };
  
  render() {
    
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      Consultant_grader,
      CDM,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });


    return (
      <>
      
      <PageHeading2 active="Consultants" page={["Consultants"]}>
        </PageHeading2>
        <MainArea>
        {!CDM ? (
            <MyLoading />
          ) :  <FormContainer>
          <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-md-12">
            <div className="col-6 col-sm-6 col-md-6 col-md-6">
          <FormInputGroup
            id="name"
            name="name"
            label="Name"
            placeholder="e.g. Kelmac Group"
            data={this.state}
            setValidation={this.setValidation}
            sendValue={this.setRequest}
            disabled={true}
          />
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-md-6">
          <FormInputGroup
            id="email"
            name="email"
            label="Email"
            placeholder="e.g. kelmacgroup@gmail.com"
            data={this.state}
            setValidation={this.setValidation}
            sendValue={this.setRequest}
            disabled={true}
          />
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-md-6">
          <FormInputGroup
            id="Mobile"
            name="mobile"
            label="Mobile"
            placeholder="e.g. 9999999999"
            data={this.state}
            setValidation={this.setValidation}
            sendValue={this.setRequest}
            disabled={true}
          />
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-md-6">
          <FormSelectGroup
            id="country"
            name="country"
            label="Country"
            placeholder="Country"
            data={this.state}
            options={country_array}
            option_value="country_code"
            option_text="country_name"
            // valMet={checkUuid}
            setValidation={this.setValidation}
            sendValue={this.setRequest}
            disabled={true}
          />
          </div>
         
          <div className="col-6 col-sm-6 col-md-6 col-md-6">
          <FormSelectGroup
            id="Consultant_grader"
            name="training_consultant"
            label="Consultant Grade"
            placeholder="Consultant Grade"
            data={this.state}
            disabled={true}
            options={Consultant_grader}
            option_value="_id"
            option_text="title"
            // valMet={checkUuid}
            setValidation={this.setValidation}
            sendValue={this.setRequest}
          />
</div>
          </div>
          </div>
          <div style={{margin:20}}>
            <button className="btn btn-sm btn-danger" onClick={() => this.loadRecord()}>Edit</button>
          </div>
          
        </FormContainer>
            }
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Consultants"
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-md-6">
            <FormInputGroup
              id="name"
              name="name"
              label="Name"
              placeholder="e.g. Kelmac Group"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-md-6">
            <FormInputGroup
              id="email"
              name="email"
              label="Email"
              placeholder="e.g. kelmacgroup@gmail.com"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-md-12">
            <FormInputGroup
              id="Mobile"
              name="mobile"
              label="Mobile"
              placeholder="e.g. 9999999999"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-md-6">
            <FormSelectGroup
              id="country"
              name="country"
              label="Country"
              placeholder="Country"
              data={this.state}required
              options={country_array}
              option_value="country_code"
              option_text="country_name"
              // valMet={checkUuid}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
           
            <div className="col-12 col-sm-6 col-md-6 col-md-6">
            <FormSelectGroup
              id="Consultant_grader"
              name="training_consultant"
              label="Consultant Grade"
              placeholder="Consultant Grade"
              data={this.state}
              required
              options={Consultant_grader}
              option_value="_id"
              option_text="title"
              // valMet={checkUuid}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
</div>
            </div>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editLocation();
                else this.addConsultant();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>

        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Consultants);
