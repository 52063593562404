const Joi = require("joi");
// ==================================
// ==================================
const checkNumber = ({ value }) => {
  const validation_rule = { value: Joi.number().required() };
  const data = { value };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkLength = ({ value, valLen }) => {
  const data = { value };
  if (!valLen) valLen = 1;
  const validation_rule = { value: Joi.string().trim().required().min(valLen) };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkImage = ({ value }) => {
  const data = { value };
  const valLen = 5;
  const validation_rule = { value: Joi.string().trim().required().min(valLen) };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkFile = ({ value }) => {
  const data = { value };
  const valLen = 5;
  const validation_rule = { value: Joi.string().trim().required().min(valLen) };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkUuid = (str) => {
  const validation_rule = {
    str: Joi.string().trim().required().min(36).max(36),
  };
  const data = { str };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkArrayLength = (str) => {
  const validation_rule = { str: Joi.array().min(1).required() };
  const data = { str };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkCity = ({ value }) => {
  let data = { value };
  const validation_rule = { value: Joi.string().trim().required().min(3) };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkEmail = ({ value }) => {
  let data = { value };
  const validation_rule = {
    value: Joi.string()
      .trim()
      .required()
      .email({ tlds: { allow: false } }),
  };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkEmailOptional = ({ value }) => {
  let data = { value };
  const validation_rule = {
    value: Joi.string()
      .trim()
      .email({ tlds: { allow: false } }),
  };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkMobile = ({ value }) => {
  let data = { value };
  const validation_rule = {
    value: Joi.number().required(),
  };
  const r1 = check_validation(data, validation_rule);
  if (!r1) {
    if (value.length < 8 || value.length > 10)
      return "Mobile No Should Have minimum 8 to 10 Digit";
    // else if (
    //   value.charAt(0) != "9" &&
    //   value.charAt(0) != "8" &&
    //   value.charAt(0) != "7" &&
    //   value.charAt(0) != "6" &&
    //   value.charAt(0) != "5"
    // )
    //   return "Mobile No Must Start From Valid Digit";
  } else return r1;
};
// ==================================
// ==================================
const checkPassword = ({ value }) => {
  let data = { value };
  const validation_rule = {
    value: Joi.string().trim().required().min(6),
  };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
const checkCPassword = ({ value, password }) => {
  console.log("password ", password);
  const cpassword = value;
  let data = { password, cpassword };
  const validation_rule = {
    password: Joi.string().trim().required().min(6),
    cpassword: Joi.ref("password"),
  };
  return check_validation(data, validation_rule);
};
// ==================================
// ==================================
export const vj_val = {
  checkNumber,
  checkLength,
  checkImage,
  checkFile,
  checkUuid,
  checkArrayLength,
  checkCity,
  checkEmail,
  checkEmailOptional,
  checkMobile,
  checkPassword,
  checkCPassword,
};
// ==================================
// ==================================
const check_validation = (data, validation_rule) => {
  const schema = Joi.object(validation_rule);
  const { error } = schema.validate(data);
  return error;
};
// ==================================
// ==================================
const error_message = (obj) => {
  const { error, e, ref } = obj;
  if (error) {
    //validation failed
    if (ref) ref.current.classList.add("val_error");
    else if (e) e.target.classList.add("val_error");
    return false;
  } else {
    //validation pass
    if (ref) ref.current.classList.remove("val_error");
    else if (e) e.target.classList.remove("val_error");
    return true;
  }
};
