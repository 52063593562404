// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_label__VXJ71 {
  font-weight: 400;
  font-size: 14px;
}
.styles_sub_label__wYmFQ {
  font-weight: 400;
  margin-left: 5px;
  color: #b5b5c3;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/Form/FormLabel/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".label {\n  font-weight: 400;\n  font-size: 14px;\n}\n.sub_label {\n  font-weight: 400;\n  margin-left: 5px;\n  color: #b5b5c3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `styles_label__VXJ71`,
	"sub_label": `styles_sub_label__wYmFQ`
};
export default ___CSS_LOADER_EXPORT___;
