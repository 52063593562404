// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  position: absolute !important;
  top: 40px !important;
  left: -40px !important;
}
.top-nav .link,
.top-nav .dropdown-toggle {
  color: #fff !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/top-nav.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,sBAAsB;AACxB;AACA;;EAEE,sBAAsB;AACxB","sourcesContent":[".dropdown-menu {\n  position: absolute !important;\n  top: 40px !important;\n  left: -40px !important;\n}\n.top-nav .link,\n.top-nav .dropdown-toggle {\n  color: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
