import React from "react";
function MailLink(props) {
  const { mail } = props;
  return (
    <a target="_blank" href={`mailto:${mail}?Subject=Hello`}>
      {mail}
    </a>
  );
}
export default MailLink;
