import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import MyToolTip from "../MyToolTip/MyToolTip";
// ===============================================================================
function EditIcon(props) {
  const { loadData } = props;
  return (
    <span className="icon-c">
      <MyToolTip title="Edit this record.">
        <FontAwesomeIcon
          icon={faPencilAlt}
          onClick={() => {
            loadData();
          }}
        />
      </MyToolTip>
    </span>
  );
}
export default EditIcon;
