import { Drawer } from "antd";
import "./drawer.css";
function CustomOffCanvas(props) {
  const { status, close, title, children, placement, className } = props;
  return (
    <Drawer
      title={title}
      placement={placement}
      visible={status}
      onClose={close}
      className={className}
    >
      {children}
    </Drawer>
  );
}
export default CustomOffCanvas;
