import React from "react";
import { Navigate } from "react-router";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
import { myAxios } from "../../library/networkFunction";
// ===============================================================================
import FormEmailGroup from "../part/Form/FormEmailGroup";
import FormPasswordGroup from "../part/Form/FormPasswordGroup";
import FormParent from "./FormParent";
import { user_api } from "../../library/user_api";
import { userLogin } from "../../Action/userAction";
import { connect } from "react-redux";
// ===============================================================================
class Login extends FormParent {
 
  constructor(props) {
    super(props);
    this.state = {
      page_api: user_api,
      request: {},
      validation: {},
      validation_info: {
        email: { valMet: vj_val.checkEmail },
        password: { valMet: vj_val.checkPassword },
      },
      doUserLogin: props.doUserLogin,
      loginStatus: props.loginStatus,
    };
  }
  login = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    console.log("val_result ", val_result);
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    let state = this.state;
    const { request, doUserLogin } = this.state;
    const url = `${user_api}/login`;
    const response = await myAxios({
      method: "post",
      url,
      request,
    });
    if (response.status == 200) {
      const { data } = response;
      const { error, messages, status, records, role_modules, Chabi } = data;
      console.log("role_modules",role_modules)
      if (status == 200) {
        if (records.length) {
          this.setState({ request: {} });
          doUserLogin({ Chabi, loggedUser: records[0], role_modules });
        } else this.setState({ error_message: messages });
      } else state["error_message"] = messages;
    } else state["error_message"] = response.messages;
    this.setState({ ...state });
  };
  render() {
    console.log("Login State ", this.state);
    const { error_message } = this.state;
    if (this.props.loginStatus) return <Navigate to="/dashboard" />;
    return (
      <>
        <div className="row mt-5">
          <div
            className=" col-lg-2 col-sm-12 offset-lg-5 bg-light rounded border"
            style={{ boxShadow: "0px 0px 10px #999" }}
          >
            <div className="row">
              <div className="col-12 pt-3">
                <h2 className="text-center">Kelmac Group</h2>
                <p className="text-center"></p>
              </div>
            </div>
            <FormEmailGroup
              autofocus={true}
              groupClassName="mt-2"
              inputClassName="form-control"
              id="email"
              name="email"
              data={this.state}
              required
              label="Email"
              placeholder="Email"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
              validationMessage="Valid email is required."
            />
            <FormPasswordGroup
              autofocus={true}
              groupClassName="mt-2"
              inputClassName="form-control"
              id="password"
              type="password"
              name="password"
              data={this.state}
              required
              label="Password"
              placeholder="Password"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
              enter={() => this.login()}
              validationMessage="Valid password is required."
            />
            <div className="row mt-4 mb-4">
              {error_message && error_message.length ? (
                <>
                  <div className="col-12 text-center mt-2 mb-2 text-danger font-weight-bold">
                    {error_message}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-12  mt-2 mb-2 text-center">
                <button
                  className="btn btn-success form-button"
                  onClick={() => this.login()}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { loginStatus: state.loginStatus };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogin: (obj) => {
      dispatch(userLogin(obj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
