import React from "react";
import FormLabel from "./FormLabel/";
import FormInput from "./FormInput";
import { Row, Col } from "antd";
import FormValidationError from "./FormValidationError";
function FormInputGroup(props) {
  const { groupClassName, label } = props;
  return (
    <Row className={`pl-2 pr-2 mt-2 ${groupClassName}`}>
      {label ? (
        <Col span={24} className="text-left">
          <FormLabel {...props} />
        </Col>
      ) : (
        ""
      )}
      <Col span={24} className="text-left ">
        <FormInput {...props} />
      </Col>
      <FormValidationError {...props} />
    </Row>
  );
}
export default FormInputGroup;
