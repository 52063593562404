import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { lca_template_assesment_api } from "../../library/lca_template_ass_api";
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { get_region } from "../server_data/list_data";
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import Lca_template_assesment_option_list from "./Lca_template_assesment_option_list";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Lca_templates_ass extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: lca_template_assesment_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        template_name: { valMet: vj_val.checkLength, valLen: 1 },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        category: { valMet: vj_val.checkLength, valLen: 1 },
        course_type: { valMet: vj_val.checkLength, valLen: 1 },
        archived: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
      learning_scheme:[],
      course_category:[],
      course_type:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }

 
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    _id: {
      title: "Template ID",
      // width: 300,
      className: "Template_id",
      sorter: true,
      // ellipsis: true,
      order: 1,
    },
    template_name: {
      title: "Template name",
      width: 300,
      className: "template_name",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    template_revision: {
      title: "Template Revision",
      // width: 300,
      className: "template_revision",
      sorter: true,
      // ellipsis: true,
      order: 3,
    },
    archived: {
      title: "Archived",
      // width: 300,
      className: "archived",
      sorter: true,
      // ellipsis: true,
      order: 4,
    },
    active: {
      title: "Active",
      width: 150,
      className: "active",
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
          if (text)
            return <span className="text-success font-weight-bold">Active</span>;
          return <span className="text-danger font-weight-bold">Deactive</span>;
        },
      order: 5,
    },
  };
  componentDidMount = async () => {
    const { Chabi, id } = this.state;
    console.clear()
    //============= define permission =============
      let { loggedUser } = this.props
     if(loggedUser.roleId){
      const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"lca_templates";
		  let modulePermission = await myAxios({
		    method: "get",
		    url,
		    header: { Chabi },
		  });
     if(modulePermission.status == 200){
          if(modulePermission.data.records.length >= 1){
            console.log("ROLE APPLIED")
            let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
            this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
          }else{
            console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
          }
     }else{
      console.log("PERMISSION API CRASHED")
     }
     }
    //=============================================

    const url = lca_template_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
    this.setState({ CDM: true});

    const responseDropDown = await get_data({
      others: [
        "learning_scheme",
        "course_category",
        "course_type",
        "language",
        "accreditation",
        "delivery_method",
        "region",
      ],
      primary: "course",
      Chabi,
    });
    if (responseDropDown.status == 200) {
      const { data } = responseDropDown;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
         
          learning_scheme,
          course_category,
          course_type,
          language,
      
          region,
        } = data;

        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course_category2 = create_object({
          arr: course_category,
          title: "main_head",
        });
        let course_type2 = create_object({
          arr: course_type,
          title: "title",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });

        this.setState({
          learning_scheme,
          learning_scheme2,
          course_category,
          course_category2,
          course_type,
          course_type2,
          language,
          language2,

        });
      }
    } else console.log("Network Error");
 
  };

  pageReload = async () => {
    const { Chabi, id } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        if(loggedUser.roleId){
         const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"exam";
         let modulePermission = await myAxios({
           method: "get",
           url,
           header: { Chabi },
         });
        if(modulePermission.status == 200){
             if(modulePermission.data.records.length >= 1){
               console.log("ROLE APPLIED")
               let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
               this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
             }else{
               console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
             }
        }else{
         console.log("PERMISSION API CRASHED")
        }
        }
       //=============================================

    const url1 = lca_template_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url:url1,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
  }

  addPartner = async () => {

    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = lca_template_assesment_api + "create";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });

    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReload()
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };

  editPartner = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    let { _id, template_name, scheme,category,course_type,archived, template_revision} = request
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = lca_template_assesment_api + "update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id,  template_name, scheme,category,course_type,archived, template_revision },
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            // rec["location_name"] = location_name;
            // rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = lca_template_assesment_api +"status";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("lca state", this.state)
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      learning_scheme,
      course_category,
      course_type,
      CDM,
      popularcourse,
      permission
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    if(permission.childModule && permission.childModule?.unitList){
    columns.push({
      width: 150,
      title: "Units",
      dataIndex: "units",
      render: (text, record) => {
        console.log(
          "🚀 ~ file: LCA_templates.js ~ line 190 ~ LCA_templates ~ render ~ record",
          record._id
        );
        return (
          <button
          className="btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ popularcourse: record });
              this.setState({ id: record._id });
              this.setState({ _id: record._id });
            }}
          >
            Unit List
          </button>
        );
      },
    });
  }
 
    return (
      <>
       <PageHeading2 active="LCA Template" page={["LCA Template"]}>
       {permission.create ? 
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> 
          : null }
          </PageHeading2>
        <MainArea>
        {!CDM ? (
            <MyLoading />
          ) :records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
            rowKey="_id"
            columns={columns}
            dataSource={records}
            loadData={permission.update ? this.loadRecord : null}
            removeData={permission.itemRemoved ? this.removeRecord : null}
            activeData={permission.activeDeactive ? this.activeRecord : null}
            pagination={true}
          />
          ): (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="LCA Template"
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="template_name"
              name="template_name"
              label="Template Name"
              placeholder="e.g"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            </div>
<div className="col-12 col-sm-6 col-md-6 col-lg-6">
           <FormSelectGroup
               allowClear
               id="scheme"
               name="scheme"
               label="Scheme"
               placeholder="Scheme"
               data={this.state}
               required
               options={learning_scheme}
               option_value="_id"
               option_text="main_head"
               setValidation={this.setValidation}
               sendValue={this.setRequest}
             />
 </div>
<div className="col-12 col-sm-6 col-md-6 col-lg-6">
               <FormSelectGroup
                  allowClear
                  id="category"
                  name="category"
                  label="Category"
                  placeholder="Category"
                  data={this.state}
                  required
                  options={
                    request.scheme
                      ? course_category.filter(
                          (cc) => cc.learning_scheme == request.scheme
                        )
                      : []
                  }
                  option_value="_id"
                  option_text="main_head"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
 </div>
<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <FormSelectGroup
                  allowClear
                  id="course_type"
                  name="course_type"
                  label="Course Type"
                  placeholder="Course Type"
                  data={this.state}
                  required
                  options={course_type}
                  option_value="_id"
                  option_text="title"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
 </div>
<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <FormSelectGroup
                  id="template_revision"
                  name="template_revision"
                  label="Template revision"
                  placeholder="Template revision"
                  data={this.state}required
                  options={[{ value: "1" }, {  value: "2" }, {  value: "3" }, {  value: "4" }, {  value: "5" }]}
                  option_value="value"
                  option_text="value"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
 </div>
<div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <FormSelectGroup
                  id="archived"
                  name="archived"
                  label="Archived"
                  placeholder="Archived"
                  data={this.state}required
                  options={[{ value: 'Yes' }, {  value: 'No' }]}
                  option_value="value"
                  option_text="value"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
</div></div>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editPartner();
                else this.addPartner();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>

        </CustomModal>

        {popularcourse ? (
          <div className="popularbundle-modal position-fixed top-0 bottom-0 left-0 right-0 d-flex justify-content-center align-items-center">
            {/* <button
              className="modalclose"
              onClick={() => {
                this.setState({ popularcourse: false });
              }}
              >
              X
            </button> */}
            <Lca_template_assesment_option_list
              onClose={() => {
                this.setState({ popularcourse: null,cmodal:false });
              }}
              permission={permission.childModule}
              popularcourse={this.state.popularcourse}
              id={this.state.id}
            />
          </div>
        ) : (
          ""
        )}

         <style jsx global>{`
          .trustedby-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Lca_templates_ass);
