// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-image-upload_upload-validation-error__cB06D .form-image-upload_ant-upload__y59k2.form-image-upload_ant-upload-select-picture-card__Pu3xG{
  border-color: red !important;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/Form/form-image-upload.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B","sourcesContent":[".upload-validation-error .ant-upload.ant-upload-select-picture-card{\n  border-color: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-validation-error": `form-image-upload_upload-validation-error__cB06D`,
	"ant-upload": `form-image-upload_ant-upload__y59k2`,
	"ant-upload-select-picture-card": `form-image-upload_ant-upload-select-picture-card__Pu3xG`
};
export default ___CSS_LOADER_EXPORT___;
