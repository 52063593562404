import React, { Component } from "react";
import { add_sno } from "../../library/object_functions";
import { get_data } from "../server_data/get_data";
import { myAxios } from "../../library/networkFunction";
import { message} from "antd";
import { permission_api } from "../../library/permission_api";

export class FormParent extends Component {
  constructor(props) {
    super(props);
  }
  setRequest = (obj) => {
    
    const { name, value } = obj;
    const { filtered_records, request } = this.state;
    request[name] = value;
    this.setState({ request });
  };
  setRequest2 = (obj) => {
    const { name, value } = obj;
    const { request2 } = this.state;
    request2[name] = value;
    this.setState({ request2 });
  };
  setValidation = (obj) => {
    const { name, res, msg } = obj;
    const { validation } = this.state;
    validation[name] = { res, msg };
    this.setState({ validation });
  };
  setLogout = () => {
    let { doUserLogout } = this.state;
    this.setState({
      loggedUser: undefined,
      loginStatus: undefined,
    });
    doUserLogout();
  };
  checkValidation = () => {
    //checkValidation and checkNextValidation can be single function please check both function body
    const { validation_info, request } = this.state;
    let val_result = true;
    let reason = {};
    if (validation_info) {
      Object.keys(validation_info).forEach((field) => {
        if (request[field]) {
          const value = request[field];
          const { valMet, valLen, valPas } = validation_info[field];
          let password;
          if (valPas) password = request[valPas];
          const error = valMet({ value, valLen, password });
          if (error) {
            console.log(`${field} - ${error} - 2`);
            reason[field] = { res: false, msg: error, why: "invalid" };
            val_result = false;
          }
        } else {
          console.log(`${field} - ${field} Is Required - 1`);
          reason[field] = {
            res: false,
            msg: `${field} Is Required`,
            why: "blank",
          };
          val_result = false;
        }
      });
    }
    this.setState({ validation: reason });
    return val_result;
  };
  checkNextValidation = ({ fields }) => {
    const { validation_info, request } = this.state;
    //checkValidation and checkNextValidation can be single function please check both function body
    let val_result = true;
    let reason = {};
    fields.forEach((field) => {
      if (request[field]) {
        const value = request[field];
        const { valMet, valLen } = validation_info[field];
        const error = valMet({ value, valLen });
        if (error) {
          reason[field] = { res: false, msg: error, why: "invalid" };
          val_result = false;
        }
      } else {
        reason[field] = {
          res: false,
          msg: `${field} Is Required`,
          why: "blank",
        };
        val_result = false;
      }
    });
    return { val_result, reason };
  };
  checkRegionValidation = ({ val_info_obj, region_info }) => {
    let val_result = true;
    let reason = {};
    Object.keys(val_info_obj).forEach((field) => {
      if (region_info[field]) {
        const value = region_info[field];
        const { valMet, valLen } = val_info_obj[field];
        const error = valMet({ value, valLen });
        if (error) {
          reason[field] = { res: false, msg: error, why: "invalid" };
          val_result = false;
        }
      } else {
        reason[field] = {
          res: false,
          msg: `${field} Is Required`,
          why: "blank",
        };
        val_result = false;
      }
    });
    return { val_result, reason };
  };
  set_filter = (obj) => {
    const { value, name } = obj;
    let { filter } = this.state;
    if (value && value.length) filter[name] = value;
    else delete filter[name];
    this.setState({ filter });
  };
  run_filter = () => {
    let { filter, records, filtered_records } = this.state;
    const fields = Object.keys(filter);
    if (fields.length) {
      filtered_records = records;
      fields.forEach((f) => {
        filtered_records = filtered_records.filter((r) => r[f] == filter[f]);
      });
      this.setState({ filtered_records });
    } else {
      const state = this.state;
      delete state["filtered_records"];
      this.setState(state);
    }
  };
  close_modal = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };

  close_payment_modal = () => {
    this.setState({
      request:{},
      paymentCmodel: false,
    });
  };

  close_modal_course = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal_course: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };
  
  close_modal_course_region = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal_course_region: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };
  
  close_modal_team = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal_team: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };
  
   close_modal_team_region = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal_team_region: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };

  close_modal_import = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodali: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };

  close_modal_utype = () => {
    this.setState({
      cmodal_user_type: false,

    });
  };


  close_modal_country = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal_country: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };
  close_modal_news_update = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal_news_update: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };
  close_modal_leadsDetails_view = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      leadsDetailsViewModelStatus: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  }

  close_pre_course_template_modal = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      addTestcmodal: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedTest:undefined,
      marks:undefined,
    });
  };

  qclose_modal = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      qmodal: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
      questionRecords:[],
    });
  };
  
  changeUploadImageList = (uploadImageList) => {
    if (uploadImageList.length == 0)
      this.setRequest({ name: "image", value: "" });
    this.setState({ uploadImageList });
  };

  changeUploadBannerImageList = (uploadImageList) => {
    if (uploadImageList.length == 0)
      this.setRequest({ name: "banner", value: "" });
    this.setState({ uploadBannerImageList:  uploadImageList});
  };

  
  changeUploadFileList = (uploadFileList) => {
    if (uploadFileList.length == 0)
      this.setRequest({ name: "file", value: "" });
    this.setState({ uploadFileList });
  };
  removeRecord = async (record) => {
    let { records } = this.state;
    const { _id } = record;
    if (!window.confirm("Are you sure? Record will be delete!")) return false;
    const result = await this.fastEdit({ field: "removed", value: 1, record });
    if (result) {
      records = records.filter((r) => r._id != _id);
      records = add_sno(records);
      this.setState({ records });
    }
  };
  activeRecord = async (record) => {
    const active = +!parseInt(record["active"]);
    this.fastEdit({ field: "active", value: active, record });
  };
  activeRegionRecord = async (record) => {
    const active = +!parseInt(record["active"]);
    this.courseRegionActive({ field: "active", value: active, record });
  };
  loadRecord = async (_id) => {
    let { records, request } = this.state;
    const record = records.filter((r) => r._id == _id)[0];
    request = { ...request, ...record };
    this.setState({
      edit: true,
      request,
      cmodal: true,
    });
  };
  loadRecordRegion = async (_id) => {
    let { regionRecords, request } = this.state;
    const regionRecord = regionRecords.filter((r) => r._id == _id)[0];
    request = { ...request, ...regionRecord };
    this.setState({
      edit: true,
      request,
      cmodal_course: true,
    });
  };
  set_region_request = (obj) => {
    const { name, value, _id } = obj;
    console.log("obj 2", obj);
    const { request } = this.state;
    let { region_info } = request;
    if (!region_info) region_info = {};
    const single_region = { ...region_info[_id] };
    single_region[name] = value;
    region_info[_id] = single_region;

    request["region_info"] = region_info;
    this.setState({ request });
  };

  pageReloadWithAxios = async (url) => {
    const { Chabi, id } = this.state;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
  }

  pageReloadWithoutAxios = async (obj) => {
    const { Chabi } = this.state;
    const response = await get_data(obj);
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let { records } = data;
        records = add_sno(records);
        this.setState({
          records,
        });
      }
    } else console.log("Network Error");
  }

  insertDataCallApiHelper = async (url,reloadUrl) => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReloadWithAxios(reloadUrl);
        const { records: r } = data;
        // records.push({ ...r[0] });
        // records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };

  editDataApiCallHepler = async (url,reloadUrl) => {
    console.log("called edit api helper");
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    delete request["sno"];
    delete request["active"];
    
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: request,
    });
    if (response.status == 200) {
      this.pageReloadWithAxios(reloadUrl);
      const { data } = response;
      if(data.status == 420)this.setLogout(); 
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == request._id) {
            // rec["location_name"] = location_name;
            // rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  getRolePermission = async (moduleName) => {
    const { Chabi } = this.state;
    //============= define permission =============
      let { loggedUser } = this.props
     if(loggedUser.roleId){
      const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+moduleName;
		  let modulePermission = await myAxios({
		    method: "get",
		    url,
		    header: { Chabi },
		  });
     if(modulePermission.status == 200){
          if(modulePermission.data.records.length >= 1){
            console.log("ROLE APPLIED")
            let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
            this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
          }else{
            console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
          }
     }else{
      console.log("PERMISSION API CRASHED")
     }
     }

    //=============================================
  }

  updateStatus_DeleteApiCallHelper = async (obj, url,reloadUrl) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
      this.pageReloadWithAxios(reloadUrl);
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

}
export default FormParent;
