import React, { Component } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import  "./form-image-upload.module.css";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export class FormBannerImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
    };
  }
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = async (obj) => {
    const { imageUpload } = this.props;
    const { fileList } = obj;
    fileList.forEach((fl) => {
      imageUpload(fl);
    });
  };
  removeImage = (file) => {
    const { data, changeUploadImageList } = this.props;
    let { uploadBannerImageList } = data;
    uploadBannerImageList = uploadBannerImageList.filter((f) => f.uid != file.uid);
    changeUploadImageList(uploadBannerImageList);
  };
  componentDidMount = () => {
    const { name, data, src, changeUploadImageList } = this.props;
    const { request } = data;
    console.log("changeUploadImageList", request[name], changeUploadImageList)
    if (request[name]) {
      const prevalue = request[name];
      changeUploadImageList([
        {
          uid: "-1",
          name: prevalue,
          status: "done",
          url: src + prevalue,
        },
      ]);
    }
  };
  render() {
    const { name, data, value, imageCount } = this.props;
    const { previewVisible, previewImage, previewTitle } = this.state;
    const { validation, showErr, request, uploadBannerImageList } = data;
    let prevalue = "";
    if (value) prevalue = value;
    else if (request && request[name]) prevalue = request[name];
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <Upload
          // className={`${
          //   showErr && validation[name] && validation[name].msg
          //     ? "upload-validation-error"
          //     : ""
          // }`}
          key={uploadBannerImageList.length}
          accept=".png,.jpg,.jpeg"
          beforeUpload={() => false}
          listType="picture-card"
          fileList={uploadBannerImageList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.removeImage}
        >
          {uploadBannerImageList.length >= imageCount ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  }
}
export default FormBannerImageUpload;
