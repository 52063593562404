import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { client_api } from "../../library/client_api";
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea/";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable/";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
// ===============================================================================
class Clients extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: client_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        image: { valMet: vj_val.checkImage },
        client_name: { valMet: vj_val.checkLength, valLen: 1 },
        region: { valMet: vj_val.checkLength, valLen: 1 },
        order: { valMet: vj_val.checkNumber },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    client_name: {
      title: "Client Name",
      // width: 300,
      className: "client_name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    website: {
      title: "Website",
      // width: 300,
      className: "website",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    order: {
      title: "Order",
      width: 100,
      className: "order",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 4,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 5,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${client_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = client_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        if(loggedUser.roleId){
         const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"clients";
         let modulePermission = await myAxios({
           method: "get",
           url,
           header: { Chabi },
         });
        if(modulePermission.status == 200){
             if(modulePermission.data.records.length >= 1){
               console.log("ROLE APPLIED")
               let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
               this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
             }else{
               console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
             }
        }else{
         console.log("PERMISSION API CRASHED")
        }
        }
       //=============================================
    const response = await get_data({
      others: ["region"],
      primary: "client",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { records, region } = data;
        records = add_sno(records);
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
          records,
          region,
          region2,
        });
      }
    } else console.log("Network Error");
  };
  addClient = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = client_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add client ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editClient = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { client_name, _id, order, website, image, region } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = client_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, client_name, image, order, website, region },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["client_name"] = client_name;
            rec["image"] = image;
            rec["website"] = website;
            rec["order"] = order;
            rec["region"] = region;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${client_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    // console.log("client State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      region,
      permission
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    return (
      <>
        <PageHeading2
          active="Clients"
          page={["Website Administration", "Home Page", "Clients"]}
        >
          {permission.create ? 
          <button
            className="add-btn  btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> : null}
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={permission.update ? this.loadRecord : null}
              removeData={permission.itemRemoved ? this.removeRecord : null}
              activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Clients"
          close={this.close_modal}
          className="drawer-form-client"
        >
          <FormContainer>
            <FormImageUploadGroup
              // src={`${page_api}/image/`}
              src={`${PORTAL.api_url}uploaded_images/`}
              id="image"
              name="image"
              label="Client Logo"
              sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
              imageCount="1"
              data={this.state}
              required
              imageUpload={this.imageUpload}
              changeUploadImageList={this.changeUploadImageList}
            />
            <FormInputGroup
              id="client_name"
              name="client_name"
              label="Client Name"
              placeholder="e.g. Kelmac Group"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <Row>
              <Col span={12}>
                <FormInputGroup
                  id="website"
                  name="website"
                  label="Website"
                  sub_label="(Optional)"
                  placeholder="e.g. www.kelmacgroup.com"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={12}>
                <FormInputGroup
                  id="order"
                  type="number"
                  name="order"
                  label="Order"
                  placeholder="e.g. 1"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <FormMultiSelectGroup
              allowClear
              id="region"
              name="region"
              label="Region"
              placeholder="Region"
              data={this.state}
              required
              options={region}
              option_value="_id"
              option_text="region_name"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editClient();
                else this.addClient();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
