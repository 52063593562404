import React, { useEffect } from "react";
import { userLogout } from "../../Action/userAction";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { myAxios } from "../../library/networkFunction";
import { logout_api } from "../../library/logout_api";
function Logout(props) {
  console.log("logout ", props);
  useEffect(() => {
    const url = logout_api;
    myAxios({ method: "get", url });
    props.doUserLogout();
  }, [1]);
  if (props.loginStatus == false) return <Navigate  to="/" />;
  return <></>;
}
const mapStateToProps = (state) => {
  return { loginStatus: state.loginStatus };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
