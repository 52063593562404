import React from "react";
// ===============================================================================
import styles from "./styles.module.css";
function FormLabel(props) {
  const { label, sub_label, id, labelClassName, style, required } = props;
  return (
    <label
      htmlFor={id}
      className={`${labelClassName} ${styles.label}`}
      style={style}
    >
      {label}
      {` `}
      {required ? (
        <span
          style={{
            color: "red",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          *
        </span>
      ) : (
        ""
      )}
      {sub_label ? <span className={styles.sub_label}>{sub_label}</span> : ""}
    </label>
  );
}
export default FormLabel;
