import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { pre_order_api } from "../../library/pre_order_api";
import { learners_api } from "../../library/learners_api";
import { country_api } from "../../library/country_api";
import { states_api } from "../../library/states_api";
import { payment_api} from '../../library/payment_api';
import { permission_api } from "../../library/permission_api";
import { Row, Col, Select, DatePicker, Input, message, Upload, Image   } from "antd";
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormDateGroup from "../part/Form/FormDateGroup";
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import CustomOffCanvas from "../part/CustomOffCanvas";
import { get_data } from "../server_data/get_data";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { currency_obj } from "../local_data/currency";
import { vj_val } from "../../library/validation_function";
import { PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
// ===============================================================================

class Payment_orders extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: pre_order_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      uploadImageList: [],
      validation: {},
      validation_info: {
        paymentDate: { valMet: vj_val.checkLength, valLen: 1 },
        paymentAmt: { valMet: vj_val.checkLength, valLen: 1 },
        transactionId: { valMet: vj_val.checkLength, valLen: 1 },
      },
      cmodal: false,
      coffcanvas: false,
	    poffcanvas: false,
      selectedRecord: {},
      serchTxt: "",
      startDt:"",
      endDt:"",
      orderType:["1","2"],
      countryList:[],
      stateList:[],
      paymentCmodel:false,
      uploadFileList:"",
      paymentHistory:[],
      CDMI:false,
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 50,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    create_at: {
      title: "Order Meta",
      width: 300,
      // sorter: true,
      ellipsis: true,
      order: 1,
      custom: (text, record) => {
        return <>
        <span><b>Order Number:</b></span> {(record.payment_mode == "2") ? record.order_number : "-"}
           <br />
           <span><b>Order Type:</b></span> <span>{(record.payment_mode == "2") ? "PO Order" : "Online Payment"}</span>
           <br />
           <span><b>Order Id:</b></span>{record._id}
           <br />
           <span><b>Order Date:</b></span> <span>{record.create_at}</span>
     </>
      }
    },
    // order_number:{
    //   title: "Order No.",
    //   // width: 300,
    //   className: "order_number",
    //   order: 2,
    //   // sorter: true,
    //   ellipsis: true,
    // },
    // orderType:{
    //   title: "Order Type",
    //   // width: 300,
    //   className: "orderType",
    //   order: 3,
    //   // sorter: true,
    //   ellipsis: true,
    //   custom: (text, record) => {
    //     if(text == "1") return <span>PO Order</span>
    //     else return <span>Normal order</span>
    //   },
    // },
    
    payment_ref: {
      title: "Payment Ref",
      //width: 300,
      order: 4,
    },
    user_id: {
      title: "Learner",
      width: 200,
      // sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => {
        return <>
          <span><b>Name:</b> {`${record.learnerData.first_name} ${record.learnerData.last_name}`}</span><br />
          <span><b>Email:</b> {`${record.learnerData.email}`}</span>
        </>
      },
    },
    total_amount: {
      title: "Amount",
      //width: 200,
      // sorter: true,
      ellipsis: true,
      order: 6,
      custom: (text, record) => {
        const { currency, amount } = record;
        let historyTotal = 0
        record.orderHistory.map( rec => historyTotal += +rec.paymentAmt)
        // let total = record.amount + record.cardCharges
        // let pendingAmt = record.status == "success" ? record.isfullPayment == "2" ? total - (record.partial_amount + record.cardCharges + historyTotal) : 0 : total
        // let paid = record.isfullPayment == "1" ? total : record.partial_amount + record.cardCharges
        
        let bookingAmount = record.total_amount
        let total = record.total_amount
        let pendingAmt = record.payment_status == 1 ? record.isfullPayment == "2" ? total - (record.partial_amount + historyTotal) : record.isfullPayment == "3" ? bookingAmount - historyTotal : 0 : total - historyTotal
        let amoutpaid = record.isfullPayment == "1" ? total : record.partial_amount

        return (
          <>
            {/* {record.currency ? currency_obj[record.currency] : ""} */}
            <span><b>Booking:</b></span> {currency ? currency == "INR" ? <FontAwesomeIcon icon={faIndianRupeeSign} style={{fontWeight:100, fontSize:10}} /> : currency_obj[currency].symbol_native : ""}
            {bookingAmount.toFixed(2)}
            <br />
            <span><b>Paid:</b></span> <span className="text-success">{record.payment_status == 1 ? <span>{currency ? currency == "INR" ? <FontAwesomeIcon icon={faIndianRupeeSign} style={{fontWeight:100, fontSize:10}} /> : currency_obj[currency].symbol_native : ""}{amoutpaid.toFixed(2)}</span> : "-"}</span>
            <br />
            <span><b>Unpaid:</b></span> <span className={pendingAmt > 0 ? "text-danger" : ""}>{currency ? currency == "INR" ? <FontAwesomeIcon icon={faIndianRupeeSign} style={{fontWeight:100, fontSize:10}} /> : currency_obj[currency].symbol_native : ""}
            {pendingAmt.toFixed(2)}</span>
            <br />
            <span><b>Repaid:</b></span> <span className={historyTotal > 0 ? "text-success" : ""}>{currency ? currency == "INR" ? <FontAwesomeIcon icon={faIndianRupeeSign} style={{fontWeight:100, fontSize:10}} /> : currency_obj[currency].symbol_native : ""}
            {historyTotal.toFixed(2)}</span>
          </>
        );
      },
    },
   
    status: {
      title: "Status",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 8,
      custom: (text, record) => {
        return record.payment_status == 1 ? <span className="text-success">success</span> : <span className="text-danger">Pending</span>
      }
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    //============= define permission =============
    let { loggedUser } = this.props
    if(loggedUser.roleId){
     const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"payment_orders";
     let modulePermission = await myAxios({
       method: "get",
       url,
       header: { Chabi },
     });
    if(modulePermission.status == 200){
         if(modulePermission.data.records.length >= 1){
           console.log("ROLE APPLIED")
           let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
           this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
         }else{
           console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
         }
    }else{
     console.log("PERMISSION API CRASHED")
    }
    }
   //=============================================

    const url = `${pre_order_api}`;
    const order = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {serchTxt:this.state.serchTxt, startDt: this.state.startDt, endDt: this.state.endDt, orderType:this.state.orderType, country: this.state.country, states: this.state.states}
    });

    const urlGet = country_api+"dropdown/state/get";
    const countryList = await myAxios({
      method: "get",
      url: urlGet,
      header: { Chabi },
    });

    const response = await get_data({
      others: [
        "region",
        "organization",
        "course",
        "language",
        "delivery_method",
      ],
      primary: "",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          region,
          records,
          organization,
          course,
          language,
          delivery_method,
        } = data;
        const learner_ids = records.map(({ user_id }) => user_id);
        const url = learners_api + "/learner_list";
        const learner_ids_response = await myAxios({
          method: "post",
          url,
          request: { learner_ids },
          header: { Chabi },
        });
        records = add_sno(order.data.records);
        let learner2 = create_object({
          arr: learner_ids_response.data.records,
          title: "first_name",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        this.setState({
          records,
          region,
          learner2,
          region2,
          organization2,
          course2,
          language2,
          delivery_method2,
          countryList: countryList.data.records
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
 
  fiterResult = async () => {
    this.setState({ CDM: false });
    const { serchTxt, startDt, endDt, orderType, country, stateId } = this.state
    const { doUserLogout, Chabi } = this.state;
    const url = `${pre_order_api}`;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {serchTxt, startDt, endDt, orderType,  country, stateId}
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(records);
        this.setState({ records })
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }

  countryHandler = async (value) => {
    const { request } = this.state;   
      let stateList = []
      let { Chabi , records} = this.state;
      const urlGet = states_api+"dropDown/get?countryCode="+value;
        const countryList = await myAxios({
          method: "get",
          url: urlGet,
          header: { Chabi },
        });
        stateList = countryList.data.records;
        let data = request;
        delete data['states']
        this.setState({ country : value, stateList });
  }

  fileUpload = async (fl) => {
    const { uploadFileList, Chabi } = this.state;
    const { file } = fl;
    console.log("file",file.originFileObj);
    const url = payment_api + "image_upload";
    const formData = new FormData();
    formData.append("type", "png"); //notice here
    formData.append("itemName", this.state.selectedRecord._id); //notice here
    formData.append("folder", "paymentScreenshort"); //notice here
    formData.append("file", file.originFileObj, file.originFileObj.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 200){
        const { data: locationData } = data;
        this.setRequest({ name: "image", value: locationData.location });
        this.setState({ uploadFileList: locationData.location });
      }else{
        return message.error("image not uploaded")
      }
      
    }
  };

  createPaymentOrder = async () => {
    // this.setState({ CDM: false });
    console.log("called")
    const { Chabi } = this.state;
    const url = payment_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: this.state.request
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
       this.setState({
        request: {},
        validation: {},
        paymentCmodel: false,
       })
       this.loadSubTableRecords(this.state.request.order_primary_id)
      }
    } else console.log("Network Error");
 
  }

  reloadTable = async () => {
    this.setState({ CDMI: false });
    const { Chabi, serchTxt, startDt, endDt, orderType, country, stateId } = this.state;
 
    const url = `${pre_order_api}`;
    const order = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {serchTxt, startDt, endDt, orderType,  country, stateId}
    });

    if (order.status == 200) {
      const { data } = order;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(order.data.records);
        this.setState({ records })
      }
    }else console.log("Network Error");
    this.setState({ CDM: true });
  }

  updateStatusPo = async (id,rec, status) => {
    this.setState({ CDMI: false });
    const { Chabi } = this.state;
    let request = { _id: id, rec, status}
    const url = pre_order_api + `/status`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("records updated");
        this.reloadTable()
        this.setState({ coffcanvas: false, selectedRecord: {} })
      }
    } else console.log("Network Error");
    this.setState({ CDMI: true });
  }

  loadSubTableRecords = async (id) => {
    this.setState({ CDMI: false });
    const { Chabi } = this.state;
    const url = payment_api + `getPaymentOrder?id=${id}&orderType=1`;
    const response = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(records);
        this.setState({ paymentHistory: records })
      }
    } else console.log("Network Error");
    this.setState({ CDMI: true });
  }

  render() {
    const { RangePicker } = DatePicker;
    console.log("Pre Order State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      selectedRecord,
      coffcanvas,
	  poffcanvas,
      region2,
      organization2,
      course2,
      language2,
      delivery_method2,
      CDM,
      countryList,
      stateList,
      paymentCmodel,
      CDMI,
      permission
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    if(permission.childModule && permission.childModule?.details){
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        let historyTotal = 0
        selectedRecord.orderHistory.map( rec => historyTotal += +rec.paymentAmt)
        let pendingAmt = selectedRecord.total_amount - selectedRecord.amount - historyTotal
       
        return (
			<>
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              const url = learners_api + "/get";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord.user_id },
                header: { Chabi },
              });
              let learner = {};
              if (response.status == 200) learner = response.data.records[0];
              this.setState({
                coffcanvas: true,
                selectedRecord: { ...selectedRecord, learner },
              });
            }}
            className="btn btn-outline-primary btn-sm mb-1"
          >
            Details
          </span>
		  <br/>
      {
      permission.childModule &&   permission.childModule?.paymentHistory ? 
      (selectedRecord.isfullPayment == 2 || selectedRecord.payment_status == 0 || selectedRecord.orderHistory.length > 0) ? 
		  <span onClick={ async () => {
                  this.loadSubTableRecords(selectedRecord._id)
                  this.setState({
                    poffcanvas: true,
                    selectedRecord: { ...selectedRecord, pendingAmt: pendingAmt },
                    request : { order_primary_id: selectedRecord._id, order_type:"1"}
                  });
                }}
			      className="btn btn-primary btn-sm">
			      Payment History
		  </span>
      : 
      null 
      : null 
      }
		  </>
        );
      },
    });
  }
    const beforeUpload = (file) => {
      console.log("sdds",file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
      <div>
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );

    return (
      <>
        <PageHeading2
          active="Payment Orders"
          page={["Orders", "Payment Orders"]}
        ></PageHeading2>
        <MainArea>
          <Row style={{ marginTop: "20px" }}>
          <Col span={4} style={{marginLeft: 10}}>
              <label>Order Type</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose Order Type"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ orderType: [e.toString()]})}
               options={[{label: "Po Orders", value: 2}, {label: "Online Orders", value:1 }]}
            />
            </Col>
            <Col span={4} style={{marginLeft: 10}}>
              <label>Order Date</label>
              <RangePicker onChange={(e) => this.dateChanger(e)}/>
            </Col>
            <Col span={4} style={{marginLeft: 10}}>
            <label>Search by Payment Ref/Order No.</label>
            <Input placeholder="Search by payment ref and order number" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>
            <Col span={4} style={{marginLeft: 10}}>
              <label>Country</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Country"
              //  defaultValue={[""]}
               onChange={(e) => this.countryHandler(e)}
               options={countryList.map( rec => {return { label:rec.country_name , value:rec.isoCode }})}
            />
            </Col>
            <Col span={4} style={{marginLeft: 10}}>
              <label>State</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose State"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ stateId: e})}
               options={stateList.map( rec => {return { label:rec.name , value:rec._id }})}
            />
            </Col>
         
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()} style={{ marginTop: "21px" }}>
                Submit
              </button>
            </Col>
          </Row>
         
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>

        <CustomModal
          key={request._id}
          status={paymentCmodel}
          title="Add Payment History"
          close={this.close_payment_modal}
          className="drawer-form-location"
        >
          <FormContainer>
          <Col span={24}>
            <label>Upload screenshot</label>
          <Upload
              maxCount={1}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={this.fileUpload}
            >
              {this.state.request.image ? (
                <img
                  src={this.state.request.image}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            </Col>
                  <Col span={24}>
                  <FormDateGroup
                      id="paymentDate"
                      name="paymentDate"
                      label="Payment Date"
                      placeholder="Payment Date"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                 
                  <Col span={24}>
                    <FormInputGroup
                      allowClear
                      id="paymentAmt"
                      name="paymentAmt"
                      label="Payment Amount"
                      placeholder="Payment Amount"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={24}>
                    <FormInputGroup
                      allowClear
                      id="transactionId"
                      name="transactionId"
                      label="Transaction Id"
                      placeholder="Transaction Id"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <FormButton
                     submit={() => this.createPaymentOrder()}
                     cancel={this.close_payment_modal}
            />
                  </FormContainer>
               
          </CustomModal>


		{poffcanvas ? (
          <CustomOffCanvas
            key={"m"+selectedRecord._id}
            status={poffcanvas}
            placement={"right"}
            title="Payment History"
            close={() => {
              this.setState({ poffcanvas: false, selectedRecord: {} });
            }}
            className="drawer-form-order"
          >
             
            <div className="row mb-3">
              <div className="col-12 col-sm-12 col-md-12 text-end">
              {selectedRecord.pendingAmt > 0 ?
               <>
                <p className="text-danger">Pending Amount : {selectedRecord.pendingAmt}</p>
                <a href="#" className="btn btn-sm btn-primary" onClick={() => this.setState({paymentCmodel: true})}>Add New</a>
                </>
                : <p className="text-success">All payment due cleared</p> }
                </div>
            </div> 
		  <div className="ant-table">
        <table className="table table-bordered table-hover">
        <thead>
          <tr className="table-light">
            <th>S.N.</th>
            <th>Image</th>   
            <th>Payment Date</th>
            <th>Transaction No</th>
            <th>Amount</th>
           
          </tr>
          </thead>
          <tbody>
            {!CDMI ? (
            <MyLoading />
          ) :this.state.paymentHistory.length >= 1 ? this.state.paymentHistory.map( (rec,index) => {
              return  <tr>
              <td>{index+1}</td>
              <td><Image width={50} src={rec.image} /></td>
              <td>{rec.paymentDate}</td>
              <td>{rec.transactionId}</td>
              <td>{rec.paymentAmt}</td>
            </tr>
          })
          : <MyEmpty /> }
          </tbody>
        </table>
      </div>
		  </CustomOffCanvas>
        ) : (
          ""
        )}
        {coffcanvas ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Order Details"
            close={() => {
              this.setState({ coffcanvas: false, selectedRecord: {} });
            }}
            className="drawer-form-order"
          >
            <div className="p-2">
              <p className="info-main-heading">Payment Order Details</p>
              <div className="row mt-1 mb-1">
                <div className="col-3 text-left mb-3">
                  <span className="d-block info-heading">Booked On</span>
                  <span className="d-block info-value">
                    {selectedRecord.create_at}
                  </span>
                </div>
                <div className="col-3 mb-3">
                  <span className="d-block info-heading">Amount</span>
                  <span className="d-block info-value">
                    {selectedRecord.total_amount}
                  </span>
                </div>
                <div className="col-2 text-left mb-3">
                  <span className="d-block info-heading">Status</span>
                  <span className="d-block info-value">
                    {selectedRecord.status}
                  </span>
                </div>
                <div className="col-2 mb-3">
                  <span className="d-block info-heading">Discount</span>
                  <span className="d-block info-value">
                    {selectedRecord.discount}
                  </span>
                </div>
                <div className="col-2 mb-3">
                  <span className="d-block info-heading">Tax</span>
                  <span className="d-block info-value">
                    {selectedRecord.tax}
                  </span>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6 mb-3">
                  <span className="d-block info-heading">
                    Payment Refference
                  </span>
                  <span className="d-block info-value">
                    {selectedRecord.payment_ref}
                  </span>
                </div>
                <div className="col-6 mb-3">
                  <span className="d-block info-heading">
                    Gateway Refference
                  </span>
                  <span className="d-block info-value">
                    {selectedRecord.gatway_ref}
                  </span>
                </div>
              </div>
            </div>
            {selectedRecord.learner ? (
              <div className="p-2 bg-light">
                <p className="info-main-heading">Learner Details</p>
                {[selectedRecord.learner].map((learner) => {
                  const {
                    first_name,
                    last_name,
                    email,
                    organization,
                    country,
                    region,
                    mobile,
                    
                  } = learner;
                  return (
                    <div className="row mb-1">
                      <div className="col-2 text-left mb-3">
                        <span className="d-block info-heading">
                          Learner Name
                        </span>
                        <span className="d-block info-value">
                          {first_name} {` `}
                          {last_name}
                        </span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">Email</span>
                        <span className="d-block info-value">{email}</span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">Mobile</span>
                        <span className="d-block info-value">{mobile}</span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">
                          Organization
                        </span>
                        <span className="d-block info-value">
                          {organization2[organization]}
                        </span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">Country</span>
                        <span className="d-block info-value">
                          {countryListAlpha2[country]}
                        </span>
                      </div>
                      <div className="col-2 text-left mb-3">
                        <span className="d-block info-heading">Region</span>
                        <span className="d-block info-value">
                          {region2[region]}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <div className="p-2">
              <p className="info-main-heading">Training Details</p>
              {selectedRecord.items.map(({ product, course_fees, class_date, matrial_fee_value, matrial_fee_choose, examination_fee, certificate_fees, material_fees,  delivery_method,  languages,  start_date,  start_time,  timezone, }) => {
                
                const {
                  duration,
                  course_name,
                  main_head
                } = product;
                return (
                  <div
                    style={{
                      background: "#ffffff",
                      marginBottom: "20px",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-6 text-left  mb-3">
                        <span className="d-block info-heading">Course</span>
                        <span className="d-block info-value">
                          {main_head}
                        </span>
                      </div>
                      <div className="col-2 text-left mb-3">
                        <span className="d-block info-heading">
                          Course Fees
                        </span>
                        <span className="d-block info-value">
                          {course_fees}
                        </span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">
                          Certificate Fees
                        </span>
                        <span className="d-block info-value">
                          {certificate_fees}
                        </span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">
                          Examination Fees
                        </span>
                        <span className="d-block info-value">
                          {examination_fee}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3 mb-3">
                        <span className="d-block info-heading">
                          Delivery Method
                        </span>
                        <span className="d-block info-value">
                          {delivery_method2[delivery_method]}
                        </span>
                      </div>
                      {delivery_method == "623afef06fbc25650029f46e" ?
                      <div className="col-3 mb-3">
                        <span className="d-block info-heading">
                          Classroom Date
                        </span>
                        <span className="d-block info-value">
                          {class_date}
                        </span>
                      </div> : null }
                      <div className="col-3 mb-3">
                        <span className="d-block info-heading">Languages</span>
                        <span className="d-block info-value">
                          {languages
                            .split(",")
                            .map((lm) => {
                              return language2[lm];
                            })
                            .toString(",")}
                        </span>
                      </div>
                      <div className="col-2 text-left mb-3">
                        <span className="d-block info-heading">Date</span>
                        <span className="d-block info-value">{start_date}</span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">Time</span>
                        <span className="d-block info-value">{start_time}</span>
                      </div>
                      <div className="col-2 mb-3">
                        <span className="d-block info-heading">Timezone</span>
                        <span className="d-block info-value">{timezone}</span>
                      </div>
                    </div>
                    <div className="row">

                    <div className="col-2 text-left mb-3">
                        <span className="d-block info-heading">material type</span>
                        <span className="d-block info-value">{(matrial_fee_value == 1) ? "Hard Copy Material" : "Soft Copy Material"}</span>
                      </div>
                      <div className="col-2 text-left mb-3">
                        <span className="d-block info-heading">material fee</span>
                        <span className="d-block info-value">{matrial_fee_choose}</span>
                      </div>
                      <div className="col-6 mb-3">
                        <span className="d-block info-heading">Address</span>
                        <span className="d-block info-value">{selectedRecord.learner.address}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {selectedRecord.payment_mode == "2" ?
            <div className="p-2">
              <p className="info-main-heading">Po Details</p>
                  <div className="row mt-1 mb-1">
                    <div className="col-3 col-sm-3 col-md-3 mb-3 text-left">
                      <span className="d-block info-heading">Lastest Po Order Pdf</span>
                      <span className="d-block info-value">
                      {selectedRecord.pdf ? <a href={selectedRecord.pdf} target="_blank"><FilePdfOutlined style={{ fontSize: '32px' }}/></a> : "-"}
                      </span>
                    </div>
                  </div>
                  {selectedRecord.pdfAttempt || selectedRecord.pdfAttempt?.length >= 1 ? <p className="info-main-heading">Po History</p> : null }
                  {selectedRecord.pdfAttempt ? selectedRecord.pdfAttempt.map((rec, index) => {
                    return  <>
                        
                  <div className="row mt-1 mb-1">
                  <div className="col-1 col-sm-1 col-md-1 mb-1 text-left">
                      <span className="d-block info-heading">SNO.</span>
                      <span className="d-block info-value">
                      {index+1}
                      </span>
                    </div>

                    <div className="col-3 col-sm-3 col-md-3 mb-3 text-left">
                      <span className="d-block info-heading">Po Order Pdf</span>
                      <span className="d-block info-value">
                      <a href={rec.uploadedFile} target="_blank"><FilePdfOutlined style={{ fontSize: '32px' }}/></a>
                      </span>
                    </div>

                    <div className="col-1 col-sm-1 col-md-1 mb-1 text-left">
                      <span className="d-block info-heading">Attempt</span>
                      <span className="d-block info-value">
                         {rec.attempt}
                      </span>
                    </div>

                    <div className="col-3 col-sm-3 col-md-3 mb-3 text-left">
                      <span className="d-block info-heading">Status</span>
                      <span className="d-block info-value">
                       { rec.approved == 2 ? <span className="text-danger">Rejected</span> : (rec.approved == 1) ? <span className="text-success">Approved</span> : <span className="text-primary">Not Updated Yet</span>}
                      </span>
                    </div>
                    {permission.childModule && permission.childModule?.poPdfAction ? 
                    <div className="col-3 col-sm-3 col-md-3 mb-3 text-left">
                      <span className="d-block info-heading">Action</span>
                      <span className="d-block info-value">
                       { rec.approved == 2 ? (rec.attempt != 2 ? "Another PDF Required" : "Order Rejected Due to PDF") : (rec.approved == 1) ? "-" : <><button className="btn btn-sm btn-success" onClick={() => this.updateStatusPo(selectedRecord._id,rec,1)}>Accept</button> <button className="btn btn-sm btn-danger" onClick={() => this.updateStatusPo(selectedRecord._id,rec,2)}>Reject</button></>}
                      </span>
                    </div> : null }
                  </div>
                     </>
                  }) : null}
            </div> : null }
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment_orders);
