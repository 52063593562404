import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { learner_evaluation_response } from "../../library/learner_evaluation_response_api";

// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import { get_region } from "../server_data/list_data";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class ViewFeedbackForm extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: learner_evaluation_response,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        email: { valMet: vj_val.checkEmail },
        phone: { valMet: vj_val.checkLength, valLen: 1 },
        website: { valMet: vj_val.checkLength, valLen: 1 },
        address: { valMet: vj_val.checkLength, valLen: 1 },
        region: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
    };
  }
 
  componentDidMount = async () => {
    let queryParms = new URLSearchParams(window.location.search);
	let pageId = queryParms.get("formId")
   
    const { doUserLogout, Chabi } = this.state;
    const url = learner_evaluation_response + "getLearnerEvalautionResponse?formId="+pageId;

    const response = await myAxios({
        method: "get",
        url,
        header: { Chabi },
      });

    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
       this.setState({records : data.records})
    } else console.log("Network Error");
    }
  }
  
  render() {
    //console.log("settings State ", this.state);
    let {
      records,
      loginStatus,
   
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
  
    return (
      <>
        <PageHeading2 active="Feedback Form" page={["Feedback Form"]}></PageHeading2>
        <MainArea>
        {records.length > 0 ?
         records.map( _ => {
            return <Row style={{padding:20}}>
            <h5><b>Step {_.order} : {_._id}</b></h5>
            {_.ele.map( __ => {
                return <Col span={24}>
                <p><b>Question:</b> {__.eval_template_question_name}</p>
                <p><b>Answer:</b> {__.eval_template_text_answer}</p>
             </Col>
            })}
            
        </Row>
         })
          : null}
        </MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewFeedbackForm);
