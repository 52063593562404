// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-form .ant-drawer-content-wrapper {
  width: 500px !important;
}
.mobile-offcanvas .ant-drawer-content-wrapper {
  width: 60% !important;
}
.drawer-form-venue .ant-drawer-content-wrapper {
  width: 1200px !important;
}
.drawer-form-banner .ant-drawer-content-wrapper {
  width: 1200px !important;
}
.drawer-form-organization .ant-drawer-content-wrapper {
  width: 1200px !important;
}
.drawer-form-ticket .ant-drawer-content-wrapper {
  width: 800px !important;
}
.drawer-form-ticket .ant-drawer-content-wrapper {
  width: 800px !important;
}
.drawer-form-order .ant-drawer-content-wrapper {
  width: 1200px !important;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/CustomOffCanvas/drawer.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".drawer-form .ant-drawer-content-wrapper {\n  width: 500px !important;\n}\n.mobile-offcanvas .ant-drawer-content-wrapper {\n  width: 60% !important;\n}\n.drawer-form-venue .ant-drawer-content-wrapper {\n  width: 1200px !important;\n}\n.drawer-form-banner .ant-drawer-content-wrapper {\n  width: 1200px !important;\n}\n.drawer-form-organization .ant-drawer-content-wrapper {\n  width: 1200px !important;\n}\n.drawer-form-ticket .ant-drawer-content-wrapper {\n  width: 800px !important;\n}\n.drawer-form-ticket .ant-drawer-content-wrapper {\n  width: 800px !important;\n}\n.drawer-form-order .ant-drawer-content-wrapper {\n  width: 1200px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
