import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col ,Select, DatePicker, Input, Upload  } from "antd";
import { Tabs } from "antd";

import { training_api } from "../../library/training_api";
import { permission_api } from "../../library/permission_api";
import { dashboard_api } from "../../library/dashboard_api";
import { back_api } from "../../library/back_api";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { add_sno, create_object } from "../../library/object_functions";
import { get_data } from "../server_data/get_data";
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import MyLoading from "../part/MyLoading";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

// ===============================================================================
class Training_schedule extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      trainingreviewimport: "",
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
      uploadFileList: [],
      country:[],
      serchTxt: "",
      startDt:"",
      endDt:"",
	    countryList:[],
      stateList:[],
      states:[],
      delivery_method : [],
      region:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      },
      learning_scheme : []
    };
  }

  displayFields = {
    sno: {
      title: "SN",
      width: 30,
      defaultSortOrder: "descend",
      order: 0,
    },
    course_title: {
      title: "Course Name",
       width:200, 
      className: "course_name",
      order: 1,
    },
    regionName: {
      title: "Region",
      width: 80,
      className: "regionName",
      order: 2,
      ellipsis: true,
    },
    start_date: {
      title: "Start Date",
      width: 80,
      className: "start_date",
      order: 3,
      ellipsis: true,
    },
    course_fees: {
      title: "Course Fees",
      width: 100,
      className: "course_fees",
      order: 4,
      ellipsis: true,
    },

    devlieryMethodName: {
      title: "Delivery method",
      width: 100,
      className: "devlieryMethodName",
      order: 5,
      ellipsis: true,
    },
    languages: {
      title: "Language",
      width: 100,
      className: "languages",
      order: 6,
      ellipsis: true,
    },
    TotalOrder: {
      title: "Orders",
      width: 100,
      className: "TotalOrder",
      order: 7,
      ellipsis: true,
      custom : (text,records) => {
        let queryParms = new URLSearchParams(window.location.search);
        let comp = queryParms.get("comp")
        return (comp != undefined || comp == true) ? <>{text}</>: <>
           <p>Total: {text}</p>
           <p>Organization: {records.bulkSize}</p>
           <p>Individual: {records.preSize}</p>
        </>
      }
    },
    ended_on: {
      title: "Status",
      width: 100,
      className: "Status",
      order: 8,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        let currentDt = new Date()
        if(currentDt > new Date(text)){
           return <span className="text-success">Completed</span>
        }else{
          return <span className="text-primary">Not yet completed</span>
        }
        
      }
    }
 
   
    
  }
  componentDidMount = async () => {
    let queryParms = new URLSearchParams(window.location.search);
		let type = queryParms.get("type")
    let comp = queryParms.get("comp")
    let less = queryParms.get("less")

    if(comp == undefined && comp.includes(true, false)){
      alert("somthing wrong ..!!!")
      window.history.back()
      return false;
    }

    const { doUserLogout, Chabi, validation_info } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        // if(loggedUser.roleId){
        //  const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"training";
        //  let modulePermission = await myAxios({
        //    method: "get",
        //    url,
        //    header: { Chabi },
        //  });
        // if(modulePermission.status == 200){
        //      if(modulePermission.data.records.length >= 1){
        //        console.log("ROLE APPLIED")
        //        let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
        //        this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
        //      }else{
        //        console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
        //      }
        // }else{
        //  console.log("PERMISSION API CRASHED")
        // }
        // }
        const response = await get_data({
          others: [
            "learning_scheme",
            "delivery_method",
            "region",
            "course"
          ],
          primary: "",
          Chabi,
        });
  
        if (response.status == 200) {
          const { data } = response;
          if (data.status == 420) this.setLogout();
          else if (data.status == 200) {
            let {
              learning_scheme,
              delivery_method,
              region,
              course,
            } = data;
            let learning_scheme2 = create_object({
              arr: learning_scheme,
              title: "main_head",
            });
            let delivery_method2 = create_object({
              arr: delivery_method,
              title: "title",
            });
            let course2 = create_object({
              arr: course,
              title: "main_head",
            });
            let region2 = create_object({ arr: region, title: "region_name" });
            this.setState({
              learning_scheme,
              learning_scheme2,
              delivery_method,
              delivery_method2,
              course2,
              region,
              region2,
            });
          }
        } else console.log("Network Error");
   
       //=============================================
    
    const url = `${dashboard_api}/trainingschedule`;
    const trainingResponse = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { type: type, comp, less: less == undefined ? 0 : less}
    });
    if(trainingResponse.data.status == 200){
      let { records } = trainingResponse.data
      records = add_sno(records);
    this.setState({ records })
  }
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  
  fiterResult = async () => {
    let queryParms = new URLSearchParams(window.location.search);
		let type = queryParms.get("type")
    let comp = queryParms.get("comp")
    let less = queryParms.get("less")
    if(comp == undefined || comp.includes(true, false)){
      alert("somthing wrong ..!!!")
      window.history.back()
      return false;
    }

    this.setState({ CDM: false });
    let { loggedUser } = this.props
    const {startDt, endDt, filter_scheme, serchTxt, filter_region } = this.state
    const { doUserLogout, Chabi } = this.state;
    const url = `${dashboard_api}/trainingschedule`;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {startDt, endDt, serchTxt,  type: type, filter_scheme, filter_region, comp: Boolean(comp), less: less == undefined ? 0 : less}
    });
    if (response.status == 420) this.setLogout();
    if (response.status == 200) {
     
        let { records } = response.data
        records = add_sno(records);
	    this.setState({ records })
 
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }


  setRequest = (obj) => {
    const { name, value } = obj;
    const { filtered_records, request, validation_info } = this.state;
    if(name == "delivery_method"){
      if(value == "623afef06fbc25650029f46e" || value == "623afee36fbc25650029f46c"){
             this.setState({
               validation_info : { 
                 ...validation_info, 
                 country: { valMet: vj_val.checkLength, valLen: 1 },
                 states: { valMet: vj_val.checkLength, valLen: 1 },
                 location: { valMet: vj_val.checkLength, valLen: 1 },
                 venue: { valMet: vj_val.checkLength, valLen: 1 },
           }
         })
      }else{
        this.setState({ validation_info });
      }

    }
    request[name] = value;
    this.setState({ request });
  };





  render() {
	  const { RangePicker } = DatePicker;
    let queryParms = new URLSearchParams(window.location.search);
		let type = queryParms.get("type")
    let comp = queryParms.get("comp")
    let less = queryParms.get("less")
    let {
      records,
      loginStatus,
      learning_scheme,
      region,
      filtered_records,
      selectedRecord,
      delivery_method,
      coffcanvas,
      CDM,
      region2,
      course2
    } = this.state;

    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    
  
    columns.push({
      title: "Details",
      className: "details",
       width: 80,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <>
        
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              const url = back_api + "/training_details";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord._id },
                header: { Chabi },
              });
              let learner = {};
              let bulk_order = {};
              if (response.status == 200) {
                const { data } = response;
                learner = data.learner;
                bulk_order = data.bulk_order;
              }
              this.setState({
                coffcanvas: true,
                selectedRecord: {
                  ...selectedRecord,
                  extra: { bulk_order, learner },
                },
              });
            }}
            className="btn btn-outline-primary btn-sm"
          >
            Details
          </span> 
        {/* <Link to={`/tranning_learner?training=${btoa(btoa(selectedRecord._id))}&orderType=${btoa(btoa("1"))}`}><button className="btn btn-sm btn-primary mt-1">Learners</button></Link> 
        <Link to={`/tranning_learner?training=${btoa(btoa(selectedRecord._id))}&orderType=${btoa(btoa("2"))}`}><button className="btn btn-sm btn-primary mt-1">Organization</button></Link> */}
          </>
        );
      },
    });
    let breadcumName = ""
    
    if(type == "3" && comp == "false" && less == "0"){
      breadcumName = "Total Public Training Classes Scheduled"
    }else if(type == "3" && comp == "true" && less == "0"){
      breadcumName = "Total Public Training Classes Completed"
    }else if(type == "3" && comp == "false" && less == "1"){
      breadcumName = "Public Training Classes Scheduled below 8"
    }else if(type == "1" && comp == "false" && less == "0"){
      breadcumName = "Total No of Organization classes"
    }else if(type == "2" && comp == "false" && less == "0"){
      breadcumName = "Total No of classes"
    }

    return (
      <>
        <PageHeading2 active={breadcumName} page={[breadcumName]}>
         
        </PageHeading2>
        <MainArea>
          
         <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{marginLeft: 10}}>
              <label>Training Start Date</label>
              <RangePicker onChange={(e) => this.dateChanger(e)} style={{width: 381}}/>
            </Col>
            <Col span={8} style={{marginLeft: 10}}>
            <label>Search by Course Name.</label>
            <Input placeholder="Search by Course Name" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col>
          
            <Col span={6} style={{marginLeft: 10}}>
              <label>Scheme</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose Scheme"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_scheme : e})}
               options={learning_scheme.map(_ => { return { value : _._id, label: _.main_head}})}
            />
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Region</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Region"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_region : e})}
               options={region.map( rec => {return { label:rec.region_name , value:rec._id }})}
            />
            </Col>

        
          {/* end of filter sections */}
          </Row>
          {/* end of filter sections */}
          <Row>
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()}>
                Submit
              </button>
            </Col>
          </Row>


          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}

              // loadData={ this.loadRecord}
            //   removeData={permission.itemRemoved ? this.removeRecord : null}
            //   activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
            {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      course_name,
                      certificate_fees,
                      course_fees,
                      currency,
                      delivery_method,
                      duration,
                      languages,
                      region,
                      material_fees,
                      seats,
                      start_date,
                      start_time,
                      timezone,
                    } = selectedRecord;
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {course2[course_name]}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">Seats</span>
                            <span className="d-block info-value">{seats}</span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Start Date
                            </span>
                            <span className="d-block info-value">
                              {start_date}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Start_time
                            </span>
                            <span className="d-block info-value">
                              {start_time}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Timezone
                            </span>
                            <span className="d-block info-value">
                              {timezone}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">Region</span>
                            <span className="d-block info-value">
                              {region2[region]}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Course_fees
                            </span>
                            <span className="d-block info-value">
                              {course_fees}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Certificate_fees
                            </span>
                            <span className="d-block info-value">
                              {certificate_fees}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">
                              Material_fees
                            </span>
                            <span className="d-block info-value">
                              {material_fees}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Delivery_method
                            </span>
                            <span className="d-block info-value">
                              {delivery_method}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3">
                            <span className="d-block info-heading">
                              Languages
                            </span>
                            <span className="d-block info-value">
                              {languages}
                            </span>
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-3 text-left">
                            <span className="d-block info-heading">
                             Duration
                            </span>
                            <span className="d-block info-value">
                              {duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
            
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
        </MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Training_schedule);
