// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validation-error {
  box-shadow: 0px 0px 5px red;
}
`, "",{"version":3,"sources":["webpack://./src/assets/error.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B","sourcesContent":[".validation-error {\n  box-shadow: 0px 0px 5px red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
