const initialState = { loginStatus: false };
const userReducer = (state = initialState, action) => {
  if (action.type == true) {
    return {
      ...state,
      loginStatus: true,
      Chabi: action.Chabi,
      loggedUser: action.loggedUser,
      role_modules:action.role_modules
    };
  }
  if (action.type == false) {
    return {
      ...state,
      loginStatus: false,
      Chabi: action.Chabi,
      loggedUser: action.loggedUser,
      role_modules:[]
    };
  }
  return state;
};
export default userReducer;
