import React from "react";
import { Row, Col } from "antd";
import footercss from "./footer.module.css";
// ===============================================================================
function Footer(props) {
  return (
    <Row className={`${footercss.footer}`}>
      <Col span={24}>{props.children}</Col>
    </Row>
  );
}
export default Footer;
