import React from "react";
import { Select } from "antd";
const { Option } = Select;
function FilterSelect(props) {
  const {
    name,
    placeholder,
    inputClassName, // css class
    sendValue, //method
    options,
    option_value,
    option_text,
    defaultValue,
  } = props;
  const local_props = {};
  if (defaultValue) {
    local_props["value"] = defaultValue[option_value];
    sendValue({ name, value: local_props["value"] });
  }
  return (
    <Select
      {...local_props}
      allowClear={true}
      className={`${inputClassName} `}
      showSearch
      style={{ width: "100%" }}
      placeholder={placeholder}
      onChange={(value) => {
        sendValue({ name, value });
      }}
    >
      {options.map((ps) => {
        return (
          <Option
            key={ps[option_value]}
            value={ps[option_value]}
            data-extra={JSON.stringify(ps)}
          >
            {ps[option_text]}
          </Option>
        );
      })}
    </Select>
  );
}
export default FilterSelect;
