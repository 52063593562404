import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { learning_scheme_api } from "../../library/learning_scheme_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea/";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable/";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import { get_region } from "../server_data/list_data";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CustomSwitch from "../part/Display/CustomSwitch";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import MyToolTip from "../part/MyToolTip/MyToolTip";
// ===============================================================================
class Learning_scheme extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: learning_scheme_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        main_head: { valMet: vj_val.checkLength, valLen: 1 },
        image: { valMet: vj_val.checkImage },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    _id: {
      title: "SchemaId",
      width: 200,
      defaultSortOrder: "descend",
      order: 1,
    },
    main_head: {
      title: "Main Head",
      width: 300,
      className: "main_head",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 4,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${learning_scheme_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = learning_scheme_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    console.clear()
    //============= define permission =============
    this.getRolePermission("consulting_scheme")
    //=============================================

    const response = await get_data({
      others: ["region"],
      primary: "learning_scheme",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region, records } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // this.setState({ region, region2 });
    // const url = learning_scheme_api;
    // const response = await myAxios({ method: "get", url,    header: { Chabi }, });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addLearning_scheme = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = learning_scheme_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add learning_scheme ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editLearning_scheme = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { _id, image, main_head, region_info } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = learning_scheme_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        region_info,
        image,
        main_head,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["image"] = image;
            rec["main_head"] = main_head;
            rec["region_info"] = region_info;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${learning_scheme_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      region,
      selectedTab,
      permission
    } = this.state;
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    return (
      <>
        <PageHeading2
          active="Learning Scheme"
          page={["Website Administration", "Learning", "Learning Scheme"]}
        >
          {permission.create ? 
          <button
            className="add-btn btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        : null}
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
             <MyDataTable
             rowKey="_id"
             columns={columns}
             dataSource={records}
             loadData={permission.update ? this.loadRecord : null}
             removeData={permission.itemRemoved ? this.removeRecord : null}
             activeData={permission.activeDeactive ? this.activeRecord : null}
             pagination={true}
           />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Learning Scheme"
          close={this.close_modal}
          className="modal-form-learning_scheme"
        >
          <FormContainer>
            <FormImageUploadGroup
              // src={`${page_api}/image/`}
              src={`${PORTAL.api_url}uploaded_images/`}
              id="image"
              name="image"
              label="Learning Scheme Image"
              sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
              imageCount="1"
              data={this.state}
              required
              imageUpload={this.imageUpload}
              changeUploadImageList={this.changeUploadImageList}
            />
            <FormInputGroup
              id="main_head"
              name="main_head"
              label="Main Head"
              placeholder="Main Head"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            {/* ================================== */}
            {region && region.length ? (
              <Tab.Container defaultActiveKey="default">
                <Row className="mt-4">
                  <Col span={5}>
                    <Nav variant="" className="flex-column">
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let active = 0;
                        if (single_region) active = single_region["active"];
                        return (
                          <Row
                            className={`region-nav-link ${
                              r._id == selectedTab ? "region-active" : ""
                            }`}
                          >
                            <MyToolTip
                              title={active == 1 ? "" : "Enable before select"}
                              placement="left"
                              color="#2F4B70"
                            >
                              <Col span={18}>
                                <Nav.Link
                                  eventKey={r.region_name}
                                  disabled={active == 1 ? false : true}
                                  onClick={() => {
                                    this.setState({ selectedTab: r._id });
                                  }}
                                >
                                  {r.region_name}
                                </Nav.Link>
                              </Col>
                            </MyToolTip>
                            <Col span={6}>
                              <CustomSwitch
                                key={active}
                                activeData={(active) => {
                                  this.set_region_request({
                                    name: "active",
                                    value: +active,
                                    _id: r._id,
                                  });
                                }}
                                active={active}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col span={19} className="border pb-2">
                    <Tab.Content>
                      <Tab.Pane eventKey="default">
                        <MyEmpty message="Region Not Selected" />
                      </Tab.Pane>
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let scheme = "";
                        let short_description = "";
                        let active = false;
                        if (single_region) {
                          scheme = single_region["scheme"];
                          short_description =
                            single_region["short_description"];
                          active = single_region["active"];
                        }
                        return (
                          <Tab.Pane eventKey={r.region_name}>
                            <Row className="mt-2">
                              <Col
                                span={20}
                                className="text-center font-weight-bold"
                              >
                                Region : {r.region_name}
                              </Col>
                              <Col span={4}>
                                <button
                                  className="add-btn"
                                  onClick={() => {
                                    const region_info2 = {};
                                    region.forEach((r2) => {
                                      region_info2[r2._id] = {
                                        scheme,
                                        short_description,
                                        active,
                                      };
                                    });
                                    this.setState({
                                      request: {
                                        ...request,
                                        region_info: region_info2,
                                      },
                                    });
                                  }}
                                  // disabled={!active}
                                >
                                  Copy To All
                                </button>
                              </Col>
                            </Row>
                            <FormInputGroup
                              id="scheme"
                              name="scheme"
                              label="Scheme"
                              placeholder="Scheme"
                              data={this.state}
                              required
                              value={scheme}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                            <FormTextareaGroup
                              id="short_description"
                              name="short_description"
                              label="Short Description"
                              placeholder="Short Description"
                              data={this.state}
                              required
                              value={short_description}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              ""
            )}
            {/* ================================== */}
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editLearning_scheme();
                else this.addLearning_scheme();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Learning_scheme);
