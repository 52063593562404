import React from "react";
// ===============================================================================
function FormLabel(props) {
  const { label, id, labelClassName, style } = props;
  return (
    <label
      htmlFor={id}
      className={`font-weight-bold ${labelClassName}`}
      style={style}
    >
      {label}
    </label>
  );
}
export default FormLabel;
