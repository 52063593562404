import React from "react";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
function FormTextEditor(props) {
  const {
    name,
    placeholder,
    inputClassName, // css class
    sendValue, //method
    setValidation, // method
    data,
    rows,
    value,
  } = props;
  const { validation, showErr, request, validation_info } = data;
  let valMet;
  let valLen;
  if (validation_info) {
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
    valLen = validation_info[name] ? validation_info[name].valLen : undefined;
  }
  let prevalue = "";
  if (value) prevalue = value;
  else if (request && request[name]) prevalue = request[name];
  const modules = {
    toolbar: [
      ["bold", "italic","underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      [{ header: [1, 2, false] }],
      //   ["link", "image"],
	  ["link", "image", "video"],
      ["clean"],
    ],
  };
  //   const formats = [
  //     "header",
  //     "bold",
  //     "italic",
  //     "underline",
  //     "strike",
  //     "blockquote",
  //     "list",
  //     "bullet",
  //     "indent",
  //     "link",
  //     "image",
  //   ];
  return (
    <>
      <ReactQuill
        // theme="snow"
        modules={modules}
        // formats={formats}
        value={prevalue}
        onChange={(content, delta, source, editor) => {
          const value = content;
          if (valMet) {
            const error = valMet({ value, valLen });
            if (error) setValidation({ name, res: false, msg: error });
            else setValidation({ name, res: true });
          }
          sendValue({ name, value });
        }}
        // onBlur={(range, user, method) => {
        //   console.log("aaaaaa", name);
        //   const value = method.getHTML();
        //   if (valMet) {
        //     const error = valMet({ value, valLen });
        //     if (error) setValidation({ name, res: false, msg: error });
        //     else setValidation({ name, res: true });
        //   }
        //   sendValue({ name, value });
        // }}
        //     className={`${inputClassName} ${
        //       showErr && validation[name] && validation[name].msg
        //         ? "validation-error"
        //         : ""
        //     }`}
      />
    </>
  );
}
export default FormTextEditor;
