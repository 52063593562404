import React from "react";
import { Input } from "antd";
function FormMobile(props) {
  const {
    inputClassName, // css class
    sendValue, //method
    setValidation, // method
    data,
    value,
    onKeyUp,
    onBlur,
  } = props;
  let { name, placeholder } = props;
  if (!name) name = "mobile";
  if (!placeholder) placeholder = "Mobile";
  const { validation, showErr, request, validation_info } = data;
  let valMet;
  if (validation_info)
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
  let prevalue = "";
  if (value) prevalue = value;
  else if (request && request[name]) prevalue = request[name];
  return (
    <Input
      type="number"
      className={`${inputClassName} ${
        showErr && validation[name] && validation[name].msg
          ? "validation-error"
          : ""
      }`}
      value={prevalue}
      placeholder={placeholder}
      onChange={(e) => {
        let value = e.target.value;
        if (valMet) {
          const error = valMet({ value });
          if (error) setValidation({ name, res: false, msg: error });
          else setValidation({ name, res: true });
        }
        sendValue({ name, value });
      }}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
    />
  );
}
export default FormMobile;
