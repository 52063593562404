import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { pre_order_api } from "../../library/pre_order_api";
import { learners_api } from "../../library/learners_api";
import { country_api } from "../../library/country_api";
import { states_api } from "../../library/states_api";
import { payment_api} from '../../library/payment_api';
import { dashboard_api } from "../../library/dashboard_api";
import { permission_api } from "../../library/permission_api";
import { sales_target_api } from "../../library/sales_target_api";
import axios from "axios"
import { Row, Col, Select, DatePicker, Input, message, Upload, Image   } from "antd";
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
import moment from "moment";
// ===============================================================================
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormDateGroup from "../part/Form/FormDateGroup";
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import CustomOffCanvas from "../part/CustomOffCanvas";
import { get_data } from "../server_data/get_data";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { currency_obj } from "../local_data/currency";
import { vj_val } from "../../library/validation_function";
import { PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
// ===============================================================================

class couponRevenue extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: pre_order_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      uploadImageList: [],
      validation: {},
      validation_info: {
        paymentDate: { valMet: vj_val.checkLength, valLen: 1 },
        paymentAmt: { valMet: vj_val.checkLength, valLen: 1 },
        transactionId: { valMet: vj_val.checkLength, valLen: 1 },
      },
      cmodal: false,
      coffcanvas: false,
	    poffcanvas: false,
      selectedRecord: {},
      serchTxt: "",
      startDt:"",
      endDt:"",
      orderType:["1","2"],
      countryList:[],
      stateList:[],
      paymentCmodel:false,
      uploadFileList:"",
      paymentHistory:[],
      CDMI:false,
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      },
      region:[],
      learning_scheme:[],
      dahboardString:"",
      isActiveButton: true
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 20,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },  
    _id: {
      title: "Coupon code",
      width: 250,
      ellipsis: true,
      order: 1,
    },
    totalamountDiscount:{
      title: "Total coupon Amount",
      width: 150,
      className: "revenue",
      order: 2,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        return `${record.currency ? record.currency == "INR" ? <FontAwesomeIcon icon={faIndianRupeeSign} style={{fontWeight:100, fontSize:10}} /> : currency_obj[record.currency].symbol_native : ""} ${text}`
      }
    },
    totalOrder: {
      title: "Total order",
      width: 250,
      ellipsis: true,
      order: 3,
    },

  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({ others: ["region"], primary: "", Chabi});
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region} = data;
        let region2 = create_object({ arr: region, title: "region_name"});
        this.setState({ region, region2});
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
 
  fiterResult = async () => {
    const { Chabi, yearlyYear, filter_region } = this.state;
    let regionId = "", gte = "", lt = ""

    if(filter_region == undefined || filter_region == ""){
        alert("region required..!!")
        return false
      }else{
        regionId = filter_region
      }

    if(yearlyYear != undefined && yearlyYear.length >= 1){
      gte = yearlyYear[0]
      lt = yearlyYear[1]
    }

    this.setState({ CDM: false });
    const url = `${dashboard_api}/couponRevenue?regionId=${regionId}&gte=${gte}&lt=${lt}`;
    const response = await myAxios({
      method: "get",
      url,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
          let {
            records,
            currency
          } = data;
          records = records.map(_ => {
            return {
              _id: _._id,
                totalamountDiscount: _.totalamountDiscount.toFixed(2),
                totalOrder: _.totalOrder.length,
                currency: currency.currency
            }
          })
          console.log(records)
          records = add_sno(records);
          
          this.setState({ records,  isActiveButton: records.length >= 1 ? false : true })
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  exportToExcel = async () => {

    const { Chabi, yearlyYear, filter_region } = this.state;
    let regionId = "", gte = "", lt = ""

    if(filter_region == undefined || filter_region == ""){
        alert("region required..!!")
        return false
      }else{
        regionId = filter_region
      }

    if(yearlyYear != undefined && yearlyYear.length >= 1){
      gte = yearlyYear[0]
      lt = yearlyYear[1]
    }

    this.setState({ CDM: false });

    const urls = `${dashboard_api}/exportCouponRevenueReport?regionId=${regionId}&gte=${gte}&lt=${lt}`;

    const response = await axios({
      url: urls,
      method: "get", // Changed to POST
      responseType: "blob",
      headers: {
        token: Chabi,
        "Content-Type": "application/json", // Added content type for POST request
      },
    });
  
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.download = "schema-revenue.xlsx";
    document.body.appendChild(link);
    link.click();
    // Clean up the URL object when the download is complete
    URL.revokeObjectURL(url);
    this.setState({ CDM: true });
    message.success("report downloaded")
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }

  countryHandler = async (value) => {
    const { request } = this.state;   
      let stateList = []
      let { Chabi , records} = this.state;
      const urlGet = states_api+"dropDown/get?countryCode="+value;
        const countryList = await myAxios({
          method: "get",
          url: urlGet,
          header: { Chabi },
        });
        stateList = countryList.data.records;
        let data = request;
        delete data['states']
        this.setState({ country : value, stateList });
  }


  reloadTable = async () => {
    this.setState({ CDMI: false });
    const { Chabi, serchTxt, startDt, endDt, orderType, country, stateId } = this.state;
 
    const url = `${pre_order_api}`;
    const order = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {serchTxt, startDt, endDt, orderType,  country, stateId, payment_status: 1}
    });

    if (order.status == 200) {
      const { data } = order;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
        } = data;
        records = add_sno(order.data.records);
        this.setState({ records })
      }
    }else console.log("Network Error");
    this.setState({ CDM: true });
  }


  render() {
    const { RangePicker } = DatePicker;
    console.log("Pre Order State ", this.state);
    let {
      records,
      loginStatus,
      filtered_records,
      selectedRecord,
      CDM,
      permission,
      region,
      dahboardString
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    columns.push({
      width: 100,
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return <a href={`/coupon-order-list?regid=${this.state.filter_region}&coupon=${record._id}`}><button className="btn btn-sm btn-primary">Orders</button></a>
      },
    });

    return (
      <>
        <PageHeading2
          active="Scheme Revenue"
          page={["Dahboard", "Scheme Revenue"]}
        ></PageHeading2>
        <MainArea>
        
          <Row style={{ marginTop: "20px", marginBottom: 20}}>
                    <Col span={3} style={{ marginLeft: 10 }}>
		          	   <Select
		          	     allowClear
		          	     style={{width: "100%",}}
		          	     showSearch
		          	     optionFilterProp="children"
		          	     filterOption={(input, option) =>(option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
		          	     placeholder="Choose Region"
		          	     onChange={(e) => this.setState({ filter_region: e })}
		          	     options={region.map((rec) => {
		          	   	return { label: rec.region_name, value: rec._id };
		          	     })}
		          	   />
		            </Col>
					<Col span={6} style={{ marginLeft: 10 }}>
                      <RangePicker 
                      onChange={(e,value) => this.setState({ yearlyYear: value })} 
                      placeholder="Choose Year" style={{ width: 350 }}/>
          </Col>
					<Col className="m-6">
					<button className="btn btn-sm btn-primary" onClick={() => this.fiterResult("filter")}>Submit</button>
					<button className="ml-2 btn-sm btn btn-success" disabled={this.state.isActiveButton} onClick={() => this.exportToExcel("export")}>Export To Excel</button>
                    <button className="ml-2 btn-sm btn btn-warning" disabled={this.state.isActiveButton} onClick={() => window.print()}>Generate Report</button>
                    </Col>

                    {/* end of filter sections */}
                  </Row>
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(couponRevenue);
