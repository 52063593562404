import { location_api } from "../../library/location_api";
import { myAxios } from "../../library/networkFunction";
import { team_group_api } from "../../library/team_group_api";
import { insight_category_api } from "../../library/insight_category_api";
import { consulting_scheme_api } from "../../library/consulting_scheme_api";
import { consulting_type_api } from "../../library/consulting_type_api";
import { consulting_service_type_point_api } from "../../library/consulting_service_type_point_api";
import { auditing_service_type_point_api } from "../../library/auditing_service_type_point_api";
import { auditing_type_api } from "../../library/auditing_type_api";
import { auditing_scheme_api } from "../../library/auditing_scheme_api";
import { learning_scheme_api } from "../../library/learning_scheme_api";
import { region_api } from "../../library/region_api";
import { evaluation_section_api } from "../../library/evaluation_section_api";
import { course_category_api } from "../../library/course_category_api";
import { course_type_api } from "../../library/course_type_api";
import { course_api } from "../../library/course_api";
import { language_api } from "../../library/language_api";
import { organization_api } from "../../library/organization_api";
import { accreditation_api } from "../../library/accreditation_api";
import { delivery_method_api } from "../../library/delivery_method_api";
import { venue_api } from "../../library/venue_api";
import { learners_api } from "../../library/learners_api";
import { subscribe } from "../../library/subscribe_api";
import { landingform_api } from "../../library/landingform_api";
// ========================================================
const get_data = (response) => {
  if (response.status == 200) {
    let { data } = response;
    if (data.status == 200) {
      let { records } = data;
      return records;
    }
  }
  return [];
};
// ========================================================
export const get_learners = async ({ Chabi }) => {
  const response = await myAxios({
    method: "post",
    url: learners_api + "/get_dropdown",
    header: { Chabi },
  });
  return get_data(response);
};
export const get_region = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: region_api + "/get_dropdown",
    header: { Chabi },
  });
  return get_data(response);
};
export const get_accreditation = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: accreditation_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_consulting_type = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: consulting_type_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_consulting_scheme = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: consulting_scheme_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_consulting_service_type_point = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: consulting_service_type_point_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_location = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: location_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_language = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: language_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_team_group = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: team_group_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_insight_category = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: insight_category_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_auditing_service_type_point = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: auditing_service_type_point_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_auditing_type = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: auditing_type_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_auditing_scheme = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: auditing_scheme_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_learning_scheme = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: learning_scheme_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_evaluation_section = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: evaluation_section_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_course_category = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: course_category_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_course = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: course_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_course_type = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: course_type_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_delivery_method = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: delivery_method_api,
    header: { Chabi },
  });
  return get_data(response);
};
export const get_venue = async ({ Chabi }) => {
  const response = await myAxios({
    method: "get",
    url: venue_api,
  });
  return get_data(response);
};
export const get_organization = async ({ Chabi }) => {
  const response = await myAxios({
    method: "post",
    url: organization_api + "/get",
    header: { Chabi },
  });
  return get_data(response);
};
export const get_subscriber = async ({ Chabi }) => {
  return await myAxios({
    method: "get",
    url: subscribe + "/sub_news",
    header: { Chabi },
  });
};
export const get_insightsubscriber = async ({ Chabi }) => {
  return await myAxios({
    method: "get",
    url: subscribe + "/sub_insight",
    header: { Chabi },
  });
  
};
export const get_landingdata = async ({ Chabi }) => {
  return await myAxios({
    method: "get",
    url: landingform_api,
    header: { Chabi },
  });
};