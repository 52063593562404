import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogout } from "../../Action/userAction";
import { PORTAL } from "../../currrent_info";
import { landingform_api } from "../../library/landingform_api";
import { myAxios } from "../../library/networkFunction";
import { message, Row, Col } from "antd";
import { Upload } from 'antd';
import { Checkbox, Radio, Select  } from 'antd';

import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { timezone_array, timezone_obj } from "../local_data/timezones";
const { Option } = Select;

export class PopularCourseLandingForm extends FormParent {
  constructor(props) {
    console.log(
      "🚀 ~ file: PopularCourseLandingForm.js ~ line 11 ~ PopularCourseLandingForm ~ constructor ~ props",
      props.data._id,
    );
    super(props);
    this.state = {
      data: props.data,
      Chabi: props.Chabi,
      id: props.id,
      _id: props._id,
      title: (props.data.title) ? props.data.title : "",
      image: (props.data.image) ? props.data.image : "",
      popular_courses: (props.data.popular_courses) ? props.data.popular_courses : "",
      heading: (props.data.heading) ? props.data.heading : "",
      exam_include: (props.data.exam_include) ? props.data.exam_include : "Yes",
      duration: (props.data.duration) ? props.data.duration : "",
      time_zone: (props.data.time_zone) ? props.data.time_zone : "",
      delivery_method: (props.data.delivery_method) ? props.data.delivery_method : [],
      loading: false,
      imageUrl : (props.data.image) ? `${PORTAL.api_url}uploaded_images/${props.data.image}` : "",
      partner_image: (props.data.partner_image) ? `${PORTAL.api_url}uploaded_images/${props.data.partner_image}` : "",
      image_file_name: (props.data.partner_image) ? props.data.image : "",
      partner_image_file_name: (props.data.partner_image) ? props.data.partner_image : "",
      //   popular_courses_multi_image: [],
    };
    

    this.updateState = this.updateState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleCHangeCheck = this.handleCHangeCheck.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  handleEdit = async (event) => {
    const { Chabi, image, partner_image, _id } = this.state;
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("partner_image", partner_image);
    const landingdata = {
      _id: this.state.data._id,
      title: this.state.title ? this.state.title : this.state.data.title,
      image: this.state.image_file_name,
      popular_courses: this.state.popular_courses
        ? this.state.popular_courses
        : this.state.data.popular_courses,
      partner_image: this.state.partner_image_file_name ? this.state.partner_image_file_name : this.state.data.partner_image,
      heading: this.state.heading
        ? this.state.heading
        : this.state.data.heading,
      exam_include: this.state.exam_include
        ? this.state.exam_include
        : this.state.data.exam_include,
      duration: this.state.duration
        ? this.state.duration
        : this.state.data.duration,
      time_zone: this.state.time_zone
        ? this.state.time_zone
        : this.state.data.time_zone,
      //delivery_method: [],
	  delivery_method: this.state.delivery_method
        ? this.state.delivery_method
        : this.state.data.delivery_method,
      //   popular_courses_multi_image: this.state.popular_courses_multi_image,
    };
   
    const url = landingform_api + "popular_course";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: landingdata,
    });

    // refresh data
    this.props.reloadData();
    message.success("Record Saved");

    if (response.status === 200) {
      this.props.onClose();
    }
  };

  
   handleChangeFiles = (info,name) => {
      this.getBase64(info.file.originFileObj, (url) => {
        (name == "name") ? this.setState({ loading : false, imageUrl : url, image_file_name: info.file.response.records[0].filename}) : this.setState({ loading : false, partner_image : url, partner_image_file_name: info.file.response.records[0].filename})
      });
  }

  handleSubmit = async (event) => {
    const { Chabi, image, partner_image, id } = this.state;
  
    event.preventDefault();
 
    const landingdata = {
      id: id,
      title: this.state.title,
      image: this.state.image_file_name,
      popular_courses: this.state.popular_courses,
      partner_image: this.state.partner_image_file_name,
      heading: this.state.heading,
      exam_include: this.state.exam_include,
      duration: this.state.duration,
      time_zone: this.state.time_zone,
      description: this.state.description,
      delivery_method: this.state.delivery_method
      //   popular_courses_multi_image: this.state.popular_courses_multi_image,
    };
    console.log(landingdata);
    const url = landingform_api + "/popular_course";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: landingdata,
    });
    

    if (response.status === 200) {
      // refrensh data
      this.props.reloadData();
      message.success("Record Saved");
      this.setState({
        data : {"title": "","popular_courses": "","heading": "","exam_include": "","duration": "","time_zone": "","delivery_method": [],"image": "","partner_image": ""},
        title: " ",
        imageUrl: "",
        popular_courses: "",
        partner_image: "",
        heading: "",
        duration: "",
      });
    }
  
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFileChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  handlerSelectChnage = (e) => {
      this.setState({time_zone: e})
  }

  handleCHangeCheck = (e) => {
    //let dataArr = [...this.state.popular_courses_delivery_method];
	let dataArr = [...this.state.delivery_method];
	
    let checkIfValueExist = dataArr.includes(e.target.value);
    if (checkIfValueExist) {
      const newArr = dataArr.filter((object) => {
        return object !== e.target.value;
      });
      this.setState({
        //popular_courses_delivery_method: newArr.join(),
		//delivery_method: newArr.join(),
		delivery_method: newArr,
      });
    } else {
      dataArr.push(e.target.value);
      this.setState({
        //popular_courses_delivery_method: dataArr.join(),
		//delivery_method: dataArr.join(),
		delivery_method: dataArr,
      });
	   
    }
  };

  updateState() {
    // Changing state
    this.setState({
      flag: true,
      count: this.state.count + 1,
      inputs: [...this.state.inputs, {}],
    });
  }

   options = [
    {
      label: 'Virtual',
      value: 'Virtual',
    },
    {
      label: 'Classroom',
      value: 'Classroom',
    },
    {
      label: 'Blended',
      value: 'Blended',
    },
  ];

   radioOptions = [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    }
  ];

  onChangeCheckBox = (checkedValues) => {
    console.log('checked = ', checkedValues);
    this.setState({ delivery_method : checkedValues})
  };

  onChangeRadio = ({ target: { value } }) => {
    this.setState({exam_include : value}) ;
  }
  
  uploadButton = (
    <div>
      {/* {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

   getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  
   beforeUpload = (file) => {
    console.log("called");
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
  
    const isLt2M = file.size / 1024 / 1024 < 2;
  
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
  
    return isJpgOrPng && isLt2M;
  };

  render() {
    return (
      <>
        <section className="wrap bg-light">
          <form
          //   onSubmit={this.handleSubmit}
          >
            <div className="container-fluid">
              <div className="card mb-4">
                <div className="card-header">Most Popular Courses</div>
                <div className="card-body">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="title"
                            placeholder="Type here"
                            // value={this.state.popular_courses_title}
                            value={this.state.title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>Popular Courses</label>
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            name="popular_courses"
                            placeholder="Type here"
                            // value={this.state.popular_courses}
                            value={this.state.popular_courses}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="AddMore-item">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                        <label>
                        Upload Image
                            </label>
                        <Upload
                             name="file"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"name")}
                           >
                             {this.state.imageUrl ? (
                               <img
                                 src={this.state.imageUrl}
                                 alt="avatar"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                           </Upload>
                           </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>
                              Upload Approved Training Partner image
                            </label>
                             <Upload
                             name="file"
                             listType="picture-card"
                             className="avatar-uploader"
                             showUploadList={false}
                             action={`${landingform_api}image_upload`}
                             beforeUpload={this.beforeUpload}
                             onChange={(e) => this.handleChangeFiles(e,"partner_image")}
                           >
                             {this.state.partner_image ? (
                               <img
                                 src={this.state.partner_image}
                                 alt="avatar"
                                 style={{
                                   width: '100%',
                                 }}
                               />
                             ) : (
                               this.uploadButton
                             )}
                           </Upload>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Heading</label>
                            <input
                              type="text"
                              className="form-control form-ctrl"
                              name="heading"
                              placeholder="Type here"
                              value={this.state.heading}
                              onChange={this.handleChange} 
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Exam Included</label>
                            <div className="row">
                            <Radio.Group options={this.radioOptions} onChange={this.onChangeRadio} defaultValue={this.state.exam_include} />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Duration</label>
                            <select
                              name="duration"
                              value={this.state.duration}
                              onChange={this.handleChange}
                              className="form-select form-ctrl"
                              aria-label="Default select example"
                            >
                              <option selected="">Select</option>
                              <option value={1}>1 Day</option>
                              <option value={2}>2 Day</option>
                              <option value={3}>3 Day</option>
                              <option value={4}>4 Day</option>
                              <option value={5}>5 Day</option>
                              <option value={6}>6 Day</option>
                              <option value={7}>7 Day</option>
                              <option value={8}>8 Day</option>
                              <option value={9}>9 Day</option>
                              <option value={10}>10 Day</option>
                              <option value={11}>11 Day</option>
                              <option value={12}>12 Day</option>
                              <option value={13}>13 Day</option>
                              <option value={14}>14 Day</option>
                              <option value={15}>15 Day</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label>Time Zone</label><br />
                         <Select 
                             defaultValue={this.state.time_zone ? this.state.time_zone : ""}
                             showSearch
                             placeholder="Select a time zone" 
                             style={{ width: 430 }} 
                             onChange={this.handlerSelectChnage}>
                           {
                             timezone_array.map( (_) => {
                            
                               return <Option value={_.offset} key={_.name}>{_.full_name}</Option>
                             })
                           }
                        </Select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label>Delivery Methods</label>
                            <div className="row">
                            <Checkbox.Group options={this.options} defaultValue={this.state.delivery_method ? this.state.delivery_method : []} onChange={this.onChangeCheckBox} />
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-danger btn-submit"
                      onClick={
                        this.state.data._id
                          ? this.handleEdit
                          : this.handleSubmit
                      }
                    >
                      SAVE
                    </button>
                    {/* <div
                      id="AddMore"
                      onClick={this.updateState}
                      className="AddMore-btn"
                    >
                      +
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>

        <style jsx global>{`
          @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
          @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
          body {
            font-family: "Open Sans", sans-serif;
            /*font-family: 'Josefin Sans', sans-serif;*/
            color: #323031;
          }
          .media {
          }
          .media .media-left,
          .media .media-body,
          .media .media-right {
            display: table-cell;
            vertical-align: top;
          }
          .media .media-left {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
          }
          .media .media-body {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            width: 10000px;
            popular_bundles_title: "",
            iso: "",
            popular_bundles_courses: "",
            popular_bundles_image: "",
            popular_bundles_description: "",
            about_image: "",
            about_title: "",
            about_description: "",
            about_us_username: "",
            about_us_designation: "",
            about_us_description: "",
            trusted_image: "",
            faq_question: "",
            faq_answer: "",
            footer_contact_phone: "",
            footer_contact_address: "",
            top: 0px;
            z-index: 999;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-name: slideInDown;
            animation-name: slideInDown;
            box-shadow: 0px 6px 5px 0px rgb(0 0 0 / 7%);
          }
          .btn-radius {
            border-radius: 30px;
          }
          .btn-danger {
            background-color: #de1936;
            border-color: #de1936;
            font-size: 14px;
            font-weight: 600;
          }
          .btn-danger:hover {
            background-color: #e10525;
            border-color: #e10525;
          }
          .btn-outline-danger {
            color: #c6001d;
            border-color: #c6001d;
          }
          .btn-outline-danger:hover {
            color: #fff;
            border-color: #c6001d;
            background-color: #e10525;
          }
          .owl-nav {
          }
          .owl-nav button span {
            /*font-size: 38px;
           line-height: 26px;
           font-weight: 500;
           width: 35px;
           height: 35px;
           background: #DE1936;
           border-radius: 50%;
           display: block;
           color: #fff;*/
          }
          .owl-nav button span img {
            width: 34px;
          }
          .owl-nav button span:hover {
            /*border: 1px solid #E93131;
           color: #E93131;*/
          }
          .owl-nav .owl-next,
          .owl-nav .owl-prev {
            position: absolute;
            top: 45%;
            left: -50px;
          }
          .owl-nav .owl-next {
            right: -50px;
            left: initial;
          }
          .owl-nav .owl-prev {
          }
          .list-point {
            margin-bottom: 35px;
          }
          .list-point li:before {
            content: "";
            position: absolute;
            left: 2px;
            top: 11px;
            width: 5px;
            height: 5px;
            background-color: #333;
            border-radius: 50%;
          }
          .list-point li {
            line-height: 22px;
            font-size: 14px;
            padding-left: 25px;
            position: relative;
            font-weight: 400;
            margin-bottom: 10px;
            color: #333;
          }
          p {
            color: #000;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 10px;
          }
          a {
            color: #333;
            text-decoration: none;
          }
          .text-red {
            color: #de1936;
          }

          /*========= Header ==========*/
          header {
            width: 100%;
          }
          .sticky .navbar-light .navbar-brand img {
            max-height: 40px;
          }
          .navbar-light .navbar-brand img {
            max-height: 50px;
          }
          .navbar-expand-lg .navbar-nav .nav-link {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            padding: 10px 25px;
          }
          .navbar-nav .nav-link.topnav_appointment-btn__nrlLl {
            background: #de1936;
            color: #fff !important;
            border-radius: 30px;
            padding: 5px 15px;
            margin-left: 10px;
            margin-top: 5px;
            font-weight: 400;
          }

          /*========= Section ==========*/
          .form-control[type="file"] {
            padding-top: 9px;
          }
          .container-fluid {
            padding-left: 0px;
            padding-right: 0px;
          }
          .wrap {
            position: relative;
          }
          .form-icon,
          .form_icon {
            position: absolute;
            right: 10px;
            bottom: 8px;
            font-size: 18px;
          }
          .form_icon {
            right: inherit;
            left: 10px;
          }
          .form-check-input:checked {
            background-color: #de1936;
            border-color: #de1936;
          }
          .form-box {
            position: relative;
          }
          .form-box .form-group label {
            margin-bottom: 5px;
            font-size: 14px;
          }
          .form-check-group .form-check-inline {
            margin-right: 0px;
            width: 24%;
            margin-bottom: 15px;
            margin-top: 15px;
            display: inline-flex;
            padding-left: 2em;
          }
          .form-check-group .form-check-inline:last-child {
          }
          .form-check-group .form-check-input {
            width: 1.2em;
            height: 1.2em;
            margin-left: -2em;
            border-radius: 0px;
            border: 1px solid #333;
          }
          .form-check-group .form-check label {
            font-size: 14px;
            margin-left: 10px;
          }
          .form-check-group .form-check-input:checked {
            background-color: #000;
            border-color: #000;
          }
          .group-btns {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 20px;
          }
          .group-btns .btn {
            border-radius: 30px;
            margin-right: 10px;
            padding: 10px 35px;
            font-size: 14px;
            font-weight: 600;
          }
          .group-btns .btn:last-child {
            margin-right: 0px;
          }
          .btn-block {
            display: block;
            width: 100%;
          }
          .form-box .form-group {
            margin-bottom: 20px;
            position: relative;
          }
          .form-box .form-ctrl {
            border: 1px solid #d1d1d1;
            font-size: 14px;
            height: 42px;
            background-color: #fff;
            border-radius: 0px;
          }
          .btn-submit {
            padding: 10px 20px;
          }
          .SubscribeArticles {
          }
          .SubscribeArticles .title {
          }
          .SubscribeArticles .form-box .form-ctrl {
            padding-left: 40px;
          }
          .form-box textarea.form-ctrl {
            height: auto;
          }

          /*====================================================
           53. FIRST FOOTER SECTION.
         ====================================================*/
          .first_footer_section {
            padding-bottom: 25px;
          }

          .cpyrgt-wrap {
            position: relative;
            background-color: #323031;
            padding: 15px 0px 5px;
          }
          .cpyrgt-wrap p {
            color: #fff;
            font-size: 12px;
          }
          .cpyrgt-wrap p a {
            color: #ebebeb;
            font-weight: 700;
            text-decoration: underline;
          }
          .cpyrgt-wrap p a:hover {
            color: #fff;
            text-decoration: none;
          }

          /*============================*/
          .card {
            border: none;
            box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 13%);
          }
          .card-header {
            padding: 15px 20px;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            font-size: 18px;
            font-weight: 600;
          }
          .card-body {
            padding: 15px 20px;
          }
          .AddMore-btn {
            margin-bottom: 20px;
            color: #fff;
            font-size: 27px;
            font-weight: 900;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            background-color: #de1936;
            display: block;
            width: 26px;
            text-align: center;
            height: 26px;
            line-height: 26px;
            border-radius: 4px;
            cursor: pointer;
          }
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopularCourseLandingForm);
