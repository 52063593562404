import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { ticket_api } from "../../library/ticket_api";
import { learners_api } from "../../library/learners_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import CustomOffCanvas from "../part/CustomOffCanvas";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Reports extends FormParent {
  textarea = React.createRef(null);
  comment_box = React.createRef(null);
  constructor(props) {
    super(props);
    this.state = {
      page_api: ticket_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    title: {
      title: "Title",
      // width: 300,
      className: "title",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    description: {
      title: "Description",
      // width: 300,
      className: "description",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    type: {
      title: "Type",
      // width: 300,
      className: "type",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    priority: {
      title: "Priority",
      // width: 300,
      className: "priority",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    role: {
      title: "User Type",
      // width: 300,
      className: "role",
      sorter: true,
      ellipsis: true,
      order: 5,
    },
    create_at: {
      title: "Date",
      // width: 300,
      className: "date",
      sorter: true,
      ellipsis: true,
      order: 6,
    },
    status: {
      title: "Status",
      width: 120,
      className: "status",
      sorter: true,
      ellipsis: true,
      order: 6,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      primary: "ticket",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { records } = data;
        records = add_sno(records);
        this.setState({
          records,
        });
      }
    } else console.log("Network Error");
    // const url = ticket_api + "/get";
    // const response = await myAxios({ method: "post", url, header: { Chabi } });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${ticket_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  chat = async () => {
    let { selectedRecord, doUserLogout, Chabi } = this.state;
    const comment = this.textarea.current.value.trim();
    if (!comment.length) return;
    let { _id, chat } = selectedRecord;
    const url = `${ticket_api}/c`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, comment },
    });
    console.log(response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        this.textarea.current.value = "";
        if (chat) chat.push(data.values);
        else chat = [data.values];
        selectedRecord["chat"] = chat;
        this.setState({ selectedRecord });
        setTimeout(() => {
          this.comment_box.current.scrollTop =
            this.comment_box.current.scrollHeight;
        }, 100);
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("Ticket State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      selectedRecord,
      coffcanvas,
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={async () => {
              console.log({ _id: selectedRecord });
              const { Chabi } = this.state;
              const url = learners_api + "/get";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord.create_by },
                header: { Chabi },
              });
              let learner = {};
              if (response.status == 200) learner = response.data.records[0];
              this.setState({
                coffcanvas: true,
                selectedRecord: { ...selectedRecord, learner },
              });
              setTimeout(() => {
                // this.comment_box.current.scrollIntoView();
                // this.comment_box.current.scrollIntoView(false);
                this.comment_box.current.scrollTop =
                  this.comment_box.current.scrollHeight;
              }, 1000);
            }}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    return (
      <>
        <PageHeading2
          active="Reports"
          page={["Reports"]}
        ></PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              // loadData={this.loadRecord}
              // removeData={this.removeRecord}
              // activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        {coffcanvas ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Ticket Details"
            close={() => {
              this.setState({ coffcanvas: false, selectedRecord: {} });
            }}
            className="drawer-form-ticket"
          >
            <div className="p-2">
              <p className="info-main-heading">Learner Details</p>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Name</span>
                  <span className="d-block info-value">
                    {selectedRecord.learner.first_name}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Email</span>
                  <span className="d-block info-value">
                    {selectedRecord.learner.email}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Company</span>
                  <span className="d-block info-value">
                    {selectedRecord.learner.organization}
                  </span>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Country</span>
                  <span className="d-block info-value">
                    {selectedRecord.learner.country}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Mobile</span>
                  <span className="d-block info-value">
                    {selectedRecord.learner.mobile}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Region</span>
                  <span className="d-block info-value">
                    {selectedRecord.learner.region}
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-light p-2 mt-2">
              <p className="info-main-heading ">
                Ticket - ({selectedRecord.status})
              </p>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Title</span>
                  <span className="d-block info-value">
                    {selectedRecord.title}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Type</span>
                  <span className="d-block info-value">
                    {selectedRecord.type}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Priority</span>
                  <span className="d-block info-value">
                    {selectedRecord.priority}
                  </span>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Date</span>
                  <span className="d-block info-value">
                    {selectedRecord.create_at}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">User Type</span>
                  <span className="d-block info-value">
                    {selectedRecord.role}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Description</span>
                  <span className="d-block info-value">
                    {selectedRecord.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="p-2 mt-2 border-bottom">
              <p className="info-main-heading ">Feedback</p>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Feedback</span>
                  <span className="d-block info-value">
                    {selectedRecord.feedback}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Satisfaction</span>
                  <span className="d-block info-value">
                    {selectedRecord.satisfaction}
                  </span>
                </div>
              </div>
            </div>
            <div className="p-2 mt-2">
              <p className="info-main-heading ">Comments</p>
              <div className="row mt-1 mb-1 p-2 bg-light">
                <div
                  className="col-12 text-left p-1"
                  ref={this.comment_box}
                  style={{
                    minHeight: "300px",
                    maxHeight: "350px",
                    background: "#fff",
                    overflowY: "auto",
                  }}
                >
                  {selectedRecord.chat
                    ? selectedRecord.chat.map((c) => {
                        return (
                          <div
                            key={c.create_at}
                            style={{
                              background:
                                c.role == "admin" ? "#ececec" : "#ffc107",
                              float: c.role == "admin" ? "left" : "right",
                              marginTop: "20px",
                              padding: "10px",
                              minHeight: "50px",
                              borderRadius: "10px",
                              width: "80%",
                              ...(c.role == "admin"
                                ? { borderBottomLeftRadius: "unset" }
                                : { borderBottomRightRadius: "unset" }),
                            }}
                          >
                            <div>
                              <span
                                style={{
                                  display: "inline-block",
                                  float: "left",
                                }}
                              >
                                {c.reply_by}
                              </span>
                              <span
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                }}
                              >
                                {c.create_at}
                              </span>
                            </div>
                            <br />
                            <div>{c.comment}</div>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <div className="row mt-2">
                  <div className="col-11 p-0">
                    <textarea
                      ref={this.textarea}
                      style={{
                        minHeight: "75px",
                        width: "100%",
                        padding: "5px",
                        outline: "none",
                      }}
                    ></textarea>
                  </div>
                  <div className="col-1">
                    <a
                      className="btn btn-sm"
                      style={{
                        background: "#c6001d",
                        color: "#ffffff",
                        fontWeight: "bold",
                      }}
                      onClick={this.chat}
                    >
                      Send
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
