import React from "react";
import { Col } from "antd";
function FormValidationError(props) {
  let { data, name, validationMessage, label } = props;
  let validation;
  let showErr;
  if (data) {
    validation = data.validation;
    showErr = data.showErr;
  }
  if (!validationMessage) {
    //if validationMessage not passed ad props create custom
    validationMessage = `Please enter the ${label.toLowerCase()}`;
  }
  return (
    <Col
      span={24}
      className="text-left text-danger"
      style={{ fontSize: "12px", height: "19px" }}
    >
      {/* {showErr && validation && validation[name] && !validation[name].res
        ? validationMessage
        : ""} */}
      {validation && validation[name] && !validation[name].res
        ? validationMessage
        : ""}
    </Col>
  );
}
export default FormValidationError;
