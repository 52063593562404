import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col ,Select, DatePicker, Input, Upload, Tooltip   } from "antd";
import { Tabs } from "antd";

import { training_api } from "../../library/training_api";
import { permission_api } from "../../library/permission_api";
import { dashboard_api } from "../../library/dashboard_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { add_sno, create_object } from "../../library/object_functions";
import { get_data } from "../server_data/get_data";
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import MyLoading from "../part/MyLoading";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormButton from "../part/Form/FormButton";
import { Link } from "react-router-dom";

import {
  InfoCircleOutlined
} from '@ant-design/icons';

const { TabPane } = Tabs;

// ===============================================================================
class Evaluation_result extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      trainingreviewimport: "",
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodali: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
      uploadFileList: [],
      country:[],
      serchTxt: "",
      startDt:"",
      endDt:"",
	    countryList:[],
      stateList:[],
      states:[],
      delivery_method : [],
      region:[],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      },
      rating: "",
      learning_scheme: [],
      filter_scheme: "",
      filter_region: "",
      markresolvedModel: false
    };
  }

  displayFields = {
    sno: {
      title: "SN",
      width: 40,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    trainingName: {
      title: "Course Name",
       width:180, 
      className: "course_name",
      order: 1,
    },
    trainingDate: {
      title: "Start Date",
      width: 80,
      className: "start_date",
      order: 2,
      ellipsis: true,
    },
    learnerName: {
      title: "Learner",
      width: 80,
      className: "start_date",
      order: 3,
      ellipsis: true,
      custom: (text, record) => {
        return <span>{record.learnerName} {record.learnerLastName}</span>
      }
    },
    learnerEmail: {
      title: "Learner Email",
      width: 100,
      className: "learnerEmail",
      order: 4,
      ellipsis: false,
    },
    rating: {
        title: "Rating",
        width: 80,
        className: "rating",
        order: 5,
        ellipsis: true,
      },
      review: {
        title: "Learner Feedback",
        width: 150,
        className: "review",
        order: 6,
        ellipsis: false,
      },
      course_id: {
        title: "resolved comment",
        width: 150,
        className: "review",
        order: 7,
        ellipsis: false,
        custom: (text, record) => {
          if(record.evaluation_query != undefined){
          return (record.evaluation_query.query_status == 3) ?  record.evaluation_query.ticketCloseComment : "-"
          }else{
            return "-"
          }
        }
      },
      learner_id: {
        title: "Query Status",
        width: 150,
        className: "review",
        order: 8,
        ellipsis: true,
        custom: (text, record) => {
          if(record.evaluation_query != undefined){
            return (record.evaluation_query.query_status == 1) ? <span className="text-primary">New</span> : (record.evaluation_query.query_status == 2) ? <span className="text-warning">In Progress</span> : <span className="text-success">Resolved</span>
          }else{
            return "-"
          }
          
        }
      }
      
    }
   
  componentDidMount = async () => {
    const { doUserLogout, Chabi, validation_info } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        let queryParms = new URLSearchParams(window.location.search);
		    let type = queryParms.get("pageFor")
        let searchTxt = queryParms.get("searchTxt")
        // if(loggedUser.roleId){
        //  const url = permission_api+'/role/permission/'+loggedUser.roleId+"/"+"training";
        //  let modulePermission = await myAxios({
        //    method: "get",
        //    url,
        //    header: { Chabi },
        //  });
        // if(modulePermission.status == 200){
        //      if(modulePermission.data.records.length >= 1){
        //        console.log("ROLE APPLIED")
        //        let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
        //        this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
        //      }else{
        //        console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
        //      }
        // }else{
        //  console.log("PERMISSION API CRASHED")
        // }
        // }
   
       //=============================================
       const response = await get_data({
        others: [
          "learning_scheme",
          "delivery_method",
          "region",
        ],
        primary: "",
        Chabi,
      });

      if (response.status == 200) {
        const { data } = response;
        if (data.status == 420) this.setLogout();
        else if (data.status == 200) {
          let {
            learning_scheme,
            delivery_method,
            region,
          } = data;
          let learning_scheme2 = create_object({
            arr: learning_scheme,
            title: "main_head",
          });
          let delivery_method2 = create_object({
            arr: delivery_method,
            title: "title",
          });
          let region2 = create_object({ arr: region, title: "region_name" });
          this.setState({
            learning_scheme,
            learning_scheme2,
            delivery_method,
            delivery_method2,
            region,
            region2,
          });
        }
      } else console.log("Network Error");

    const url = `${dashboard_api}/evalautionResult`;
    const trainingResponse = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { type :  type == undefined || type == 2 ? 1 : 2, trainingId: searchTxt}
    });
    if(trainingResponse.data.status == 200){
        let { records } = trainingResponse.data
        records = add_sno(records);
	    this.setState({ records })
    }
    
    this.setState({ CDM: true });
    this.setState({ CDMI: true });
  };

  
  fiterResult = async () => {
  
    this.setState({ CDM: false });
    let { loggedUser } = this.props
    const {startDt, endDt, rating, serchTxt, filter_scheme, filter_region } = this.state
    const { doUserLogout, Chabi } = this.state;
    let queryParms = new URLSearchParams(window.location.search);
    let type = queryParms.get("pageFor")
    let trainingId = queryParms.get("searchTxt")
   // const url = `${training_api}/training_list/post`;
    // const url = `${training_api}/training_list/post?data_for=${loggedUser.role}&id=${loggedUser.email}`;
    const url = `${dashboard_api}/evalautionResult`;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: {startDt, endDt, rating, serchTxt, filter_scheme, filter_region, type :  type == undefined || type == 2 ? 1 : 2, trainingId: trainingId}
    });
    if (response.status == 420) this.setLogout();
    if (response.status == 200) {
        let { records } = response.data
        records = add_sno(records);
	    this.setState({ records })
    } else console.log("Network Error");
    this.setState({ CDM: true });
 
  }

  dateChanger = e => {
    if(e){
      this.setState({ startDt : e[0].toString(), endDt:e[1].toString() })
    }else{
      this.setState({ startDt : "", endDt:"" })
    }
    
  }


  setRequest = (obj) => {
    const { name, value } = obj;
    const { filtered_records, request, validation_info } = this.state;
    if(name == "delivery_method"){
      if(value == "623afef06fbc25650029f46e" || value == "623afee36fbc25650029f46c"){
             this.setState({
               validation_info : { 
                 ...validation_info, 
                 country: { valMet: vj_val.checkLength, valLen: 1 },
                 states: { valMet: vj_val.checkLength, valLen: 1 },
                 location: { valMet: vj_val.checkLength, valLen: 1 },
                 venue: { valMet: vj_val.checkLength, valLen: 1 },
           }
         })
      }else{
        this.setState({ validation_info });
      }

    }
    request[name] = value;
    this.setState({ request });
  };

  renderMarkAsResolvedPopup = (obj) => {
    this.setState({markresolvedModel: true, selectedRecord : obj})
  }

  updateMarkAsCompleted = async (type,id) => {
    const { Chabi, selectedRecord, request } = this.state;
    let queryParms = new URLSearchParams(window.location.search);
    let page = queryParms.get("pageFor")
    if(type == 2){
    if(request.ticketCloseComment == undefined || request.ticketCloseComment == ""){
      message.error("For mark as resovled comment required..!!");
      return false
    }
  }

    const url = `${dashboard_api}/rating/queries`;
    const evalautionResponse = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: type == 1 ? {status : 2, _id: id} : { ticketCloseComment: request.ticketCloseComment, status: 3, _id: selectedRecord.evaluation_query._id}
    });
    if(evalautionResponse.data.status == 200){
      // after done ticket resolved reload pages
      this.setState({markresolvedModel: false, selectedRecord : {}})
      const url = `${dashboard_api}/evalautionResult`;
      const trainingResponse = await myAxios({
        method: "post",
        url,
        header: { Chabi },
        request: { type :  page == undefined || page == 2 ? 1 : 2}
      });
      if(trainingResponse.data.status == 200){
          let { records } = trainingResponse.data
          records = add_sno(records);
        this.setState({ records })
      }
      
      this.setState({ CDM: true });
      this.setState({ CDMI: true });
    }else{
      message.error(evalautionResponse.data.message)
    }
 
  }

  closeMarkAsResolvedPopup = () => {
    this.setState({markresolvedModel: false, selectedRecord: {}})
  }

  renderInProgress = async (selected) => {
    this.setState({selectedRecord : selected})
    await this.updateMarkAsCompleted(1, selected.evaluation_query._id)
  }




  render() {
	  const { RangePicker } = DatePicker;
    console.log("training State ", this.state);
    let {
      records,
      loginStatus,
      learning_scheme,
      region,
      filtered_records,
      delivery_method,
      CDM,
    } = this.state;

    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    let { loggedUser } = this.props

    columns.push({
      title: "Action",
      className: "action",
      // width: 70,
      dataIndex: "action",
      render: (text, selectedRecord) => {
        let button = ""
        if(selectedRecord.evaluation_query == undefined){
          // button = <Tooltip placement="right" title="Mark as resolved only allow for rating < 4" className="mb-1"><InfoCircleOutlined style={{fontSize: 18, color: '#182e4b'}}/></Tooltip>
       
        }else{
          if(selectedRecord.evaluation_query.query_status == 1){
            button = <button className="btn btn-outline-success btn-sm mb-1"  onClick={() => this.renderInProgress(selectedRecord, 1)}>Mark in Progress</button>
          }else if(selectedRecord.evaluation_query.query_status == 2){
            button = <button className="btn btn-outline-success btn-sm mb-1"  onClick={() => this.renderMarkAsResolvedPopup(selectedRecord)}>Mark as Resolved</button>
          }else{
            button = <span>Resolved</span>
          }
        }
         return <>
           {loggedUser.role == "marketing_and_sales" ? button : null}<br />
           <Link to={`/viewfeedbackform?formId=${selectedRecord._id}`}><button className="btn btn-outline-primary btn-sm mb-1">view feedback form</button></Link>
         </>
      },
    });

    return (
      <>
        <PageHeading2 active="Evalaution Result List" page={["Evalaution Result List"]}>
         
        </PageHeading2>
        <MainArea>
          
         <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{marginLeft: 10}}>
              <label>Training Start Date</label>
              <RangePicker onChange={(e) => this.dateChanger(e)} style={{width: 381}}/>
            </Col>
            {/* <Col span={8} style={{marginLeft: 10}}>
            <label>Search by Course & learner Name</label>
            <Input placeholder="Search by Course & learner Name" onChange={(e) => this.setState({ serchTxt: e.target.value})}/>
            </Col> */}
          {/* end of filter sections */}
          <Col span={6} style={{marginLeft: 10}}>
              <label>Scheme</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose Scheme"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_scheme : e})}
               options={learning_scheme.map(_ => { return { value : _._id, label: _.main_head}})}
            />
            </Col>

            <Col span={6} style={{marginLeft: 10}}>
              <label>Region</label>
            
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               placeholder="Choose Region"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ filter_region : e})}
               options={region.map( rec => {return { label:rec.region_name , value:rec._id }})}
            />
            </Col>

          <Col span={6} style={{marginLeft: 10}}>
              <label>Rating Star</label>
            <Select
               allowClear
               style={{
                 width: '100%',
               }}
               placeholder="Choose Rating"
              //  defaultValue={[""]}
               onChange={(e) => this.setState({ rating : e})}
               options={[
                // { label: "5 Star" , value: "5" }, 
                // { label: "4 Star" , value: "4" },
                { label: "3 Star" , value: "3" },
                { label: "2 Star" , value: "2" },
                { label: "1 Star" , value: "1" }]}
            />
            </Col>

          </Row>
          {/* end of filter sections */}
          <Row>
          <Col span={2} className="m-2">
              <button className="filter-btn btn btn-primary" onClick={() => this.fiterResult()}>
                Submit
              </button>
            </Col>
          </Row>


          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}

              // loadData={ this.loadRecord}
            //   removeData={permission.itemRemoved ? this.removeRecord : null}
            //   activeData={permission.activeDeactive ? this.activeRecord : null}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>

        <CustomModal
          key={"total"}
          status={this.state.markresolvedModel}
          title="Marks As Resolved"
          close={this.closeMarkAsResolvedPopup}
          className="drawer-form-location"
        >
          <FormContainer>
            <p className="text-danger">To marked as resolve need to add comment</p>
            <FormTextareaGroup
				  id="ticketCloseComment"
				  name="ticketCloseComment"
				  label="Comment"
				  sub_label=""
				  placeholder="Enter some comment"
				  data={this.state}
          required
				  sendValue={this.setRequest}
				/>

<FormButton
				  submit={() => this.updateMarkAsCompleted(2)}
				  cancel={this.closeMarkAsResolvedPopup}
				/>
          </FormContainer>
               
          </CustomModal>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Evaluation_result);
