import React from "react";
import { Select } from "antd";
const { Option } = Select;
function FormSelect(props) {
  const {
    name,
    placeholder,
    inputClassName, // css class
    sendValue, //method
    setValidation, // method
    data,
    options,
    option_value,
    option_text,
    defaultValue,
    allowClear,
    value,
    disabled,
  } = props;
  let validation, showErr, request, validation_info;
  if (data) {
    validation = data.validation;
    showErr = data.showErr;
    request = data.request;
    validation_info = data.validation_info;
  }
  let valMet;
  let valLen;

  if (validation_info) {
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
    valLen = validation_info[name] ? validation_info[name].valLen : undefined;
  }
  const local_props = {};
  if (allowClear) local_props["allowClear"] = allowClear;
  let prevalue = "";
  if (value) prevalue = value.split(",");
  else if (request[name]) prevalue = request[name].split(",");
  return (
    <Select
      disabled={disabled}
      {...local_props}
      value={prevalue.length ? prevalue : []}
      mode="multiple"
      className={`${inputClassName} ${
        showErr && validation[name] && validation[name].msg
          ? "validation-error"
          : ""
      }`}
      showSearch
      style={{ width: "100%" }}
      placeholder={placeholder}
      // optionFilterProp="children"
      onChange={(value) => {
        if (value) {
          value = value.toString();
          if (valMet) {
            const error = valMet({ value, valLen });
            if (error) setValidation({ name, res: false, msg: error });
            else setValidation({ name, res: true });
          }
          sendValue({ name, value });
        } else sendValue({ name, value: "" });
      }}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options.map((ps) => {
        return (
          <Option
            key={ps[option_value]}
            value={ps[option_value]}
            data-extra={JSON.stringify(ps)}
          >
            {ps[option_text]}
          </Option>
        );
      })}
    </Select>
  );
}
export default FormSelect;
