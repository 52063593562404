import React from "react";
import { Input } from "antd";
function FormInput(props) {
  const {
    name,
    placeholder,
    type, //input type
    inputClassName, // css class
    sendValue, //method
    setValidation, // method
    data,
    value,
    disabled,
    onKeyUp,
    onBlur,
  } = props;
  let validation, showErr, request, validation_info;
  if (data) {
    validation = data.validation;
    showErr = data.showErr;
    request = data.request;
    validation_info = data.validation_info;
  }
  let valMet;
  let valLen;
  if (validation_info) {
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
    valLen = validation_info[name] ? validation_info[name].valLen : undefined;
  }
  let prevalue = "";
  if (value) prevalue = value;
  else if (request && request[name]) prevalue = request[name];
  return (
    <Input
      type={type ? type : "text"}
      className={`${inputClassName} ${
        showErr && validation[name] && validation[name].msg
          ? "validation-error"
          : ""
      }`}
      value={prevalue}
      placeholder={placeholder}
      onChange={(e) => {
        let value = e.target.value;
        if (valMet) {
          const error = valMet({ value, valLen });
          if (error) setValidation({ name, res: false, msg: error });
          else setValidation({ name, res: true });
        }
        sendValue({ name, value });
      }}
      disabled={disabled}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
    />
  );
}
export default FormInput;
