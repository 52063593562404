import React from "react";
import { Switch } from "antd";
import styles from "./styles.module.css";
function CustomSwitch(props) {
  const { activeData, active } = props;
  return (
    <Switch
      className={styles["custom-switch"]}
      size="small"
      checked={active}
      // defaultChecked={!!active}
      onChange={activeData}
    ></Switch>
  );
}
export default CustomSwitch;
