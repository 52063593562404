import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { evaluation_question_assesment_api } from "../../library/evaluation_question_assesment";
import { permission_api } from "../../library/permission_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { get_region } from "../server_data/list_data";
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import MyLoading from "../part/MyLoading";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import Evaluation_question_option_list from "./Evaluation_question_option_list";
// ===============================================================================
class Evaluation_question_ass extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: evaluation_question_assesment_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        question: { valMet: vj_val.checkLength, valLen: 1 },
        // question_secondary: { valMet: vj_val.checkLength, valLen: 1 },
        evaluation_section: { valMet: vj_val.checkLength, valLen: 1 },
        // evaluation_section_secondary: { valMet: vj_val.checkLength, valLen: 1 },
        text_allowed: { valMet: vj_val.checkLength, valLen: 1 },
        title: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
      permission:{
        create: false, remove: false, upload: false, update: false, read: false, download: false, activeDeactive:false, childModule: undefined
      }
    };
  }

 
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    
    question: {
      title: "Question",
      width: 450,
      className: "question",
      sorter: true,
      // ellipsis: true,
      order: 1,
    },
    evaluation_section: {
      title: "Section",
      width: 150,
      className: "evaluation_section",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    text_allowed: {
      title: "Text Allowed",
      width: 150,
      className: "text_allowed",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    active: {
      title: "Active",
      width: 150,
      className: "active",
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
          if (text)
            return <span className="text-success font-weight-bold">Active</span>;
          return <span className="text-danger font-weight-bold">Deactive</span>;
        },
      order: 4,
    },
  };
  componentDidMount = async () => {
    const { Chabi, id } = this.state;
        //============= define permission =============
        let { loggedUser } = this.props
        if(loggedUser.roleId){
         let modulePermission = await myAxios({
           method: "get",
           url: permission_api+'/role/permission/'+loggedUser.roleId+"/"+"evaluation_questions_ass",
           header: { Chabi },
         });
        if(modulePermission.status == 200){
             if(modulePermission.data.records.length >= 1){
               console.log("ROLE APPLIED")
               let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
               this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
             }else{
               console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
             }
        }else{
         console.log("PERMISSION API CRASHED")
        }
        }
       //=============================================

    const url = evaluation_question_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
    this.setState({ CDM: true});
 
  };

  pageReload = async () => {
    const { Chabi, id } = this.state;
      //============= define permission =============
      let { loggedUser } = this.props
      if(loggedUser.roleId){
       let modulePermission = await myAxios({
         method: "get",
         url: permission_api+'/role/permission/'+loggedUser.roleId+"/"+"evaluation_questions_ass",
         header: { Chabi },
       });
      if(modulePermission.status == 200){
           if(modulePermission.data.records.length >= 1){
             console.log("ROLE APPLIED")
             let { create, remove, upload, update, read, download, activeDeactive, childModule } = modulePermission.data.records[0]
             this.setState({ permission : { create, remove, upload, update, read, download, activeDeactive, childModule }})
           }else{
             console.log("PERMISSION API NOT ALLOWED FOR THIS MODULE")
           }
      }else{
       console.log("PERMISSION API CRASHED")
      }
      }
     //=============================================
    const url = evaluation_question_assesment_api+"get";

    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420) this.setLogout();
     else if (data.status == 200) {
        let {records } = data;
        records = add_sno(records);
        this.setState({records,});
      }
    } else console.log("Network Error");
  }

  addPartner = async () => {

    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = evaluation_question_assesment_api + "create";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });

    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReload()
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };

  editPartner = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    let { _id } = request
    delete request['sno']
    delete request['removed']
    delete request['active']
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = evaluation_question_assesment_api + "update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            // rec["location_name"] = location_name;
            // rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };

  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = evaluation_question_assesment_api +"status";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      this.pageReload()
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      CDM,
      questions,
      permission
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    if(permission.childModule && permission.childModule?.optionList){
    columns.push({
      width: 100,
      title: "Questions Options",
      dataIndex: "questions_options",
      render: (text, record) => {
        console.log(
          "🚀 ~ file: Evaluation_question.js ~ line 190 ~ Evaluation_question ~ render ~ record"
        );
        return (
          <button
          className="btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ questions: record });
              this.setState({ id: record._id });
              this.setState({ _id: record._id });
            }}
          >
            Options
          </button>
        );
      },
    });
  }
 
    return (
      <>
       <PageHeading2 active="Evaluation Questions" page={["Evaluation Questions"]}>
       {permission.create ? 
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> : null }
          </PageHeading2>
        <MainArea>
        {!CDM ? (
            <MyLoading />
          ) :records && records.length && columns && columns.length > 0 ? (
          <MyDataTable
            rowKey="_id"
            columns={columns}
            dataSource={records}
            loadData={permission.update ? this.loadRecord : null}
            removeData={permission.itemRemoved ? this.removeRecord : null}
            activeData={permission.activeDeactive ? this.activeRecord : null}
            pagination={true}
          />
          ): (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Evaluation Questions"
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="question"
              name="question"
              label="Question"
              placeholder="e.g."
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
             <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="question_secondary"
              name="question_secondary"
              label="Question Secondary"
              placeholder="e.g."
              data={this.state}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
             <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="evaluation_section"
              name="evaluation_section"
              label="Section"
              placeholder="e.g."
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
             <div className="col-12 col-sm-6 col-md-6 col-lg-6">
             <FormInputGroup
              id="evaluation_section_secondary"
              name="evaluation_section_secondary"
              label="Section Secondary"
              placeholder="e.g."
              data={this.state}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
 <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormSelectGroup
              id="text_allowed"
              name="text_allowed"
              label="Text Allowed"
              placeholder="Text Allowed"
              data={this.state}required
              options={[{ value: 'Yes' }, {  value: 'No' }]}
              option_value="value"
              option_text="value"
              // valMet={checkUuid}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /></div>
 <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <FormInputGroup
              id="title"
              name="title"
              label="Title"
              placeholder="e.g."
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
</div>
</div>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editPartner();
                else this.addPartner();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>

        </CustomModal>

        
        {questions ? (
          <div className="popularbundle-modal position-fixed top-0 bottom-0 left-0 right-0 d-flex justify-content-center align-items-center">
            <Evaluation_question_option_list
              onClose={() => {
                this.setState({ questions: null,cmodal:false });
              }}
              permission={permission.childModule}
              popularcourse={this.state.popularcourse}
              id={this.state.id}
            />
          </div>
        ) : (
          ""
        )}

        <style jsx global>{`
          .trustedby-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Evaluation_question_ass);
