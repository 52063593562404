import React from "react";
function AddNewImport(props) {
  const { onClick, disabled } = props;
  return (
    <button
	  style={{float: "left"}}
      disabled={disabled}
      className={`add-btn ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      Import Training Review
    </button>
  );
}
export default AddNewImport;
