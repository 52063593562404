import { Modal } from "antd";
import "./styles.css";
function CustomModal(props) {
  const { status, close, title, children, className, wrapClassName } = props;
  return (
    <Modal
      className={`${className} custom-modal`}
      maskClosable={false}
      // wrapClassName={wrapClassName} //working
      title={title}
      visible={status}
      //   onOk={handleOk}
      onCancel={close}
      footer={null}
    >
      {children}
    </Modal>
  );
}
export default CustomModal;
