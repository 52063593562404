import React from "react";
import { Input } from "antd";
function FilterInput(props) {
  const {
    name,
    placeholder,
    type, //input type
    inputClassName, // css class
    sendValue, //method
  } = props;
  return (
    <Input
      type={type ? type : "text"}
      className={inputClassName}
      placeholder={placeholder}
      onChange={(e) => {
        let value = e.target.value;
        sendValue({ name, value });
      }}
    />
  );
}
export default FilterInput;
