import React from "react";
import { Input } from "antd";
function FormPassword(props) {
  const {
    inputClassName, // css class
    sendValue, //method
    setValidation, // method
    data,
    value,
  } = props;
  let { name, placeholder } = props;
  if (!name) name = "password";
  if (!placeholder) placeholder = "Password";
  let validation, showErr, request, validation_info;
  if (data) {
    validation = data.validation;
    showErr = data.showErr;
    request = data.request;
    validation_info = data.validation_info;
  }
  let valMet;
  if (validation_info)
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
  let prevalue = "";
  if (value) prevalue = value;
  else if (request && request[name]) prevalue = request[name];
  return (
    <Input
      type="password"
      className={`${inputClassName} ${
        showErr && validation[name] && validation[name].msg
          ? "validation-error"
          : ""
      }`}
      value={prevalue}
      placeholder={placeholder}
      onChange={(e) => {
        let value = e.target.value;
        if (valMet) {
          const error = valMet({ value });
          if (error) setValidation({ name, res: false, msg: error });
          else setValidation({ name, res: true });
        }
        sendValue({ name, value });
      }}
    />
  );
}
export default FormPassword;
