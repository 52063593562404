import React from "react";
import { useSelector } from "react-redux";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import urls from "../../../urls";
import "./style.css";
function MySider() {
  const location = useLocation();
  const history = useNavigate();
  const loggedUser = useSelector((state) => state.loggedUser);
  const role_modules = useSelector((state) => state.role_modules);
  const { role } = loggedUser;
  const { SubMenu } = Menu;
  const { Sider } = Layout;
  // const [selectedKey, setSelectedKey] = useState([location.pathname]);
  return (
    <Sider className="side">
      <Menu
        mode="inline"
        selectedKeys={["website", "homepage", location.pathname]}
        className="side-menu"
        onSelect={(obj) => {}}
      >
        <>
          <Menu.Item key={urls.DASHBOARD.path}>
            <Link className="link" to={urls.DASHBOARD.path}>
              {urls.DASHBOARD.text}
            </Link>
          </Menu.Item>
    {role == "finance" ? 
          <SubMenu key="Revenue" title="Revenue">
            <Menu.Item key={urls.COURSEWISEREVENUELIST.path}>
              <Link className="link" to={urls.COURSEWISEREVENUELIST.path}>
                {urls.COURSEWISEREVENUELIST.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.SCHEMAWISEREVENUELIST.path}>
              <Link className="link" to={urls.SCHEMAWISEREVENUELIST.path}>
                {urls.SCHEMAWISEREVENUELIST.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.COUPONREVENUELIST.path}>
              <Link className="link" to={urls.COUPONREVENUELIST.path}>
                {urls.COUPONREVENUELIST.text}
              </Link>
            </Menu.Item>
            
          </SubMenu> : null }

          {role_modules.map((module) => {
            if (module.sideBarShow) {
              return (
                <Menu.Item key={`/${module.module.url}`}>
                  <Link className="link" to={`/${module.module.url}`}>
                    {module.module.moduleName}
                  </Link>
                </Menu.Item>
              );
            }
          })}
        </>
      </Menu>
    </Sider>
  );
}
export default MySider;
