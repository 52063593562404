export const add_sno = (obj) => {
  return obj.map((rec, index) => {
    if (rec.sno) {
      rec["sno"] = index + 1;
      return rec;
    }
    return { sno: index + 1, ...rec };
  });
};
// ----------------------------------------------
// ----------------------------------------------
export const create_object = ({ arr, title }) => {
  let obj = {};
  arr.forEach((l) => (obj[l._id] = l[title]));
  return obj;
};
// ----------------------------------------------
// ----------------------------------------------
export const get_value = ({ arr, id, key }) => {
  let value;
  for (let i = 0; i <= arr.length - 1; i++) {
    const { _id } = arr[i];
    if (_id == id) {
      value = arr[i][key];
      break;
    }
  }
  return value;
};
export const get_record = ({ arr, id }) => {
  let record;
  for (let i = 0; i <= arr.length - 1; i++) {
    const { _id } = arr[i];
    if (_id == id) {
      record = arr[i];
      break;
    }
  }
  return record;
};
export const set_order = ({ arr }) => {
  function compare(a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  }
  return arr.sort(compare);
};
