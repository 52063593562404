import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogout } from "../../Action/userAction";
import { PORTAL } from "../../currrent_info";
import { exam_template_option_assesment_api } from "../../library/exam_template_option_assesment_api";
import { vj_val } from "../../library/validation_function";
import { myAxios } from "../../library/networkFunction";
import FormContainer from "../part/Form/FormContainer";
import FormButton from "../part/Form/FormButton";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { message, Row, Col, Input,Select  } from "antd";
const { Option } = Select;



export class Exam_assesment_test_list_form extends FormParent {
  constructor(props) {
    console.log(
      "🚀 ~ file: Exam_assesment_test_list_form.js ~ line 11 ~ ExamAssesmentTestListForm ~ constructor ~ props",
      props,
    );
    super(props);
    this.state = {
      data: props.data,
      Chabi: props.Chabi,
      id: props.id,
      _id: props._id,
      questionList:[],
      request:[],
      test_option : (props.data.test_option) ? props.data.test_option : "",
      min_marks: (props.data.min_marks) ? props.data.min_marks : "",
      max_marks: (props.data.max_marks) ? { value : props.data.max_marks} : "",
      order: (props.data.order) ? { value : props.data.order} : "",
      edit: (props.data.edit) ? props.data.edit : false,
      validationMsg:"",
      test_option_error: false,
      questions: []
    };

  }

  handleEdit = async () => {
   
    const { Chabi, _id, id, test_option } = this.state;
    if(test_option == undefined || test_option == ""){
        this.setState({ test_option_error: true, validationMsg: "Test Name in required"});
        return false;
    }else{
      this.setState({ test_option_error: false, validationMsg: ""});
    }

    const landingdata = {
      _id, exam_id: id, test_option
    };
    const url = exam_template_option_assesment_api + "update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: landingdata,
    });

    // refresh data
    this.props.reloadData();
    message.success("Record Saved");

    if (response.status === 200) {
      this.props.onClose();
    }
  };


  handleSubmit = async () => {
   
    const { Chabi, id, test_option, request,  } = this.state;
    if(test_option == undefined || test_option == ""){
      this.setState({ test_option_error: true, validationMsg: "Test Name in required"});
      return false;
  }else{
    this.setState({ test_option_error: false, validationMsg: ""});
  }
    const dataRequest = {
     exam_id: id, test_option
    };
    
    const url = exam_template_option_assesment_api + "/create";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: dataRequest,
    });
    
    if (response.status === 200) {
      if(response.data.status == 200){
      this.props.reloadData();
      message.success("Record Saved");
      this.props.onClose();
      }else{
        return alert(response.data.message);
      }
    }
  
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount = async () => {
    const { Chabi, id } = this.state;
    const responseQuestion = await myAxios({
        method: "get",
        url:  exam_template_option_assesment_api+"exam_section_list",
        header: { Chabi },
        request: { id },
      });
      if (responseQuestion.status == 200) {
        const { data } = responseQuestion;
        if(data.status == 420) this.setLogout();
       else if (data.status == 200) {
          let {records } = data;
          
          this.setState({questions: records, questionList: records.map( (_) => {
            return <Option value={_._id}>{_.test_name}</Option>
          })});
        }
      } else console.log("Network Error");

    this.setState({
      ...this.props.data,
    });
  }

  onChangeHandler = (obj, name) => {
    this.setState({ [name] : obj})
  }

  set_filter = async (obj) => {
    this.state.questions.map( (objs) => {
       if(objs._id.toString() == obj.toString()){
        this.setState({ min_marks: objs.min_marks, max_marks: objs.max_marks, order: objs.order, test_option: obj})
       }
    })
  
  }

  render() {
    let {
      edit,
      validationMsg,
      test_option_error
    } = this.state;
    return (
      <>
        <section className="wrap bg-light">
            <div className="container-fluid">
              <div className="card mb-4">
              
                <div className="card-body">
                <FormContainer>
            
            <Row className={`mt-2`}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
               <Col span={24} className="text-left">
                  <label htmlFor="section">Test Name*</label>
               </Col>
               <Col span={24} className="text-left ">
            <Select className="" defaultValue={this.state.test_option} style={{ }} onChange={(obj) => this.set_filter(obj)}>
                  {this.state.questionList}
             </Select>
             </Col>
             { (test_option_error == true) ? <span className="text-danger">{validationMsg}</span> : null}
             </div>
            
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                <Col span={24} className="text-left">
                  <label htmlFor="min_marks">Min Rerquired Marks</label>
               </Col>
               <Col span={24} className="text-left ">
                   <Input type="text" className="" id="min_marks"  name="min_marks" value={this.state.min_marks} placeholder="" disabled />
               </Col>
              </div>
           
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
               <Col span={24} className="text-left">
                  <label htmlFor="max_marks">Max Marks</label>
               </Col>
             
               <Col span={24} className="text-left ">
                   <Input type="text" className="" id="max_marks"  name="max_marks" value={this.state.max_marks} placeholder="" disabled />
               </Col>
               </div>
           
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
               <Col span={24} className="text-left">
                  <label htmlFor="order">Sort Order</label>
               </Col>
               <Col span={24} className="text-left ">
                   <Input type="text" className="" id="order"  name="order" value={this.state.order} placeholder="" disabled />
               </Col>
               </div>
            </Row>

            <Row>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.handleEdit();
                else this.handleSubmit();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
            </div>
            </Row>
          </FormContainer>
                </div>
              </div>
            </div>
        
        </section>

        <style jsx global>{`
          @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
          @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
          body {
            font-family: "Open Sans", sans-serif;
            /*font-family: 'Josefin Sans', sans-serif;*/
            color: #323031;
          }
          .media {
          }
          .media .media-left,
          .media .media-body,
          .media .media-right {
            display: table-cell;
            vertical-align: top;
          }
          .media .media-left {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
          }
          .media .media-body {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            width: 10000px;
            popular_bundles_title: "",
            iso: "",
            popular_bundles_courses: "",
            popular_bundles_image: "",
            popular_bundles_description: "",
            about_image: "",
            about_title: "",
            about_description: "",
            about_us_username: "",
            about_us_designation: "",
            about_us_description: "",
            trusted_image: "",
            faq_question: "",
            faq_answer: "",
            footer_contact_phone: "",
            footer_contact_address: "",
            top: 0px;
            z-index: 999;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-name: slideInDown;
            animation-name: slideInDown;
            box-shadow: 0px 6px 5px 0px rgb(0 0 0 / 7%);
          }
          .ant-select { width: 100%;}
          .btn-radius {
            border-radius: 30px;
          }
          .btn-danger {
            background-color: #de1936;
            border-color: #de1936;
            font-size: 14px;
            font-weight: 600;
          }
          .btn-danger:hover {
            background-color: #e10525;
            border-color: #e10525;
          }
          .btn-outline-danger {
            color: #c6001d;
            border-color: #c6001d;
          }
          .btn-outline-danger:hover {
            color: #fff;
            border-color: #c6001d;
            background-color: #e10525;
          }
          .owl-nav {
          }
          .owl-nav button span {
            /*font-size: 38px;
           line-height: 26px;
           font-weight: 500;
           width: 35px;
           height: 35px;
           background: #DE1936;
           border-radius: 50%;
           display: block;
           color: #fff;*/
          }
          .owl-nav button span img {
            width: 34px;
          }
          .owl-nav button span:hover {
            /*border: 1px solid #E93131;
           color: #E93131;*/
          }
          .owl-nav .owl-next,
          .owl-nav .owl-prev {
            position: absolute;
            top: 45%;
            left: -50px;
          }
          .owl-nav .owl-next {
            right: -50px;
            left: initial;
          }
          .owl-nav .owl-prev {
          }
          .list-point {
            margin-bottom: 35px;
          }
          .list-point li:before {
            content: "";
            position: absolute;
            left: 2px;
            top: 11px;
            width: 5px;
            height: 5px;
            background-color: #333;
            border-radius: 50%;
          }
          .list-point li {
            line-height: 22px;
            font-size: 14px;
            padding-left: 25px;
            position: relative;
            font-weight: 400;
            margin-bottom: 10px;
            color: #333;
          }
          p {
            color: #000;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 10px;
          }
          a {
            color: #333;
            text-decoration: none;
          }
          .text-red {
            color: #de1936;
          }

          /*========= Header ==========*/
          header {
            width: 100%;
          }
          .sticky .navbar-light .navbar-brand img {
            max-height: 40px;
          }
          .navbar-light .navbar-brand img {
            max-height: 50px;
          }
          .navbar-expand-lg .navbar-nav .nav-link {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            padding: 10px 25px;
          }
          .navbar-nav .nav-link.topnav_appointment-btn__nrlLl {
            background: #de1936;
            color: #fff !important;
            border-radius: 30px;
            padding: 5px 15px;
            margin-left: 10px;
            margin-top: 5px;
            font-weight: 400;
          }

          /*========= Section ==========*/
          .form-control[type="file"] {
            padding-top: 9px;
          }
          .container-fluid {
            padding-left: 0px;
            padding-right: 0px;
          }
          .wrap {
            position: relative;
          }
          .form-icon,
          .form_icon {
            position: absolute;
            right: 10px;
            bottom: 8px;
            font-size: 18px;
          }
          .form_icon {
            right: inherit;
            left: 10px;
          }
          .form-check-input:checked {
            background-color: #de1936;
            border-color: #de1936;
          }
          .form-box {
            position: relative;
          }
          .form-box .form-group label {
            margin-bottom: 5px;
            font-size: 14px;
          }
          .form-check-group .form-check-inline {
            margin-right: 0px;
            width: 24%;
            margin-bottom: 15px;
            margin-top: 15px;
            display: inline-flex;
            padding-left: 2em;
          }
          .form-check-group .form-check-inline:last-child {
          }
          .form-check-group .form-check-input {
            width: 1.2em;
            height: 1.2em;
            margin-left: -2em;
            border-radius: 0px;
            border: 1px solid #333;
          }
          .form-check-group .form-check label {
            font-size: 14px;
            margin-left: 10px;
          }
          .form-check-group .form-check-input:checked {
            background-color: #000;
            border-color: #000;
          }
          .group-btns {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 20px;
          }
          .group-btns .btn {
            border-radius: 30px;
            margin-right: 10px;
            padding: 10px 35px;
            font-size: 14px;
            font-weight: 600;
          }
          .group-btns .btn:last-child {
            margin-right: 0px;
          }
          .btn-block {
            display: block;
            width: 100%;
          }
          .form-box .form-group {
            margin-bottom: 20px;
            position: relative;
          }
          .form-box .form-ctrl {
            border: 1px solid #d1d1d1;
            font-size: 14px;
            height: 42px;
            background-color: #fff;
            border-radius: 0px;
          }
          .btn-submit {
            padding: 10px 20px;
          }
          .SubscribeArticles {
          }
          .SubscribeArticles .title {
          }
          .SubscribeArticles .form-box .form-ctrl {
            padding-left: 40px;
          }
          .form-box textarea.form-ctrl {
            height: auto;
          }

          /*====================================================
           53. FIRST FOOTER SECTION.
         ====================================================*/
          .first_footer_section {
            padding-bottom: 25px;
          }

          .cpyrgt-wrap {
            position: relative;
            background-color: #323031;
            padding: 15px 0px 5px;
          }
          .cpyrgt-wrap p {
            color: #fff;
            font-size: 12px;
          }
          .cpyrgt-wrap p a {
            color: #ebebeb;
            font-weight: 700;
            text-decoration: underline;
          }
          .cpyrgt-wrap p a:hover {
            color: #fff;
            text-decoration: none;
          }

          /*============================*/
          .card {
            border: none;
            box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 13%);
          }
          .card-header {
            padding: 15px 20px;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            font-size: 18px;
            font-weight: 600;
          }
          .card-body {
            padding: 15px 20px;
          }
          .AddMore-btn {
            margin-bottom: 20px;
            color: #fff;
            font-size: 27px;
            font-weight: 900;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            background-color: #de1936;
            display: block;
            width: 26px;
            text-align: center;
            height: 26px;
            line-height: 26px;
            border-radius: 4px;
            cursor: pointer;
          }
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exam_assesment_test_list_form);
