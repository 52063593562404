import React from "react";
import { Checkbox } from "antd";
function FormCheckboxSingle(props) {
  const {
    name,
    sendValue, //method
    setValidation, // method
    data,
    options,
    option_value,
    option_text,
    defaultValue,
    disabled,
  } = props;
  let request, validation_info;
  if (data) {
    request = data.request;
    validation_info = data.validation_info;
  }
  let valMet;
  if (validation_info)
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
  const local_props = {};
  if (request && request[name])
    local_props["checked"] = request[name].length ? true : false;
  else if (defaultValue) {
    local_props["checked"] = defaultValue.length ? true : false;
    // sendValue({ name, value: defaultValue });
    if (valMet) setValidation({ name, res: true });
  }
  return (
    <Checkbox
      disabled={disabled}
      className="ml-2"
      onChange={(e) => {
        let value = "";
        if (e.target.checked) value = options[option_value];
        if (valMet) {
          const error = valMet(value);
          if (error) setValidation({ name, res: false, msg: error });
          else setValidation({ name, res: true });
        }
        sendValue({ name, value });
      }}
      {...local_props}
    >
      {options[option_text]}
    </Checkbox>
  );
}
export default FormCheckboxSingle;
