// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp */



/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  /* ::-webkit-scrollbar-track {
    background: #f1f1f1;
  } */
  
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: #343A40;
  }
   */
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

`, "",{"version":3,"sources":["webpack://./src/assets/scrollbar.css"],"names":[],"mappings":"AAAA,mEAAmE;;;;AAInE,UAAU;AACV;IACI,UAAU;EACZ;;EAEA,UAAU;EACV;;KAEG;;EAEH,WAAW;EACX;;;IAGE;EACF,oBAAoB;EACpB;;KAEG","sourcesContent":["/* https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp */\n\n\n\n/* width */\n::-webkit-scrollbar {\n    width: 5px;\n  }\n  \n  /* Track */\n  /* ::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  } */\n  \n  /* Handle */\n  /* ::-webkit-scrollbar-thumb {\n    background: #343A40;\n  }\n   */\n  /* Handle on hover */\n  /* ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  } */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
