import { myAxios } from "../../library/networkFunction";
import { back_api } from "../../library/back_api";
// ========================================================
export const get_data = async ({ primary, Chabi, others, extra }) => {
  return await myAxios({
    method: "post",
    url: `${back_api}/page`,
    header: { Chabi },
    request: { primary, others, extra },
  });
};
