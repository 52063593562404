// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_main-area__z4Soz {
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/component/part/MainArea/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".main-area {\n  height: auto;\n  background-color: #fff;\n  border-radius: 5px;\n  margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-area": `styles_main-area__z4Soz`
};
export default ___CSS_LOADER_EXPORT___;
