import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import styles from "./styles.module.css";
function PageHeading2(props) {
  const { page, active } = props;
  return (
    <Row>
      <Col span={16} className={`${styles.col}`}>
        <Breadcrumb className={styles["b"]}>
          {/* <Breadcrumb.Item className="prev-link">
            <Link to="/">Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
            Library
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{page}</Breadcrumb.Item> */}
          {page.map((name, index) => {
            return (
              <Breadcrumb.Item
                key={index}
                className={`${styles["b-item"]} ${
                  page.length - 1 == index ? styles["active"] : ""
                  // page == active ? styles["active"] : ""
                }`}
                // active={page == active ? true : false}
              >
                {name}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </Col>
      <Col className={`${styles.col} text-right pr-3`} span={8}>
        {props.children}
      </Col>
    </Row>
  );
}
export default PageHeading2;
