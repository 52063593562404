import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import MyToolTip from "../MyToolTip/MyToolTip";
// ===============================================================================
function StatusIcon(props) {
  const { activeData, active } = props;
  return (
    <span className="  icon-c">
      <MyToolTip
        title={
          active == "1"
            ? "It Is Active, Deactive Now!"
            : "It Is Deactive, Active This!"
        }
      >
        <FontAwesomeIcon
          icon={active == "1" ? faThumbsUp : faThumbsDown}
          onClick={() => {
            activeData();
          }}
          style={{ color: active == "1" ? "green" : "gray" }}
        />
      </MyToolTip>
    </span>
  );
}
export default StatusIcon;
