import React from "react";
import { TimePicker } from "antd";
import moment from "moment";
function FormTime(props) {
  const {
    name,
    placeholder,
    inputClassName, // css class
    sendValue, //method
    setValidation,
    data,
    showCurrentTime, //display cuurent time if true
  } = props;
  const { validation, showErr, request, validation_info } = data;
  let valMet;
  let valLen;
  if (validation_info) {
    valMet = validation_info[name] ? validation_info[name].valMet : undefined;
    valLen = validation_info[name] ? validation_info[name].valLen : undefined;
  }
  const format = "HH:mm";
  let defaultValue = "";
  const local_props = {};
  if (request[name]) defaultValue = moment(request[name], format);
  else if (showCurrentTime) defaultValue = moment(moment(), format);
  return (
    <>
      <TimePicker
        key={request[name]}
        defaultValue={defaultValue}
        {...local_props}
        onChange={(time, timeString) => {
          const value = timeString;
          if (valMet) {
            const error = valMet({ value, valLen });
            if (error) setValidation({ name, res: false, msg: error });
            else setValidation({ name, res: true });
          }
          sendValue({ name, value });
        }}
        className={`${inputClassName} ${
          showErr && validation[name] && validation[name].msg
            ? "validation-error"
            : ""
        }`}
        style={{ width: "100%" }}
        use12Hours
        format={format}
        placeholder={placeholder}
      />
    </>
  );
}
export default FormTime;
