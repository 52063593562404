import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
// ===============================================================================

import { lca_template_assesment_option_list_api } from "../../library/lca_template_assesment_option_list_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import SeoBox from "./SeoBox";
import { get_data } from "../server_data/get_data";
import AddNew from "../part/AddNew";
import MyLoading from "../part/MyLoading";
import Landingform from "./Landingform";
import { get_landingdata, Set_Bannerdata } from "../server_data/list_data";

import { myAxios } from "../../library/networkFunction";
import { PORTAL } from "../../currrent_info";
import PopularCourseLandingForm from "./PopularCourseLandingForm";
import Lca_template_assesment_option_form from "./Lca_template_assesment_option_form";
import axios from "axios";

// ===============================================================================
class Lca_template_assesment_option_list extends FormParent {
  constructor(props) {
    console.log(
      "🚀 ~ file: Evaluation_template_question.js ~ line 32 ~ Evaluation_template_question ~ constructor ~ props",
      props.popularcourse
    );
    super(props);
    this.state = {
      page_api: lca_template_assesment_option_list_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      seomodal: false,
      popularcourse: props.popularcourse,
      popularbundle: false,
      trustedby: false,
      faq: false,
      inputs: [],
      activeKey: "1",
      id: props.id,
      _id: "",
    };
    this.updateState = this.updateState.bind(this);
    this.close = this.close.bind(this);
   
  }
 
  close() {
    console.log("popup closing")
    const request = {};
    this.setState(
      { 
        data: {},
         request, 
         competency_unit: "",
        examination_topic: "",
        topic_ref: "",
        option_sequence: "",
        option_day:"", 
        cmodal:false });
          }

  updateState() {
    this.setState({
      flag: true,
      count: this.state.count + 1,
      inputs: [...this.state.inputs, {}],
    });
  }
  displayFields = {
 
  };

  addQuestion = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi, template, evaluation_question } = this.state;
    let { records } = this.state;
    const url = lca_template_assesment_option_list_api + "create";
    console.log(request);
    let { evaluation_text_allowed } = request;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { evaluation_text_allowed, evaluation_question, evaluation_template_id: template._id},
    });

    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        this.pageReload()
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };


  activeRecord = async (record) => {
    const active = +!parseInt(record["active"]);
    this.fastEdit({ field: "active", value: active, record });
  };
  editRecord = async (_id) => {
    let { records, request } = this.state;
    const record = records.filter((r) => r._id == _id)[0]
    console.log("🚀 ~ files:Evaluation_template_question.js ~ line 107 ~ Evaluation_template_question ~ editRecord= ~ record", record)
    request = { ...request, ...record,  edit: true, cmodal: true,};
    console.log("🚀 ~ files: Evaluation_template_question.js ~ line 107 ~ Evaluation_template_question ~ editRecord= ~ request", request)
    this.setState({
        edit: true,
        cmodal: true,
    });
    this.setState({request :request})
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${lca_template_assesment_option_list_api}/status`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };

  tableReload = async () => {
    const { Chabi, id } = this.state;
    const url = lca_template_assesment_option_list_api + "/get/"+id;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    let { records } = response.data;
    records = add_sno(records);

    this.setState({
      records,
    });
  }
  
  componentDidMount = async () => {
    const { Chabi, id } = this.state;
    const url = lca_template_assesment_option_list_api+"get/"+id;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { id },
    });
    let { records } = response.data;
    records = add_sno(records);

    this.setState({
      records,
    });
  };
  render() {
    console.log("training State ", this.state);
    let {
      records,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      region,
      seomodal,
      popularcourse,
      popularbundle,
      trustedby,
      faq,
      course,
      CDM,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      width: 100,
      title: "SN",
      dataIndex: "sno",
    });

    columns.push({
      width: 150,
      title: "Competency Unit",
      dataIndex: "competencyUnitName",
    });

    columns.push({
      width: 150,
      title: "Exercise/Examination Topic",
      dataIndex: "examination_topic",
    });
    columns.push({
      width: 150,
      title: "Topic Ref.",
      dataIndex: "topic_ref",
    });
    columns.push({
      width: 150,
      title: "Sequence",
      dataIndex: "option_sequence",
    });
    columns.push({
      width: 150,
      title: "	Day",
      dataIndex: "option_day",
    });

    let filter_region = [];
    let selected_region_info;
    if (request.course_name) {
      selected_region_info = course.filter((ls) => {
        return ls._id == request.course_name;
      })[0]["region_info"];
      const selected_region = Object.keys(selected_region_info).filter(
        (sri) => selected_region_info[sri].active
      );
      filter_region = region.filter((r1) => {
        return selected_region.includes(r1._id);
      });
    }
   
    return (
      <>
        {/* {popularcourse ? <> */}
        <MainArea>
          <PageHeading2
            active="Unit List"
            page={["Unit List"]}
          >
            {this.props.permission.unitListAdd ? 
            <AddNew
              // disabled={!CDM}
              onClick={() => {
                this.setState({ cmodal: !cmodal, edit: false, request: {} });
              }}
            />
            : null }
            <button
              className="modalclose"
              onClick={() => {
               
                this.props.onClose();
                // this.setState({ popularcourse: false });
              }}
            >
              X
            </button>
          </PageHeading2>
          <MyDataTable

            rowKey="_id"
            columns={columns}
            dataSource={records}
            // loadData={this.loadRecord}
            loadData={this.props.permission.unitListEdit ? this.loadRecord : null}
            removeData={this.props.permission.unitListRemoved ? this.removeRecord : null}
            activeData={this.props.permission.unitListActiveDeactive ? this.activeRecord : null}
            pagination={true}
          />
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Unit"
          close={this.close}
          className="modal-form-course"
        >
          {/* <Landingform /> */}
          <Lca_template_assesment_option_form
          onClose={() => {
            this.setState({ data: {},request:{}, competency_unit: "",
            examination_topic: "",
            topic_ref: "",
            option_sequence: "",
            option_day:"", cmodal:false });
          }}
            data={this.state.request}
            id={this.state.id}
            _id={this.state._id}
            Chabi={this.state.Chabi}
            reloadData={() => this.tableReload()}
          />
        </CustomModal>

        <style jsx global>{`
        .styles_b__acmil ol {padding-left: 0px;}
        .text-right.pr-3{padding-right: 0rem!important;}
          .faq-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
          .popularbundle-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
        `}</style>
        {/* </>:<></>} */}

        {/* <MainArea>
          <PageHeading2 active="Landing Page" page={["Landing Page"]}>
            <AddNew
              // disabled={!CDM}
              onClick={() => {
                this.setState({ cmodal: !cmodal, edit: false, request: {} });
              }}
            />
            <button className="modalclose" onClick={this.close}>
              X
            </button>
          </PageHeading2>
          <MyDataTable
            rowKey="_id"
            columns={columns}
            dataSource={records}
            loadData={this.loadRecord}
            removeData={this.removeRecord}
            activeData={this.activeRecord}
            pagination={true}
          />
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Landing Page"
          close={this.close_modal}
          className="modal-form-course"
        >
          <Landingform />
        </CustomModal>

      

        <style jsx global>{`
          .faq-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
          .popularbundle-modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
          }
          .modalclose {
            border: none;
            background: transparent;
          }
        `}</style> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Lca_template_assesment_option_list);
