import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import MyToolTip from "../MyToolTip/MyToolTip";
// ===============================================================================
function DeleteIcon(props) {
  const { removeData } = props;
  return (
    <span className="  icon-c">
      <MyToolTip title="Remove this record">
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => {
            removeData();
          }}
        />
      </MyToolTip>
    </span>
  );
}
export default DeleteIcon;
