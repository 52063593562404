// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-nav-link {
  border: 1px solid #dee2e6;
  /* border-right: unset; */
  margin-top: 10px;
  margin-right: 10px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  height: 40px;
}
.region-nav-link a {
  color: rgba(0, 0, 0, 0.85);
}
.region-nav-link:hover a {
  color: #c6001d;
}
/* .region-nav-link.active {
  background: unset !important;
} */
/* .region-enable {
  background: #c6001d;
} */
.region-nav-link.region-active {
  z-index: 999;
  background: #fff;
  font-weight: bold;
  border-right: unset;
  margin-right: -1px;
}

.region-nav-link.region-active a {
  color: #c6001d;
}
`, "",{"version":3,"sources":["webpack://./src/assets/region_panel.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,+BAA+B;EAC/B,4BAA4B;EAC5B,YAAY;AACd;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,cAAc;AAChB;AACA;;GAEG;AACH;;GAEG;AACH;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".region-nav-link {\n  border: 1px solid #dee2e6;\n  /* border-right: unset; */\n  margin-top: 10px;\n  margin-right: 10px;\n  border-bottom-left-radius: 20px;\n  border-top-left-radius: 20px;\n  height: 40px;\n}\n.region-nav-link a {\n  color: rgba(0, 0, 0, 0.85);\n}\n.region-nav-link:hover a {\n  color: #c6001d;\n}\n/* .region-nav-link.active {\n  background: unset !important;\n} */\n/* .region-enable {\n  background: #c6001d;\n} */\n.region-nav-link.region-active {\n  z-index: 999;\n  background: #fff;\n  font-weight: bold;\n  border-right: unset;\n  margin-right: -1px;\n}\n\n.region-nav-link.region-active a {\n  color: #c6001d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
